import React from "react";
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'

const CustomerImgModal = ({ selectedImage, imageType, onClose }) => {
  return (
    <Dialog open={!!selectedImage} onClose={onClose}>
      <DialogTitle>Image Preview - {imageType}</DialogTitle>
      <DialogContent>
        {selectedImage ? (
          <img
            src={selectedImage}
            alt={imageType}
            style={{ width: "100%", height: "auto", maxWidth: "500px" }}
          />
        ) : (
          "No Image Available"
        )}
      </DialogContent>
    </Dialog>
  );
};

export default CustomerImgModal;
