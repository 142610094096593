import React, { useState, useEffect } from "react";
import axios from "axios";
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import CardMedia from "@mui/material/CardMedia";
import IconButton from '@mui/material/IconButton'
import Swal from "sweetalert2";
import ArchiveIcon from "@mui/icons-material/Archive";
import { getAuthCredentials } from "../../utils/auth";

const MenuItems = ({
  menuItems,
  ingredients,
  onArchive,
  onEdit,
  onEditOptions,
  onEditTags,
}) => {
  const [menuWithImages, setMenuWithImages] = useState([]);
  const { token } = getAuthCredentials();

  useEffect(() => {
    const loadImages = async () => {
      const updatedMenuItems = await Promise.all(
        menuItems.map(async (item) => {
          const imageUrl = item.menuImage || null;

          return { ...item, image: imageUrl };
        })
      );

      const sortedMenuItems = updatedMenuItems.sort((a, b) =>
        (a.category || "").localeCompare(b.category || "")
      );

      setMenuWithImages(sortedMenuItems);
    };

    loadImages();
  }, [menuItems]);

  const findIngredient = (ingredientName) => {
    const ingredient = ingredients.find(
      (ingredient) => ingredient.inventoryName === ingredientName
    );

    if (!ingredient) return null;

    const validInstances = ingredient.instances.filter(
      (instance) =>
        instance.currentAmount > 0 && new Date(instance.expiryDate) > new Date()
    );

    const totalCurrentAmount = validInstances.reduce(
      (sum, instance) => sum + instance.currentAmount,
      0
    );

    return {
      ...ingredient,
      totalCurrentAmount,
    };
  };

  const categorizedMenuItems = menuWithImages.reduce((acc, item) => {
    const category = item.categoryName || "Uncategorized";
    if (!acc[category]) {
      acc[category] = [];
    }
    acc[category].push(item);
    return acc;
  }, {});

  const onFetchAllergens = async (menuItem) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/v1/api/menu/fetch-allergens/${menuItem._id}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      const allergens = response.data.allergens.join(", ");

      Swal.fire({
        title: "Allergens",
        text: allergens,
        icon: "info",
        confirmButtonText: "OK",
      });
    } catch (error) {
      console.error("Error fetching allergens:", error);
      Swal.fire({
        title: "Error",
        text: "Failed to fetch allergens. Please try again.",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };

  return (
    <Box>
      {Object.keys(categorizedMenuItems).map((category, categoryIndex) => (
        <Box key={categoryIndex} sx={{ marginBottom: 4 }}>
          <Typography variant="h5" gutterBottom>
            {category}
          </Typography>
          <Grid container spacing={3}>
            {categorizedMenuItems[category].map((item, index) => {
              const hasMissingIngredients = item.menuIngredients?.some(
                (ingredient) => !findIngredient(ingredient.ingredientName)
              );

              const status =
                item.options && item.options.length > 0
                  ? "Options Added"
                  : "Options Not Added";
              const statusColor =
                status === "Options Added" ? "#B0EACD" : "#FFB6C1";

              return (
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <Card
                    sx={{
                      backgroundColor: hasMissingIngredients
                        ? "#FFCCCB"
                        : "#ADD8E6",
                      height: "100%",
                      display: "flex",
                      flexDirection: "row",
                      position: "relative",
                      cursor: "pointer",
                      transition: "transform 0.2s ease-in-out",
                      "&:hover": {
                        transform: "scale(1.05)",
                      },
                    }}
                    onClick={() => onEdit(item)}
                  >
                    {/* Status Label */}
                    <Box
                      sx={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        backgroundColor: statusColor,
                        color: "#000",
                        padding: "4px 8px",
                        fontSize: "0.9em",
                        fontWeight: "bold",
                        borderRadius: "0 0 4px 0",
                      }}
                    >
                      {status}
                    </Box>

                    {item.image && (
                      <CardMedia
                        component="img"
                        image={item.image}
                        alt={item.name}
                        sx={{
                          width: 150,
                          height: "100%",
                          objectFit: "cover",
                          borderRadius: "4px 0 0 4px",
                        }}
                      />
                    )}

                    <CardContent sx={{ flex: 1 }}>
                      <Typography variant="h6" gutterBottom>
                        {item.menuName}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        Ingredients:
                      </Typography>
                      <ul>
                        {item.menuIngredients?.map((ingredient, idx) => {
                          const ingredientDetails = findIngredient(
                            ingredient.ingredientName
                          );

                          const isOutOfStock =
                            !ingredientDetails ||
                            ingredientDetails.totalCurrentAmount === 0;

                          return (
                            <li
                              key={idx}
                              style={{
                                textDecoration: isOutOfStock
                                  ? "line-through"
                                  : "none",
                                color: isOutOfStock ? "red" : "inherit",
                              }}
                            >
                              {ingredient.ingredientName} - {ingredient.amount}{" "}
                              {ingredientDetails?.unitOfMeasurement || ""}
                              {isOutOfStock && (
                                <span style={{ fontWeight: "bold" }}>
                                  {" "}
                                  (Out of Stock)
                                </span>
                              )}
                            </li>
                          );
                        })}
                      </ul>
                      <Typography variant="body1">
                        Price: PHP {item.basePrice}
                      </Typography>
                      {item.menuTags?.some((tag) => tag.startsWith("spicy:")) &&
                        (() => {
                          const spicyTag = item.menuTags.find((tag) =>
                            tag.startsWith("spicy:")
                          );
                          const spicyName = spicyTag?.split("spicy: ")[1] || "";
                          const chiliCount =
                            spicyName === "mild"
                              ? 1
                              : spicyName === "pinoy"
                              ? 2
                              : spicyName === "thai"
                              ? 3
                              : 0;

                          return (
                            <Typography variant="body1">
                              Spicy: {spicyName} {"🌶️".repeat(chiliCount)}
                            </Typography>
                          );
                        })()}
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          marginTop: 2,
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexWrap: "wrap",
                            gap: 1,
                            marginBottom: 2,
                          }}
                        >
                          <Button
                            onClick={(e) => {
                              e.stopPropagation();
                              onEditOptions(item);
                            }}
                            variant="contained"
                            color="primary"
                            size="small"
                            sx={{ marginRight: 1 }}
                          >
                            Options
                          </Button>
                          <Button
                            onClick={(e) => {
                              e.stopPropagation();
                              onEditTags(item);
                            }}
                            variant="contained"
                            color="secondary"
                            size="small"
                          >
                            Tags
                          </Button>
                          <Button
                            onClick={(e) => {
                              e.stopPropagation();
                              onEdit(item);
                            }}
                            variant="contained"
                            color="success"
                            size="small"
                          >
                            Edit
                          </Button>
                          <Button
                            onClick={(e) => {
                              e.stopPropagation();
                              onFetchAllergens(item);
                            }}
                            variant="contained"
                            color="warning"
                            size="small"
                          >
                            Allergens
                          </Button>
                        </Box>

                        <IconButton
                          onClick={(e) => {
                            e.stopPropagation();
                            onArchive(item);
                          }}
                          color="secondary"
                          size="small"
                        >
                          <ArchiveIcon />
                        </IconButton>
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
              );
            })}
          </Grid>
        </Box>
      ))}
    </Box>
  );
};

export default MenuItems;
