import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import axios from "axios";
import { getAuthCredentials } from "../../../utils/auth";

const EJournalModal = ({ open, onClose }) => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [loading, setLoading] = useState(false);
  const { token } = getAuthCredentials();

  const handleGenerateReport = async () => {
    if (!startDate || !endDate) {
      alert("Please select a date.");
      return;
    }

    setLoading(true);
    try {
      const startUTC = new Date(startDate + "T00:00:00.000Z").toISOString();
      const endUTC = new Date(endDate + "T23:59:59.999Z").toISOString();

      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/v1/api/reports/e-journal`,
        { startDate: startUTC, endDate: endUTC },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        const blob = new Blob([response.data], {
          type: "text/plain",
        });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `E-Journal_${startDate}_to_${endDate}.txt`;
        link.click();
        alert("E-Journal generated successfully.");
      }
    } catch (error) {
      console.error("Error generating E-Journal Report:", error);
      alert("Failed to generate the E-Journal. Please try again.");
    } finally {
      setLoading(false);
      onClose();
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Generate E-Journal</DialogTitle>
      <DialogContent>
        <Typography variant="body1" sx={{ marginBottom: 2 }}>
          Please select the date range for the E-Journal Report:
        </Typography>
        <TextField
          label="Start Date"
          type="date"
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
          fullWidth
          InputLabelProps={{ shrink: true }}
          sx={{ mb: 2 }}
        />
        <TextField
          label="End Date"
          type="date"
          value={endDate}
          onChange={(e) => setEndDate(e.target.value)}
          fullWidth
          InputLabelProps={{ shrink: true }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} disabled={loading}>
          Cancel
        </Button>
        <Button
          onClick={handleGenerateReport}
          variant="contained"
          color="primary"
          disabled={loading}
        >
          {loading ? "Generating..." : "Generate"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EJournalModal;
