import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import SecureLS from "secure-ls";
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CssBaseline from '@mui/material/CssBaseline';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import { Visibility, VisibilityOff } from "@mui/icons-material";
import axios from "axios";
import Swal from "sweetalert2";
import Loading from "../../theme/Loading";

const ls = new SecureLS({ encodingType: "aes" });

const Signup = () => {
  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    companyname: "",
    password: "",
    confirmPassword: "",
  });
  const [isFormValid, setIsFormValid] = useState(false);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [isPasswordFocused, setIsPasswordFocused] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));

    let updatedErrors = { ...errors };

    if (name === "email") {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      updatedErrors.email = emailPattern.test(value)
        ? ""
        : "Please enter a valid email address.";
    }

    if (name === "password") {
      updatedErrors.passwordLength = value.length >= 8;
      updatedErrors.passwordUppercase = /[A-Z]/.test(value);
      updatedErrors.passwordLowercase = /[a-z]/.test(value);
      updatedErrors.passwordNumber = /[0-9]/.test(value);
      updatedErrors.passwordSpecial = /[!@#$%^&*(),.?":{}|<>]/.test(value);
    }

    if (name === "confirmPassword") {
      updatedErrors.confirmPassword =
        value === formData.password ? "" : "Passwords do not match.";
    }

    setErrors(updatedErrors);

    setIsFormValid(
      Object.values({ ...formData, [name]: value }).every(
        (field) => field.trim() !== ""
      ) &&
        updatedErrors.email === "" &&
        updatedErrors.passwordLength &&
        updatedErrors.passwordUppercase &&
        updatedErrors.passwordLowercase &&
        updatedErrors.passwordNumber &&
        updatedErrors.passwordSpecial &&
        updatedErrors.confirmPassword === ""
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const {
      firstname,
      lastname,
      email,
      companyname,
      password,
      confirmPassword,
    } = formData;

    const newErrors = {};
    if (!firstname) newErrors.firstname = "First name is required.";
    if (!lastname) newErrors.lastname = "Last name is required.";
    if (!email) newErrors.email = "Email is required.";
    if (!companyname) newErrors.companyname = "Company name is required.";
    if (!password) newErrors.password = "Password is required.";
    if (!confirmPassword)
      newErrors.confirmPassword = "Confirm password is required.";

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    if (password !== confirmPassword) {
      setErrors({ ...newErrors, confirmPassword: "Passwords do not match." });
      return;
    }

    try {
      setLoading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/v1/api/auth/signup`,
        { firstname, lastname, email, companyname, password, role: "user" }
      );

      Swal.fire({
        icon: "success",
        title: "Signup Successful",
        text: response.data.message,
      });

      navigate(response.data.redirectUrl);
    } catch (error) {
      if (error.response) {
        if (error.response.data.errors) {
          const messages = error.response.data.errors
            .map((err) => err.msg)
            .join("\n");
          Swal.fire({
            icon: "error",
            title: "Signup Failed",
            text: messages,
          });
        } else if (error.response.data.message) {
          Swal.fire({
            icon: "error",
            title: "Signup Failed",
            text: error.response.data.message,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Signup Failed",
            text: "An unexpected error occurred. Please try again.",
          });
        }
      } else {
        Swal.fire({
          icon: "error",
          title: "Signup Failed",
          text: "Network error. Please check your connection and try again.",
        });
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <CssBaseline />
      {loading && <Loading />}
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            bgcolor: "#f0f0f0",
            padding: 4,
            borderRadius: 2,
            boxShadow: 3,
          }}
        >
          <Typography
            component="h1"
            variant="h5"
            sx={{ color: "primary.main" }}
          >
            POS Signup
          </Typography>
          <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              label="First Name"
              name="firstname"
              value={formData.firstname}
              onChange={handleChange}
              error={!!errors.firstname}
              helperText={errors.firstname}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              label="Last Name"
              name="lastname"
              value={formData.lastname}
              onChange={handleChange}
              error={!!errors.lastname}
              helperText={errors.lastname}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              label="Email"
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              error={!!errors.email}
              helperText={errors.email}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              label="Company Name"
              name="companyname"
              value={formData.companyname}
              onChange={handleChange}
              error={!!errors.companyname}
              helperText={errors.companyname}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              label="Password"
              type={showPassword ? "text" : "password"}
              name="password"
              value={formData.password}
              onChange={handleChange}
              onFocus={() => setIsPasswordFocused(true)}
              onBlur={() => setIsPasswordFocused(false)}
              error={!!errors.password}
              helperText={errors.password}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {isPasswordFocused && (
              <Box sx={{ mt: 2 }}>
                <Typography variant="body2" color="textSecondary">
                  Password must include:
                </Typography>
                <Typography
                  variant="body2"
                  color={errors.passwordLength ? "success.main" : "error.main"}
                >
                  - At least 8 characters
                </Typography>
                <Typography
                  variant="body2"
                  color={
                    errors.passwordUppercase ? "success.main" : "error.main"
                  }
                >
                  - At least one uppercase letter
                </Typography>
                <Typography
                  variant="body2"
                  color={
                    errors.passwordLowercase ? "success.main" : "error.main"
                  }
                >
                  - At least one lowercase letter
                </Typography>
                <Typography
                  variant="body2"
                  color={errors.passwordNumber ? "success.main" : "error.main"}
                >
                  - At least one number
                </Typography>
                <Typography
                  variant="body2"
                  color={errors.passwordSpecial ? "success.main" : "error.main"}
                >
                  - At least one special character
                </Typography>
              </Box>
            )}
            <TextField
              margin="normal"
              required
              fullWidth
              label="Confirm Password"
              type={showConfirmPassword ? "text" : "password"}
              name="confirmPassword"
              value={formData.confirmPassword}
              onChange={handleChange}
              error={!!errors.confirmPassword}
              helperText={errors.confirmPassword}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() =>
                        setShowConfirmPassword(!showConfirmPassword)
                      }
                      edge="end"
                    >
                      {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              disabled={!isFormValid}
            >
              Signup
            </Button>
            <Typography variant="body2" align="center" sx={{ mt: 2 }}>
              <Button
                onClick={() => navigate("/login")}
                variant="text"
                sx={{ textTransform: "none" }}
              >
                Already Registered? Login
              </Button>
            </Typography>
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default Signup;
