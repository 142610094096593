import React, { useState, useRef, useEffect, useCallback } from "react";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import { QRCodeSVG } from "qrcode.react";
import { generateQrPdf } from "./KioskBuildQR";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import PaymentIcon from "@mui/icons-material/Payment";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import Swal from "sweetalert2";
import axios from "axios";
import orders from "../../../api/orders";

const PaymentDialog = ({
  open,
  onClose,
  onCancel,
  cartItems,
  tableNumber,
  posId,
  discount = discount || 0,
  discountType = discountType || "None",
  paymentMethod: initialPaymentMethod = "cash",
  voucherCode = voucherCode || " ",
  voucherDesc = voucherDesc || "",
  finalTotal = 0,
  translate,
  onClearCart,
  customerId,
  orderId,
}) => {
  const [activeStep, setActiveStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [orderNumber, setOrderNumber] = useState("");
  const [receiptNumber, setReceiptNumber] = useState("");
  const [error, setError] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState(initialPaymentMethod);
  const [paymentError, setPaymentError] = useState(null);
  const qrRef = useRef(null);
  // Paymongo checkout specific states
  const [paymongoCheckoutUrl, setPaymongoCheckoutUrl] = useState(null);
  const [paymentStatus, setPaymentStatus] = useState(null); // 'pending', 'success', 'failed'
  const [paymentReference, setPaymentReference] = useState(null);
  const [checkingPaymentStatus, setCheckingPaymentStatus] = useState(false);
  const [paymentSessionId, setPaymentSessionId] = useState(null);
  const [isProcessingOrder, setIsProcessingOrder] = useState(false);
  const [errorDialogOpen, setErrorDialogOpen] = useState(false);
  const [errorDialogMessage, setErrorDialogMessage] = useState("");
  const [errorDialogDetails, setErrorDialogDetails] = useState([]);
  // Minimum amount for e-cash payments (in PHP)
  const MINIMUM_ECASH_AMOUNT = 100;

  const steps = [
    translate
      ? translate("Confirm Order", {
        en: "Confirm Order",
        zh: "确认订单",
        th: "ยืนยันคำสั่งซื้อ",
        tl: "Kumpirmahin ang Order",
      })
      : "Confirm Order",
    translate
      ? translate("Process Payment", {
        en: "Process Payment",
        zh: "处理付款",
        th: "กำลังประมวลผลการชำระเงิน",
        tl: "Iproseso ang Bayad",
      })
      : "Process Payment",
    translate
      ? translate("Order Confirmed", {
        en: "Order Confirmed",
        zh: "订单确认",
        th: "ยืนยันคำสั่งซื้อแล้ว",
        tl: "Nakumpirma ang Order",
      })
      : "Order Confirmed",
  ];

  // Format price in PHP
  const formatPrice = (price) => {
    return `PHP ${(price * 100).toFixed(2)}`;
  };

  // Calculate order totals
  const subtotal = cartItems.reduce((sum, item) => sum + item.totalPrice, 0);
  const total = finalTotal || subtotal - discount;

  // Generate a unique order ID
  const generateOrderId = () => {
    const timestamp = new Date().getTime();
    const random = Math.floor(Math.random() * 1000);
    return `${timestamp}-${random}`;
  };

  const createEmptyReceipt = async () => {
    try {
      const transformedMenuItems = cartItems.map((item) => {
        const optionsGroupedByOptionId = {};

        (item.selectedOptions || []).forEach((opt) => {
          if (!opt.optionId || !opt.choice) return;

          if (!optionsGroupedByOptionId[opt.optionId]) {
            optionsGroupedByOptionId[opt.optionId] = {
              optionId: opt.optionId,
              choices: [],
            };
          }

          // Push choiceId instead of choice name
          if (!optionsGroupedByOptionId[opt.optionId].choices.includes(opt.choice)) {
            optionsGroupedByOptionId[opt.optionId].choices.push(opt.choice);
          }
        });

        return {
          menuItemId: item._id,
          quantity: item.quantity,
          mealType: "Menu",
          additionalPrice: (item.selectedOptions || []).reduce((sum, opt) => sum + (opt.price || 0), 0),
          options: Object.values(optionsGroupedByOptionId),
        };
      });


      console.log("transformedMenuItems", transformedMenuItems);

      // Derive tenderType from the paymentMethod
      const formatTenderType = (method) => {
        switch (method.toLowerCase()) {
          case "credit_card":
            return "Credit";
          case "e-cash":
            return "E-Cash";
          case "cash":
          default:
            return "Cash";
        }
      };
      const tenderType = formatTenderType(paymentMethod);

      const receiptPayload = {
        posId,
        salesType: "Dine-In",
        tableNumber,
        tenderType,
        discountAmount: discount,
        discountType,
        voucherCode,
        voucherDesc,
        customerId,
        orderId,
        menuItems: transformedMenuItems,
        salesType: "Dine-In",
      };

      console.log("Receipt Payload:", receiptPayload);

      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/v1/api/order/create-empty-receipt`,
        receiptPayload
      );

      if (response.data && response.data.orderNumber && response.data.receiptNumber) {
        return {
          orderNumber: response.data.orderNumber,
          receiptNumber: response.data.receiptNumber,
        };
      } else {
        throw new Error("Empty receipt creation failed.");
      }
    } catch (err) {
      console.error("[❌ createEmptyReceipt error]", err);
    
      const errorMessage =
        err?.response?.data?.message ||
        err?.message ||
        "Something went wrong creating the receipt.";
    
      const showInDialogMessages = [
        "Z-Reading already generated. No more transactions for the day",
        "Z-Reading for yesterday is missing. Please generate it first before transaction can proceed.",
      ];
    
      if (showInDialogMessages.includes(errorMessage)) {
        setErrorDialogMessage(errorMessage);
        setErrorDialogDetails([]);
        setErrorDialogOpen(true);
      } else {
        Swal.fire({
          icon: "error",
          title: "Receipt Error",
          text: errorMessage,
        });
      }
    
      throw err;
    }
  };


  //reset process

  const resetProcess = useCallback(() => {
    setActiveStep(0)
    setLoading(false)
    setOrderNumber("")
    setError(null)
    setPaymentMethod(initialPaymentMethod)
    setPaymentError(null)
    qrRef.current = null

    // Paymongo checkout specific states
    setPaymongoCheckoutUrl(null)
    setPaymentStatus(null)
    setCheckingPaymentStatus(false)
    setPaymentSessionId(null)
    setErrorDialogOpen(false)
    setErrorDialogMessage("")
    setErrorDialogDetails([])
  }, [])

  // Handle order confirmation step
  const handleConfirmOrder = async () => {
    try {
      setLoading(true);
      const newReceiptNumber = await createEmptyReceipt();
      console.log("newReceiptNumber", newReceiptNumber);
      setOrderNumber(newReceiptNumber.orderNumber);
      setReceiptNumber(newReceiptNumber.receiptNumber);
      setActiveStep(1);

       //after 10 seconds - reset process
       setTimeout(() => {
        const currentUrl = location.pathname;
    
        const kioskUrl = currentUrl.replace("/order", "");
        window.location.href = kioskUrl;
       }, 10000);
    } catch (err) {
      console.error(err);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Failed to create empty receipt. Please try again.",
      });
    } finally {
      setLoading(false);
    }
  };

  // Error Dialog Component
  const ErrorDialog = () => (
    <Dialog
      open={errorDialogOpen}
      onClose={() => setErrorDialogOpen(false)}
      aria-labelledby="error-dialog-title"
      aria-describedby="error-dialog-description"
      PaperProps={{
        sx: {
          borderTop: "4px solid #f44336",
          minWidth: "320px",
          maxWidth: "500px",
        },
      }}
    >
      <DialogTitle id="error-dialog-title" sx={{ pb: 1 }}>
        <Box display="flex" alignItems="center" color="error.main">
          <ErrorOutlineIcon sx={{ mr: 1 }} />
          {translate
            ? translate("Payment Error", {
              en: "Payment Error",
              zh: "支付错误",
              th: "ข้อผิดพลาดในการชำระเงิน",
              tl: "Error sa Pagbabayad",
            })
            : "Payment Error"}
        </Box>
      </DialogTitle>

      <DialogContent sx={{ pt: 1 }}>
        <Typography variant="body1" id="error-dialog-description" gutterBottom>
          {errorDialogMessage}
        </Typography>

        {errorDialogDetails && errorDialogDetails.length > 0 && (
          <>
            <Typography variant="subtitle2" sx={{ mt: 2, fontWeight: "bold" }}>
              {translate
                ? translate("Error Details:", {
                  en: "Error Details:",
                  zh: "错误详情：",
                  th: "รายละเอียดข้อผิดพลาด:",
                  tl: "Mga Detalye ng Error:",
                })
                : "Error Details:"}
            </Typography>

            <Box
              sx={{
                mt: 1,
                bgcolor: "error.light",
                p: 2,
                borderRadius: 1,
                color: "error.contrastText",
                maxHeight: "200px",
                overflow: "auto",
              }}
            >
              {errorDialogDetails.map((detail, index) => (
                <Box
                  key={index}
                  sx={{ mb: index < errorDialogDetails.length - 1 ? 2 : 0 }}
                >
                  {detail.code && (
                    <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                      {detail.code}
                    </Typography>
                  )}
                  {detail.detail && (
                    <Typography variant="body2">{detail.detail}</Typography>
                  )}
                  {detail.source && (
                    <Typography
                      variant="caption"
                      sx={{ display: "block", mt: 0.5, fontStyle: "italic" }}
                    >
                      Source: {detail.source}
                    </Typography>
                  )}
                </Box>
              ))}
            </Box>

            <Typography
              variant="caption"
              sx={{ display: "block", mt: 1, color: "text.secondary" }}
            >
              {translate
                ? translate(
                  "Try using only gcash and paymaya as payment methods.",
                  {
                    en: "Try using only gcash and paymaya as payment methods.",
                    zh: "尝试仅使用gcash和paymaya作为支付方式。",
                    th: "ลองใช้เฉพาะ gcash และ paymaya เป็นวิธีการชำระเงิน",
                    tl: "Subukang gumamit lamang ng gcash at paymaya bilang mga paraan ng pagbabayad.",
                  }
                )
                : "Try using only gcash and paymaya as payment methods."}
            </Typography>
          </>
        )}
      </DialogContent>

      <DialogActions sx={{ px: 3, pb: 2 }}>
        <Button
          variant="contained"
          onClick={() => setErrorDialogOpen(false)}
          color="primary"
          fullWidth
        >
          {translate
            ? translate("OK", { en: "OK", zh: "确定", th: "ตกลง", tl: "OK" })
            : "OK"}
        </Button>
      </DialogActions>
    </Dialog>
  );

  // Create Paymongo checkout session for e-cash payments
  const createPaymongoCheckout = async () => {
    setLoading(true);
    setPaymentError(null);

    console.log("Cart items:", cartItems);
    console.log("Table number:", tableNumber);
    console.log("Total:", total);

    const receiptNumber = orderNumber || generateOrderId();

    try {
      // Generate callback URLs
      const baseUrl = window.location.origin;

      // Create unique payment session ID to track this specific payment
      const paymentSessionId = `${Date.now()}-${Math.random()
        .toString(36)
        .substring(2, 9)}`;

      // Store payment session ID in component state for reference
      setPaymentSessionId(paymentSessionId);

      // Create success URL with payment session ID and order info
      const successUrl =
        `${baseUrl}/payment/success?` +
        `session_id=${encodeURIComponent(paymentSessionId)}` +
        `&order_id=${encodeURIComponent(receiptNumber)}` +
        `&table=${encodeURIComponent(tableNumber)}` +
        `&amount=${encodeURIComponent(total)}` +
        `&origin=${encodeURIComponent(window.location.href)}`;

      // Create cancel URL with payment session ID and order info
      const cancelUrl =
        `${baseUrl}/payment/cancel?` +
        `session_id=${encodeURIComponent(paymentSessionId)}` +
        `&order_id=${encodeURIComponent(receiptNumber)}` +
        `&table=${encodeURIComponent(tableNumber)}` +
        `&origin=${encodeURIComponent(window.location.href)}`;

      // Format line items according to Paymongo's expected format
      const lineItems = cartItems.map((item) => {
        // Allocate each item's total proportionally based on the cart's subtotal
        const effectiveTotalForItem = (item.totalPrice / subtotal) * total;
        const effectiveUnitPrice = effectiveTotalForItem / item.quantity;
        return {
          amount: Math.round(effectiveUnitPrice * 100), // effective unit price in cents
          currency: "PHP",
          description: item.menuName,
          images: item.menuImage ? [item.menuImage] : [],
          name: item.menuName,
          quantity: item.quantity,
        };
      });

      // Call your backend API to create a checkout session
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/v1/api/payment/create-checkout`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            data: {
              attributes: {
                cancel_url: cancelUrl,
                description: `Order #${receiptNumber}`,
                line_items: lineItems,
                metadata: {
                  order_number: receiptNumber,
                  table_number: tableNumber,
                },
                payment_method_types: ["gcash", "paymaya", "card"],
                reference_number: receiptNumber,
                send_email_receipt: true,
                show_description: true,
                show_line_items: true,
                success_url: successUrl,
              },
              type: "checkout_session",
            },
          }),
        }
      );

      const data = await response.json();

      if (data.success) {
        setPaymentReference(data.referenceId || receiptNumber);
        setPaymongoCheckoutUrl(data.checkoutUrl);
        setPaymentStatus("pending");

        // Open the checkout URL in a new tab
        const paymongoWindow = window.open(data.checkoutUrl, "_blank");
        window.paymongoWindow = paymongoWindow;
      } else {
        let errorMessage = data.error || "Failed to create checkout session";
        let errorDetails = [];

        if (
          data.details &&
          data.details.errors &&
          Array.isArray(data.details.errors)
        ) {
          errorDetails = data.details.errors.map((err) => ({
            code: err.code,
            detail: err.detail,
            source: err.source
              ? `${err.source.attribute || ""} ${err.source.pointer || ""
                }`.trim()
              : "",
          }));
        }

        throw {
          message: errorMessage,
          details: errorDetails,
          isStructured: true,
        };
      }
    } catch (error) {
      console.error("Error creating Paymongo checkout:", error);
      if (error.isStructured) {
        setErrorDialogMessage(error.message);
        setErrorDialogDetails(error.details);
      } else {
        setErrorDialogMessage(
          error.message ||
          "Failed to create payment checkout. Please try again."
        );
        setErrorDialogDetails([]);
      }
      setErrorDialogOpen(true);
      setPaymentError(
        "Payment checkout failed. See details for more information."
      );
    } finally {
      setLoading(false);
    }
  };

  // Check payment status for e-cash payments
  const checkPaymentStatus = async () => {
    if (!paymentReference) return;
    setCheckingPaymentStatus(true);

    try {
      console.log("Checking payment status for reference:", paymentReference);

      // If we already know the payment was successful from the success page, don't check again
      if (paymentStatus === "success") {
        console.log(
          "Payment already marked as successful, skipping status check"
        );
        setCheckingPaymentStatus(false);
        return;
      }

      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/v1/api/payment/status/${paymentReference}`
      );
      const data = await response.json();
      console.log("Payment status response:", data);

      if (data.success) {
        const status = data.status;
        console.log("Payment status:", status);

        if (status === "succeeded" || status === "paid") {
          console.log("Payment succeeded according to backend");
          const verification = await axios.post(
            `${process.env.REACT_APP_BACKEND_URL}/v1/api/payment/verify`,
            { paymentId: paymentReference, amount: total }
          );
          console.log("Payment verification response:", verification.data);

          if (verification.data.valid) {
            // Handle successful payment
            handlePaymentSuccessful(paymentReference);
          } else {
            setPaymentStatus("failed");
            setErrorDialogMessage("Payment verification failed");
            setErrorDialogDetails([]);
            setErrorDialogOpen(true);
          }
        } else if (
          status === "awaiting_payment_method" ||
          status === "processing"
        ) {
          // Don't show error dialog for pending status, just update the state
          console.log("Payment is still processing");
          setPaymentStatus("pending");
        } else {
          console.log("Payment failed with status:", status);
          setPaymentStatus("failed");
          setErrorDialogMessage(
            "Payment was not completed. Please try again or choose another payment method."
          );
          setErrorDialogOpen(true);
        }
      } else {
        throw new Error(data.error || "Failed to check payment status");
      }
    } catch (error) {
      console.error("Error checking payment status:", error);
      setPaymentStatus("failed");
      if (error.response && error.response.data) {
        const data = error.response.data;
        setErrorDialogMessage(data.error || "Failed to verify payment status");
        if (data.details && data.details.errors) {
          setErrorDialogDetails(
            data.details.errors.map((err) => ({
              code: err.code,
              detail: err.detail,
              source: err.source
                ? `${err.source.attribute || ""} ${err.source.pointer || ""
                  }`.trim()
                : "",
            }))
          );
        } else {
          setErrorDialogDetails([]);
        }
      } else {
        setErrorDialogMessage(
          "Failed to verify payment status. Please contact support."
        );
        setErrorDialogDetails([]);
      }
      setErrorDialogOpen(true);
    } finally {
      setCheckingPaymentStatus(false);
    }
  };

  const processedReceipts = new Set();

  // Process payment based on selected method
  const handleProcessPayment = async () => {
    setLoading(true);
    setPaymentError(null);

    if (processedReceipts.has(orderNumber)) {
      console.warn("Duplicate order attempt blocked:", orderNumber);
      setLoading(false);
      return Swal.fire({
        icon: "warning",
        title: "Duplicate Order",
        text: "This order is already being processed. Please wait.",
      });
    }

    processedReceipts.add(orderNumber);

    // For e-cash, enforce minimum amount check
    if (paymentMethod === "e-cash" && total / 100 < MINIMUM_ECASH_AMOUNT) {
      setErrorDialogMessage(
        translate
          ? translate(
            "The minimum amount for e-cash payment is PHP 100. Please add more items or choose a different payment method.",
            {
              en: "The minimum amount for e-cash payment is PHP 100. Please add more items or choose a different payment method.",
              zh: "电子现金支付的最低金额为PHP 100。请添加更多商品或选择其他支付方式。",
              th: "จำนวนเงินขั้นต่ำสำหรับการชำระเงินอิเล็กทรอนิกส์คือ PHP 100 โปรดเพิ่มรายการหรือเลือกวิธีการชำระเงินอื่น",
              tl: "Ang minimum na halaga para sa e-cash payment ay PHP 100. Mangyaring magdagdag ng mga item o pumili ng ibang paraan ng pagbabayad.",
            }
          )
          : "The minimum amount for e-cash payment is PHP 100. Please add more items or choose a different payment method."
      );
      setErrorDialogDetails([]);
      setErrorDialogOpen(true);
      setLoading(false);
      return;
    }

    switch (paymentMethod) {
      case "cash":
        const receiptNumber = orderNumber || generateOrderId();
        setOrderNumber(receiptNumber)
        // For Cash payment, simulate QR code printing and completion
        setLoading(false)
        setActiveStep(2)

        /*
        setTimeout(() => {
          setLoading(false);
          setOrderNumber(receiptNumber || generateOrderId());
          setActiveStep(2);
        }, 2000);*/
        break;
      case "e-cash":
        // For e-cash, create or check Paymongo checkout
        if (paymentStatus === "pending") {
          checkPaymentStatus();
        } else {
          await createPaymongoCheckout();
        }
        break;
      case "credit_card":
        try {
          const receiptNumber = orderNumber || generateOrderId();

          const processOrderResponse = await axios.post(
            `${process.env.REACT_APP_BACKEND_URL}/v1/api/order/process-order`,
            { posId, receiptNumber },
            {
              headers: { "Content-Type": "application/json" },
              responseType: "blob",
            }
          );
          if (processOrderResponse.status === 200) {
            const pdfBlob = new Blob([processOrderResponse.data], {
              type: "application/pdf",
            });
            const pdfUrl = URL.createObjectURL(pdfBlob);
            window.open(pdfUrl, "_blank");
            setOrderNumber(receiptNumber);
            setActiveStep(2);
            Swal.fire({
              icon: "success",
              title: "Order Generated",
              text: "Order generated successfully.",
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "Order Processing Error",
              text: "Order processing failed. Please try again.",
            });
          }
        } catch (err) {
          console.error("Error processing credit card payment:", err);
          Swal.fire({
            icon: "error",
            title: "Order Processing Error",
            text: "Order processing failed. Please try again.",
          });
        } finally {
          setLoading(false);
        }
        break;
      default:
        // Fallback: simulate completion
        setTimeout(() => {
          setLoading(false);
          setOrderNumber(generateOrderId());
          setActiveStep(2);
        }, 2000);
    }
  };

  // Handle order completion
  const handleComplete = () => {
    handlePrintReceipt();
    onClose();

    if (typeof onClearCart === "function") {
      onClearCart();
    }

    setActiveStep(0);
    setLoading(false);
    setOrderNumber("");
    setError(null);
    setPaymentMethod(initialPaymentMethod);
    setPaymentError(null);
    setPaymongoCheckoutUrl(null);
    setPaymentStatus(null);
    setPaymentReference(null);
    setCheckingPaymentStatus(false);
    setPaymentSessionId(null);
    setIsProcessingOrder(false);
    setErrorDialogOpen(false);
    setErrorDialogMessage("");
    setErrorDialogDetails([]);

    const currentUrl = location.pathname;
    // alert(currentUrl)
    const kioskUrl = currentUrl.replace("/order", "");
    navigate(kioskUrl);
  };

  // Check if e-cash is available based on minimum amount
  const isEcashAvailable = total >= MINIMUM_ECASH_AMOUNT;

  // Generate payment data for QR code
  const generatePaymentQRData = () => {
    const receiptNumber = orderNumber || generateOrderId();
    const now = new Date();
    const pad = (num) => String(num).padStart(2, "0");
    const year = now.getFullYear();
    const month = pad(now.getMonth() + 1);
    const day = pad(now.getDate());
    const hour = pad(now.getHours());
    const min = pad(now.getMinutes());
    const sec = pad(now.getSeconds());
    const dateStamp = `${year}${month}${day}T${hour}${min}${sec}`;

    let qrString = `${receiptNumber || orderId}^5^`;

    if (cartItems && cartItems.length > 0) {
      cartItems.forEach((item) => {
        const name = item.menuName
          ? item.menuName.replace(/\s/g, "")
          : "UnknownProduct";
        const quantity = item.quantity;
        const unitPrice = (item.totalPrice / quantity).toFixed(2);
        qrString += `${name}_${quantity}_${unitPrice}^`;
      });
    } else {
      qrString += "NoItems_0_0^";
    }

    qrString += `${total.toFixed(2)}^${dateStamp}`;

    return qrString;
  };

  // Render order summary
  const renderOrderSummary = () => (
    <Box sx={{ mb: 3 }}>
      <Typography variant="h6" gutterBottom>
        Order Summary
      </Typography>
      <Paper elevation={2} sx={{ p: 2 }}>
        <Typography variant="subtitle2" gutterBottom>
          Table #{tableNumber}
        </Typography>
        {cartItems.map((item) => (
          <Box
            key={item.id}
            sx={{ display: "flex", justifyContent: "space-between", my: 1 }}
          >
            <Typography variant="body2">
              {item.quantity}x {item.menuName}
              {item.selectedOptions && item.selectedOptions.length > 0 && (
                <Typography variant="caption" display="block" sx={{ ml: 2 }}>
                  {item.selectedOptions
                    .map((opt) => `${opt.name}: ${opt.choice}`)
                    .join(", ")}
                </Typography>
              )}
            </Typography>
            <Typography variant="body2">
              {formatPrice(item.totalPrice / 100)}
            </Typography>
          </Box>
        ))}
        <Divider sx={{ my: 1 }} />
        <Box sx={{ display: "flex", justifyContent: "space-between", my: 0.5 }}>
          <Typography variant="body2">Subtotal:</Typography>
          <Typography variant="body2">{formatPrice(subtotal / 100)}</Typography>
        </Box>
        {discount > 0 && (
          <Box
            sx={{ display: "flex", justifyContent: "space-between", my: 0.5 }}
          >
            <Typography variant="body2">Discount:</Typography>
            <Typography variant="body2" color="error">
              -{formatPrice(discount / 100)}
            </Typography>
          </Box>
        )}
        <Box sx={{ display: "flex", justifyContent: "space-between", mt: 1 }}>
          <Typography variant="subtitle1" fontWeight="bold">
            Total:
          </Typography>
          <Typography variant="subtitle1" fontWeight="bold">
            {formatPrice(total / 100)}
          </Typography>
        </Box>
      </Paper>
    </Box>
  );

  const handlePrint = async () => {
    try {
      const currentReceipt = orderNumber || receiptNumber || generateOrderId();

      await generateQrPdf(currentReceipt, cartItems, total, posId, orderNumber, receiptNumber);
    } catch (err) {
      console.error("Error printing receipt:", err);
      Swal.fire({
        icon: "error",
        title: "Print Error",
        text: "Failed to print receipt. Please try again.",
      });
    }
  };

  const handlePrintReceipt = async () => {
    try {
      console.log("🔄 Attempting to reprint receipt...");

      if (!orderNumber) {
        console.error("❌ No order number found for reprinting.");
        Swal.fire({
          icon: "error",
          title: "Print Error",
          text: "No valid receipt number found. Please try again.",
        });
        return;
      }

      // Construct the receipt data payload
      const receiptData = {
        posclient: true,
        receiptNumber: orderNumber,
        posId,
      };

      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/v1/api/receipt/generate-receipt/public?disposition=print`,
        receiptData,
        {
          responseType: "blob",
        }
      );

      if (response.status === 200) {
        console.log("✅ Receipt PDF successfully generated.");

        const pdfBlob = new Blob([response.data], { type: "application/pdf" });
        const pdfUrl = URL.createObjectURL(pdfBlob);

        const newWindow = window.open(pdfUrl, "_blank");
        if (newWindow) {
          console.log("🖨️ Opening print dialog...");
          setTimeout(() => {
            newWindow.print();
          }, 1000);
        } else {
          console.error("❌ Popup Blocked: Unable to open new window.");
          Swal.fire({
            icon: "error",
            title: "Printing Blocked",
            text: "Your browser blocked the print window. Please allow pop-ups and try again.",
          });
        }
      } else {
        throw new Error("Receipt generation failed.");
      }
    } catch (error) {
      console.error("❌ Error reprinting receipt:", error);
      Swal.fire({
        icon: "error",
        title: "Print Error",
        text: "Failed to generate and print receipt. Please try again.",
      });
    }
  };


  //fetch on interval to server to get status when payment method is cash and active step === 2
  //once the payment is done we will show the ok message
  useEffect(() => {
    console.log("useeeffct ongoing");
    console.log(" activeStep:  ", activeStep);
    console.log(" paymentMethod: ", paymentMethod);

    let orderStatusFetch

    if (activeStep === 1 && paymentMethod === "cash") {
      console.log(
        "[PaymentDialog] Cash confirmation reached. Auto-printing..."
      );
      handlePrint();


      orderStatusFetch = setInterval(async () => {
        let res = await orders.getOrderStatus(receiptNumber)
        if (res.status === 'success') {
          console.log('order success')
          clearInterval(orderStatusFetch)
          handleProcessPayment()
        }
        console.log(res)
      }, 2000)
    }

    return () => {
      clearInterval(orderStatusFetch)
    }
  }, [activeStep, paymentMethod, orderNumber]);

  // Define global success handler function
  useEffect(() => {
    // Expose a global function to handle payment success
    window.handlePaymentSuccess = (sessionId, orderId) => {
      console.log(
        "Direct function call: handlePaymentSuccess",
        sessionId,
        orderId
      );
      if (sessionId === paymentSessionId) {
        handlePaymentSuccessful(orderId);
      }
    };

    return () => {
      // Clean up global function when component unmounts
      delete window.handlePaymentSuccess;
    };
  }, [paymentSessionId]);

  // Handle successful payment completion
  const handlePaymentSuccessful = async (orderId) => {
    console.log("Payment successful for order:", orderId);

    // Prevent duplicate processing
    if (paymentStatus === "success" || isProcessingOrder) {
      console.log("Payment already processed or in progress, skipping");
      return;
    }

    try {
      // Set UI state first for better UX
      setIsProcessingOrder(true);
      setPaymentStatus("success");

      // Clear any error messages that might be showing
      setErrorDialogOpen(false);

      // Close payment window if it exists
      if (window.paymongoWindow) {
        window.paymongoWindow.close();
        window.paymongoWindow = null;
      }

      const existingReceipt = processedReceipts.has(orderId);
      if (existingReceipt) {
        console.warn("Order already processed:", orderId);
        return;
      }

      processedReceipts.add(orderId);

      // Create new receipt and update state
      console.log("Processing Order for successful payment");

      const receiptNumber = orderNumber || generateOrderId();

      const processOrderResponse = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/v1/api/order/process-order`,
        { receiptNumber, posId },
        {
          headers: { "Content-Type": "application/json" },
          responseType: "blob",
        }
      );

      if (processOrderResponse.status === 200) {
        console.log("✅ Order processed successfully! Generating PDF...");

        const pdfBlob = new Blob([processOrderResponse.data], {
          type: "application/pdf",
        });
        const pdfUrl = URL.createObjectURL(pdfBlob);

        console.log("📄 PDF created, URL:", pdfUrl);

        const newWindow = window.open(pdfUrl, "_blank");
        if (newWindow) {
          console.log("🆕 Opened PDF in new tab. Waiting for print dialog...");
          setTimeout(() => {
            newWindow.print();
          }, 1000); // Delay to ensure the document loads before printing
        } else {
          console.error("❌ Popup Blocked: Unable to open new window.");
          Swal.fire({
            icon: "error",
            title: "Printing Blocked",
            text: "Your browser blocked the print window. Please allow pop-ups and try again.",
          });
        }

        setActiveStep(2);
      } else {
        Swal.fire({
          icon: "error",
          title: "Order Processing Error",
          text: "Order processing failed. Please try again.",
        });
      }

    } catch (error) {
      console.error("Error handling successful payment:", error);
      setPaymentStatus("failed");
      setErrorDialogMessage("Error processing payment confirmation");
      setErrorDialogDetails(error.message ? [error.message] : []);
      setErrorDialogOpen(true);
    } finally {
      setIsProcessingOrder(false);
    }
  };

  // Listen for messages from payment success/cancel pages
  useEffect(() => {
    const handlePaymentMessage = (event) => {
      console.log("Received postMessage:", event.data);
      console.log("Current paymentSessionId:", paymentSessionId);

      // Verify the message is from our payment flow
      if (event.data && event.data.type) {
        if (event.data.sessionId === paymentSessionId) {
          console.log("SessionId match confirmed");
          if (event.data.type === "payment_success") {
            // Payment was successful - handle it
            handlePaymentSuccessful(event.data.orderId);
          } else if (event.data.type === "payment_cancel") {
            // Payment was canceled
            if (window.paymongoWindow) {
              window.paymongoWindow.close();
              window.paymongoWindow = null;
            }

            setPaymentStatus("failed");
            setErrorDialogMessage("Payment was canceled by user.");
            setErrorDialogDetails([]);
            setErrorDialogOpen(true);
          }
        }
      }
    };

    // Add event listener
    window.addEventListener("message", handlePaymentMessage);

    // Clean up
    return () => {
      window.removeEventListener("message", handlePaymentMessage);
    };
  }, [paymentSessionId]);

  // Separate polling from window management
  useEffect(() => {
    let interval;
    if (paymentStatus === "pending") {
      interval = setInterval(() => {
        checkPaymentStatus();
      }, 5000);
    }
    return () => {
      clearInterval(interval);
    };
  }, [paymentStatus]);

  // Handle window cleanup only on component unmount
  useEffect(() => {
    return () => {
      if (window.paymongoWindow) {
        window.paymongoWindow.close();
        window.paymongoWindow = null;
      }
    };
  }, []);

  // Render QR code for payment
  const renderPaymentQR = () => (
    <Box sx={{ textAlign: "center", my: 3 }}>
      <Typography variant="h6" gutterBottom>
        {translate
          ? translate("Scan to Pay", {
            en: "Scan to Pay",
            zh: "扫码支付",
            th: "สแกนเพื่อชำระเงิน",
            tl: "I-scan para Magbayad",
          })
          : "Scan to Pay"}
      </Typography>
      <Typography variant="body2" gutterBottom>
        {translate
          ? translate(
            "Scan this QR code with your mobile payment app or show it to the cashier",
            {
              en: "Scan this QR code with your mobile payment app or show it to the cashier",
              zh: "使用您的移动支付应用扫描此二维码，或将其显示给收银员",
              th: "สแกน QR รหัสนี้ด้วยแอปชำระเงินมือถือหรือแสดงต่อพนักงานเก็บเงิน",
              tl: "I-scan ang QR code na ito gamit ang iyong mobile payment app o ipakita sa cashier",
            }
          )
          : "Scan this QR code with your mobile payment app or show it to the cashier"}
      </Typography>
      <Box
        ref={qrRef}
        sx={{
          display: "inline-block",
          p: 2,
          bgcolor: "white",
          borderRadius: 1,
          boxShadow: 2,
          mt: 2,
        }}
      >
        <QRCodeSVG
          value={generatePaymentQRData()}
          size={200}
          level="H"
          includeMargin
        />
      </Box>
      <Typography variant="body2" sx={{ mt: 2 }}>
        {translate
          ? translate("Amount", {
            en: "Amount",
            zh: "金额",
            th: "จำนวนเงิน",
            tl: "Halaga",
          })
          : "Amount"}
        : {formatPrice(total / 100)}
      </Typography>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column'
      }}>
        <Button
          variant="outlined"
          color="primary"
          onClick={handlePrint}
          sx={{ mt: 2 }}
        >
          {translate
            ? translate("Reprint Receipt", {
              en: "Reprint Receipt",
              zh: "重新打印收据",
              th: "พิมพ์ใบเสร็จใหม่",
              tl: "I-reprint ang Resibo",
            })
            : "Reprint Receipt"}
        </Button>

        <Button
          color="primary"
          variant="contained"
          onClick={onClose}
          sx={{ mt: 2 }}
        >
          {translate
            ? translate("Done", {
              en: "Done",
              zh: "完成",
              th: "เสร็จเรียบร้อย่",
              tl: "Okay na.",
            })
            : "Done"}
        </Button>
      </Box>
    </Box>
  );

  // Render confirmation screen
  const renderConfirmation = () => (
    <Box sx={{ textAlign: "center", my: 4 }}>
      <CheckCircleIcon color="success" sx={{ fontSize: 64, mb: 2 }} />
      <Typography variant="h5" gutterBottom>
        {translate
          ? translate("Order Confirmed!", {
            en: "Order Confirmed!",
            zh: "订单已确认！",
            th: "ยืนยันคำสั่งซื้อแล้ว!",
            tl: "Nakumpirma na ang Order!",
          })
          : "Order Confirmed!"}
      </Typography>
      <Typography variant="body1" gutterBottom>
        {translate
          ? translate(
            "Your order #{orderNumber} has been placed successfully.",
            {
              en: `Your order #${orderNumber} has been placed successfully.`,
              zh: `您的订单 #${orderNumber} 已成功下单。`,
              th: `คำสั่งซื้อของคุณ #${orderNumber} ได้รับการยืนยันแล้ว`,
              tl: `Ang iyong order #${orderNumber} ay matagumpay na naiproseso.`,
            }
          )
          : `Your order #${orderNumber} has been placed successfully.`}
      </Typography>
      <Typography variant="body2" sx={{ mt: 2 }}>
        {translate
          ? translate(
            "Please wait at Table #{tableNumber}. Your food will be prepared and delivered shortly.",
            {
              en: `Please wait at Table #${tableNumber}. Your food will be prepared and delivered shortly.`,
              zh: `请在${tableNumber}号桌等候。您的食物将很快准备好并送达。`,
              th: `โปรดรอที่โต๊ะ #${tableNumber} อาหารของคุณจะถูกเตรียมและส่งให้ในไม่ช้า`,
              tl: `Mangyaring maghintay sa mesa #${tableNumber}. Ang iyong pagkain ay ihahanda at ise-serve sa madaling panahon.`,
            }
          )
          : `Please wait at Table #${tableNumber}. Your food will be prepared and delivered shortly.`}
      </Typography>
      {/* <Button
        variant="contained"
        color="primary"
        onClick={handlePrintReceipt}
        sx={{ mt: 3 }}
      >
        {translate
          ? translate("Reprint Receipt", {
              en: "Reprint Receipt",
              zh: "重新打印收据",
              th: "พิมพ์ใบเสร็จใหม่",
              tl: "I-reprint ang Resibo",
            })
          : "Reprint Receipt"}
      </Button> */}
    </Box>
  );

  // Select payment method UI
  const getPaymentMethodUI = () => {
    switch (paymentMethod) {
      case "cash":
        return renderPaymentQR();
      case "e-cash":
        return renderECashPayment();
      case "credit-card":
        return renderECashPayment()
      default:
        return renderPaymentQR();
    }
  };

  const handleCloseDialog = () => {
    // Close dialog only this is when cancel is clicked
    
  };

  // Render e-cash payment with Paymongo checkout
  const renderECashPayment = () => (
    <Box sx={{ my: 3, textAlign: "center" }}>

      {/*}
      <Typography variant="h6" gutterBottom>
        {translate
          ? translate("E-Cash Payment", {
              en: "E-Cash Payment",
              zh: "电子现金支付",
              th: "ชำระเงินอิเล็กทรอนิกส์",
              tl: "E-Cash Payment",
            })
          : "E-Cash Payment"}
      </Typography> */}
      {paymentStatus === "pending" && paymongoCheckoutUrl ? (
        <Box sx={{ mt: 3 }}>
          <Typography variant="body1" gutterBottom>
            {translate
              ? translate(
                "Payment is being processed. Please complete your payment in the opened tab.",
                {
                  en: "Payment is being processed. Please complete your payment in the opened tab.",
                  zh: "付款正在处理中。请在已打开的标签页中完成付款。",
                  th: "กำลังประมวลผลการชำระเงิน โปรดทำการชำระเงินให้เสร็จสิ้นในแท็บที่เปิดไว้",
                  tl: "Ang pagbabayad ay pinoproseso. Mangyaring kumpletuhin ang iyong pagbabayad sa nakabukas na tab.",
                }
              )
              : "Payment is being processed. Please complete your payment in the opened tab."}
          </Typography>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => window.open(paymongoCheckoutUrl, "_blank")}
            sx={{ mt: 2, mb: 3 }}
          >
            {translate
              ? translate("Reopen Payment Page", {
                en: "Reopen Payment Page",
                zh: "重新打开支付页面",
                th: "เปิดหน้าชำระเงินอีกครั้ง",
                tl: "Buksan Muli ang Payment Page",
              })
              : "Reopen Payment Page"}
          </Button>
          <Divider sx={{ my: 2 }} />
          <Typography variant="body2" gutterBottom>
            {translate
              ? translate(
                "After completing payment, return here and click the button below:",
                {
                  en: "After completing payment, return here and click the button below:",
                  zh: "完成付款后，请返回此处并点击下方按钮：",
                  th: "หลังจากชำระเงินเสร็จสิ้น กลับมาที่นี่และคลิกปุ่มด้านล่าง:",
                  tl: "Pagkatapos makumpleto ang pagbabayad, bumalik dito at i-click ang button sa ibaba:",
                }
              )
              : "After completing payment, return here and click the button below:"}
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={checkPaymentStatus}
            disabled={checkingPaymentStatus}
            sx={{ mt: 2 }}
          >
            {checkingPaymentStatus ? (
              <CircularProgress size={24} color="inherit" sx={{ mr: 1 }} />
            ) : null}
            {translate
              ? translate("I've Completed Payment", {
                en: "I've Completed Payment",
                zh: "我已完成付款",
                th: "ฉันได้ชำระเงินเรียบร้อยแล้ว",
                tl: "Nakumpleto Ko Na ang Pagbabayad",
              })
              : "I've Completed Payment"}
          </Button>
        </Box>
      ) : paymentStatus === "failed" ? (
        <Box sx={{ mt: 3 }}>
          <Alert severity="error" sx={{ mb: 3 }}>
            {paymentError ||
              (translate
                ? translate("Payment failed. Please try again.", {
                  en: "Payment failed. Please try again.",
                  zh: "支付失败。请重试。",
                  th: "การชำระเงินล้มเหลว โปรดลองอีกครั้ง",
                  tl: "Nabigo ang pagbabayad. Pakisubukang muli.",
                })
                : "Payment failed. Please try again.")}
          </Alert>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setPaymentStatus(null);
              setPaymongoCheckoutUrl(null);
              setPaymentError(null);
            }}
            sx={{ mt: 2 }}
          >
            {translate
              ? translate("Try Again", {
                en: "Try Again",
                zh: "重试",
                th: "ลองอีกครั้ง",
                tl: "Subukang Muli",
              })
              : "Try Again"}
          </Button>
        </Box>
      ) : (
        <Box sx={{ mt: 3 }}>
          <Typography variant="body1" gutterBottom>
            {translate
              ? translate("Click the button below to proceed with payment:", {
                en: "Click the button below to proceed with payment:",
                zh: "点击下方按钮继续付款：",
                th: "คลิกปุ่มด้านล่างเพื่อดำเนินการชำระเงิน:",
                tl: "I-click ang button sa ibaba para magpatuloy sa pagbabayad:",
              })
              : "Click the button below to proceed with payment:"}
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              gap: 2,
              flexWrap: "wrap",
              mb: 3,
            }}
          >
            <CardMedia
              component="img"
              image="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTD9xk-8elxUlacFt2-lHbO2ZJ-MMVOus4Q8g&s"
              alt="GCash"
              sx={{ height: 30, width: "auto", objectFit: "contain" }}
            />
            <CardMedia
              component="img"
              image="https://play-lh.googleusercontent.com/fdQjxsIO8BTLaw796rQPZtLEnGEV8OJZJBJvl8dFfZLZcGf613W93z7y9dFAdDhvfqw=w600-h300-pc0xffffff-pd"
              alt="Maya"
              sx={{ height: 30, width: "auto", objectFit: "contain" }}
            />
            <CardMedia
              component="img"
              image="https://t4.ftcdn.net/jpg/04/06/75/39/360_F_406753914_SFSBhjhp6kbHblNiUFZ1MXHcuEKe7e7P.jpg"
              alt="Card"
              sx={{ height: 30, width: "auto", objectFit: "contain" }}
            />
          </Box>
          <Button
            variant="contained"
            color="primary"
            onClick={createPaymongoCheckout}
            disabled={loading}
            sx={{ mt: 2 }}
          >
            {loading ? (
              <CircularProgress size={24} color="inherit" sx={{ mr: 1 }} />
            ) : null}
            {translate
              ? translate("Proceed to Payment", {
                en: "Proceed to Payment",
                zh: "继续付款",
                th: "ดำเนินการชำระเงิน",
                tl: "Magpatuloy sa Pagbabayad",
              })
              : "Proceed to Payment"}
          </Button>
        </Box>
      )}
      <Typography
        variant="body2"
        color="primary"
        sx={{ fontWeight: "bold", mt: 3 }}
      >
        {translate
          ? translate("Total Amount", {
            en: "Total Amount",
            zh: "总金额",
            th: "จำนวนเงินทั้งหมด",
            tl: "Kabuuang Halaga",
          })
          : "Total Amount"}
        : {formatPrice(total / 100)}
      </Typography>
    </Box>
  );

  return (
    <Dialog
      open={open}
      onClose={activeStep === 2 ? onClose : undefined}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle sx={{ pb: 1 }}>
        <Typography variant="h5">Checkout</Typography>
      </DialogTitle>

      <DialogContent>
        <Stepper activeStep={activeStep} sx={{ mb: 4 }}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>

        {activeStep === 0 && (
          <>
            {renderOrderSummary()}

            {/* Payment Method Selection */}
            <Box sx={{ my: 3 }}>
              <Typography variant="h6" gutterBottom>
                {translate
                  ? translate("Select Payment Method", {
                    en: "Select Payment Method",
                    zh: "选择支付方式",
                    th: "เลือกวิธีการชำระเงิน",
                    tl: "Pumili ng Paraan ng Pagbabayad",
                  })
                  : "Select Payment Method"}
              </Typography>

              <FormControl component="fieldset" fullWidth>
                <RadioGroup
                  value={paymentMethod}
                  onChange={(e) => setPaymentMethod(e.target.value)}
                >
                  <FormControlLabel
                    value="cash"
                    control={<Radio />}
                    label={
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <PaymentIcon sx={{ mr: 1 }} />
                        <Typography>
                          {translate
                            ? translate("Cash", {
                              en: "Cash",
                              zh: "现金",
                              th: "เงินสด",
                              tl: "Cash",
                            })
                            : "Cash"}
                        </Typography>
                      </Box>
                    }
                  />
                  <FormControlLabel
                    value="e-cash"
                    disabled={!isEcashAvailable}
                    control={<Radio />}
                    label={
                      <Box>
                        <Box display="flex" alignItems="center">
                          <CreditCardIcon sx={{ mr: 1 }} />
                          <Typography>
                            {translate
                              ? translate("E-Cash", {
                                en: "E-Cash",
                                zh: "电子现金",
                                th: "อี-แคช",
                                tl: "E-Cash",
                              })
                              : "E-Cash"}
                          </Typography>
                        </Box>
                        {!isEcashAvailable && (
                          <Typography
                            variant="caption"
                            color="error"
                            sx={{ ml: 4, display: "block" }}
                          >
                            {translate
                              ? translate("Minimum amount required: PHP 100", {
                                en: "Minimum amount required: PHP 100",
                                zh: "最低金额要求: PHP 100",
                                th: "จำนวนเงินขั้นต่ำที่ต้องการ: PHP 100",
                                tl: "Minimum na halaga na kinakailangan: PHP 100",
                              })
                              : "Minimum amount required: PHP 100"}
                          </Typography>
                        )}
                      </Box>
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Box>

            <Alert severity="info" sx={{ mb: 2 }}>
              Please review your order before confirming.
            </Alert>
          </>
        )}

        {activeStep === 1 && (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Paper
                elevation={1}
                sx={{ p: 2, mb: 3, display: "flex", alignItems: "center" }}
              >
                {paymentMethod === "cash" && <PaymentIcon sx={{ mr: 1 }} />}
                {paymentMethod === "credit_card" && (
                  <CreditCardIcon sx={{ mr: 1 }} />
                )}
                {paymentMethod === "gcash" && (
                  <AccountBalanceIcon sx={{ mr: 1 }} />
                )}
                <Typography variant="subtitle1">
                  {paymentMethod === "cash" &&
                    (translate
                      ? translate("Cash Payment", {
                        en: "Cash Payment",
                        zh: "现金支付",
                        th: "ชำระด้วยเงินสด",
                        tl: "Cash Payment",
                      })
                      : "Cash Payment")}
                  {paymentMethod === "credit_card" &&
                    (translate
                      ? translate("Credit Card Payment", {
                        en: "Credit Card Payment",
                        zh: "信用卡支付",
                        th: "ชำระด้วยบัตรเครดิต",
                        tl: "Credit Card Payment",
                      })
                      : "Credit Card Payment")}
                  {paymentMethod === "gcash" && "GCash Payment"}
                </Typography>
              </Paper>
              {getPaymentMethodUI()}
            </Grid>
            {paymentMethod === "cash" && (
              <Grid item xs={12}>
                <Alert severity="info">
                  {translate
                    ? translate(
                      "For Cash Payment: Please show this QR to the cash machine.",
                      {
                        en: "For Cash Payment: Please show this QR to the cash machine.",
                        zh: "现金支付：请将此二维码出示给自助收银机。",
                        th: "สำหรับการชำระเงินด้วยเงินสด: โปรดแสดง QR นี้ให้กับเครื่องรับชำระเงินสด",
                        tl: "Para sa Cash Payment: Pakita ang QR na ito sa cash machine.",
                      }
                    )
                    : "For Cash Payment: Please show this QR to the cash machine."}
                </Alert>
              </Grid>
            )}
            {loading && (
              <Grid item xs={12} sx={{ textAlign: "center", my: 2 }}>
                <CircularProgress />
                <Typography variant="body2" sx={{ mt: 1 }}>
                  {translate
                    ? translate("Processing payment...", {
                      en: "Processing payment...",
                      zh: "正在处理付款...",
                      th: "กำลังประมวลผลการชำระเงิน...",
                      tl: "Pinoproseso ang pagbabayad...",
                    })
                    : "Processing payment..."}
                </Typography>
              </Grid>
            )}
          </Grid>
        )}

        {activeStep === 2 && renderConfirmation()}
      </DialogContent>

      <DialogActions>
        {activeStep === 0 && (
          <>
            <Button onClick={onCancel}>
              {translate
                ? translate("Cancel", {
                  en: "Cancel",
                  zh: "取消",
                  th: "ยกเลิก",
                  tl: "Kanselahin",
                })
                : "Cancel"}
            </Button>
            <Button variant="contained" onClick={handleConfirmOrder}>
              {translate
                ? translate("Confirm Order", {
                  en: "Confirm Order",
                  zh: "确认订单",
                  th: "ยืนยันคำสั่งซื้อ",
                  tl: "Kumpirmahin ang Order",
                })
                : "Confirm Order"}
            </Button>
          </>
        )}

        {/*
        {activeStep === 1 && (
          <>
            <Button onClick={() => setActiveStep(0)}>
              {translate
                ? translate("Back", {
                    en: "Back",
                    zh: "返回",
                    th: "กลับ",
                    tl: "Bumalik",
                  })
                : "Back"}
            </Button>
            <Button
              variant="contained"
              onClick={handleProcessPayment}
              disabled={loading}
            >
              {loading
                ? translate
                  ? translate("Processing...", {
                      en: "Processing...",
                      zh: "处理中...",
                      th: "กำลังประมวลผล...",
                      tl: "Pinoproseso...",
                    })
                  : "Processing..."
                : translate
                ? translate("Complete Payment", {
                    en: "Complete Payment",
                    zh: "完成付款",
                    th: "ชำระเงิน",
                    tl: "Kumpletuhin ang Pagbabayad",
                  })
                : "Complete Payment"}
            </Button>
          </>
        )} 
                */}

        {activeStep === 2 && (
          <Button variant="contained" onClick={handleComplete} fullWidth>
            Print Receipt and Done
          </Button>
        )}
      </DialogActions>
      <ErrorDialog />
    </Dialog>
  );
};

export default PaymentDialog;
