import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Box from '@mui/material/Box'
import Typography from "@mui/material/Typography";
import Button from '@mui/material/Button'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import TextField from "@mui/material/TextField";
import MenuItems from "./MenuItems";
import MenuArchive from "./MenuArchive";
import CreateMenuItemDialog from "./Dialogs/CreateMenuItemDialog";
import EditMenuItemDialog from "./Dialogs/EditMenuItemDialog";
import EditOptionItems from "./Dialogs/EditOptionItems";
import EditTagsItems from "./Dialogs/EditTagsItems";
import EditArrangementDialog from "./Dialogs/EditArrangementDialog";
import axios from "axios";
import { getAuthCredentials } from "../../utils/auth";
import Swal from "sweetalert2";

const MenuCreation = () => {
  const navigate = useNavigate();
  const [tabValue, setTabValue] = useState(0);
  const [menuItems, setMenuItems] = useState([]);
  const [menuCategories, setMenuCategories] = useState([]);
  const [archivedItems, setArchivedItems] = useState([]);
  const [ingredients, setIngredients] = useState([]);
  const [openCreateDialog, setOpenCreateDialog] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [archiveDialogOpen, setArchiveDialogOpen] = useState(false);
  const [itemToArchive, setItemToArchive] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [itemToEdit, setItemToEdit] = useState(null);
  const [editOptionsDialogOpen, setEditOptionsDialogOpen] = useState(false);
  const [itemToEditOptions, setItemToEditOptions] = useState(null);
  const [editTagsDialogOpen, setEditTagsDialogOpen] = useState(false);
  const [itemToEditTags, setItemToEditTags] = useState(null);
  const [editArrangementDialogOpen, setEditArrangementDialogOpen] =
    useState(false);

  const { token } = getAuthCredentials(navigate);
  if (!token) {
    return;
  }

  useEffect(() => {
    fetchMenuData();
    fetchIngredientsData();
    fetchMenuCategories();
  }, []);

  const fetchMenuData = async () => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/v1/api/menu/fetch`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      setMenuItems(data.menu.menuItems || []);
      setArchivedItems(data.menu.archivedItems || []);
    } catch (error) {
      console.error("Error fetching menu data:", error);
    }
  };

  const fetchMenuCategories = async () => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/v1/api/menu/fetch-categories`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setMenuCategories(data.categories || []);
    } catch (error) {
      console.error("Error fetching menu categories:", error);
    }
  };

  const fetchIngredientsData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/v1/api/inventory/fetch`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setIngredients(response.data.inventoryItems || []);
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setIngredients([]);
        Swal.fire({
          icon: "info",
          title: "No Inventory Items",
          text: "No inventory items found for this POS.",
        });
      } else {
        console.error("Error fetching inventory:", error);
      }
    }
  };

  const handleTabChange = (event, newValue) => setTabValue(newValue);

  const handleCreateItem = () => setOpenCreateDialog(true);

  const handleSaveNewItem = async (newItem) => {
    try {
      const formData = new FormData();

      if (newItem.menuImage) {
        formData.append("menuImage", newItem.menuImage);
      }

      const jsonPayload = {
        name: newItem.name,
        category: newItem.category,
        price: newItem.price,
        description: newItem.description,
        status: newItem.status,
        tags: newItem.tags,
        ingredients: newItem.ingredients,
        addons: newItem.addons,
        processTime: newItem.processTime,
      };
      formData.append("data", JSON.stringify(jsonPayload));

      await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/v1/api/menu/create`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      fetchMenuData();

      Swal.fire({
        icon: "success",
        title: "Created",
        text: "Menu item created successfully.",
      });
    } catch (error) {
      console.error("Error creating menu item:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Failed to create menu item. Please try again.",
      });
    }
  };

  const handleEditOptions = async (menuItemId, updatedItem) => {
    try {
      const payload = { ...updatedItem, options: updatedItem.options };

      await axios.put(
        `${process.env.REACT_APP_BACKEND_URL}/v1/api/menu/options/${menuItemId}`,
        payload,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      fetchMenuData();
      Swal.fire({
        icon: "success",
        title: "Updated",
        text: "Options updated successfully.",
      });
    } catch (error) {
      console.error("Error editing menu item options:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Failed to update options. Please try again.",
      });
    }
  };

  const handleArchiveItem = async (item) => {
    try {
      await axios.put(
        `${process.env.REACT_APP_BACKEND_URL}/v1/api/menu/archive/${item._id}`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      fetchMenuData();
      Swal.fire({
        icon: "success",
        title: "Archived",
        text: "Menu item archived successfully.",
      });
    } catch (error) {
      console.error("Error archiving item:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Failed to archive menu item. Please try again.",
      });
    }
  };

  const filteredMenuItems = menuItems.filter((item) =>
    item.menuName.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const filteredArchivedItems = archivedItems.filter((item) =>
    item.menuName.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <Box sx={{ padding: 3 }}>
      <Button
        variant="contained"
        color="primary"
        onClick={() => navigate(-1)}
        sx={{ marginBottom: 2 }}
      >
        Back
      </Button>

      <Typography variant="h4" align="center" gutterBottom>
        Menu Creation
      </Typography>

      <Tabs value={tabValue} onChange={handleTabChange} centered>
        <Tab label="Active" />
        <Tab label="Archive" />
      </Tabs>

      <Box sx={{ paddingTop: 3 }}>
        {tabValue === 0 && (
          <>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                gap: 2,
                marginBottom: 2,
              }}
            >
              <TextField
                label="Search Menu"
                variant="outlined"
                size="small"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              <Button
                variant="contained"
                color="primary"
                onClick={handleCreateItem}
              >
                Create Item
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => setEditArrangementDialogOpen(true)}
              >
                Edit Arrangement
              </Button>
            </Box>

            <MenuItems
              menuItems={filteredMenuItems}
              ingredients={ingredients}
              onArchive={handleArchiveItem}
              onEdit={(item) => {
                setItemToEdit(item);
                setEditDialogOpen(true);
              }}
              onEditOptions={(item) => {
                setItemToEditOptions(item);
                setEditOptionsDialogOpen(true);
              }}
              onEditTags={(item) => {
                setItemToEditTags(item);
                setEditTagsDialogOpen(true);
              }}
            />
          </>
        )}

        {tabValue === 1 && (
          <>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                gap: 2,
                marginBottom: 2,
              }}
            >
              {/* <TextField
                label="Search Archived Items"
                variant="outlined"
                size="small"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              /> */}
            </Box>

            <MenuArchive
              archivedItems={filteredArchivedItems}
              ingredients={ingredients}
              fetchMenuData={fetchMenuData}
            />
          </>
        )}
      </Box>

      <CreateMenuItemDialog
        open={openCreateDialog}
        onClose={() => setOpenCreateDialog(false)}
        onSave={handleSaveNewItem}
        ingredients={ingredients}
        menuCategories={menuCategories}
        setMenuCategories={setMenuCategories}
        menuItems={menuItems}
        fetchMenuCategories={fetchMenuCategories}
      />

      {editDialogOpen && itemToEdit && (
        <EditMenuItemDialog
          open={editDialogOpen}
          onClose={() => setEditDialogOpen(false)}
          item={itemToEdit}
          ingredients={ingredients}
          menuCategories={menuCategories}
          setMenuCategories={setMenuCategories}
          menuItems={menuItems}
          fetchMenuCategories={fetchMenuCategories}
          fetchMenuData={fetchMenuData}
        />
      )}

      {editOptionsDialogOpen && itemToEditOptions && (
        <EditOptionItems
          open={editOptionsDialogOpen}
          onClose={() => setEditOptionsDialogOpen(false)}
          menuItem={itemToEditOptions}
          onSave={(updatedItem) =>
            handleEditOptions(itemToEditOptions._id, updatedItem)
          }
          menuItems={menuItems}
          ingredients={ingredients}
        />
      )}

      {editTagsDialogOpen && itemToEditTags && (
        <EditTagsItems
          open={editTagsDialogOpen}
          onClose={() => setEditTagsDialogOpen(false)}
          menuItem={itemToEditTags}
          menuTags={itemToEditTags.menuTags}
          onRefetch={fetchMenuData}
        />
      )}

      {editArrangementDialogOpen && (
        <EditArrangementDialog
          open={editArrangementDialogOpen}
          onClose={() => setEditArrangementDialogOpen(false)}
          menuCategories={menuCategories}
          fetchMenuCategories={fetchMenuCategories}
          fetchMenuData={fetchMenuData}
          activeMenuItems={menuItems}
        />
      )}
    </Box>
  );
};

export default MenuCreation;
