import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import useMediaQuery from "@mui/material/useMediaQuery";
import DescriptionIcon from "@mui/icons-material/Description";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { useTheme } from "@mui/material/styles";

export const exportToExcel = (history, date) => {
  const worksheet = XLSX.utils.json_to_sheet(history);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Bill History");
  XLSX.writeFile(workbook, `Bill_History_${date}.xlsx`);
};

export const exportToPDF = (history, date) => {
  const doc = new jsPDF();
  doc.text("Bill History", 14, 10);

  const tableColumn = [
    "Date/Time",
    "Action",
    "Bill Type",
    "Denomination",
    "Qty Before",
    "Qty After",
    "Total Before",
    "Total After",
  ];
  const tableRows = history.map((record) => [
    new Date(record.timestamp).toLocaleString(),
    record.action,
    record.billType,
    `${record.denomination}`,
    record.quantityBefore,
    record.quantityAfter,
    `${record.totalValueBefore.toFixed(2)}`,
    `${record.totalValueAfter.toFixed(2)}`,
  ]);

  doc.autoTable({
    head: [tableColumn],
    body: tableRows,
  });

  doc.save(`Bill_History_${date}.pdf`);
};

export const ExportButtons = ({ history, date }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm")); 

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: isMobile ? "column" : "row", 
        gap: 2,
        alignItems: "center",
      }}
    >
      <Button
        variant="contained"
        color="primary"
        onClick={() => exportToExcel(history, date)}
        startIcon={<DescriptionIcon />}
        sx={{ width: isMobile ? "100%" : "auto" }} 
      >
        {isMobile ? "Export CSV" : "Export to CSV"}
      </Button>
      <Button
        variant="contained"
        color="secondary"
        onClick={() => exportToPDF(history, date)}
        startIcon={<PictureAsPdfIcon />}
        sx={{ width: isMobile ? "100%" : "auto" }} 
      >
        {isMobile ? "Export PDF" : "Export to PDF"}
      </Button>
    </Box>
  );
};
