import React from "react";
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import { StarBorder, Star } from "@mui/icons-material";
import BillDialog from "./BillDialog";
import { toggleViewModule } from "./BillFunctions";
import { validateOTP } from "../../../../component/OTPValidator";

const BillInvDetailed = ({ bills, refreshBills }) => {
  const [selectedBill, setSelectedBill] = React.useState(null);
  const [billDialogOpen, setBillDialogOpen] = React.useState(false);

  const openDialog = async (bill) => {
    const isValid = await validateOTP(`Replenish Bill - ₱${bill.denomination} ${bill.boxType}`);
    if (isValid) {
      setSelectedBill(bill);
      setBillDialogOpen(true);
    }
  };

  const closeDialog = () => {
    setBillDialogOpen(false);
    setSelectedBill(null);
  };

  return (
    <Box sx={{ marginTop: 3, display: "flex", gap: 3 }}>
      {bills.length === 0 ? (
        <Typography
          variant="h6"
          sx={{ textAlign: "center", width: "100%", color: "gray" }}
        >
          No Bills Found
        </Typography>
      ) : (
        <>
          {/* Cashbox Section */}
          <Box sx={{ flex: 1 }}>
            <Typography variant="h4" sx={{ marginBottom: 2 }}>
              Cashbox
            </Typography>

            {/* Cash in Cashbox */}
            {bills.some(
              (bill) => bill.billType === "Cash" && bill.boxType === "Cashbox"
            ) && (
              <>
                <Typography variant="h5" sx={{ marginBottom: 2 }}>
                  Cash
                </Typography>
                {bills
                  .filter(
                    (bill) =>
                      bill.billType === "Cash" && bill.boxType === "Cashbox"
                  )
                  .map((bill) => (
                    <Box
                      key={bill._id}
                      sx={{
                        position: "relative",
                        padding: 2,
                        marginBottom: 1,
                        border: "1px solid #ccc",
                        borderRadius: 2,
                        cursor: "pointer",
                      }}
                      onClick={() => openDialog(bill)}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Typography variant="h6">
                          ₱{bill.denomination} x {bill.quantity}
                        </Typography>
                        <IconButton
                          sx={{ marginLeft: "auto" }}
                          onClick={(e) => {
                            e.stopPropagation();
                            toggleViewModule(bill._id, refreshBills);
                          }}
                        >
                          {bill.viewModule ? (
                            <Star color="primary" />
                          ) : (
                            <StarBorder />
                          )}
                        </IconButton>
                      </Box>
                      <Typography variant="body2">
                        Total Value: ₱{bill.totalValue.toFixed(2)}
                      </Typography>
                    </Box>
                  ))}
              </>
            )}

            {/* Coins in Cashbox */}
            {bills.some(
              (bill) => bill.billType === "Coin" && bill.boxType === "Cashbox"
            ) && (
              <>
                <Typography variant="h5" sx={{ marginBottom: 2 }}>
                  Coins
                </Typography>
                {bills
                  .filter(
                    (bill) =>
                      bill.billType === "Coin" && bill.boxType === "Cashbox"
                  )
                  .map((bill) => (
                    <Box
                      key={bill._id}
                      sx={{
                        position: "relative",
                        padding: 2,
                        marginBottom: 1,
                        border: "1px solid #ccc",
                        borderRadius: 2,
                        cursor: "pointer",
                      }}
                      onClick={() => openDialog(bill)}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Typography variant="h6">
                          ₱{bill.denomination} x {bill.quantity}
                        </Typography>
                        <IconButton
                          sx={{ marginLeft: "auto" }}
                          onClick={(e) => {
                            e.stopPropagation();
                            toggleViewModule(bill._id, refreshBills);
                          }}
                        >
                          {bill.viewModule ? (
                            <Star color="primary" />
                          ) : (
                            <StarBorder />
                          )}
                        </IconButton>
                      </Box>
                      <Typography variant="body2">
                        Total Value: ₱{bill.totalValue.toFixed(2)}
                      </Typography>
                    </Box>
                  ))}
              </>
            )}
          </Box>

          {/* Changebox Section (Excluding if changeLimit is 0) */}
          {bills.some(
            (bill) => bill.boxType === "Changebox" && bill.changeLimit > 0
          ) && (
            <Box sx={{ flex: 1 }}>
              <Typography variant="h4" sx={{ marginBottom: 2 }}>
                Changebox
              </Typography>

              {/* Cash in Changebox */}
              {bills.some(
                (bill) =>
                  bill.billType === "Cash" &&
                  bill.boxType === "Changebox" &&
                  bill.changeLimit > 0
              ) && (
                <>
                  <Typography variant="h5" sx={{ marginBottom: 2 }}>
                    Cash
                  </Typography>
                  {bills
                    .filter(
                      (bill) =>
                        bill.billType === "Cash" &&
                        bill.boxType === "Changebox" &&
                        bill.changeLimit > 0
                    )
                    .map((bill) => (
                      <Box
                        key={bill._id}
                        sx={{
                          position: "relative",
                          padding: 2,
                          marginBottom: 1,
                          border: "1px solid #ccc",
                          borderRadius: 2,
                          cursor: "pointer",
                        }}
                        onClick={() => openDialog(bill)}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Typography variant="h6">
                            ₱{bill.denomination} x {bill.quantity}
                          </Typography>
                          <IconButton
                            sx={{ marginLeft: "auto" }}
                            onClick={(e) => {
                              e.stopPropagation();
                              toggleViewModule(bill._id, refreshBills);
                            }}
                          >
                            {bill.viewModule ? (
                              <Star color="primary" />
                            ) : (
                              <StarBorder />
                            )}
                          </IconButton>
                        </Box>
                        <Typography variant="body2">
                          Total Value: ₱{bill.totalValue.toFixed(2)}
                        </Typography>
                      </Box>
                    ))}
                </>
              )}

              {/* Coins in Changebox */}
              {bills.some(
                (bill) =>
                  bill.billType === "Coin" &&
                  bill.boxType === "Changebox" &&
                  bill.changeLimit > 0
              ) && (
                <>
                  <Typography variant="h5" sx={{ marginBottom: 2 }}>
                    Coins
                  </Typography>
                  {bills
                    .filter(
                      (bill) =>
                        bill.billType === "Coin" &&
                        bill.boxType === "Changebox" &&
                        bill.changeLimit > 0
                    )
                    .map((bill) => (
                      <Box
                        key={bill._id}
                        sx={{
                          position: "relative",
                          padding: 2,
                          marginBottom: 1,
                          border: "1px solid #ccc",
                          borderRadius: 2,
                          cursor: "pointer",
                        }}
                        onClick={() => openDialog(bill)}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Typography variant="h6">
                            ₱{bill.denomination} x {bill.quantity}
                          </Typography>
                          <IconButton
                            sx={{ marginLeft: "auto" }}
                            onClick={(e) => {
                              e.stopPropagation();
                              toggleViewModule(bill._id, refreshBills);
                            }}
                          >
                            {bill.viewModule ? (
                              <Star color="primary" />
                            ) : (
                              <StarBorder />
                            )}
                          </IconButton>
                        </Box>
                        <Typography variant="body2">
                          Total Value: ₱{bill.totalValue.toFixed(2)}
                        </Typography>
                      </Box>
                    ))}
                </>
              )}
            </Box>
          )}

          {/* Bill Dialog */}
          <BillDialog
            open={billDialogOpen}
            onClose={closeDialog}
            bill={selectedBill}
            refreshBills={refreshBills}
          />
        </>
      )}
    </Box>
  );
};

export default BillInvDetailed;
