import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import SecureLS from "secure-ls";
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CssBaseline from '@mui/material/CssBaseline';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import axios from "axios";
import Loading from "../../theme/Loading";
import appConfig from "../../config/appConfig";

const ls = new SecureLS({ encodingType: "aes" });

const Login = () => {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const authToken = ls.get("authToken");
    const user = ls.get("user");

    if (authToken && user) {
      navigate("/dashboard");
    }
  }, [navigate]);

  const handleEmailChange = (e) => {
    const inputEmail = e.target.value;
    setEmail(inputEmail);

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setEmailError(
      !emailPattern.test(inputEmail)
        ? "Please enter a valid email address."
        : ""
    );
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    setPasswordError("");
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  const handleLogin = async (e) => {
    e.preventDefault();

    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/v1/api/auth/login`,
        { email, password }
      );
      const { token, user, redirectUrl } = response.data;

      ls.set("authToken", token);
      ls.set("user", user);

      navigate(redirectUrl);

      setEmail("");
      setPassword("");
      setEmailError("");
      setPasswordError("");
    } catch (error) {
      console.error("Login failed", error);

      if (error.response && error.response.status === 400) {
        if (
          error.response.data.message ===
          "No account is associated with this email."
        ) {
          setEmailError("No account is associated with this email.");
        } else if (
          error.response.data.message === "Invalid email or password"
        ) {
          setPasswordError("Incorrect password. Please try again.");
        }
      } else {
        setPasswordError("Something went wrong. Please try again.");
      }
    } finally {
      setLoading(false);
    }
  };

  const isLoginDisabled = !!emailError || !email || !password;

  return (
    <>
      <CssBaseline />
      {loading && <Loading />}
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            bgcolor: "#f0f0f0",
            padding: 4,
            borderRadius: 2,
            boxShadow: 3,
          }}
        >
          <Typography
            component="h1"
            variant="h5"
            sx={{ color: "primary.main" }}
          >
            POS Login
          </Typography>
          <Box component="form" onSubmit={handleLogin} sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              label="Email"
              type="email"
              value={email}
              onChange={handleEmailChange}
              error={!!emailError}
              helperText={emailError}
              variant="outlined"
              autoComplete="email"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              label="Password"
              type={showPassword ? "text" : "password"}
              value={password}
              onChange={handlePasswordChange}
              error={!!passwordError}
              helperText={passwordError}
              variant="outlined"
              autoComplete="current-password"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleTogglePasswordVisibility}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              disabled={isLoginDisabled}
            >
              Login
            </Button>
            <Typography variant="body2" align="center" sx={{ mt: 2 }}>
              <Button
                onClick={() => navigate("/signup")}
                variant="text"
                sx={{ textTransform: "none" }}
              >
                New User? Signup
              </Button>
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            position: "absolute",
            bottom: { xs: "auto", sm: 10 },
            top: { xs: 10, sm: "auto" },
            left: 10,
            opacity: 0.75,
          }}
        >
          {/* <Typography variant="body2" color="textSecondary">
            {`${appConfig.appName} Version ${appConfig.appVersion}`}
          </Typography> */}
        </Box>
      </Container>
    </>
  );
};

export default Login;
