import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Autocomplete from "@mui/material/Autocomplete";
import { Delete } from "@mui/icons-material";
import axios from "axios";
import { getAuthCredentials } from "../../../utils/auth";
import OptionsModal from "./OptionsModal";
import SecureLS from "secure-ls";

const ls = new SecureLS({ encodingType: "aes" });

const OrderModal = ({ open, onClose, onOrderGenerated }) => {
  const [menuItems, setMenuItems] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [discountType, setDiscountType] = useState("PWD");
  const [voucherCode, setVoucherCode] = useState("");
  const [salesType, setSalesType] = useState("Dine-In");
  const [tenderType, setTenderType] = useState("Cash");
  const [optionModalOpen, setOptionModalOpen] = useState(false);
  const [currentMenuItem, setCurrentMenuItem] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState({});
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const user = ls.get("user");
  const { token } = getAuthCredentials();

  useEffect(() => {
    if (open) {
      fetchMenuItems();
      setSelectedItems([]);
    }
  }, [open]);

  const fetchMenuItems = async () => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/v1/api/menu/fetch`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setMenuItems(data.menu.menuItems || []);
    } catch (error) {
      console.error("Error fetching menu items:", error);
    }
  };

  const handleAddMenuItem = () => {
    setSelectedItems((prev) => [...prev, { menuItemId: "", quantity: 1 }]);
  };

  const handleMenuItemChange = (index, menuItemId) => {
    const updatedItems = [...selectedItems];
    updatedItems[index].menuItemId = menuItemId;

    const menuItem = menuItems.find((m) => m._id === menuItemId);

    if (menuItem?.options?.length) {
      setCurrentMenuItem({ index, ...menuItem });
      setOptionModalOpen(true);
    } else {
      updatedItems[index].options = [];
      setSelectedItems(updatedItems);
    }
  };

  const handleOptionConfirm = (optionsData) => {
    if (!currentMenuItem) return;

    const updatedItems = [...selectedItems];
    let additionalPrice = 0;

    const formattedOptions = Object.entries(optionsData.options).map(
      ([optionId, choices]) => {
        return {
          optionId,
          choices: Array.isArray(choices) ? choices : [choices], // Ensure array format
        };
      }
    );

    currentMenuItem.options.forEach((option) => {
      const selectedChoices = optionsData.options[option._id] || [];
      const choiceArray = Array.isArray(selectedChoices)
        ? selectedChoices
        : [selectedChoices];

      choiceArray.forEach((choiceName) => {
        const foundChoice = option.choices.find((c) => c.name === choiceName);
        if (foundChoice) additionalPrice += foundChoice.price;
      });
    });

    updatedItems[currentMenuItem.index] = {
      ...updatedItems[currentMenuItem.index],
      mealType: optionsData.mealType,
      options: formattedOptions,
      additionalPrice,
    };

    setSelectedItems(updatedItems);
    setOptionModalOpen(false);
    setCurrentMenuItem(null);
  };

  const handleQuantityChange = (index, quantity) => {
    const updatedItems = [...selectedItems];
    updatedItems[index] = {
      ...updatedItems[index],
      quantity: Math.max(1, Number(quantity)),
    };
    setSelectedItems(updatedItems);
  };

  const handleRemoveMenuItem = (index) => {
    const updatedItems = [...selectedItems];
    updatedItems.splice(index, 1);
    setSelectedItems(updatedItems);
  };

  const handleClose = () => {
    setSelectedItems([]);
    setDiscountType("PWD");
    setVoucherCode("");
    setSalesType("Dine-In");
    setTenderType("Cash");
    setErrorMessage("");
    onClose();
  };

  const handleSubmit = async () => {
    if (
      selectedItems.length === 0 ||
      selectedItems.some((item) => !item.menuItemId || item.quantity < 1) ||
      !salesType ||
      !tenderType ||
      (discountType === "Voucher" && !voucherCode)
    ) {
      setErrorMessage("Please fill out all fields before submitting.");
      return;
    }

    setLoading(true);
    setErrorMessage("");

    try {
      const emptyReceiptResponse = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/v1/api/order/create-empty-receipt`,
        {
          posId: user.posId,
          salesType,
          tenderType,
          discountType,
          voucherCode,
          discountDesc: voucherCode,
          menuItems: selectedItems,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      const receiptNumber = emptyReceiptResponse.data.receiptNumber;

      const processOrderResponse = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/v1/api/order/process-order`,
        {
          posId: user.posId,
          receiptNumber,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
          responseType: "blob",
        }
      );

      if (processOrderResponse.status === 200) {
        const pdfBlob = new Blob([processOrderResponse.data], {
          type: "application/pdf",
        });

        const pdfUrl = URL.createObjectURL(pdfBlob);
        window.open(pdfUrl, "_blank");

        onOrderGenerated(processOrderResponse.data);
        alert("Sample order generated successfully.");
        handleClose();
      }
    } catch (error) {
      console.error("Error processing order:", error);
      handleApiError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleApiError = async (error) => {
    console.log("Error:", error);

    if (error.response) {
      try {
        const contentType = error.response.headers["content-type"];

        if (contentType && contentType.includes("application/json")) {
          if (error.response.data instanceof Blob) {
            const errorText = await error.response.data.text();
            const errorData = JSON.parse(errorText);
            const backendMessage =
              errorData.message ||
              "An error occurred while processing the order.";

            setErrorMessage(backendMessage);
            alert(backendMessage);
          } else {
            const backendMessage =
              error.response.data.message ||
              "An error occurred while processing the order.";

            setErrorMessage(backendMessage);
            alert(backendMessage);
          }
        } else {
          setErrorMessage("An error occurred, but the response was not JSON.");
          alert("An error occurred, but the response was not JSON.");
        }
      } catch (parseError) {
        console.error("Error parsing backend response:", parseError);
        setErrorMessage("An unexpected error occurred. Please try again.");
        alert("An unexpected error occurred. Please try again.");
      }
    } else {
      setErrorMessage("A network error occurred. Please try again.");
      alert("A network error occurred. Please try again.");
    }
  };

  const handleDiscountTypeChange = (e) => {
    const selectedDiscount = e.target.value;
    setDiscountType(selectedDiscount);

    if (selectedDiscount !== "Voucher") {
      setVoucherCode("");
    }
  };

  return (
    <>
      <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
        <DialogTitle>Generate Sample Order</DialogTitle>
        <DialogContent>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
            {/* Discount Type */}
            <TextField
              select
              label="Select Discount Type"
              value={discountType}
              onChange={handleDiscountTypeChange}
              fullWidth
              sx={{ marginTop: 1 }}
            >
              <MenuItem value="None">None</MenuItem>
              <MenuItem value="PWD">PWD</MenuItem>
              <MenuItem value="Senior">Senior</MenuItem>
              <MenuItem value="Athlete">Athlete</MenuItem>
              <MenuItem value="Diplomat">Diplomat</MenuItem>
              <MenuItem value="SoloParent">Solo Parent</MenuItem>
              <MenuItem value="Voucher">Voucher</MenuItem>
            </TextField>

            {discountType === "Voucher" && (
              <TextField
                label="Voucher Code"
                value={voucherCode}
                onChange={(e) => setVoucherCode(e.target.value)}
                fullWidth
              />
            )}

            <TextField
              select
              label="Select Sales Type"
              value={salesType}
              onChange={(e) => setSalesType(e.target.value)}
              fullWidth
            >
              <MenuItem value="Dine-In">Dine-In</MenuItem>
              <MenuItem value="Take-Out">Take-Out</MenuItem>
              <MenuItem value="Delivery">Delivery</MenuItem>
            </TextField>

            <TextField
              select
              label="Select Tender Type"
              value={tenderType}
              onChange={(e) => setTenderType(e.target.value)}
              fullWidth
            >
              <MenuItem value="Cash">Cash</MenuItem>
              <MenuItem value="Credit">Credit</MenuItem>
            </TextField>

            {selectedItems.map((item, index) => {
              const menuItem = menuItems.find((m) => m._id === item.menuItemId);
              const itemPrice = menuItem ? menuItem.basePrice : 0;
              const optionsCost = item.additionalPrice || 0;
              const totalItemPrice = (
                (itemPrice + optionsCost) *
                item.quantity
              ).toFixed(2);

              return (
                <Box
                  key={index}
                  sx={{ display: "flex", flexDirection: "column", gap: 1 }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 2,
                      width: "100%",
                    }}
                  >
                    <Autocomplete
                      options={menuItems}
                      getOptionLabel={(option) =>
                        option.menuName
                          ? `${
                              option.menuName
                            } - PHP ${option.basePrice.toFixed(2)}`
                          : ""
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Search Menu Item"
                          fullWidth
                          sx={{ minWidth: 250 }}
                        />
                      )}
                      value={
                        menuItems.find((m) => m._id === item.menuItemId) || null
                      }
                      onChange={(_, newValue) => {
                        if (newValue) {
                          handleMenuItemChange(index, newValue._id);
                        }
                      }}
                      sx={{ flex: 1 }}
                    />

                    <TextField
                      label="Quantity"
                      type="number"
                      value={item.quantity}
                      onChange={(e) =>
                        handleQuantityChange(index, e.target.value)
                      }
                      sx={{ width: 80, minWidth: 80 }}
                      InputProps={{ inputProps: { min: 1 } }}
                    />

                    <Typography
                      sx={{
                        minWidth: 80,
                        textAlign: "right",
                        fontWeight: "bold",
                        whiteSpace: "nowrap",
                      }}
                    >
                      PHP {totalItemPrice}
                    </Typography>

                    <IconButton
                      color="error"
                      onClick={() => handleRemoveMenuItem(index)}
                    >
                      <Delete />
                    </IconButton>
                  </Box>

                  {/* Display Selected Options */}
                  {item.options && item.options.length > 0 && (
                    <Box sx={{ marginLeft: 2 }}>
                      <Typography variant="subtitle2">Options:</Typography>
                      {item.options.map((option) => (
                        <Typography
                          key={option.optionId}
                          variant="body2"
                          color="textSecondary"
                        >
                          {
                            menuItem.options.find(
                              (o) => o._id === option.optionId
                            )?.optionName
                          }
                          : {option.choices.join(", ")}
                        </Typography>
                      ))}
                    </Box>
                  )}
                </Box>
              );
            })}

            <Button variant="outlined" onClick={handleAddMenuItem}>
              Add Menu Item
            </Button>
          </Box>
          {errorMessage && (
            <Typography
              color="error"
              sx={{ textAlign: "center", mb: 2, mt: 2, fontWeight: "bold" }}
            >
              {errorMessage}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="secondary">
            Cancel
          </Button>
          <Button
            onClick={handleSubmit}
            variant="contained"
            color="primary"
            disabled={
              loading ||
              selectedItems.length === 0 ||
              selectedItems.some(
                (item) => !item.menuItemId || item.quantity < 1
              ) ||
              !salesType ||
              !tenderType ||
              (discountType === "Voucher" && !voucherCode)
            }
          >
            {loading ? (
              <>
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                Ordering...
              </>
            ) : (
              "Generate Order"
            )}
          </Button>
        </DialogActions>
      </Dialog>
      <OptionsModal
        open={optionModalOpen}
        menuItem={currentMenuItem}
        selectedOptions={selectedOptions}
        onClose={() => setOptionModalOpen(false)}
        onConfirm={handleOptionConfirm}
      />
    </>
  );
};

export default OrderModal;
