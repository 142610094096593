import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import SecureLS from "secure-ls";

const ls = new SecureLS({ encodingType: "aes" });

const AccountHero = ({ title }) => {
  const navigate = useNavigate();
  const user = ls.get("user");
  const modules = user?.modules || [];
  const role = user?.role || "";

  const isOtpRole =
    modules.some((m) =>
      ["OTPGeneration", "OTP Generation", "Superadmin"].includes(m)
    ) || ["OTPGeneration", "OTP Generation", "Superadmin"].includes(role);

  return (
    <Box sx={{ padding: 3 }}>
      <Button
        variant="contained"
        color="primary"
        startIcon={<ArrowBackIcon />}
        onClick={() => navigate("/dashboard")}
        sx={{ marginBottom: 3 }}
      >
        Back
      </Button>
      <Typography variant="h4" align="center" gutterBottom>
        {title || "Account Management"}
      </Typography>
      {isOtpRole && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            marginBottom: 4,
            gap: 2,
          }}
        >
          <Button
            variant="outlined"
            onClick={() => navigate("/account-management")}
          >
            Profile
          </Button>
          <Button
            variant="outlined"
            onClick={() => navigate("/account-management/otps")}
          >
            OTPs
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default AccountHero;
