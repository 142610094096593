import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import SecureLS from "secure-ls";

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Autocomplete from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import AddCategoryDialog from "./AddCategoryDialog";

const ls = new SecureLS({ encodingType: "aes" });

const EditInventoryDialog = ({
  open,
  onClose,
  item,
  categories = [],
  fetchCategories,
  fetchInventory,
  setCategories,
  existingInventoryNames,
}) => {
  const [name, setName] = useState("");
  const [category, setCategory] = useState("");
  const [unitOfMeasurement, setUnitOfMeasurement] = useState("");
  const [optionalMeasurements, setOptionalMeasurements] = useState([]);
  const [allergenInput, setAllergenInput] = useState("");
  const [allergenTag, setAllergenTag] = useState([]);
  const [isAddingCategory, setIsAddingCategory] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);

  useEffect(() => {
    if (item) {
      setName(item.inventoryName || "");
      setCategory(item.categoryId || "");
      setUnitOfMeasurement(item.unitOfMeasurement || "");
      setOptionalMeasurements(item.optionalMeasurements);
      setAllergenTag(item.allergenTag);
    }
  }, [item]);

  useEffect(() => {
    if (item) {
      const originalOptional = JSON.stringify(item.optionalMeasurements || []);
      const currentOptional = JSON.stringify(optionalMeasurements);
      const originalAllergen = JSON.stringify(item.allergenTag || []);
      const currentAllergen = JSON.stringify(allergenTag);

      const changes =
        item.inventoryName !== name ||
        item.categoryId !== category ||
        item.unitOfMeasurement !== unitOfMeasurement ||
        originalOptional !== currentOptional ||
        originalAllergen !== currentAllergen;

      setHasChanges(changes);
    }
  }, [
    name,
    category,
    unitOfMeasurement,
    optionalMeasurements,
    allergenTag,
    item,
  ]);

  const handleDeleteCategory = async (categoryId) => {
    const user = ls.get("user");
    const token = ls.get("authToken");
    const posId = user?.posId;

    if (!token || !posId) {
      onClose();
      return;
    }

    try {
      await axios.delete(
        `${process.env.REACT_APP_BACKEND_URL}/v1/api/inventory/delete-category`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          data: { categoryId, posId },
        }
      );

      await fetchCategories(token);

      Swal.fire({
        icon: "success",
        title: "Success",
        text: "Category has been successfully deleted.",
      });
    } catch (error) {
      console.error("Error deleting category:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Failed to delete category. Please try again.",
      });
    }
  };

  const handleEdit = async () => {
    const selectedCategory = categories.find(
      (cat) => cat.categoryId === category
    );

    if (!selectedCategory) {
      Swal.fire({
        icon: "error",
        title: "Failed",
        text: "Invalid category selected. Please try again.",
      });
      return;
    }

    const updatedItem = {
      name,
      categoryId: category,
      categoryName: selectedCategory.categoryName,
      unitOfMeasurement,
      optionalMeasurements,
      allergenTag,
    };

    try {
      const user = ls.get("user");
      const token = ls.get("authToken");
      const posId = user?.posId;

      if (!token || !posId) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "You must be logged in to edit an item.",
        });
        return;
      }

      await axios.put(
        `${process.env.REACT_APP_BACKEND_URL}/v1/api/inventory/edit/${item._id}`,
        updatedItem,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      await fetchInventory();

      Swal.fire({
        icon: "success",
        title: "Success!",
        text: "Item updated successfully.",
      });
    } catch (error) {
      console.error("Error updating item:", error);

      Swal.fire({
        icon: "error",
        title: "Failed",
        text: "Failed to update item. Please try again.",
      });
    }

    resetFields();
    onClose();
  };

  const resetFields = () => {
    setName("");
    setCategory("");
  };

  const addOptionalMeasurement = () => {
    setOptionalMeasurements((prev) => [...prev, { name: "", value: "" }]);
  };

  const handleOptionalMeasurementChange = (index, field, newValue) => {
    setOptionalMeasurements((prev) =>
      prev.map((measurement, i) =>
        i === index ? { ...measurement, [field]: newValue } : measurement
      )
    );
  };

  const removeOptionalMeasurement = (index) => {
    setOptionalMeasurements((prev) => prev.filter((_, i) => i !== index));
  };

  const handleAddAllergen = () => {
    const trimmed = allergenInput.trim();
    if (trimmed) {
      const normalized = trimmed.charAt(0).toUpperCase() + trimmed.slice(1);
      if (
        !allergenTag.some((a) => a.toLowerCase() === normalized.toLowerCase())
      ) {
        setAllergenTag([...allergenTag, normalized]);
      }
    }
    setAllergenInput("");
  };

  const handleDeleteAllergen = (index) => {
    setAllergenTag(allergenTag.filter((_, i) => i !== index));
  };

  const isDuplicateName =
    existingInventoryNames?.some(
      (existingName) => existingName.toLowerCase() === name.trim().toLowerCase()
    ) && name.trim().toLowerCase() !== item.inventoryName.toLowerCase();

  const isAnyFieldEmpty = !name.trim() || !category || !unitOfMeasurement;
  const isAnyOptionalEmpty = optionalMeasurements.some(
    (m) => !m.name.trim() || !String(m.value).trim()
  );
  const isSaveDisabled =
    !hasChanges || isAnyFieldEmpty || isDuplicateName || isAnyOptionalEmpty;

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>Edit Inventory</DialogTitle>
      <DialogContent>
        <TextField
          margin="normal"
          label="Name"
          fullWidth
          value={name}
          onChange={(e) => setName(e.target.value)}
          error={
            existingInventoryNames?.some(
              (existingName) =>
                existingName.toLowerCase() === name.trim().toLowerCase()
            ) && name.trim().toLowerCase() !== item.inventoryName.toLowerCase()
          }
          helperText={
            existingInventoryNames?.some(
              (existingName) =>
                existingName.toLowerCase() === name.trim().toLowerCase()
            ) && name.trim().toLowerCase() !== item.inventoryName.toLowerCase()
              ? "Name already exists"
              : ""
          }
        />
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <TextField
            margin="normal"
            label="Primary Unit of Measurement"
            fullWidth
            value={unitOfMeasurement}
            onChange={(e) => setUnitOfMeasurement(e.target.value)}
            helperText="Lowest Measurement for this Inventory"
          />
          <Button variant="outlined" onClick={addOptionalMeasurement}>
            +UOM
          </Button>
        </Box>
        {optionalMeasurements.map((measurement, index) => (
          <Grid container spacing={2} key={index} sx={{ marginBottom: 1 }}>
            <Grid item xs={5}>
              <TextField
                label="Optional Unit of Measurement"
                fullWidth
                value={measurement.name}
                onChange={(e) =>
                  handleOptionalMeasurementChange(index, "name", e.target.value)
                }
              />
            </Grid>
            <Grid item xs={5}>
              <TextField
                label="Value"
                type="number"
                fullWidth
                value={measurement.value}
                onChange={(e) =>
                  handleOptionalMeasurementChange(
                    index,
                    "value",
                    e.target.value
                  )
                }
                helperText={
                  measurement.name && measurement.value
                    ? `${measurement.name} = ${measurement.value} ${unitOfMeasurement}`
                    : ""
                }
              />
            </Grid>
            <Grid
              item
              xs={2}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <IconButton
                color="error"
                onClick={() => removeOptionalMeasurement(index)}
              >
                <DeleteIcon />
              </IconButton>
            </Grid>
          </Grid>
        ))}

        <Autocomplete
          options={[
            ...categories,
            { categoryId: "add-new", categoryName: "Add New Category" },
          ]}
          getOptionLabel={(option) => option.categoryName || ""}
          value={categories.find((cat) => cat.categoryId === category) || null}
          onChange={(event, newValue) => {
            if (newValue && newValue.categoryId === "add-new") {
              setIsAddingCategory(true);
            } else {
              setCategory(newValue ? newValue.categoryId : "");
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Category"
              variant="outlined"
              margin="normal"
            />
          )}
          renderOption={(props, option) => (
            <li {...props} key={option.categoryId}>
              <span style={{ flexGrow: 1 }}>{option.categoryName}</span>
              {option.categoryId !== "add-new" && (
                <Tooltip title="Delete category">
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDeleteCategory(option.categoryId);
                    }}
                    color="error"
                    size="small"
                  >
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              )}
            </li>
          )}
        />

        {isAddingCategory && (
          <AddCategoryDialog
            open={isAddingCategory}
            onClose={() => setIsAddingCategory(false)}
            onCategoryAdded={(newCategory) => {
              setCategories((prevCategories) => [
                ...prevCategories,
                newCategory,
              ]);
              setCategory(newCategory.categoryId);
              setIsAddingCategory(false);
            }}
          />
        )}

        <TextField
          margin="normal"
          label="Allergen"
          fullWidth
          value={allergenInput}
          onChange={(e) => setAllergenInput(e.target.value)}
          helperText={
            allergenInput.trim() &&
            allergenTag.some(
              (a) => a.toLowerCase() === allergenInput.trim().toLowerCase()
            )
              ? "Allergen already exists"
              : "Type an allergen and click 'Add Allergen'"
          }
        />

        <Button
          variant="outlined"
          onClick={handleAddAllergen}
          sx={{ marginTop: 1 }}
          disabled={
            !allergenInput.trim() ||
            allergenTag.some(
              (a) => a.toLowerCase() === allergenInput.trim().toLowerCase()
            )
          }
        >
          Add Allergen
        </Button>

        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1, marginTop: 2 }}>
          {allergenTag.map((allergen, index) => (
            <Chip
              key={index}
              label={allergen}
              onDelete={() => handleDeleteAllergen(index)}
              color="primary"
              variant="outlined"
              sx={{ textTransform: "none" }}
            />
          ))}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleEdit} color="primary" disabled={isSaveDisabled}>
          Save Changes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditInventoryDialog;
