import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import dayjs from "dayjs";
import SecureLS from "secure-ls";
import Swal from "sweetalert2";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import ArchiveIcon from "@mui/icons-material/Archive";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

// Dialogs
import AddInventoryDialog from "./Dialogs/AddInventoryDialog";
import EditInventoryDialog from "./Dialogs/EditInventoryDialog";
import EditInventoryInstanceDialog from "./Dialogs/EditInventoryInstanceDialog";
import RestockInventoryDialog from "./Dialogs/RestockInventoryDialog";
import AlertDialog from "./Dialogs/AlertDialog";
import LegendDialog from "./Dialogs/LegendDialog";
import ImportConfigDialog from "./Dialogs/ImportConfigDialog";

// Functions
import HandleArchiveItem from "./Functions/HandleArchiveItem";
import HandleDeleteItem from "./Functions/HandleDeleteItem";
import handleAddInventory from "./Functions/HandleAddInventory";
import handleRestockInventory from "./Functions/handleRestockInventory";
import InventoryHero from "./InventoryHero";
import { getAuthCredentials } from "../../utils/auth";

const ls = new SecureLS({ encodingType: "aes" });

const Inventory = () => {
  const [inventory, setInventory] = useState([]);
  const [categories, setCategories] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [archiveDialogOpen, setArchiveDialogOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [itemToArchive, setItemToArchive] = useState(null);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [itemToEdit, setItemToEdit] = useState(null);
  const [restockDialogOpen, setRestockDialogOpen] = useState(false);
  const [legendDialogOpen, setLegendDialogOpen] = useState(false);
  const [itemToRestock, setItemToRestock] = useState(null);
  const defaultCurrencySymbol = "₱";
  const [currencySymbol, setCurrencySymbol] = useState(defaultCurrencySymbol);
  const [searchQuery, setSearchQuery] = useState("");
  const [editInstanceDialogOpen, setEditInstanceDialogOpen] = useState(false);
  const [importDialogOpen, setImportDialogOpen] = useState(false);
  const [instanceToEdit, setInstanceToEdit] = useState(null);
  const [instanceIndexToEdit, setInstanceIndexToEdit] = useState(null);
  const [inventoryIdForInstance, setInventoryIdForInstance] = useState(null);
  const [alertDialogOpen, setAlertDialogOpen] = useState(false);
  const [expiredItems, setExpiredItems] = useState([]);
  const [lowStockItems, setLowStockItems] = useState([]);
  const [existingInventoryNames, setExistingInventoryNames] = useState([]);
  const [currentItem, setCurrentItem] = useState(null);
  const [uomSelections, setUomSelections] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);

  const navigate = useNavigate();
  const hasShownAlert = useRef(false);

  const { user, token, posId } = getAuthCredentials(navigate);
  if (!user || !token || !posId) {
    return;
  }

  const fetchCategories = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/v1/api/inventory/categories`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setCategories(response.data.categories || []);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  const fetchInventory = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/v1/api/inventory/fetch`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const inventoryItems = response.data.inventoryItems || [];
      const currentDate = new Date();

      const expired = [];
      const lowStock = [];

      const updatedNames = inventoryItems.map((item) => item.inventoryName);
      setExistingInventoryNames(updatedNames);

      inventoryItems.forEach((item) => {
        const totalCurrentAmount = item.instances
          .filter((instance) => new Date(instance.expiryDate) >= currentDate)
          .reduce((sum, instance) => sum + (instance.currentAmount || 0), 0);

        if (totalCurrentAmount <= 10) {
          lowStock.push({
            inventoryName: item.inventoryName,
            currentAmount: totalCurrentAmount,
          });
        }

        item.instances.forEach((instance) => {
          if (new Date(instance.expiryDate) < currentDate) {
            expired.push({
              inventoryName: item.inventoryName,
              expiryDate: instance.expiryDate,
            });
          }
        });
      });

      setExpiredItems(expired);
      setLowStockItems(lowStock);

      if (
        !hasShownAlert.current &&
        (expired.length > 0 || lowStock.length > 0)
      ) {
        setAlertDialogOpen(true);
        hasShownAlert.current = true;
      }

      setInventory(inventoryItems);
    } catch (error) {
      console.error("Error fetching inventory:", error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await fetchInventory();
      await fetchCategories();
    };
    fetchData();
  }, [navigate]);

  const openDeleteDialog = (item) => {
    setItemToDelete(item);
    setDeleteDialogOpen(true);
  };

  const openArchiveDialog = (item) => {
    setItemToArchive(item);
    setArchiveDialogOpen(true);
  };

  const openEditDialog = (item) => {
    setItemToEdit(item);
    setEditDialogOpen(true);
  };

  const getItemBackgroundColor = (expiryDate) => {
    if (!expiryDate) return "#ffffff";
    const today = dayjs().startOf("day");
    const expiry = dayjs(expiryDate).startOf("day");
    const diffDays = expiry.diff(today, "day");

    if (diffDays >= 4) return "#90EE90";
    if (diffDays >= 0 && diffDays <= 3) return "#FFFF99";
    if (diffDays < 0) return "#FFCCCC";
    return "#ffffff";
  };

  const groupedInventory = inventory.reduce((acc, item) => {
    const hasExpiredInstance = item.instances?.some((instance) =>
      dayjs(instance.expiryDate).startOf("day").isBefore(dayjs().startOf("day"))
    );

    if (hasExpiredInstance) {
      acc["Expired"] = acc["Expired"] || [];
      acc["Expired"].push(item);
    } else {
      const category = item.categoryName || "Uncategorized";
      acc[category] = acc[category] || [];
      acc[category].push(item);
    }

    return acc;
  }, {});

  const filterGroupedInventory = (grouped, query) => {
    if (!query) return grouped;
    const lowerQuery = query.toLowerCase();
    const result = {};

    for (const [categoryName, items] of Object.entries(grouped)) {
      const lowerCategoryName = categoryName.toLowerCase();

      if (lowerCategoryName.includes(lowerQuery)) {
        result[categoryName] = items;
      } else {
        const filteredItems = items.filter((item) =>
          item.inventoryName.toLowerCase().includes(lowerQuery)
        );
        if (filteredItems.length > 0) {
          result[categoryName] = filteredItems;
        }
      }
    }
    return result;
  };

  const getUOMFactor = (item) => {
    const selection = uomSelections[item._id];
    if (!selection || !selection.name) return 1;
    return Number(selection.value) || 1;
  };

  const getUOMName = (item) => {
    const selection = uomSelections[item._id];
    return selection && selection.name
      ? selection.name
      : item.unitOfMeasurement;
  };

  const filteredGroupedInventory = filterGroupedInventory(
    groupedInventory,
    searchQuery
  );

  const sortedCategories = Object.keys(filteredGroupedInventory).sort(
    (a, b) => {
      if (a === "Expired") return -1;
      if (b === "Expired") return 1;
      return a.localeCompare(b);
    }
  );

  return (
    <Box sx={{ padding: 3 }}>
      <InventoryHero />

      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          alignItems: { xs: "stretch", sm: "center" },
          justifyContent: "center",
          gap: 2,
          marginBottom: 4,
        }}
      >
        <TextField
          label="Search"
          variant="outlined"
          size="small"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          sx={{ width: { xs: "100%", sm: "auto" } }}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={() => setOpenDialog(true)}
        >
          Add New Inventory
        </Button>
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => setImportDialogOpen(true)}
        >
          Import Config
        </Button>
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => setLegendDialogOpen(true)}
        >
          Legend
        </Button>
      </Box>

      {sortedCategories.length > 0 ? (
        sortedCategories.map((category, index) => (
          <Box key={index} sx={{ marginBottom: 4 }}>
            <Typography variant="h5" gutterBottom>
              {category}
            </Typography>
            <Grid container spacing={2}>
              {filteredGroupedInventory[category].map((item, idx) => {
                const totalPurchaseAmount = item.instances
                  .filter((instance) =>
                    dayjs(instance.expiryDate).isAfter(dayjs().add(0, "day"))
                  )
                  .reduce(
                    (sum, instance) => sum + (instance.purchaseAmount || 0),
                    0
                  );

                const totalCurrentAmount = item.instances
                  .filter((instance) =>
                    dayjs(instance.expiryDate).isAfter(dayjs().add(1, "day"))
                  )
                  .reduce(
                    (sum, instance) => sum + (instance.currentAmount || 0),
                    0
                  );

                const totalInventoryPrice = item.instances
                  .filter((instance) =>
                    dayjs(instance.expiryDate).isAfter(dayjs().add(1, "day"))
                  )
                  .reduce(
                    (sum, instance) => sum + (instance.inventoryPrice || 0),
                    0
                  );

                const factor = getUOMFactor(item);
                const displayUOM = getUOMName(item);
                const displayTotalPurchase = (
                  totalPurchaseAmount / factor
                ).toFixed(2);
                const displayTotalCurrent = (
                  totalCurrentAmount / factor
                ).toFixed(2);

                return (
                  <Grid item xs={12} key={idx}>
                    <Box
                      sx={{
                        padding: 2,
                        border: "1px solid #ccc",
                        borderRadius: 2,
                        backgroundColor:
                          item.instances.length === 0
                            ? "#ffffff"
                            : item.instances.some((instance) =>
                                dayjs(instance.expiryDate).isBefore(dayjs())
                              )
                            ? "#FFCCCC"
                            : item.instances.some((instance) =>
                                dayjs(instance.expiryDate).isBefore(
                                  dayjs().add(4, "day")
                                )
                              )
                            ? "#FFFF99"
                            : "#90EE90",
                        transition: "transform 0.2s ease-in-out",
                        "&:hover": {
                          transform: "scale(1.02)",
                          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                        },
                        cursor: "pointer",
                        position: "relative",
                      }}
                      onClick={() => {
                        const updatedInventory = inventory.map((inv) =>
                          inv._id === item._id
                            ? { ...inv, isOpen: !inv.isOpen }
                            : inv
                        );
                        setInventory(updatedInventory);
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography variant="h6">
                          {item.inventoryName}
                        </Typography>
                        {item.isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                      </Box>
                      <Typography>
                        Total Purchased Items: {displayTotalPurchase}{" "}
                        {displayUOM}
                      </Typography>
                      <Typography>
                        Current Stock of Items: {displayTotalCurrent}{" "}
                        {displayUOM}
                      </Typography>
                      <Typography>
                        Total Purchase Price: {currencySymbol}
                        {totalInventoryPrice.toFixed(2) || "N/A"}
                      </Typography>
                      <Box
                        sx={{
                          display: "grid",
                          gridTemplateColumns: {
                            xs: "1fr 1fr",
                            sm: "repeat(4, auto)",
                          },
                          gap: 1,
                          marginTop: 2,
                          position: { xs: "static", sm: "absolute" },
                          bottom: { sm: 8 },
                          right: { sm: 8 },
                        }}
                      >
                        <Button
                          variant="outlined"
                          size="small"
                          onClick={(e) => {
                            e.stopPropagation();
                            setAnchorEl(e.currentTarget);
                            setCurrentItem(item);
                          }}
                        >
                          UOM
                        </Button>
                        <Button
                          variant="outlined"
                          size="small"
                          onClick={(e) => {
                            e.stopPropagation();
                            setItemToEdit(item);
                            setEditDialogOpen(true);
                          }}
                        >
                          Edit
                        </Button>
                        <Button
                          variant="outlined"
                          size="small"
                          onClick={(e) => {
                            e.stopPropagation();
                            setItemToRestock(item);
                            setRestockDialogOpen(true);
                          }}
                        >
                          Restock
                        </Button>
                        <Button
                          variant="outlined"
                          size="small"
                          onClick={(e) => {
                            e.stopPropagation();
                            setItemToArchive({
                              inventoryId: item._id,
                              inventoryName: item.inventoryName,
                              isMainInventory: true,
                            });
                            setArchiveDialogOpen(true);
                          }}
                        >
                          Archive
                        </Button>
                      </Box>
                    </Box>

                    {item.isOpen && (
                      <Box sx={{ marginTop: 2 }}>
                        {item.instances.map((instance, instIdx) => {
                          const factor = getUOMFactor(item);
                          const displayUOM = getUOMName(item);
                          const displayInstancePurchase = (
                            instance.purchaseAmount / factor
                          ).toFixed(2);
                          const displayInstanceCurrent = (
                            instance.currentAmount / factor
                          ).toFixed(2);

                          return (
                            <Box
                              key={instIdx}
                              sx={{
                                padding: 2,
                                border: "1px solid #ddd",
                                borderRadius: 2,
                                marginBottom: 2,
                                backgroundColor: getItemBackgroundColor(
                                  instance.expiryDate
                                ),
                                position: "relative",
                              }}
                            >
                              <Box
                                sx={{
                                  position: "absolute",
                                  top: 8,
                                  right: 8,
                                  display: "flex",
                                  gap: 1,
                                }}
                              >
                                <IconButton
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    openDeleteDialog({
                                      inventoryId: item._id,
                                      instanceIndex: instIdx,
                                      inventoryName: item.inventoryName,
                                    });
                                  }}
                                  color="error"
                                  size="small"
                                >
                                  <DeleteIcon />
                                </IconButton>
                                <IconButton
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    openArchiveDialog({
                                      inventoryId: item._id,
                                      instanceIndex: instIdx,
                                      inventoryName: item.inventoryName,
                                      isMainInventory: false,
                                    });
                                  }}
                                  color="secondary"
                                  size="small"
                                >
                                  <ArchiveIcon />
                                </IconButton>
                                <IconButton
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setCurrentItem(item);
                                    setInstanceToEdit(instance);
                                    setInstanceIndexToEdit(instIdx);
                                    setInventoryIdForInstance(item._id);
                                    setEditInstanceDialogOpen(true);
                                  }}
                                  color="primary"
                                  size="small"
                                >
                                  <EditIcon />
                                </IconButton>
                              </Box>
                              <Typography>
                                Items Ordered: {displayInstancePurchase}{" "}
                                {displayUOM}
                              </Typography>
                              <Typography>
                                Current Items: {displayInstanceCurrent}{" "}
                                {displayUOM}
                              </Typography>
                              <Typography>
                                Price: {currencySymbol}
                                {instance.inventoryPrice.toFixed(2) || "N/A"}
                              </Typography>
                              <Typography>
                                Delivery Date:{" "}
                                {instance.deliveryDate
                                  ? new Date(
                                      instance.deliveryDate
                                    ).toLocaleDateString()
                                  : "N/A"}
                              </Typography>
                              <Typography>
                                Expiry Date:{" "}
                                {instance.expiryDate
                                  ? new Date(
                                      instance.expiryDate
                                    ).toLocaleDateString()
                                  : "N/A"}
                              </Typography>
                              <Typography
                                sx={{
                                  position: "absolute",
                                  bottom: 8,
                                  right: 8,
                                  fontWeight: "bold",
                                  color:
                                    instance.status === "Active"
                                      ? "green"
                                      : "red",
                                }}
                              >
                                {instance.status === "Active"
                                  ? "Active"
                                  : "Inactive"}
                              </Typography>
                            </Box>
                          );
                        })}
                      </Box>
                    )}
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        ))
      ) : (
        <Typography align="center">No inventory found</Typography>
      )}

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl) && Boolean(currentItem)}
        onClose={() => {
          setAnchorEl(null);
          setCurrentItem(null);
        }}
      >
        {currentItem && (
          <>
            <MenuItem
              onClick={() => {
                setUomSelections((prev) => ({
                  ...prev,
                  [currentItem._id]: {
                    name: currentItem.unitOfMeasurement,
                    value: 1,
                  },
                }));
                setAnchorEl(null);
              }}
            >
              {currentItem.unitOfMeasurement} (Primary)
            </MenuItem>

            {currentItem.optionalMeasurements?.map((opt) => (
              <MenuItem
                key={opt.name}
                onClick={() => {
                  setUomSelections((prev) => ({
                    ...prev,
                    [currentItem._id]: { name: opt.name, value: opt.value },
                  }));
                  setAnchorEl(null);
                }}
              >
                {opt.name} = {opt.value} {currentItem.unitOfMeasurement}
              </MenuItem>
            ))}
          </>
        )}
      </Menu>

      <AddInventoryDialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        onAdd={(newItem) =>
          handleAddInventory(
            newItem,
            token,
            posId,
            fetchInventory,
            (prevInventory) => [
              ...(prevInventory?.inventoryItems || []),
              newItem,
            ],
            setOpenDialog
          )
        }
        categories={categories}
        fetchCategories={fetchCategories}
        setCategories={setCategories}
        setInventory={setInventory}
        existingInventoryNames={existingInventoryNames}
      />

      {deleteDialogOpen && itemToDelete && (
        <HandleDeleteItem
          item={itemToDelete}
          onClose={() => setDeleteDialogOpen(false)}
          onDeleteConfirm={async () => {
            try {
              await fetchInventory();
              setDeleteDialogOpen(false);
            } catch (error) {
              console.error(
                "Error refetching inventory after deletion:",
                error
              );
            }
          }}
          token={token}
        />
      )}

      {archiveDialogOpen && itemToArchive && (
        <HandleArchiveItem
          item={itemToArchive}
          onClose={() => setArchiveDialogOpen(false)}
          onArchiveConfirm={async () => {
            try {
              await fetchInventory();
              setArchiveDialogOpen(false);
            } catch (error) {
              console.error(
                "Error refetching inventory after archiving:",
                error
              );
            }
          }}
        />
      )}

      <EditInventoryDialog
        open={editDialogOpen}
        onClose={() => setEditDialogOpen(false)}
        item={itemToEdit}
        categories={categories}
        fetchCategories={fetchCategories}
        fetchInventory={fetchInventory}
        setCategories={setCategories}
        existingInventoryNames={existingInventoryNames}
      />
      <EditInventoryInstanceDialog
        open={editInstanceDialogOpen}
        onClose={() => setEditInstanceDialogOpen(false)}
        inventoryId={inventoryIdForInstance}
        instance={instanceToEdit}
        instanceIndex={instanceIndexToEdit}
        fetchInventory={fetchInventory}
        availableUOMs={
          currentItem
            ? [
                { name: currentItem.unitOfMeasurement, value: 1 },
                ...(currentItem.optionalMeasurements || []),
              ]
            : []
        }
      />

      {restockDialogOpen && itemToRestock && (
        <RestockInventoryDialog
          open={restockDialogOpen}
          onClose={() => setRestockDialogOpen(false)}
          onRestock={async (restockData) => {
            await handleRestockInventory(
              itemToRestock._id,
              restockData,
              token,
              fetchInventory
            );
            setRestockDialogOpen(false);
          }}
          availableUOMs={
            itemToRestock
              ? [
                  { name: itemToRestock.unitOfMeasurement, value: 1 },
                  ...(itemToRestock.optionalMeasurements || []),
                ]
              : []
          }
        />
      )}

      <AlertDialog
        open={alertDialogOpen}
        onClose={() => setAlertDialogOpen(false)}
        expiredItems={expiredItems}
        lowStockItems={lowStockItems}
      />
      <LegendDialog
        open={legendDialogOpen}
        onClose={() => setLegendDialogOpen(false)}
      />
      <ImportConfigDialog
        open={importDialogOpen}
        onClose={() => setImportDialogOpen(false)}
        fetchInventory={fetchInventory}
      />
    </Box>
  );
};

export default Inventory;
