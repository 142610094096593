import React, { useState, useEffect } from "react";
import Box from '@mui/material/Box'
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import CircularProgress from "@mui/material/CircularProgress";
import Paper from "@mui/material/Paper";
import axios from "axios";
import BillHero from "../BillHero";
import { ExportButtons } from "./BillExport";
import { getAuthCredentials } from "../../../../utils/auth";
import { Pagination, useMediaQuery, useTheme } from "@mui/material";
import BillHistoryDetailed from "./BillHistoryDetailed";
import BillHistoryChart from "./BillHistoryChart";

const BillHistory = () => {
  const [history, setHistory] = useState([]);
  const [date, setDate] = useState(new Date().toISOString().split("T")[0]);
  const [billType, setBillType] = useState("All");
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [limit, setLimit] = useState(10);
  const [viewMode, setViewMode] = useState("Detailed");

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { token } = getAuthCredentials();

  useEffect(() => {
    fetchHistory();
  }, [date, billType, page, limit, viewMode]);

  const fetchHistory = async () => {
    try {
      setLoading(true);

      const fetchAll = viewMode === "Chart";
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/v1/api/bill/fetch-bill-history`,
        {
          params: {
            date,
            billType: billType !== "All" ? billType : undefined,
            page: fetchAll ? 1 : page,
            limit: fetchAll ? 10000 : limit,
          },
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setHistory(response.data.history);
      setTotalPages(fetchAll ? 1 : response.data.totalPages);
    } catch (error) {
      console.error("Error fetching bill history:", error);
      setHistory([]);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box sx={{ padding: 3 }}>
      <BillHero activeTab="history" />

      {/* Filters */}
      <Box
        sx={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          gap: 2,
          justifyContent: isMobile ? "flex-start" : "center",
          marginBottom: 3,
          alignItems: isMobile ? "stretch" : "center",
          width: isMobile ? "100%" : "auto",
        }}
      >
        <TextField
          label="Select Date"
          type="date"
          value={date}
          onChange={(e) => {
            setDate(e.target.value);
            setPage(1);
          }}
          InputLabelProps={{ shrink: true }}
          fullWidth
          sx={{ maxWidth: isMobile ? "100%" : 250 }}
        />
        <FormControl sx={{ maxWidth: isMobile ? "100%" : 200 }} fullWidth>
          <InputLabel>Bill Type</InputLabel>
          <Select
            value={billType}
            onChange={(e) => {
              setBillType(e.target.value);
              setPage(1);
            }}
          >
            <MenuItem value="All">All</MenuItem>
            <MenuItem value="Cash">Cash</MenuItem>
            <MenuItem value="Coin">Coin</MenuItem>
          </Select>
        </FormControl>

        {/* View Mode Selector */}
        <FormControl sx={{ maxWidth: isMobile ? "100%" : 200 }} fullWidth>
          <InputLabel>View Mode</InputLabel>
          <Select
            value={viewMode}
            onChange={(e) => setViewMode(e.target.value)}
          >
            <MenuItem value="Detailed">Detailed View</MenuItem>
            <MenuItem value="Chart">Graph View</MenuItem>
          </Select>
        </FormControl>

        {!isMobile && <ExportButtons history={history} date={date} />}
      </Box>

      {isMobile && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            width: "100%",
          }}
        >
          <ExportButtons history={history} date={date} />
        </Box>
      )}

      <Paper elevation={3} sx={{ padding: 2, overflowX: "auto" }}>
        {loading ? (
          <Box sx={{ display: "flex", justifyContent: "center", padding: 3 }}>
            <CircularProgress />
          </Box>
        ) : viewMode === "Chart" ? (
          <BillHistoryChart history={history} />
        ) : (
          <>
            <BillHistoryDetailed history={history} />

            <Box
              sx={{ display: "flex", justifyContent: "center", marginTop: 3 }}
            >
              <Pagination
                count={totalPages}
                page={page}
                onChange={(event, value) => setPage(value)}
                color="primary"
                showFirstButton
                showLastButton
                sx={{
                  "& .MuiPaginationItem-root": { borderRadius: "5px" },
                  "& .Mui-selected": {
                    backgroundColor: "#4CAF50",
                    color: "white",
                  },
                  "& .MuiPaginationItem-previousNext": {
                    fontSize: "1.2rem",
                    fontWeight: "bold",
                  },
                }}
              />
            </Box>
          </>
        )}
      </Paper>
    </Box>
  );
};

export default BillHistory;
