import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import CircularProgress from '@mui/material/CircularProgress';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { Pagination, useMediaQuery, useTheme } from "@mui/material";
import CustomerHero from "../CustomerHero";
import CustomerImgModal from "./CustomerImgModal";
import { ExportButtons } from "../CustomerExport";
import { getAuthCredentials } from "../../../../utils/auth";

const CustomerHistory = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const initialSearch = queryParams.get("search") || "";
  const [history, setHistory] = useState([]);
  const [searchQuery, setSearchQuery] = useState(initialSearch);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedDiscount, setSelectedDiscount] = useState("");
  const [discountTypes, setDiscountTypes] = useState([]);
  const [imageType, setImageType] = useState("");
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [limit, setLimit] = useState(10);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { token } = getAuthCredentials();

  useEffect(() => {
    fetchCustomerHistory();
  }, [searchQuery, selectedDiscount, page, limit, location.search]);

  const fetchCustomerHistory = async () => {
    try {
      setLoading(true);

      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/v1/api/customer/fetch-customer-history`,
        {
          params: {
            page,
            limit,
            name: searchQuery || undefined,
            customerId: searchQuery || undefined,
          },
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      const { orders, totalPages } = response.data;

      setHistory(orders);
      setTotalPages(totalPages);

      const uniqueDiscounts = [
        ...new Set(orders.map((item) => item.customerId?.discountType)),
      ];
      setDiscountTypes(uniqueDiscounts);
    } catch (error) {
      console.error("Error fetching customer history:", error);
      setHistory([]);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box sx={{ padding: 3 }}>
      <CustomerHero activeTab="history" />

      <Typography variant="h4" align="center" gutterBottom>
        Customer History
      </Typography>

      <Box
        sx={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          gap: 2,
          justifyContent: isMobile ? "flex-start" : "center",
          marginBottom: 3,
          alignItems: isMobile ? "stretch" : "center",
          width: isMobile ? "100%" : "auto",
        }}
      >
        <TextField
          label="Search Customer ID or Name"
          value={searchQuery}
          onChange={(e) => {
            setSearchQuery(e.target.value);
            setPage(1);
          }}
          fullWidth
          sx={{ maxWidth: isMobile ? "100%" : 250 }}
        />

        <FormControl sx={{ maxWidth: isMobile ? "100%" : 200 }} fullWidth>
          <InputLabel>Discount Type</InputLabel>
          <Select
            value={selectedDiscount}
            onChange={(e) => {
              setSelectedDiscount(e.target.value);
              setPage(1);
            }}
          >
            <MenuItem value="">All</MenuItem>
            {discountTypes.map((type, index) => (
              <MenuItem key={index} value={type}>
                {type}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {!isMobile && <ExportButtons history={history} />}
      </Box>

      {isMobile && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            width: "100%",
          }}
        >
          <ExportButtons history={history} />
        </Box>
      )}

      <Paper elevation={3} sx={{ padding: 2, overflowX: "auto" }}>
        {loading ? (
          <Box sx={{ display: "flex", justifyContent: "center", padding: 3 }}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Customer Name</TableCell>
                  <TableCell>Order Date</TableCell>
                  <TableCell>Order ID</TableCell>
                  <TableCell>Discount Type</TableCell>
                  <TableCell>Discount ID</TableCell>
                  <TableCell>Discount ID Image</TableCell>
                  <TableCell>Discount Selfie</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {history.map((order, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      "&:hover": {
                        backgroundColor: "rgba(0, 0, 0, 0.1)",
                      },
                    }}
                  >
                    <TableCell>{order.customerId?.name || "N/A"}</TableCell>
                    <TableCell>
                      {new Date(order.orderDate).toLocaleDateString()}
                    </TableCell>
                    <TableCell>{order.orderId}</TableCell>
                    <TableCell>
                      {order.customerId?.discountType || "N/A"}
                    </TableCell>
                    <TableCell>{order.discountId || "N/A"}</TableCell>
                    <TableCell>
                      {order.discountIDImg ? (
                        <img
                          src={order.discountIDImg}
                          alt="Discount ID"
                          style={{
                            width: 50,
                            height: 50,
                            objectFit: "cover",
                            borderRadius: "5px",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setSelectedImage(order.discountIDImg);
                            setImageType("Discount ID");
                          }}
                        />
                      ) : (
                        "No Image"
                      )}
                    </TableCell>
                    <TableCell>
                      {order.discountSelfieImg ? (
                        <img
                          src={order.discountSelfieImg}
                          alt="Discount Selfie"
                          style={{
                            width: 50,
                            height: 50,
                            objectFit: "cover",
                            borderRadius: "5px",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setSelectedImage(order.discountSelfieImg);
                            setImageType("Discount Selfie");
                          }}
                        />
                      ) : (
                        "No Image"
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>

            <Box
              sx={{
                display: "flex",
                flexDirection: isMobile ? "column" : "row",
                justifyContent: "center",
                alignItems: "center",
                marginTop: 3,
                gap: isMobile ? 1 : 3,
              }}
            >
              <FormControl
                sx={{
                  maxWidth: isMobile ? "100%" : 80,
                  "& .MuiInputBase-root": {
                    height: 30,
                    fontSize: "0.85rem",
                  },
                  "& .MuiSelect-select": {
                    padding: "5px 10px",
                  },
                }}
                fullWidth
              >
                <InputLabel sx={{ fontSize: "0.85rem" }}>Rows</InputLabel>
                <Select
                  value={limit}
                  onChange={(e) => {
                    setLimit(parseInt(e.target.value, 10));
                    setPage(1);
                  }}
                >
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={25}>25</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                  <MenuItem value={100}>100</MenuItem>
                </Select>
              </FormControl>
              <Pagination
                count={totalPages}
                page={page}
                onChange={(event, value) => setPage(value)}
                color="primary"
                showFirstButton
                showLastButton
                sx={{
                  "& .MuiPaginationItem-root": { borderRadius: "5px" },
                  "& .Mui-selected": {
                    backgroundColor: "#4CAF50",
                    color: "white",
                  },
                  "& .MuiPaginationItem-previousNext": {
                    fontSize: "1.2rem",
                    fontWeight: "bold",
                  },
                }}
              />
            </Box>
          </>
        )}
      </Paper>
      <CustomerImgModal
        selectedImage={selectedImage}
        imageType={imageType}
        onClose={() => setSelectedImage(null)}
      />
    </Box>
  );
};

export default CustomerHistory;
