import jsPDF from 'jspdf';

const generateZReadingTextFile = (receiptConfig, zReading) => {
  let textContent = '';

  // Add Header
  textContent += `\n\n${receiptConfig.companyName || "N/A"}\n`;
  textContent += `Owned and Operated By:\n`;
  textContent += `${receiptConfig.storeName || "N/A"}\n`;
  textContent += `${receiptConfig.address || "N/A"}\n`;
  textContent += `VAT Registered TIN: ${receiptConfig.TIN || "N/A"}\n`;
  textContent += `SN: ${receiptConfig.SN || "N/A"} MIN: ${receiptConfig.MIN || "N/A"}\n`;
  textContent += `BIR Permit No.: ${receiptConfig.birPermit || "N/A"}\n`;
  textContent += `BIR Accreditation No.: ${receiptConfig.birAccredNo || "N/A"}\n`;
  textContent += "=========================================================\n";

  // Z-Reading Details
  textContent += `---------------------------------------------------------\n`;
  textContent += `Reset Counter No.: ${zReading.resetNo != null ? zReading.resetNo : "N/A"}\n`;
  textContent += `Day-end Report Z-Reading#: ${
    zReading.zReadingCount
      ? zReading.zReadingCount.toString().padStart(10, "0")
      : "N/A"
  }\n`;
  textContent += `Administrator: ${zReading.administrator || "N/A"}\n`;
  textContent += `Cashier: AUTO-GENERATED\n`;
  textContent += `Log Date: ${zReading.date ? new Date(zReading.date).toLocaleDateString() : "N/A"}\n`;
  textContent += `Date/Time: ${zReading.createdAt ? new Date(zReading.createdAt).toLocaleString() : "N/A"}\n`;
  textContent += `Terminal Code: 001\n`;
  textContent += `Guest Count: ${zReading.totalTransactions || 0}\n`;

  // Sales Summary
  textContent += `\n---------------------------------------------------------\n`;
  textContent += `                       SALES SUMMARY REPORT\n`;
  textContent += `---------------------------------------------------------\n`;
  textContent += `Description                 Count    Amount (PHP)\n`;
  textContent += `---------------------------------------------------------\n`;

  textContent += `Gross Sales                ${String(zReading.totalTransactions || 0).padStart(6)}   ${String(zReading.grossSales?.toFixed(2) || "0.00").padStart(12)}\n`;
  textContent += `Senior Discounts           ${String(zReading.seniorTransactionCount || 0).padStart(6)}   ${String(zReading.seniorDiscounts?.toFixed(2) || "0.00").padStart(12)}\n`;
  textContent += `SC VAT Deduction           ${String(zReading.seniorTransactionCount || 0).padStart(6)}   ${String(zReading.scVatDeduction?.toFixed(2) || "0.00").padStart(12)}\n`;
  textContent += `PWD Discounts              ${String(zReading.pwdTransactionCount || 0).padStart(6)}   ${String(zReading.pwdDiscounts?.toFixed(2) || "0.00").padStart(12)}\n`;
  textContent += `PWD VAT Deduction          ${String(zReading.pwdTransactionCount || 0).padStart(6)}   ${String(zReading.pwdVatDeduction?.toFixed(2) || "0.00").padStart(12)}\n`;
  textContent += `Athlete Discounts          ${String(zReading.athleteTransactionCount || 0).padStart(6)}   ${String(zReading.athleteDiscounts?.toFixed(2) || "0.00").padStart(12)}\n`;
  textContent += `Solo Parent Discounts      ${String(zReading.soloParentTransactionCount || 0).padStart(6)}   ${String(zReading.soloParentDiscounts?.toFixed(2) || "0.00").padStart(12)}\n`;
  textContent += `Diplomat Discounts         ${String(zReading.diplomatTransactionCount || 0).padStart(6)}   ${String(zReading.diplomatDiscounts?.toFixed(2) || "0.00").padStart(12)}\n`;
  textContent += `Voucher Discount           ${String(zReading.voucherTransactionCount || 0).padStart(6)}   ${String(zReading.voucherDiscounts?.toFixed(2) || "0.00").padStart(12)}\n`;
  // textContent += `Other Discounts            ${String(zReading.athleteTransactionCount || 0).padStart(6)}   ${String(zReading.athleteDiscounts?.toFixed(2) || "0.00").padStart(12)}\n`;
  textContent += `---------------------------------------------------------\n`;
  textContent += `Sub-Total                           ${String(zReading.subTotal?.toFixed(2) || "0.00").padStart(12)}\n`;
  textContent += `Net Total                           ${String(zReading.netTotal?.toFixed(2) || "0.00").padStart(12)}\n`;
  textContent += `---------------------------------------------------------\n`;

  // VAT Breakdown Report
  textContent += `\n                      VAT BREAKDOWN REPORT\n`;
  textContent += `---------------------------------------------------------\n`;
  textContent += `Description           Amount (PHP)   VAT (12%) (PHP)\n`;
  textContent += `---------------------------------------------------------\n`;

  const vatExclusiveSales = zReading.vatSales || 0;
  const vatAmount = zReading.vatAmount || 0;

  textContent += `VATable Sales         ${vatExclusiveSales.toFixed(2).padStart(10)}   ${vatAmount.toFixed(2).padStart(13)}\n`;
  textContent += `VAT Exempt Sales      ${zReading.vatExemptSales?.toFixed(2).padStart(10) || "0.00"}   ${"0.00".padStart(13)}\n`;
  textContent += `Zero-Rated Sales      ${zReading.zeroRatedSales?.toFixed(2).padStart(10) || "0.00"}   ${"0.00".padStart(13)}\n`;
  textContent += `---------------------------------------------------------\n`;
  textContent += `Total VAT             ${vatExclusiveSales.toFixed(2).padStart(10)}   ${vatAmount.toFixed(2).padStart(13)}\n`;
  textContent += `---------------------------------------------------------\n`;

  // VAT Summary
  textContent += `\n         TAX DECLARATION SUMMARY REPORT\n`;
  textContent += `---------------------------------------------------------\n\n`;
  textContent += `Tax Type                             Amount (PHP)\n`;
  textContent += `---------------------------------------------------------\n`;
  textContent += `VATable Sales           ${zReading.vatSales?.toFixed(2).padStart(24) || "0.00"}\n`;
  textContent += `VAT Amount (12%)        ${zReading.vatAmount?.toFixed(2).padStart(24) || "0.00"}\n`;
  textContent += `VAT Exempt Sales        ${zReading.vatExemptSales?.toFixed(2).padStart(24) || "0.00"}\n`;
  textContent += `Net of VAT Exempt       ${zReading.netOfVatExempt?.toFixed(2).padStart(24) || "0.00"}\n`;
  textContent += `Zero-Rated Sales        ${zReading.zeroRatedSales?.toFixed(2).padStart(24) || "0.00"}\n`;
  textContent += `---------------------------------------------------------\n`;


  // Mandated VAT & Discount Report
  textContent += `\n    MANDATED VAT & DISCOUNT REPORT\n`;
  textContent += `---------------------------------------------------------\n\n`;
  textContent += `Description                       Count   Amount (PHP)\n`;
  textContent += `---------------------------------------------------------\n`;
  textContent += `Discounts TRN Count                 ${String(zReading.scPwdDiplomatTransactionCount || 0).padStart(0)}\n`;
  textContent += `Senior Discount                     ${String(zReading.seniorTransactionCount || 0).padStart(0)}   ${String(zReading.seniorDiscounts?.toFixed(2) || "0.00").padStart(8)}\n`;
  textContent += `SC VAT Deduction                    ${String(zReading.seniorTransactionCount || 0).padStart(0)}   ${String(zReading.scVatDeduction?.toFixed(2) || "0.00").padStart(8)}\n`;
  textContent += `PWD Discount                        ${String(zReading.pwdTransactionCount || 0).padStart(0)}   ${String(zReading.pwdDiscounts?.toFixed(2) || "0.00").padStart(8)}\n`;
  textContent += `PWD VAT Deduction                   ${String(zReading.pwdTransactionCount || 0).padStart(0)}   ${String(zReading.pwdVatDeduction?.toFixed(2) || "0.00").padStart(8)}\n`;
  textContent += `Athlete Discount                    ${String(zReading.athleteTransactionCount || 0).padStart(0)}   ${String(zReading.athleteDiscounts?.toFixed(2) || "0.00").padStart(8)}\n`;
  textContent += `Solo Parent Discount                ${String(zReading.soloParentTransactionCount || 0).padStart(0)}   ${String(zReading.soloParentDiscounts?.toFixed(2) || "0.00").padStart(8)}\n`;
  textContent += `Diplomat Discount                   ${String(zReading.diplomatTransactionCount || 0).padStart(0)}   ${String(zReading.diplomatDiscounts?.toFixed(2) || "0.00").padStart(8)}\n`;
  textContent += `Voucher Discount                    ${String(zReading.voucherTransactionCount || 0).padStart(0)}   ${String(zReading.voucherDiscounts?.toFixed(2) || "0.00").padStart(8)}\n`;
  // textContent += `Other Discount                      ${String(zReading.athleteTransactionCount || 0).padStart(6)}   ${String(zReading.athleteDiscounts?.toFixed(2) || "0.00").padStart(12)}\n`;
  textContent += `---------------------------------------------------------\n`;

  // Transaction Details
  textContent += `          TRANSACTION DETAILS\n`;
  textContent += `---------------------------------------------------------\n\n`;
  textContent += `Beginning Inv. No.:        ${zReading.beginningInvoiceNo || "N/A"}\n`;
  textContent += `Ending Inv. No.:           ${zReading.endingInvoiceNo || "N/A"}\n`;
  textContent += `Beginning Void No.:        ${zReading.beginningVoidNo || "N/A"}\n`;
  textContent += `Ending Void No.:           ${zReading.endingVoidNo || "N/A"}\n`;
  textContent += `Total Transactions:        ${zReading.totalInvoiceCount || 0}\n`;
  textContent += `Open Drawer Count:         ${zReading.openDrawerCount || 0}\n`;
  textContent += `Void Items:                ${zReading.voidItemsCount || 0}         ${zReading.voidItemsAmount?.toFixed(2) || "0.00"}\n`;
  // textContent += `Refunds:                 ${zReading.refundsCount || 0}        ${zReading.refundsAmount?.toFixed(2) || "0.00"}\n\n`;
  textContent += `Dine-In:              ${String(zReading.dineInCount || 0).padStart(6)}   ${String(zReading.dineInSales?.toFixed(2) || "0.00").padStart(12)}\n`;
  textContent += `Take-Out:             ${String(zReading.takeOutCount || 0).padStart(6)}   ${String(zReading.takeOutSales?.toFixed(2) || "0.00").padStart(12)}\n`;
  // textContent += `Delivery:             ${String(zReading.deliveryCount || 0).padStart(6)}   ${String(zReading.deliverySales?.toFixed(2) || "0.00").padStart(12)}\n`;
  textContent += `Free Meals:           ${String(zReading.freeMeals || 0).padStart(6)}   ${String("0.00").padStart(12)}\n`;
  textContent += `---------------------------------------------------------\n`;

  // Tender Summary Report
  textContent += `\n            TENDER SUMMARY REPORT\n`;
  textContent += `---------------------------------------------------------\n\n`;
  textContent += `Tender Type               Count   Amount (PHP)\n`;
  textContent += `---------------------------------------------------------\n`;

  if (zReading.tenderSummary && zReading.tenderSummary.length > 0) {
    zReading.tenderSummary.forEach((tender) => {
      textContent += `${tender.tenderType.padEnd(20)} ${String(
        tender.transactionCount || 0
      ).padStart(7)}   ${String(tender.tenderAmount?.toFixed(2) || "0.00").padStart(12)}\n`;
    });

    textContent += `---------------------------------------------------------\n`;

    // Exclude any pre-calculated "TOTAL" entry from the summation
    const filteredTenders = zReading.tenderSummary.filter(
      (t) => t.tenderType.toUpperCase() !== "TOTAL"
    );

    const totalTenderTransactions = filteredTenders.reduce(
      (sum, t) => sum + (t.transactionCount || 0),
      0
    );
    const totalTenderAmount = filteredTenders.reduce(
      (sum, t) => sum + (t.tenderAmount || 0),
      0
    );

    textContent += `Total Payments         ${String(totalTenderTransactions).padStart(5)}   ${String(totalTenderAmount.toFixed(2)).padStart(12)}\n`;
    textContent += `\nTotal Tenders (All Modes): ${totalTenderTransactions} Transactions, PHP ${totalTenderAmount.toFixed(2)}\n`;
  } else {
    textContent += `No tender data available.\n`;
  }

  textContent += `---------------------------------------------------------\n`;

  // Cash Breakdown Report
  textContent += `\n                CASH BREAKDOWN REPORT\n`;
  textContent += `---------------------------------------------------------\n\n`;
  textContent += `Denom    Add Qty   Add Total   Ded Qty   Ded Total\n`;
  textContent += `---------------------------------------------------------\n`;

  let totalCash = 0;

  if (zReading.cashBreakdown && Object.keys(zReading.cashBreakdown).length > 0) {
    const sortedDenominations = Object.entries(zReading.cashBreakdown)
      .sort(([a], [b]) => parseFloat(b) - parseFloat(a))
      .map(([denom, details]) => {
        const denomVal = parseFloat(denom);
        const additionTotal = details.addition.count * denomVal;
        const deductionTotal = details.deduction.count * denomVal;
        totalCash += additionTotal - deductionTotal;
        return {
          denomination: denomVal,
          additionCount: details.addition.count,
          additionTotal: additionTotal.toFixed(2),
          deductionCount: details.deduction.count,
          deductionTotal: deductionTotal.toFixed(2),
        };
      });

    sortedDenominations.forEach((denom) => {
      textContent += `P ${denom.denomination.toFixed(2).padStart(6)}   ${String(denom.additionCount).padStart(5)}   ${String(denom.additionTotal).padStart(10)}   ${String(denom.deductionCount).padStart(5)}   ${String(denom.deductionTotal).padStart(10)}\n`;
    });
  } else {
    textContent += `No cash breakdown data available.\n`;
  }

  textContent += `---------------------------------------------------------\n`;
  textContent += `NET CASH:               ${String(totalCash.toFixed(2)).padStart(19)}\n`;

  // Compute OTHER PAYMENT OPTIONS (exclude 'cash')
  const otherPayments = (zReading.tenderSummary || [])
    .filter(
      (t) => !["cash", "total"].includes(t.tenderType.toLowerCase())
    )
    .reduce((sum, t) => sum + (t.tenderAmount || 0), 0);

  textContent += `OTHER PAYMENT:   - ${otherPayments.toFixed(2).padStart(24)}\n`;

  // Calculate SHORT/OVER
  const expectedTotal = zReading.netTotal || 0;
  const actualTotal = totalCash + otherPayments;
  const shortOver = actualTotal - expectedTotal;

  const shortOverLabel = shortOver < 0 ? "SHORT:" : "OVER :";
  const shortOverAmount = Math.abs(shortOver).toFixed(2);
  const sign = shortOver < 0 ? "-" : "";

  textContent += `${shortOverLabel.padEnd(30)} ${sign}${shortOverAmount.padStart(12)}\n`;
  textContent += `---------------------------------------------------------\n`;

  // Sales by Cashier Summary Report
  textContent += `\n    SALES BY CASHIER SUMMARY REPORT\n`;
  textContent += `---------------------------------------------------------\n\n`;
  textContent += `Cashier Name         Total Sales (PHP)\n`;
  textContent += `---------------------------------------------------------\n`;

  const cashierName = zReading.administrator || "AUTO-GENERATED";
  const totalCashierSales = zReading.netTotal?.toFixed(2) || "0.00";

  textContent += `${cashierName.padEnd(20)} ${totalCashierSales.padStart(12)}\n`;

  textContent += `---------------------------------------------------------\n`;

  // Sales by Category Summary Report
  textContent += `\n    SALES BY CATEGORY SUMMARY REPORT\n`;
  textContent += `---------------------------------------------------------\n\n`;
  textContent += `Category                   Qty      Amount (PHP)\n`;
  textContent += `---------------------------------------------------------\n`;

  let totalCategorySales = 0;

  zReading.salesPerCategory?.forEach((category) => {
    textContent += `${category.categoryName.padEnd(20)} ${String(category.totalQuantity || 0).padStart(8)}   ${String(category.totalSales?.toFixed(2) || "0.00").padStart(12)}\n`;
    totalCategorySales += category.totalSales || 0;
  });

  textContent += `Total Sales by Category      ${String(totalCategorySales.toFixed(2)).padStart(15)}\n`;
  textContent += `---------------------------------------------------------\n`;

  // Sales by Menu Summary Report
  textContent += `\n       SALES BY MENU SUMMARY REPORT\n`;
  textContent += `---------------------------------------------------------\n\n`;
  textContent += `Category                   Qty     Amount (PHP)\n`;
  textContent += `---------------------------------------------------------\n`;

  let totalMenuSales = 0;
  const maxNameLength = 24;

  zReading.salesPerMenu?.forEach((menuCategory) => {
    menuCategory.menuItems.forEach((item) => {
      totalMenuSales += item.sales || 0;
      
      const qtyStr = String(item.quantity || 0).padStart(4);
      const amountStr = String(item.sales?.toFixed(2) || "0.00").padStart(12);
      
      const name = item.name;
      const nameChunks = [];
      
      for (let i = 0; i < name.length; i += maxNameLength) {
        nameChunks.push(name.substring(i, i + maxNameLength));
      }
      
      nameChunks.forEach((chunk, index) => {
        if (index === nameChunks.length - 1) {
          textContent += chunk.padEnd(maxNameLength) + " " + qtyStr + "   " + amountStr + "\n";
        } else {
          textContent += chunk + "\n";
        }
      });
    });
  });

  textContent += `---------------------------------------------------------\n`;
  textContent += `Total Sales by Menu           ${String(totalMenuSales.toFixed(2)).padStart(14)}\n`;
  textContent += `---------------------------------------------------------\n`;

  // Hourly Sales Summary Report
  textContent += `\n    HOURLY SALES SUMMARY REPORT\n`;
  textContent += `---------------------------------------------------------\n\n`;
  textContent += `Hour         Trn. Count   Qty. Sold   Amount (PHP)\n`;
  textContent += `---------------------------------------------------------\n`;

  let totalTransactions = 0;
  let totalQuantity = 0;
  let totalAmount = 0;

  zReading.hourlySales?.forEach((hourData) => {
    if (hourData.transactionCount === 0 && hourData.quantitySold === 0 && hourData.amount === 0) {
      return; // Skip if no sales
    }
    textContent += `${hourData.hour.padEnd(6)}  ${hourData.transactionCount.toString().padStart(10)}   ${hourData.quantitySold.toString().padStart(10)}   ${hourData.amount.toFixed(2).padStart(12)}\n`;

    totalTransactions += hourData.transactionCount || 0;
    totalQuantity += hourData.quantitySold || 0;
    totalAmount += hourData.amount || 0;
  });

  // Add the totals row
  textContent += `---------------------------------------------------------\n`;
  textContent += `Total${"".padEnd(2)} ${totalTransactions.toString().padStart(10)}   ${totalQuantity.toString().padStart(10)}   ${totalAmount.toFixed(2).padStart(12)}\n`;
  textContent += `---------------------------------------------------------\n`;

  // Accumulated Sales
  textContent += `\n                  ACCUMULATED SALES\n`;
  textContent += `---------------------------------------------------------\n`;
  
  const beginningBalance = zReading.accumulatedSales.beginningBalance || 0;
  const endingBalance = zReading.accumulatedSales.endingBalance || 0;
  const grandTotal = endingBalance - beginningBalance;
  const grandTotalSales = zReading.grandTotalSales || 0;
  
  textContent += `Beginning Balance:         ${beginningBalance.toFixed(2).padStart(24)}\n`;
  textContent += `Ending Balance:            ${endingBalance.toFixed(2).padStart(24)}\n`;
  textContent += `Accumulated Grand Total:               ${grandTotalSales.toFixed(2).padStart(12)}\n`;
  textContent += `---------------------------------------------------------\n`;

  // Footer
  textContent += `=========================================================\n`;
  textContent += `POS PROVIDER: SparkleStar International Corporation\n`;

  // Acknowledgment Signature Section
  textContent += `\n\n---------------------------------------------------------\n`;
  textContent += `           ACKNOWLEDGMENT & SIGNATURE SECTION\n`;
  textContent += `---------------------------------------------------------\n\n\n\n`;
  textContent += `Cashier Signature: _____________________________________\n`;
  textContent += `Cashier Printed Name: ${zReading.administrator || "AUTO-GENERATED"}\n\n\n\n`;
  textContent += `Manager Signature: _____________________________________\n\n`;
  textContent += `Manager Printed Name: __________________________________\n`;
  textContent += `---------------------------------------------------------\n`;

  // Create a Blob and trigger the download of the text file
  const blob = new Blob([textContent], { type: 'text/plain' });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.download = 'zReadingReport.txt';
  link.click();

  const generatePDFFromText = (textContent) => {
    const doc = new jsPDF();
    doc.setFont("courier", "normal");
  
    const pageHeight = 280;
    const margin = 10;
    let yOffset = margin;
  
    const lines = textContent.split("\n");
  
    lines.forEach((line) => {
      const lineHeight = line.length > 60 ? 12 : 10;
  
      if (yOffset + lineHeight > pageHeight - margin) {
        doc.addPage();
        yOffset = margin;
      }
  
      doc.text(line, 10, yOffset);
      yOffset += lineHeight;
    });
  
    const pdfBlob = doc.output("blob");
    const pdfUrl = URL.createObjectURL(pdfBlob);
  
    window.open(pdfUrl, "_blank");
  };

  generatePDFFromText(textContent);
};

export default generateZReadingTextFile;
