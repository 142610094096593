import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Paper from '@mui/material/Paper';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import CustomerHero from "../CustomerHero";
import { Warning, CheckCircle } from "@mui/icons-material";
import { Pagination, useMediaQuery, useTheme } from "@mui/material";
import { getAuthCredentials } from "../../../../utils/auth";
import { ExportButtons } from "../CustomerExport";
import Swal from "sweetalert2";

const CustomerDetails = () => {
  const [customers, setCustomers] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [limit, setLimit] = useState(10);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { token } = getAuthCredentials();
  const navigate = useNavigate();

  useEffect(() => {
    fetchCustomers();
  }, [searchQuery, page, limit]);

  const fetchCustomers = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/v1/api/customer/fetch-customers`,
        {
          params: {
            page,
            limit,
            search: searchQuery || undefined,
          },
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      const { customers, totalPages } = response.data;
      setCustomers(customers);
      setTotalPages(totalPages);
    } catch (error) {
      console.error("Error fetching customers:", error);
      setCustomers([]);
    } finally {
      setLoading(false);
    }
  };

  const handleRowClick = (customerId) => {
    navigate(`/reports/customer-history?search=${customerId}`);
  };

  const toggleCustomerFlag = async (customerId, currentFlag) => {
    try {
      const action = currentFlag ? "remove the flag" : "add a flag";
      const confirmResult = await Swal.fire({
        title: `Are you sure you want to ${action}?`,
        text: `This will ${
          currentFlag ? "remove" : "add"
        } the fraud flag for this customer.`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: currentFlag ? "#3085d6" : "#d33",
        cancelButtonColor: "#aaa",
        confirmButtonText: `Yes, ${action}`,
      });

      if (confirmResult.isConfirmed) {
        await axios.patch(
          `${process.env.REACT_APP_BACKEND_URL}/v1/api/customer/toggle-customer-flag/${customerId}`,
          {},
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );

        Swal.fire({
          icon: "success",
          title: "Success!",
          text: `Customer flag has been ${currentFlag ? "removed" : "added"}!`,
          confirmButtonColor: "#3085d6",
        });

        fetchCustomers();
      }
    } catch (error) {
      console.error("Error toggling flag:", error);
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "An error occurred while updating the flag status.",
        confirmButtonColor: "#d33",
      });
    }
  };

  return (
    <Box sx={{ padding: 3 }}>
      <CustomerHero activeTab="details" />

      <Typography variant="h4" align="center" gutterBottom>
        Customer Details
      </Typography>

      <Box
        sx={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          gap: 2,
          justifyContent: isMobile ? "flex-start" : "center",
          marginBottom: 3,
          alignItems: isMobile ? "stretch" : "center",
          width: isMobile ? "100%" : "auto",
        }}
      >
        <TextField
          label="Search Customer Name"
          value={searchQuery}
          onChange={(e) => {
            setSearchQuery(e.target.value);
            setPage(1);
          }}
          fullWidth
          sx={{ maxWidth: isMobile ? "100%" : 250 }}
        />

        {!isMobile && <ExportButtons data={customers} type="details" />}
      </Box>

      {isMobile && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            width: "100%",
          }}
        >
          <ExportButtons data={customers} type="details" />
        </Box>
      )}

      <Paper elevation={3} sx={{ padding: 2, overflowX: "auto" }}>
        {loading ? (
          <Box sx={{ display: "flex", justifyContent: "center", padding: 3 }}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Customer Name</TableCell>
                  <TableCell>Discount Type</TableCell>
                  <TableCell>Discount ID</TableCell>
                  <TableCell>City</TableCell>
                  <TableCell>Date Issued</TableCell>
                  <TableCell align="right">Issues</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {customers.map((customer, index) => (
                  <TableRow
                    key={index}
                    onClick={() => handleRowClick(customer._id)}
                    sx={{
                      cursor: "pointer",
                      border: customer.flag ? "2px solid red" : "none",
                      "&:hover": {
                        backgroundColor: "rgba(0, 0, 0, 0.1)",
                      },
                    }}
                  >
                    <TableCell>{customer.name}</TableCell>
                    <TableCell>{customer.discountType}</TableCell>
                    <TableCell>{customer.discountId || "N/A"}</TableCell>
                    <TableCell>{customer.city || "N/A"}</TableCell>
                    <TableCell>
                      {customer.dateIssued
                        ? new Date(customer.dateIssued).toLocaleDateString()
                        : "N/A"}
                    </TableCell>
                    <TableCell align="right">
                      {customer.flag !== undefined && (
                        <IconButton
                          onClick={(e) => {
                            e.stopPropagation(); // Prevent row click navigation
                            toggleCustomerFlag(customer._id, customer.flag);
                          }}
                          color={customer.flag ? "error" : "success"}
                        >
                          {customer.flag ? <Warning /> : <CheckCircle />}
                        </IconButton>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
              ;
            </Table>

            <Box
              sx={{
                display: "flex",
                flexDirection: isMobile ? "column" : "row",
                justifyContent: "center",
                alignItems: "center",
                marginTop: 3,
                gap: isMobile ? 1 : 3,
              }}
            >
              <FormControl
                sx={{
                  maxWidth: isMobile ? "100%" : 80,
                  "& .MuiInputBase-root": {
                    height: 30,
                    fontSize: "0.85rem",
                  },
                  "& .MuiSelect-select": {
                    padding: "5px 10px",
                  },
                }}
                fullWidth
              >
                <InputLabel sx={{ fontSize: "0.85rem" }}>Rows</InputLabel>
                <Select
                  value={limit}
                  onChange={(e) => {
                    setLimit(parseInt(e.target.value, 10));
                    setPage(1);
                  }}
                >
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={25}>25</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                  <MenuItem value={100}>100</MenuItem>
                </Select>
              </FormControl>
              <Pagination
                count={totalPages}
                page={page}
                onChange={(event, value) => setPage(value)}
                color="primary"
                showFirstButton
                showLastButton
                sx={{
                  "& .MuiPaginationItem-root": { borderRadius: "5px" },
                  "& .Mui-selected": {
                    backgroundColor: "#4CAF50",
                    color: "white",
                  },
                  "& .MuiPaginationItem-previousNext": {
                    fontSize: "1.2rem",
                    fontWeight: "bold",
                  },
                }}
              />
            </Box>
          </>
        )}
      </Paper>
    </Box>
  );
};

export default CustomerDetails;
