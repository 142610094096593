// VoucherQR.js
import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from '@mui/material/Button'
import { QRCodeCanvas } from "qrcode.react";

const VoucherQR = ({ open, onClose, voucher }) => {
  const handleDownloadQR = () => {
    const canvas = document.getElementById("voucherQrCanvas");
    if (!canvas) return;
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    const downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = `${voucher?.voucherCode || "voucher"}.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle sx={{ fontWeight: "bold", textAlign: "center" }}>
        QR Code for {voucher?.name}
      </DialogTitle>
      <DialogContent sx={{ display: "flex", justifyContent: "center" }}>
        <QRCodeCanvas
          id="voucherQrCanvas"
          value={voucher?.voucherCode || ""}
          size={256}
        />
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center" }}>
        <Button onClick={handleDownloadQR}>Download</Button>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default VoucherQR;
