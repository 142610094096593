import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { KioskProvider, useKioskContext } from '../context/KioskContext';

// Wrapper component that provides the KioskContext
const KioskLandingPageWrapper = () => {
  return (
    <KioskProvider>
      <KioskLandingPageContent />
    </KioskProvider>
  );
};

// Main content component that uses the KioskContext
const KioskLandingPageContent = () => {
  const navigate = useNavigate();
  const { posId } = useParams();
  const { translate } = useKioskContext();
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  // Array of food images for the slideshow
  const images = [
    'https://images.unsplash.com/photo-1482049016688-2d3e1b311543?fm=jpg&q=60&w=3000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    'https://plus.unsplash.com/premium_photo-1673108852141-e8c3c22a4a22?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    'https://images.unsplash.com/photo-1512621776951-a57141f2eefd?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
  ];

  // Automatically rotate through images
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 5000); // Change image every 5 seconds

    return () => clearInterval(interval);
  }, [images.length]);

  // Handle click on the page to navigate to the kiosk ordering page
  const handleStartOrder = () => {
    navigate(`/${posId}/order`);
  };

  return (
    <Box
      sx={{
        height: '100vh',
        width: '100vw',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        position: 'relative',
        cursor: 'pointer',
      }}
      onClick={handleStartOrder}
    >
      {/* Slideshow Container - 90% of screen height */}
      <Box
        sx={{
          height: '90vh',
          width: '100%',
          position: 'relative',
          overflow: 'hidden',
        }}
      >
        {/* Slideshow Images with Fade Transition */}
        {images.map((image, index) => (
          <Box
            key={index}
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              opacity: index === currentImageIndex ? 1 : 0,
              transition: 'opacity 1.5s ease-in-out',
              backgroundImage: `url(${image})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              zIndex: index === currentImageIndex ? 1 : 0,
            }}
          />
        ))}

        {/* Dark Overlay for better text visibility */}
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.4)',
            zIndex: 2,
          }}
        />

        {/* Welcome Text */}
        <Box
          sx={{
            position: 'absolute',
            top: '40%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            textAlign: 'center',
            zIndex: 3,
          }}
        >
          <Typography
            variant="h1"
            component="h1"
            sx={{
              color: 'white',
              fontWeight: 'bold',
              textShadow: '2px 2px 4px rgba(0,0,0,0.5)',
              mb: 2,
            }}
          >
            {translate('Welcome', { en: 'Welcome', zh: '欢迎', th: 'ยินดีต้อนรับ', tl: 'Maligayang pagdating' })}
          </Typography>
          <Typography
            variant="h4"
            sx={{
              color: 'white',
              textShadow: '1px 1px 2px rgba(0,0,0,0.5)',
            }}
          >
            {translate('Touch anywhere to start', {
              en: 'Touch anywhere to start',
              zh: '触摸任意位置开始',
              th: 'แตะที่ใดก็ได้เพื่อเริ่ม',
              tl: 'Pindutin kahit saan para magsimula'
            })}
          </Typography>
        </Box>
      </Box>

      {/* Start Order Button - 10% of screen height */}
      <Box
        sx={{
          height: '10vh',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'primary.main',
          zIndex: 3,
        }}
      >
        <Button
          variant="contained"
          color="primary"
          sx={{
            width: '100%',
            height: '100%',
            borderRadius: 0,
            fontSize: '1.5rem',
            fontWeight: 'bold',
            '&:hover': {
              backgroundColor: 'primary.dark',
            },
          }}
          onClick={handleStartOrder}
        >
          {translate('Start Order', {
            en: 'Start Order',
            zh: '开始订购',
            th: 'เริ่มการสั่งซื้อ',
            tl: 'Simulan ang Order'
          })}
        </Button>
      </Box>
    </Box>
  );
};

// Export the wrapped component
export default KioskLandingPageWrapper;
