import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

const MenuCategories = ({ categories, selectedCategory, onCategorySelect }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        Categories
      </Typography>
      <List
        sx={{
          display: isMobile ? "flex" : "block",
          flexDirection: "row",
          flexWrap: "nowrap",
          overflowX: "auto",
          pb: 1,
        }}
      >
        {categories.map((category) => (
          <ListItem
            key={category}
            disablePadding
            sx={{
              width: isMobile ? "auto" : "100%",
              minWidth: isMobile ? "150px" : "auto",
            }}
          >
            <ListItemButton
              selected={selectedCategory === category}
              onClick={() => onCategorySelect(category)}
              sx={{
                borderRadius: 1,
                mb: isMobile ? 0 : 1,
                bgcolor:
                  selectedCategory === category
                    ? theme.palette.primary.light
                    : "transparent",
                "&.Mui-selected": {
                  bgcolor: theme.palette.primary.light,
                },
                "&.Mui-selected:hover": {
                  bgcolor: theme.palette.primary.main,
                },
              }}
            >
              <ListItemText
                primary={category}
                primaryTypographyProps={{
                  color: selectedCategory === category ? "white" : "inherit",
                  fontWeight: selectedCategory === category ? "bold" : "normal",
                }}
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default MenuCategories;
