import React, { useState, useEffect } from "react";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Tooltip from '@mui/material/Tooltip';

import CircularProgress from '@mui/material/CircularProgress';
import Autocomplete from '@mui/material/Autocomplete';
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import DeleteIcon from "@mui/icons-material/Delete";
import GTranslateIcon from "@mui/icons-material/GTranslate";
import axios from "axios";
import { saveImage } from "../../../demo/utilities/indexedDB";
import { getAuthCredentials } from "../../../utils/auth";
import { translateText } from "../../../component/TranslateText";
import SecureLS from "secure-ls";
import Swal from "sweetalert2";

import AddMenuCategoryDialog from "./AddMenuCategoryDialog";

const ls = new SecureLS({ encodingType: "aes" });

const CreateMenuItemDialog = ({
  open,
  onClose,
  onSave,
  ingredients,
  menuCategories,
  setMenuCategories,
  menuItems,
  fetchMenuCategories,
}) => {
  const [name, setName] = useState("");
  const [translations, setTranslations] = useState({
    en: "",
    tl: "",
    zh: "",
    th: "",
  });
  const [showTranslations, setShowTranslations] = useState(false);
  const [loadingTranslation, setLoadingTranslation] = useState({
    en: false,
    tl: false,
    zh: false,
    th: false,
  });
  const [tags, setTags] = useState("");
  const [newCategory, setNewCategory] = useState("");
  const [category, setCategory] = useState("");
  const [isAddingCategory, setIsAddingCategory] = useState(false);
  const [image, setImage] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [imageError, setImageError] = useState("");
  const [selectedIngredients, setSelectedIngredients] = useState([]);
  const [ingredientQuantities, setIngredientQuantities] = useState({});
  const [price, setPrice] = useState("");
  const [code, setCode] = useState("");
  const [description, setDescription] = useState("");
  const [addons, setAddons] = useState([]);
  const [processTime, setProcessTime] = useState(0);

  const { token } = getAuthCredentials();

  const handleTranslate = async (lang) => {
    setLoadingTranslation((prev) => ({ ...prev, [lang]: true }));
    try {
      const translated = await translateText(name, lang);
      setTranslations((prev) => ({ ...prev, [lang]: translated }));
    } catch (error) {
      console.error(`Error translating to ${lang}:`, error);
    } finally {
      setLoadingTranslation((prev) => ({ ...prev, [lang]: false }));
    }
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    const allowedTypes = ["image/jpeg", "image/png", "image/gif"];
    const maxFileSize = 5 * 1024 * 1024;

    if (!file) return;

    if (!allowedTypes.includes(file.type)) {
      setImageError(
        "Unsupported file type. Only JPEG, PNG, and GIF are allowed."
      );
      setImage(null);
      setImageFile(null);
      return;
    }

    if (file.size > maxFileSize) {
      setImageError("File size exceeds 5MB.");
      setImage(null);
      setImageFile(null);
      return;
    }

    const previewUrl = URL.createObjectURL(file);
    setImage(previewUrl);
    setImageFile(file);
    setImageError("");
  };

  const handleDeleteCategory = async (categoryId) => {
    const user = ls.get("user");
    const posId = user?.posId;

    if (!token || !posId) {
      onClose();
      return;
    }

    try {
      await axios.delete(
        `${process.env.REACT_APP_BACKEND_URL}/v1/api/menu/delete-category`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          data: { categoryId, posId },
        }
      );

      await fetchMenuCategories();

      alert(`Category has been successfully deleted.`);
    } catch (error) {
      console.error("Error deleting category:", error);
      alert("Failed to delete category. Please try again.");
    }
  };

  const handleSave = async () => {
    if (!imageFile) {
      alert("Please upload an image.");
      return;
    }

    const newItem = {
      name,
      translations,
      code: code || name,
      category: {
        id: category.categoryId,
        name: category.categoryName,
      },
      menuImage: imageFile,
      ingredients: selectedIngredients.map((ingredient) => ({
        ...ingredient,
        quantity: ingredientQuantities[ingredient.inventoryId] || 1,
      })),
      price,
      description,
      addons,
      status: "OptionsNotAdded",
      tags: tags.split(",").map((tag) => tag.trim()),
      processTime: parseInt(processTime, 10),
    };

    onSave(newItem);
    resetForm();
    onClose();
  };

  const resetForm = () => {
    setName("");
    setCategory("");
    setImage(null);
    setSelectedIngredients([]);
    setPrice("");
    setDescription("");
    setAddons([]);
    setIngredientQuantities({});
  };

  const handleAddIngredient = (ingredient) => {
    if (
      !selectedIngredients.some((item) => item.inventoryId === ingredient._id)
    ) {
      setSelectedIngredients([
        ...selectedIngredients,
        { ...ingredient, inventoryId: ingredient._id },
      ]);
      setIngredientQuantities((prev) => ({
        ...prev,
        [ingredient.inventoryId]: 1,
      }));
    }
  };

  const handleRemoveIngredient = (ingredient) => {
    setSelectedIngredients(
      selectedIngredients.filter(
        (item) => item.inventoryId !== ingredient.inventoryId
      )
    );
    const updatedQuantities = { ...ingredientQuantities };
    delete updatedQuantities[ingredient.inventoryId];
    setIngredientQuantities(updatedQuantities);
  };

  const handleQuantityChange = (inventoryId, value) => {
    setIngredientQuantities((prev) => ({
      ...prev,
      [inventoryId]: parseInt(value) || 1,
    }));
  };

  const calculateTotalIngredientPrice = () => {
    return selectedIngredients.reduce((total, ingredient) => {
      const quantity = ingredientQuantities[ingredient.inventoryId] || 1;
      const ingredientInstance = ingredient.instances[0];
      const ingredientPricePerUnit = ingredientInstance
        ? ingredientInstance.inventoryPrice / ingredientInstance.purchaseAmount
        : 0;

      return total + ingredientPricePerUnit * quantity;
    }, 0);
  };

  useEffect(() => {
    const totalPrice = calculateTotalIngredientPrice();
    setPrice(totalPrice);
  }, [selectedIngredients, ingredientQuantities]);

  const isFormValid =
    name && category && image && selectedIngredients.length > 0 && price;

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>Create New Menu Item</DialogTitle>
      <DialogContent>
        <TextField
          label="Name"
          fullWidth
          margin="normal"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <Button
          fullWidth
          variant="outlined"
          sx={{ mt: 1, mb: 1 }}
          onClick={() => setShowTranslations(!showTranslations)}
        >
          {showTranslations ? "Hide Translations" : "Show Translations"}
        </Button>

        {showTranslations && (
          <>
            <Typography
              sx={{
                mb: 1,
                fontSize: "0.75rem",
                lineHeight: 1.25,
                color: "text.secondary",
              }}
            >
              Auto Translate uses Google Translate. Use cautiously since it can
              produce inaccurate translations
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sx={{ display: "flex", alignItems: "center" }}>
                <TextField
                  label="English"
                  fullWidth
                  margin="normal"
                  value={translations.en}
                  onChange={(e) =>
                    setTranslations((prev) => ({ ...prev, en: e.target.value }))
                  }
                  InputLabelProps={{ shrink: Boolean(translations.en) }}
                />
                <Tooltip title="Translate to English">
                  <span>
                    <IconButton
                      onClick={() => handleTranslate("en")}
                      disabled={!name || loadingTranslation.en}
                    >
                      {loadingTranslation.en ? (
                        <CircularProgress size={24} />
                      ) : (
                        <GTranslateIcon />
                      )}
                    </IconButton>
                  </span>
                </Tooltip>
              </Grid>

              <Grid item xs={12} sx={{ display: "flex", alignItems: "center" }}>
                <TextField
                  label="Tagalog"
                  fullWidth
                  margin="normal"
                  value={translations.tl}
                  onChange={(e) =>
                    setTranslations((prev) => ({ ...prev, tl: e.target.value }))
                  }
                  InputLabelProps={{ shrink: Boolean(translations.tl) }}
                />
                <Tooltip title="Translate to Tagalog">
                  <span>
                    <IconButton
                      onClick={() => handleTranslate("tl")}
                      disabled={!name || loadingTranslation.tl}
                    >
                      {loadingTranslation.tl ? (
                        <CircularProgress size={24} />
                      ) : (
                        <GTranslateIcon />
                      )}
                    </IconButton>
                  </span>
                </Tooltip>
              </Grid>

              <Grid item xs={12} sx={{ display: "flex", alignItems: "center" }}>
                <TextField
                  label="Chinese"
                  fullWidth
                  margin="normal"
                  value={translations.zh}
                  onChange={(e) =>
                    setTranslations((prev) => ({ ...prev, zh: e.target.value }))
                  }
                  InputLabelProps={{ shrink: Boolean(translations.zh) }}
                />
                <Tooltip title="Translate to Chinese">
                  <span>
                    <IconButton
                      onClick={() => handleTranslate("zh")}
                      disabled={!name || loadingTranslation.zh}
                    >
                      {loadingTranslation.zh ? (
                        <CircularProgress size={24} />
                      ) : (
                        <GTranslateIcon />
                      )}
                    </IconButton>
                  </span>
                </Tooltip>
              </Grid>

              <Grid item xs={12} sx={{ display: "flex", alignItems: "center" }}>
                <TextField
                  label="Thai"
                  fullWidth
                  margin="normal"
                  value={translations.th}
                  onChange={(e) =>
                    setTranslations((prev) => ({ ...prev, th: e.target.value }))
                  }
                  InputLabelProps={{ shrink: Boolean(translations.th) }}
                />
                <Tooltip title="Translate to Thai">
                  <span>
                    <IconButton
                      onClick={() => handleTranslate("th")}
                      disabled={!name || loadingTranslation.th}
                    >
                      {loadingTranslation.th ? (
                        <CircularProgress size={24} />
                      ) : (
                        <GTranslateIcon />
                      )}
                    </IconButton>
                  </span>
                </Tooltip>
              </Grid>
            </Grid>
          </>
        )}
        <TextField
          label="Code (Optional)"
          fullWidth
          margin="normal"
          value={code}
          onChange={(e) => setCode(e.target.value)}
          helperText="Leave empty to use the name as the code."
        />

        <Autocomplete
          options={[
            ...menuCategories,
            { categoryId: "add-new", categoryName: "Add New Category" },
          ]}
          getOptionLabel={(option) => option.categoryName || ""}
          value={category}
          onChange={(event, newValue) => {
            if (newValue && newValue.categoryId === "add-new") {
              setIsAddingCategory(true);
            } else {
              setCategory(newValue);
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Category"
              variant="outlined"
              margin="normal"
            />
          )}
          renderOption={(props, option) => (
            <li {...props} key={option.categoryId}>
              <span style={{ flexGrow: 1 }}>{option.categoryName}</span>
              {option.categoryId !== "add-new" && (
                <Tooltip title="Delete category">
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDeleteCategory(option.categoryId);
                    }}
                    color="error"
                    size="small"
                  >
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              )}
            </li>
          )}
        />

        {isAddingCategory && (
          <AddMenuCategoryDialog
            open={isAddingCategory}
            onClose={() => setIsAddingCategory(false)}
            onCategoryAdded={(newCategory) => {
              setMenuCategories((prevCategories) => [
                ...prevCategories,
                newCategory,
              ]);
              setCategory(newCategory);
              setIsAddingCategory(false);
            }}
          />
        )}

        <Grid container spacing={2} alignItems="center">
          <Grid item>
            <Button
              variant="contained"
              component="label"
              startIcon={<PhotoCamera />}
            >
              Upload Image
              <input
                hidden
                accept="image/*"
                type="file"
                onChange={handleImageUpload}
              />
            </Button>
          </Grid>
          {image && (
            <Grid item>
              <img
                src={image}
                alt="Preview"
                style={{ width: 100, height: 100, objectFit: "cover" }}
              />
            </Grid>
          )}
        </Grid>
        {imageError && (
          <Typography color="error" variant="body2" sx={{ marginTop: 1 }}>
            {imageError}
          </Typography>
        )}

        <TextField
          label="Preparation Time (in minutes)"
          fullWidth
          margin="normal"
          type="number"
          value={processTime}
          onChange={(e) => setProcessTime(e.target.value)}
          helperText="Enter the estimated preparation time for this menu item"
        />

        <Autocomplete
          options={ingredients}
          getOptionLabel={(option) =>
            `${option.inventoryName} - ${option.unitOfMeasurement || ""}`
          }
          onChange={(event, newValue) => {
            if (newValue) handleAddIngredient(newValue);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Add New Ingredients"
              margin="normal"
              variant="outlined"
            />
          )}
        />

        <List>
          {selectedIngredients.map((ingredient) => (
            <ListItem key={ingredient.inventoryId}>
              <ListItemText
                primary={`${ingredient.inventoryName} (${
                  ingredient.unitOfMeasurement || "UOM"
                })`}
              />
              <TextField
                label="Quantity"
                type="number"
                inputProps={{ min: 0 }}
                value={
                  ingredientQuantities[ingredient.inventoryId] ??
                  ingredient.quantity ??
                  ""
                }
                onChange={(e) => {
                  const newValue =
                    e.target.value === ""
                      ? ""
                      : Math.max(0, Number(e.target.value));
                  handleQuantityChange(ingredient.inventoryId, newValue);
                }}
                size="small"
                sx={{ width: 80, marginRight: 1 }}
              />
              <IconButton
                edge="end"
                onClick={() => handleRemoveIngredient(ingredient)}
                color="error"
              >
                <DeleteIcon />
              </IconButton>
            </ListItem>
          ))}
        </List>

        <TextField
          label="Base Price"
          fullWidth
          margin="normal"
          type="number"
          value={price}
          onChange={(e) => setPrice(e.target.value)}
          helperText={`Total Price of ingredients: ${calculateTotalIngredientPrice()}`}
        />

        <TextField
          label="Description (Optional)"
          fullWidth
          margin="normal"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />

        {/* <TextField
          select
          label="Upselling Addons"
          fullWidth
          margin="normal"
          value=""
          onChange={(e) => {
            const selectedAddon = menuItems.find(
              (menuItem) => menuItem.menuName === e.target.value
            );
            if (selectedAddon && !addons.includes(selectedAddon._id)) {
              setAddons([...addons, selectedAddon._id]);
            }
          }}
        >
          {menuItems
            .filter((menuItem) => menuItem.menuName !== name)
            .map((menuItem) => (
              <MenuItem key={menuItem._id} value={menuItem.menuName}>
                {menuItem.menuName}
              </MenuItem>
            ))}
        </TextField> */}

        <List>
          {addons.map((addonId) => {
            const addon = menuItems.find(
              (menuItem) => menuItem._id === addonId
            );
            return (
              <ListItem key={addonId}>
                <ListItemText primary={addon?.menuName || "Unknown Addon"} />
                <IconButton
                  edge="end"
                  onClick={() =>
                    setAddons(addons.filter((id) => id !== addonId))
                  }
                  color="error"
                >
                  <DeleteIcon />
                </IconButton>
              </ListItem>
            );
          })}
        </List>

        <TextField
          label="Tags"
          fullWidth
          margin="normal"
          value={tags}
          onChange={(e) => setTags(e.target.value)}
          helperText="If more than one tag, separate with commas"
        />
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleSave} color="primary" disabled={!isFormValid}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateMenuItemDialog;
