import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import axios from "axios";
import Swal from "sweetalert2";
import { getAuthCredentials } from "../../../utils/auth";
import { saveAs } from "file-saver";
import ExcelJS from "exceljs";

const DiscountModal = ({ open, onClose }) => {
  const [discountType, setDiscountType] = useState("None");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [loading, setLoading] = useState(false);
  const { token } = getAuthCredentials();

  const exportToExcel = async () => {
    if (!startDate || !endDate) {
      alert("Start date and end date are required.");
      return;
    }
    setLoading(true);
    try {
      // Get discount summary data from backend
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/v1/api/reports/discount-summary-data`,
        { startDate, endDate, discountType },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      // Expected response: { header, columns, rows, totals }
      const { header, columns, rows, totals } = response.data;

      // Create workbook and choose worksheet name based on discountType
      const workbook = new ExcelJS.Workbook();
      let worksheetName = "";
      if (discountType === "PWD") {
        worksheetName = "PWD Report";
      } else if (discountType === "Senior") {
        worksheetName = "Senior Citizen Report";
      } else if (discountType === "SoloParent") {
        worksheetName = "Solo Parent Report";
      } else if (discountType === "Diplomat") {
        worksheetName = "Diplomat Report";
      } else if (discountType === "Athlete") {
        worksheetName = "Athlete Report";
      } else {
        worksheetName = "Discount Report";
      }
      const worksheet = workbook.addWorksheet(worksheetName);

      // Define common styles
      const headerStyle = {
        font: { bold: true, color: { argb: "000000" } },
        alignment: { horizontal: "center", vertical: "middle" },
      };
      const borderStyle = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };

      // Build header rows using receipt config from header
      if (discountType === "Senior" || discountType === "PWD") {
        worksheet.mergeCells("A1:K1");
        worksheet.getCell("A1").value = header.storeName;
        worksheet.mergeCells("A2:K2");
        worksheet.getCell("A2").value =
          header.storeName || "<Name of Taxpayer>";
        worksheet.mergeCells("A3:K3");
        worksheet.getCell("A3").value = header.address;
        worksheet.mergeCells("A4:K4");
        worksheet.getCell("A4").value = `TIN: ${header.TIN}`;
      } else if (discountType === "SoloParent") {
        worksheet.mergeCells("A1:J1");
        worksheet.getCell("A1").value = header.storeName;
        worksheet.mergeCells("A2:J2");
        worksheet.getCell("A2").value =
          header.storeName || "<Name of Taxpayer>";
        worksheet.mergeCells("A3:J3");
        worksheet.getCell("A3").value = header.address;
        worksheet.mergeCells("A4:J4");
        worksheet.getCell("A4").value = `TIN: ${header.TIN}`;
      } else {
        // For Athlete, Diplomat, All, etc.
        worksheet.mergeCells("A1:G1");
        worksheet.getCell("A1").value = header.storeName;
        worksheet.mergeCells("A2:G2");
        worksheet.getCell("A2").value =
          header.storeName || "<Name of Taxpayer>";
        worksheet.mergeCells("A3:G3");
        worksheet.getCell("A3").value = header.address;
        worksheet.mergeCells("A4:G4");
        worksheet.getCell("A4").value = `TIN: ${header.TIN}`;
      }

      // Additional header rows (software info, etc.)
      worksheet.addRow([]);
      worksheet.addRow(["Sparkle POS v0.1.0 Release No. 1"]);
      worksheet.addRow([`Serial No. ${header.SN || "N/A"}`]);
      worksheet.addRow([`MIN: ${header.MIN || "N/A"}`]);
      worksheet.addRow([`POS Terminal No.: 0001`]);
      worksheet.addRow([
        `Date and Time Generated: ${new Date().toLocaleString("en-PH", {
          timeZone: "Asia/Manila",
        })}`,
      ]);
      worksheet.addRow([`UserID: ${header.user?.name || "N/A"} | ${header.user?.id || "N/A"}`]);


      // Merge cells for report title row
      if (discountType === "Senior" || discountType === "PWD") {
        worksheet.mergeCells("A12:K12");
      } else if (discountType === "SoloParent") {
        worksheet.mergeCells("A12:J12");
      } else {
        worksheet.mergeCells("A12:G12");
      }
      worksheet.getCell("A12").value = header.reportTitle;
      worksheet.getCell("A12").alignment = headerStyle.alignment;
      worksheet.getCell("A12").font = { name: "Calibri", size: 16, bold: true };
      worksheet.getCell("A12").border = {
        top: { style: "medium" },
        bottom: { style: "medium" },
      };

      // Set worksheet columns from API data and add extra two columns
      worksheet.columns = [
        ...columns.map((col) => ({ header: col.header, width: 20 })),
        { header: "", width: 10 },
        { header: "", width: 10 },
      ];

      // For Senior/PWD, add extra merge for discount header cells
      if (discountType === "Senior" || discountType === "PWD") {
        worksheet.mergeCells("I13:J13");
        worksheet.getCell("I13").value = "Discount";
        worksheet.getCell("I13").alignment = {
          horizontal: "center",
          vertical: "middle",
        };
        worksheet.getCell("I13").font = { bold: true, size: 12 };
        worksheet.getCell("I13").fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FFD700" },
        };

        worksheet.mergeCells("K13:K14");
        worksheet.getCell("K13").value = "Net Sales";
        worksheet.getCell("K13").alignment = {
          horizontal: "center",
          vertical: "middle",
        };
        worksheet.getCell("K13").font = { bold: true, size: 12 };
        worksheet.getCell("K13").fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "808080" },
        };
        worksheet.getCell("K13").border = borderStyle;
      }

      // Merge header cells for each column header (row 13-14)
      columns.forEach((col, index) => {
        const colLetter = String.fromCharCode(65 + index);
        worksheet.mergeCells(`${colLetter}13:${colLetter}14`);
        worksheet.getCell(`${colLetter}13`).value = col.header;
        worksheet.getCell(`${colLetter}13`).alignment = {
          horizontal: "center",
          vertical: "middle",
        };
        worksheet.getCell(`${colLetter}13`).fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: col.color },
        };
        worksheet.getCell(`${colLetter}13`).font = { bold: true };
        worksheet.getCell(`${colLetter}13`).border = borderStyle;
      });

      // For discount-specific header row values:
      if (discountType === "Senior" || discountType === "PWD") {
        worksheet.getCell("I14").value = "5%";
        worksheet.getCell("I14").alignment = {
          horizontal: "center",
          vertical: "middle",
        };
        worksheet.getCell("I14").fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FFD700" },
        };
        worksheet.getCell("I14").border = borderStyle;

        worksheet.getCell("J14").value = "20%";
        worksheet.getCell("J14").alignment = {
          horizontal: "center",
          vertical: "middle",
        };
        worksheet.getCell("J14").fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FFD700" },
        };
        worksheet.getCell("J14").border = borderStyle;
      } else if (discountType === "SoloParent") {
        worksheet.getCell("I14").value = "Discount";
        worksheet.getCell("I14").alignment = {
          horizontal: "center",
          vertical: "middle",
        };
        worksheet.getCell("I14").fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FFD700" },
        };
        worksheet.getCell("I14").border = borderStyle;

        worksheet.getCell("J14").value = "Net Sales";
        worksheet.getCell("J14").alignment = {
          horizontal: "center",
          vertical: "middle",
        };
        worksheet.getCell("J14").fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "808080" },
        };
        worksheet.getCell("J14").border = borderStyle;
      }

      // Set row heights for clarity
      worksheet.getRow(2).height = 20;
      worksheet.getRow(12).height = 30;

      const isSpecialMapped =
        discountType === "PWD" || discountType === "Senior";

      // Only map specific headers for PWD and Senior
      const columnKeyMap = isSpecialMapped
        ? {
          "PWD ID No.": "OSCA ID No./SC ID No.",
          "PWD TIN": "SC TIN",
          "Senior Citizen ID": "OSCA ID No./SC ID No.",
          "Senior Citizen TIN": "SC TIN",
        }
        : {};

      // Add data rows from API
      rows.forEach((rowData) => {
        const rowValues = columns.map((col) => {
          const key = columnKeyMap[col.header] || col.header;
          return rowData[key] || "";
        });

        if (discountType === "Senior" || discountType === "PWD") {
          rowValues.push("");
          rowValues.push(rowData["20%"] || rowData["Discount 20%"] || "");
          rowValues.push(rowData["Net Sales"] || "");
        }

        worksheet.addRow(rowValues);
      });

      // Append a totals row if totals exist
      if (totals) {
        const totalRow = [];
        totalRow[0] = "GRAND TOTAL:";
        const grossIndex = columns.findIndex((col) =>
          col.header.toLowerCase().includes("gross")
        );
        const netIndex = columns.findIndex((col) =>
          col.header.toLowerCase().includes("net")
        );
        const discountIndex = columns.findIndex((col) =>
          col.header.toLowerCase().includes("discount")
        );

        for (let i = 1; i < columns.length; i++) {
          if (i === grossIndex) {
            totalRow[i] = totals.grandGrossSales;
          } else if (i === netIndex) {
            totalRow[i] = totals.grandNetSales;
          } else if (i === discountIndex) {
            totalRow[i] = totals.grandDiscountAmount;
          } else {
            totalRow[i] = "";
          }
        }
        worksheet.addRow(totalRow);
      }

      // Auto-adjust column widths
      worksheet.columns.forEach((column) => {
        column.width = 20;
      });

      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(blob, `${worksheetName.replace(/\s+/g, "_")}.xlsx`);
    } catch (error) {
      console.error("Error generating Excel report:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Failed to generate the Discount Summary Report. Please try again.",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async () => {
    if (!startDate || !endDate) {
      alert("Start date and end date are required.");
      return;
    }
    setLoading(true);
    // Uncomment and modify if you need to call an API for submission
    /*
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/v1/api/reports/discount-summary`,
        { startDate, endDate, discountType },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      if (response.status === 200) {
        Swal.fire({
          icon: "success",
          title: "Report Generated",
          text: "Discount Summary Report generated successfully.",
        });
      }
    } catch (error) {
      console.error("Error generating discount summary report:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Failed to generate the Discount Summary Report. Please try again.",
      });
    } finally {
      setLoading(false);
      onClose();
    }
    */
    setLoading(false);
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Generate Discount Summary Report</DialogTitle>
      <DialogContent>
        <Typography variant="body1" sx={{ marginBottom: 2 }}>
          Please select the discount type and the date range for the Discount
          Summary Report:
        </Typography>
        <TextField
          select
          label="Discount Type"
          fullWidth
          value={discountType}
          onChange={(e) => setDiscountType(e.target.value)}
          sx={{ marginBottom: 2 }}
        >
          <MenuItem value="PWD">PWD</MenuItem>
          <MenuItem value="Senior">Senior</MenuItem>
          <MenuItem value="SoloParent">Solo Parent</MenuItem>
          <MenuItem value="Diplomat">Diplomat</MenuItem>
          <MenuItem value="Athlete">Athlete</MenuItem>
          <MenuItem value="All">All</MenuItem>
        </TextField>
        <TextField
          label="Start Date"
          type="date"
          fullWidth
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
          sx={{ marginBottom: 2 }}
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          label="End Date"
          type="date"
          fullWidth
          value={endDate}
          onChange={(e) => setEndDate(e.target.value)}
          sx={{ marginBottom: 2 }}
          InputLabelProps={{ shrink: true }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} disabled={loading}>
          Cancel
        </Button>
        <Button
          onClick={exportToExcel}
          variant="contained"
          color="primary"
          disabled={loading}
        >
          {loading ? "Generating..." : "Generate"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DiscountModal;
