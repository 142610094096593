import jsPDF from "jspdf";

const generateXReadingTextFile = (receiptConfig, xReading) => {
  let textContent = "";

  // Add Header
  textContent += `\n\n${receiptConfig.companyName || "N/A"}\n`;
  textContent += `Owned and Operated By:\n`;
  textContent += `${receiptConfig.storeName || "N/A"}\n`;
  textContent += `${receiptConfig.address || "N/A"}\n`;
  textContent += `VAT Registered TIN: ${receiptConfig.TIN || "N/A"}\n`;
  textContent += `SN: ${receiptConfig.SN || "N/A"} MIN: ${receiptConfig.MIN || "N/A"}\n`;
  textContent += `BIR Permit No.: ${receiptConfig.birPermit || "N/A"}\n`;
  textContent += `BIR Accreditation No.: ${receiptConfig.birAccredNo || "N/A"}\n`;
  textContent += "=========================================================\n";

  // X-Reading Details
  textContent += `---------------------------------------------------------\n`;
  textContent += `Reset Counter No.: ${xReading.resetNo != null ? xReading.resetNo : "N/A"}\n`;
  textContent += `Shift-end Report X-Reading#: ${
    xReading.xReadingCount
      ? xReading.xReadingCount.toString().padStart(10, "0")
      : "N/A"
  }\n`;
  textContent += `Administrator: ${xReading.administrator || "N/A"}\n`;
  textContent += `Cashier: AUTO-GENERATED\n`;
  textContent += `Start Date/Time: ${xReading.startDate ? new Date(xReading.startDate).toLocaleString() : "N/A"
    }\n`;
  textContent += `End Date/Time: ${xReading.endDate ? new Date(xReading.endDate).toLocaleString() : "N/A"
    }\n`;
  textContent += `Date/Time: ${xReading.createdAt ? new Date(xReading.createdAt).toLocaleString() : "N/A"
    }\n`;
  textContent += `Terminal Code: 001\n`;
  textContent += `Guest Count: ${xReading.totalTransactions || 0}\n`;

  // Sales Summary
  textContent += `\n---------------------------------------------------------\n`;
  textContent += `                       SALES SUMMARY REPORT\n`;
  textContent += `---------------------------------------------------------\n\n`;
  textContent += `Description                 Count    Amount (PHP)\n`;
  textContent += `---------------------------------------------------------\n`;

  textContent += `Gross Sales                ${String(xReading.totalTransactions || 0).padStart(6)}   ${String(xReading.grossSales?.toFixed(2) || "0.00").padStart(12)}\n`;
  textContent += `Senior Discounts           ${String(xReading.seniorTransactionCount || 0).padStart(6)}   ${String(xReading.seniorDiscounts?.toFixed(2) || "0.00").padStart(12)}\n`;
  textContent += `SC VAT Deduction           ${String(xReading.seniorTransactionCount || 0).padStart(6)}   ${String(xReading.scVatDeduction?.toFixed(2) || "0.00").padStart(12)}\n`;
  textContent += `PWD Discounts              ${String(xReading.pwdTransactionCount || 0).padStart(6)}   ${String(xReading.pwdDiscounts?.toFixed(2) || "0.00").padStart(12)}\n`;
  textContent += `PWD VAT Deduction          ${String(xReading.pwdTransactionCount || 0).padStart(6)}   ${String(xReading.pwdVatDeduction?.toFixed(2) || "0.00").padStart(12)}\n`;
  textContent += `Athlete Discounts          ${String(xReading.athleteTransactionCount || 0).padStart(6)}   ${String(xReading.athleteDiscounts?.toFixed(2) || "0.00").padStart(12)}\n`;
  textContent += `Solo Parent Discounts      ${String(xReading.soloParentTransactionCount || 0).padStart(6)}   ${String(xReading.soloParentDiscounts?.toFixed(2) || "0.00").padStart(12)}\n`;
  textContent += `Diplomat Discounts         ${String(xReading.diplomatTransactionCount || 0).padStart(6)}   ${String(xReading.diplomatDiscounts?.toFixed(2) || "0.00").padStart(12)}\n`;
  textContent += `Voucher Discount           ${String(xReading.voucherTransactionCount || 0).padStart(6)}   ${String(xReading.voucherDiscounts?.toFixed(2) || "0.00").padStart(12)}\n`;
  // textContent += `Other Discounts            ${String(xReading.athleteTransactionCount || 0).padStart(6)}   ${String(xReading.athleteDiscounts?.toFixed(2) || "0.00").padStart(12)}\n`;
  textContent += `---------------------------------------------------------\n`;
  textContent += `Sub-Total                           ${String(xReading.subTotal?.toFixed(2) || "0.00").padStart(12)}\n`;
  textContent += `Net Total                           ${String(xReading.netTotal?.toFixed(2) || "0.00").padStart(12)}\n`;
  textContent += `---------------------------------------------------------\n`;

  // VAT Breakdown Report
  textContent += `\n                      VAT BREAKDOWN REPORT\n`;
  textContent += `---------------------------------------------------------\n\n`;
  textContent += `Description           Amount (PHP)   VAT (12%) (PHP)\n`;
  textContent += `---------------------------------------------------------\n`;

  const vatExclusiveSales = xReading.vatSales || 0;
  const vatAmount = xReading.vatAmount;

  textContent += `VATable Sales         ${vatExclusiveSales.toFixed(2).padStart(10)}   ${vatAmount.toFixed(2).padStart(13)}\n`;
  textContent += `VAT Exempt Sales      ${xReading.vatExemptSales?.toFixed(2).padStart(10) || "0.00"}   ${"0.00".padStart(13)}\n`;
  textContent += `Zero-Rated Sales      ${xReading.zeroRatedSales?.toFixed(2).padStart(10) || "0.00"}   ${"0.00".padStart(13)}\n`;
  textContent += `---------------------------------------------------------\n`;
  textContent += `Total VAT             ${vatExclusiveSales.toFixed(2).padStart(10)}   ${vatAmount.toFixed(2).padStart(13)}\n`;
  textContent += `---------------------------------------------------------\n`;

  // VAT Summary
  textContent += `\n         TAX DECLARATION SUMMARY REPORT\n`;
  textContent += `---------------------------------------------------------\n\n`;
  textContent += `Tax Type                             Amount (PHP)\n`;
  textContent += `---------------------------------------------------------\n`;
  textContent += `VATable Sales           ${xReading.vatSales?.toFixed(2).padStart(24) || "0.00"}\n`;
  textContent += `VAT Amount (12%)        ${xReading.vatAmount?.toFixed(2).padStart(24) || "0.00"}\n`;
  textContent += `VAT Exempt Sales        ${xReading.vatExemptSales?.toFixed(2).padStart(24) || "0.00"}\n`;
  textContent += `Net of VAT Exempt       ${xReading.netOfVatExempt?.toFixed(2).padStart(24) || "0.00"}\n`;
  textContent += `Zero-Rated Sales        ${xReading.zeroRatedSales?.toFixed(2).padStart(24) || "0.00"}\n`;
  textContent += `---------------------------------------------------------\n`;  


  // Mandated VAT & Discount Report
  textContent += `\n    MANDATED VAT & DISCOUNT REPORT\n`;
  textContent += `---------------------------------------------------------\n\n`;
  textContent += `Description                       Count   Amount (PHP)\n`;
  textContent += `---------------------------------------------------------\n`;
  textContent += `Discounts TRN Count                 ${String(xReading.scPwdDiplomatTransactionCount || 0).padStart(0)}\n`;
  textContent += `Senior Discount                     ${String(xReading.seniorTransactionCount || 0).padStart(0)}   ${String(xReading.seniorDiscounts?.toFixed(2) || "0.00").padStart(8)}\n`;
  textContent += `SC VAT Deduction                    ${String(xReading.seniorTransactionCount || 0).padStart(0)}   ${String(xReading.scVatDeduction?.toFixed(2) || "0.00").padStart(8)}\n`;
  textContent += `PWD Discount                        ${String(xReading.pwdTransactionCount || 0).padStart(0)}   ${String(xReading.pwdDiscounts?.toFixed(2) || "0.00").padStart(8)}\n`;
  textContent += `PWD VAT Deduction                   ${String(xReading.pwdTransactionCount || 0).padStart(0)}   ${String(xReading.pwdVatDeduction?.toFixed(2) || "0.00").padStart(8)}\n`;
  textContent += `Athlete Discount                    ${String(xReading.athleteTransactionCount || 0).padStart(0)}   ${String(xReading.athleteDiscounts?.toFixed(2) || "0.00").padStart(8)}\n`;
  textContent += `Solo Parent Discount                ${String(xReading.soloParentTransactionCount || 0).padStart(0)}   ${String(xReading.soloParentDiscounts?.toFixed(2) || "0.00").padStart(8)}\n`;
  textContent += `Diplomat Discount                   ${String(xReading.diplomatTransactionCount || 0).padStart(0)}   ${String(xReading.diplomatDiscounts?.toFixed(2) || "0.00").padStart(8)}\n`;
  textContent += `Voucher Discount                    ${String(xReading.voucherTransactionCount || 0).padStart(0)}   ${String(xReading.voucherDiscounts?.toFixed(2) || "0.00").padStart(8)}\n`;
  // textContent += `Other Discount                      ${String(zReading.athleteTransactionCount || 0).padStart(6)}   ${String(zReading.athleteDiscounts?.toFixed(2) || "0.00").padStart(12)}\n`;
  textContent += `---------------------------------------------------------\n`;   

  // Transaction Details
  textContent += `          TRANSACTION DETAILS\n`;
  textContent += `---------------------------------------------------------\n\n`;
  textContent += `Beginning Inv. No.:        ${xReading.beginningInvoiceNo || "N/A"}\n`;
  textContent += `Ending Inv. No.:           ${xReading.endingInvoiceNo || "N/A"}\n`;
  textContent += `Beginning Void No.:        ${xReading.beginningVoidNo || "N/A"}\n`;
  textContent += `Ending Void No.:           ${xReading.endingVoidNo || "N/A"}\n`;
  textContent += `Total Transactions:        ${xReading.totalInvoiceCount || 0}\n`;
  textContent += `Open Drawer Count:         ${xReading.openDrawerCount || 0}\n`;
  textContent += `Void Items:                ${xReading.voidItemsCount || 0}         ${xReading.voidItemsAmount?.toFixed(2) || "0.00"}\n`;
  // textContent += `Refunds:                 ${xReading.refundsCount || 0}        ${xReading.refundsAmount?.toFixed(2) || "0.00"}\n\n`;
  textContent += `Dine-In:              ${String(xReading.dineInCount || 0).padStart(6)}   ${String(xReading.dineInSales?.toFixed(2) || "0.00").padStart(12)}\n`;
  textContent += `Take-Out:             ${String(xReading.takeOutCount || 0).padStart(6)}   ${String(xReading.takeOutSales?.toFixed(2) || "0.00").padStart(12)}\n`;
  // textContent += `Delivery:             ${String(xReading.deliveryCount || 0).padStart(6)}   ${String(xReading.deliverySales?.toFixed(2) || "0.00").padStart(12)}\n`;
  textContent += `Free Meals:           ${String(xReading.freeMeals || 0).padStart(6)}   ${String("0.00").padStart(12)}\n`;
  textContent += `---------------------------------------------------------\n`;

  // Tender Summary Report
  textContent += `\n            TENDER SUMMARY REPORT\n`;
  textContent += `---------------------------------------------------------\n\n`;
  textContent += `Tender Type               Count   Amount (PHP)\n`;
  textContent += `---------------------------------------------------------\n`;

  if (xReading.tenderSummary && xReading.tenderSummary.length > 0) {
    xReading.tenderSummary.forEach((tender) => {
      textContent += `${tender.tenderType.padEnd(20)} ${String(
        tender.transactionCount || 0
      ).padStart(7)}   ${String(tender.tenderAmount?.toFixed(2) || "0.00").padStart(12)}\n`;
    });

    textContent += `---------------------------------------------------------\n`;

    const filteredTenders = xReading.tenderSummary.filter(
      (t) => t.tenderType.toUpperCase() !== "TOTAL"
    );

    const totalTenderTransactions = filteredTenders.reduce(
      (sum, t) => sum + (t.transactionCount || 0),
      0
    );
    const totalTenderAmount = filteredTenders.reduce(
      (sum, t) => sum + (t.tenderAmount || 0),
      0
    );

    textContent += `Total Payments         ${String(totalTenderTransactions).padStart(5)}   ${String(totalTenderAmount.toFixed(2)).padStart(12)}\n`;
    textContent += `\nTotal Tenders (All Modes): ${totalTenderTransactions} Transactions, PHP ${totalTenderAmount.toFixed(2)}\n`;
  } else {
    textContent += `No tender data available.\n`;
  }

  textContent += `---------------------------------------------------------\n`;

  // Cash Breakdown Report
  textContent += `\n                CASH BREAKDOWN REPORT\n`;
  textContent += `---------------------------------------------------------\n\n`;
  textContent += `Denom    Add Qty   Add Total   Ded Qty   Ded Total\n`;
  textContent += `---------------------------------------------------------\n`;

  let totalCash = 0;

  if (xReading.cashBreakdown && Object.keys(xReading.cashBreakdown).length > 0) {
    const sortedDenominations = Object.entries(xReading.cashBreakdown)
      .sort(([a], [b]) => parseFloat(b) - parseFloat(a))
      .map(([denom, details]) => {
        const denomVal = parseFloat(denom);
        const additionTotal = details.addition.count * denomVal;
        const deductionTotal = details.deduction.count * denomVal;
        totalCash += additionTotal - deductionTotal;
        return {
          denomination: denomVal,
          additionCount: details.addition.count,
          additionTotal: additionTotal.toFixed(2),
          deductionCount: details.deduction.count,
          deductionTotal: deductionTotal.toFixed(2),
        };
      });

    sortedDenominations.forEach((denom) => {
      textContent += `P ${denom.denomination.toFixed(2).padStart(6)}   ${String(denom.additionCount).padStart(5)}   ${String(denom.additionTotal).padStart(10)}   ${String(denom.deductionCount).padStart(5)}   ${String(denom.deductionTotal).padStart(10)}\n`;
    });
  } else {
    textContent += `No cash breakdown data available.\n`;
  }

  textContent += `---------------------------------------------------------\n`;
  textContent += `NET CASH:               ${String(totalCash.toFixed(2)).padStart(19)}\n`;

  // Compute OTHER PAYMENT OPTIONS (exclude 'cash')
  const otherPayments = (xReading.tenderSummary || [])
    .filter(
      (t) => !["cash", "total"].includes(t.tenderType.toLowerCase())
    )
    .reduce((sum, t) => sum + (t.tenderAmount || 0), 0);

  textContent += `OTHER PAYMENT:   - ${otherPayments.toFixed(2).padStart(24)}\n`;

  // Calculate short/over
  const expectedTotal = xReading.netTotal || 0;
  const actualTotal = totalCash + otherPayments;
  const shortOver = actualTotal - expectedTotal;

  const shortOverLabel = shortOver < 0 ? "SHORT:" : "OVER :";
  const shortOverAmount = Math.abs(shortOver).toFixed(2);
  const sign = shortOver < 0 ? "-" : "";

  textContent += `${shortOverLabel.padEnd(30)} ${sign}${shortOverAmount.padStart(12)}\n`;
  textContent += `---------------------------------------------------------\n`;

  // Sales by Cashier Summary Report
  textContent += `\n    SALES BY CASHIER SUMMARY REPORT\n`;
  textContent += `---------------------------------------------------------\n\n`;
  textContent += `Cashier Name         Total Sales (PHP)\n`;
  textContent += `---------------------------------------------------------\n`;

  const cashierName = xReading.administrator || "AUTO-GENERATED";
  const totalCashierSales = xReading.netTotal?.toFixed(2) || "0.00";

  textContent += `${cashierName.padEnd(20)} ${totalCashierSales.padStart(12)}\n`;

  textContent += `---------------------------------------------------------\n`;

  // Sales by Category Summary Report
  textContent += `\n    SALES BY CATEGORY SUMMARY REPORT\n`;
  textContent += `---------------------------------------------------------\n\n`;
  textContent += `Category                   Qty      Amount (PHP)\n`;
  textContent += `---------------------------------------------------------\n`;

  let totalCategorySales = 0;

  xReading.salesPerCategory?.forEach((category) => {
    textContent += `${category.categoryName.padEnd(20)} ${String(category.totalQuantity || 0).padStart(8)}   ${String(category.totalSales?.toFixed(2) || "0.00").padStart(12)}\n`;
    totalCategorySales += category.totalSales || 0;
  });

  textContent += `Total Sales by Category      ${String(totalCategorySales.toFixed(2)).padStart(15)}\n`;
  textContent += `---------------------------------------------------------\n`;

  // Sales by Menu Summary Report
  textContent += `\n       SALES BY MENU SUMMARY REPORT\n`;
  textContent += `---------------------------------------------------------\n\n`;
  textContent += `Category                   Qty     Amount (PHP)\n`;
  textContent += `---------------------------------------------------------\n`;

  let totalMenuSales = 0;
  const maxNameLength = 24;

  xReading.salesPerMenu?.forEach((menuCategory) => {
    menuCategory.menuItems.forEach((item) => {
      totalMenuSales += item.sales || 0;
      
      const qtyStr = String(item.quantity || 0).padStart(4);
      const amountStr = String(item.sales?.toFixed(2) || "0.00").padStart(12);
      
      const name = item.name;
      const nameChunks = [];
      
      for (let i = 0; i < name.length; i += maxNameLength) {
        nameChunks.push(name.substring(i, i + maxNameLength));
      }
      
      nameChunks.forEach((chunk, index) => {
        if (index === nameChunks.length - 1) {
          textContent += chunk.padEnd(maxNameLength) + " " + qtyStr + "   " + amountStr + "\n";
        } else {
          textContent += chunk + "\n";
        }
      });
    });
  });

  textContent += `---------------------------------------------------------\n`;
  textContent += `Total Sales by Menu           ${String(totalMenuSales.toFixed(2)).padStart(14)}\n`;
  textContent += `---------------------------------------------------------\n`;

  // Hourly Sales Summary Report
  textContent += `\n    HOURLY SALES SUMMARY REPORT\n`;
  textContent += `---------------------------------------------------------\n\n`;
  textContent += `Hour         Trn. Count   Qty. Sold   Amount (PHP)\n`;
  textContent += `---------------------------------------------------------\n`;

  let totalTransactions = 0;
  let totalQuantity = 0;
  let totalAmount = 0;

  xReading.hourlySales?.forEach((hourData) => {
    if (hourData.transactionCount === 0 && hourData.quantitySold === 0 && hourData.amount === 0) {
      return; // Skip if no sales
    }

    textContent += `${hourData.hour.padEnd(6)}  ${hourData.transactionCount.toString().padStart(10)}   ${hourData.quantitySold.toString().padStart(10)}   ${hourData.amount.toFixed(2).padStart(12)}\n`;

    totalTransactions += hourData.transactionCount || 0;
    totalQuantity += hourData.quantitySold || 0;
    totalAmount += hourData.amount || 0;
  });

  // Add the totals row
  textContent += `---------------------------------------------------------\n`;
  textContent += `Total${"".padEnd(2)} ${totalTransactions.toString().padStart(10)}   ${totalQuantity.toString().padStart(10)}   ${totalAmount.toFixed(2).padStart(12)}\n`;
  textContent += `---------------------------------------------------------\n`;

  // Accumulated Sales
  textContent += `\n                  ACCUMULATED SALES\n`;
  textContent += `---------------------------------------------------------\n`;
  
  const beginningBalance = xReading.accumulatedSales.beginningBalance || 0;
  const endingBalance = xReading.accumulatedSales.endingBalance || 0;
  const grandTotal = endingBalance - beginningBalance;
  const grandTotalSales = xReading.grandTotalSales || 0;
  
  textContent += `Beginning Balance:         ${beginningBalance.toFixed(2).padStart(12)}\n`;
  textContent += `Ending Balance:            ${endingBalance.toFixed(2).padStart(12)}\n`;
  textContent += `Accumulated Grand Total:               ${grandTotalSales.toFixed(2).padStart(12)}\n`;
  textContent += `---------------------------------------------------------\n`;

  // Footer
  textContent += `=========================================================\n`;
  textContent += `POS PROVIDER: SparkleStar International Corporation\n`;

  // Acknowledgment Signature Section
  textContent += `\n\n---------------------------------------------------------\n`;
  textContent += `           ACKNOWLEDGMENT & SIGNATURE SECTION\n`;
  textContent += `---------------------------------------------------------\n\n\n\n`;
  textContent += `Cashier Signature: _____________________________________\n`;
  textContent += `Cashier Printed Name: ${xReading.administrator || "AUTO-GENERATED"}\n\n\n\n`;
  textContent += `Manager Signature: _____________________________________\n\n`;
  textContent += `Manager Printed Name: __________________________________\n`;
  textContent += `---------------------------------------------------------\n`;

  // Create a Blob and trigger the download of the text file
  const blob = new Blob([textContent], { type: "text/plain" });
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.download = "xReadingReport.txt";
  link.click();

  const generatePDFFromText = (textContent) => {
    const doc = new jsPDF();
    doc.setFont("courier", "normal");
    
    const pageHeight = 280;
    const margin = 10;
    let yOffset = margin;
  
    const lines = textContent.split("\n");
  
    lines.forEach((line) => {
      const lineHeight = line.length > 60 ? 12 : 10;
  
      if (yOffset + lineHeight > pageHeight - margin) {
        doc.addPage();
        yOffset = margin;
      }
  
      doc.text(line, 10, yOffset);
      yOffset += lineHeight;
    });
  
    const pdfBlob = doc.output("blob");
    const pdfUrl = URL.createObjectURL(pdfBlob);
  
    window.open(pdfUrl, "_blank");
  };

  generatePDFFromText(textContent);
};

export default generateXReadingTextFile;
