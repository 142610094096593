import React, { useState, useEffect } from "react";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Tooltip from '@mui/material/Tooltip';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import CircularProgress from '@mui/material/CircularProgress';
import Autocomplete from '@mui/material/Autocomplete';
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import DeleteIcon from "@mui/icons-material/Delete";
import GTranslateIcon from "@mui/icons-material/GTranslate";
import axios from "axios";
import Swal from "sweetalert2";
import { saveImage, getImage } from "../../../demo/utilities/indexedDB";
import { getAuthCredentials } from "../../../utils/auth";
import { translateText } from "../../../component/TranslateText";
import AddMenuCategoryDialog from "./AddMenuCategoryDialog";

const EditMenuItemDialog = ({
  open,
  onClose,
  item,
  ingredients,
  menuCategories,
  setMenuCategories,
  menuItems,
  fetchMenuCategories,
  fetchMenuData,
}) => {
  const [name, setName] = useState("");
  const [translations, setTranslations] = useState({
    en: "",
    tl: "",
    zh: "",
    th: "",
  });
  const [showTranslations, setShowTranslations] = useState(false);
  const [loadingTranslation, setLoadingTranslation] = useState({
    en: false,
    tl: false,
    zh: false,
    th: false,
  });
  const [category, setCategory] = useState("");
  const [tags, setTags] = useState("");
  const [newCategory, setNewCategory] = useState("");
  const [isAddingCategory, setIsAddingCategory] = useState(false);
  const [image, setImage] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [selectedIngredients, setSelectedIngredients] = useState([]);
  const [ingredientQuantities, setIngredientQuantities] = useState({});
  const [price, setPrice] = useState("");
  const [code, setCode] = useState("");
  const [description, setDescription] = useState("");
  const [addons, setAddons] = useState([]);
  const [processTime, setProcessTime] = useState(0);
  const { token } = getAuthCredentials();

  useEffect(() => {
    if (item) {
      setName(item.menuName);
      setTranslations(item.translations || {});
      setCode(item.code || item.menuName);
      setCategory({
        categoryId: item.categoryId,
        categoryName:
          menuCategories.find((cat) => cat.categoryId === item.categoryId)
            ?.categoryName || "",
      });
      setProcessTime(item.processTime || 0);

      // Map over each ingredient, and derive a unique string "finalId"
      const mappedIngredients = item.menuIngredients.map((ingredient) => {
        const dbId = ingredient.ingredientId; // could be an object or null
        // If dbId is an object with _id, use that. Otherwise fallback to ingredientName.
        const finalId =
          dbId && dbId._id
            ? dbId._id
            : ingredient.ingredientName;

        return {
          inventoryId: finalId,
          inventoryName: ingredient.ingredientName,
          price: ingredient.ingredientPrice,
          quantity: ingredient.amount,
        };
      });

      setSelectedIngredients(mappedIngredients);

      // Initialize ingredientQuantities with the same finalId
      const initialQuantities = {};
      item.menuIngredients.forEach((ingredient) => {
        const dbId = ingredient.ingredientId;
        const finalId =
          dbId && dbId._id
            ? dbId._id
            : ingredient.ingredientName;
        initialQuantities[finalId] = ingredient.amount;
      });
      setIngredientQuantities(initialQuantities);

      setPrice(item.basePrice || "");
      setDescription(item.description);
      setAddons(item.addOns || []);
      setTags(item.menuTags?.join(", ") || "");

      if (item.imageId) {
        (async () => {
          const imageBlob = await getImage(item.imageId);
          const imageUrl = imageBlob ? URL.createObjectURL(imageBlob) : null;
          setImage(imageUrl);
        })();
      }
    }
  }, [item, menuCategories]);


  const handleTranslate = async (lang) => {
    setLoadingTranslation((prev) => ({ ...prev, [lang]: true }));
    try {
      const translated = await translateText(name, lang);
      setTranslations((prev) => ({ ...prev, [lang]: translated }));
    } catch (error) {
      console.error(`Error translating to ${lang}:`, error);
    } finally {
      setLoadingTranslation((prev) => ({ ...prev, [lang]: false }));
    }
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    const allowedTypes = ["image/jpeg", "image/png", "image/gif"];
    const maxFileSize = 5 * 1024 * 1024;

    if (!file) return;

    if (!allowedTypes.includes(file.type)) {
      alert("Unsupported file type. Only JPEG, PNG, and GIF are allowed.");
      setImage(null);
      setImageFile(null);
      return;
    }

    if (file.size > maxFileSize) {
      alert("File size exceeds 5MB.");
      setImage(null);
      setImageFile(null);
      return;
    }

    const previewUrl = URL.createObjectURL(file);
    setImage(previewUrl);
    setImageFile(file);
  };

  const handleSave = async () => {
    const formData = new FormData();
    if (imageFile) {
      formData.append("menuImage", imageFile);
    }

    const jsonPayload = {
      menuName: name,
      translations,
      code: code || name,
      categoryId: category.categoryId,
      categoryName: category.categoryName,
      basePrice: price,
      description,
      addOns: addons,
      menuTags: tags.split(",").map((tag) => tag.trim()),
      menuIngredients: selectedIngredients.map((ingredient) => {
        const isDbId = ingredients.some((ing) => ing._id === ingredient.inventoryId);

        return {
          ingredientId: isDbId ? ingredient.inventoryId : null,
          ingredientName: ingredient.inventoryName,
          ingredientPrice: ingredient.price,
          amount: ingredientQuantities[ingredient.inventoryId] ?? ingredient.quantity ?? 1,
        };
      }),
      processTime: parseInt(processTime, 10),
    };


    formData.append("data", JSON.stringify(jsonPayload));

    try {
      await axios.put(
        `${process.env.REACT_APP_BACKEND_URL}/v1/api/menu/edit/${item._id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      fetchMenuData();
      onClose();
      setTimeout(() => {
        Swal.fire({
          icon: "success",
          title: "Updated",
          text: "Menu item updated successfully.",
        });
      }, 100);
    } catch (error) {
      console.error("Error updating menu item:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Failed to update the menu item. Please try again.",
      });
    }
  };

  const handleDeleteCategory = async (categoryId) => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_BACKEND_URL}/v1/api/menu/delete-category`,
        {
          headers: { Authorization: `Bearer ${token}` },
          data: { categoryId },
        }
      );

      fetchMenuCategories();
    } catch (error) {
      console.error("Error deleting category:", error);
    }
  };

  const handleAddIngredient = (ingredient) => {
    // Use the DB’s _id if it exists, otherwise fallback to name
    const finalId = ingredient._id || ingredient.inventoryName;

    // Don’t add if it’s already in the list
    if (!selectedIngredients.some((item) => item.inventoryId === finalId)) {
      setSelectedIngredients((prev) => [
        ...prev,
        {
          inventoryId: finalId,
          inventoryName: ingredient.inventoryName,
          price: ingredient.price,
          quantity: 1,
        },
      ]);

      setIngredientQuantities((prev) => ({
        ...prev,
        [finalId]: 1,
      }));
    }
  };

  const handleRemoveIngredient = (ingredient) => {
    setSelectedIngredients((prev) =>
      prev.filter((item) => item.inventoryId !== ingredient.inventoryId)
    );

    setIngredientQuantities((prev) => {
      const updated = { ...prev };
      delete updated[ingredient.inventoryId];
      return updated;
    });
  };


  const handleQuantityChange = (inventoryId, value) => {
    setIngredientQuantities((prev) => ({
      ...prev,
      [inventoryId]: parseInt(value) || 1,
    }));
  };

  const calculateTotalIngredientPrice = () => {
    return selectedIngredients.reduce((total, ingredient) => {
      const quantity = ingredientQuantities[ingredient.inventoryId] || 1;
      const ingredientPrice = ingredient.price / ingredient.amountOrdered;
      return total + ingredientPrice * quantity;
    }, 0);
  };

  const isFormValid =
    name && category && selectedIngredients.length > 0 && price;

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>Edit Menu Item</DialogTitle>
      <DialogContent>
        <TextField
          label="Name"
          fullWidth
          margin="normal"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <Button
          fullWidth
          variant="outlined"
          sx={{ mt: 1, mb: 1 }}
          onClick={() => setShowTranslations(!showTranslations)}
        >
          {showTranslations ? "Hide Translations" : "Show Translations"}
        </Button>

        {showTranslations && (
          <>
            <Typography
              variant="caption"
              color="textSecondary"
              sx={{ mb: 1, lineHeight: 1.2 }}
            >
              Auto Translate uses Google Translate. Optional and Use Cautiously
              since it can produce inaccurate translations
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sx={{ display: "flex", alignItems: "center" }}>
                <TextField
                  label="English"
                  fullWidth
                  margin="normal"
                  value={translations.en}
                  onChange={(e) =>
                    setTranslations((prev) => ({ ...prev, en: e.target.value }))
                  }
                  InputLabelProps={{ shrink: Boolean(translations.en) }}
                />
                <Tooltip title="Translate to English">
                  <span>
                    <IconButton
                      onClick={() => handleTranslate("en")}
                      disabled={!name || loadingTranslation.en}
                    >
                      {loadingTranslation.en ? (
                        <CircularProgress size={24} />
                      ) : (
                        <GTranslateIcon />
                      )}
                    </IconButton>
                  </span>
                </Tooltip>
              </Grid>

              <Grid item xs={12} sx={{ display: "flex", alignItems: "center" }}>
                <TextField
                  label="Tagalog"
                  fullWidth
                  margin="normal"
                  value={translations.tl}
                  onChange={(e) =>
                    setTranslations((prev) => ({ ...prev, tl: e.target.value }))
                  }
                  InputLabelProps={{ shrink: Boolean(translations.tl) }}
                />
                <Tooltip title="Translate to Tagalog">
                  <span>
                    <IconButton
                      onClick={() => handleTranslate("tl")}
                      disabled={!name || loadingTranslation.tl}
                    >
                      {loadingTranslation.tl ? (
                        <CircularProgress size={24} />
                      ) : (
                        <GTranslateIcon />
                      )}
                    </IconButton>
                  </span>
                </Tooltip>
              </Grid>

              <Grid item xs={12} sx={{ display: "flex", alignItems: "center" }}>
                <TextField
                  label="Chinese"
                  fullWidth
                  margin="normal"
                  value={translations.zh}
                  onChange={(e) =>
                    setTranslations((prev) => ({ ...prev, zh: e.target.value }))
                  }
                  InputLabelProps={{ shrink: Boolean(translations.zh) }}
                />
                <Tooltip title="Translate to Chinese">
                  <span>
                    <IconButton
                      onClick={() => handleTranslate("zh")}
                      disabled={!name || loadingTranslation.zh}
                    >
                      {loadingTranslation.zh ? (
                        <CircularProgress size={24} />
                      ) : (
                        <GTranslateIcon />
                      )}
                    </IconButton>
                  </span>
                </Tooltip>
              </Grid>

              <Grid item xs={12} sx={{ display: "flex", alignItems: "center" }}>
                <TextField
                  label="Thai"
                  fullWidth
                  margin="normal"
                  value={translations.th}
                  onChange={(e) =>
                    setTranslations((prev) => ({ ...prev, th: e.target.value }))
                  }
                  InputLabelProps={{ shrink: Boolean(translations.th) }}
                />
                <Tooltip title="Translate to Thai">
                  <span>
                    <IconButton
                      onClick={() => handleTranslate("th")}
                      disabled={!name || loadingTranslation.th}
                    >
                      {loadingTranslation.th ? (
                        <CircularProgress size={24} />
                      ) : (
                        <GTranslateIcon />
                      )}
                    </IconButton>
                  </span>
                </Tooltip>
              </Grid>
            </Grid>
          </>
        )}
        <TextField
          label="Code (Optional)"
          fullWidth
          margin="normal"
          value={code}
          onChange={(e) => setCode(e.target.value)}
          helperText="Leave empty to use the name as the code."
        />

        <Autocomplete
          options={[
            ...menuCategories,
            { categoryId: "add-new", categoryName: "Add New Category" },
          ]}
          getOptionLabel={(option) => option.categoryName || ""}
          value={category}
          onChange={(event, newValue) => {
            if (newValue && newValue.categoryId === "add-new") {
              setIsAddingCategory(true);
            } else {
              setCategory(newValue);
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Category"
              variant="outlined"
              margin="normal"
            />
          )}
          renderOption={(props, option) => (
            <li {...props} key={option.categoryId}>
              <span style={{ flexGrow: 1 }}>{option.categoryName}</span>
              {option.categoryId !== "add-new" && (
                <Tooltip title="Delete category">
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDeleteCategory(option.categoryId);
                    }}
                    color="error"
                    size="small"
                  >
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              )}
            </li>
          )}
        />

        {isAddingCategory && (
          <AddMenuCategoryDialog
            open={isAddingCategory}
            onClose={() => setIsAddingCategory(false)}
            onCategoryAdded={(newCategory) => {
              setMenuCategories((prevCategories) => [
                ...prevCategories,
                newCategory,
              ]);
              setCategory(newCategory);
              setIsAddingCategory(false);
            }}
          />
        )}

        <Grid container spacing={2} alignItems="center">
          <Grid item>
            <Button
              variant="contained"
              component="label"
              startIcon={<PhotoCamera />}
            >
              Upload Image
              <input
                hidden
                accept="image/*"
                type="file"
                onChange={handleImageUpload}
              />
            </Button>
          </Grid>
          {image && (
            <Grid item>
              <img
                src={image}
                alt="Preview"
                style={{ width: 100, height: 100, objectFit: "cover" }}
              />
            </Grid>
          )}
        </Grid>

        <TextField
          label="Preparation Time (in minutes)"
          fullWidth
          margin="normal"
          type="number"
          value={processTime}
          onChange={(e) => setProcessTime(e.target.value)}
          helperText="Enter the estimated preparation time for this menu item"
        />

        <Autocomplete
          options={ingredients}
          getOptionLabel={(option) =>
            `${option.inventoryName} - ${option.unitOfMeasurement || ""}`
          }
          onChange={(event, newValue) => {
            if (newValue) handleAddIngredient(newValue);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Add New Ingredients"
              margin="normal"
              variant="outlined"
            />
          )}
        />

        <List>
          {selectedIngredients.map((ingredient) => (
            <ListItem key={ingredient.inventoryId}>
              <ListItemText
                primary={`${ingredient.inventoryName} (${ingredient.unitOfMeasurement || "UOM"})`}
              />
              <TextField
                label="Quantity"
                type="number"
                inputProps={{ min: 0 }}
                value={
                  ingredientQuantities[ingredient.inventoryId] ?? ingredient.quantity ?? ""
                }
                onChange={(e) => {
                  const newValue =
                    e.target.value === ""
                      ? ""
                      : Math.max(0, Number(e.target.value));
                  handleQuantityChange(ingredient.inventoryId, newValue);
                }}
                size="small"
                sx={{ width: 80, marginRight: 1 }}
              />
              <IconButton
                edge="end"
                onClick={() => handleRemoveIngredient(ingredient)}
                color="error"
              >
                <DeleteIcon />
              </IconButton>
            </ListItem>
          ))}
        </List>


        <TextField
          label="Base Price"
          fullWidth
          margin="normal"
          type="number"
          value={price}
          onChange={(e) => setPrice(e.target.value)}
          helperText={`Total Price of ingredients: ${calculateTotalIngredientPrice()}`}
        />

        <TextField
          label="Description"
          fullWidth
          margin="normal"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />

        {/* <TextField
          select
          label="Upselling Addons"
          fullWidth
          margin="normal"
          value=""
          onChange={(e) => {
            const selectedAddon = menuItems.find(
              (menuItem) => menuItem.menuName === e.target.value
            );
            if (selectedAddon && !addons.includes(selectedAddon._id)) {
              setAddons([...addons, selectedAddon._id]);
            }
          }}
        >
          {menuItems
            .filter((menuItem) => menuItem.menuName !== name)
            .map((menuItem) => (
              <MenuItem key={menuItem._id} value={menuItem.menuName}>
                {menuItem.menuName}
              </MenuItem>
            ))}
        </TextField> */}

        <List>
          {addons.map((addonId, index) => {
            const addon = menuItems.find(
              (menuItem) => menuItem._id === addonId
            );
            return (
              <ListItem key={addonId || index}>
                <ListItemText primary={addon?.menuName || "Unknown Addon"} />
                <IconButton
                  edge="end"
                  onClick={() =>
                    setAddons(addons.filter((id) => id !== addonId))
                  }
                  color="error"
                >
                  <DeleteIcon />
                </IconButton>
              </ListItem>
            );
          })}
        </List>

        <TextField
          label="Tags"
          fullWidth
          margin="normal"
          value={tags}
          onChange={(e) => setTags(e.target.value)}
          helperText="Separate tags with commas"
        />
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleSave} color="primary" disabled={!isFormValid}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditMenuItemDialog;
