import React, { useState } from "react";
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import Divider from '@mui/material/Divider';
import TableRestaurantIcon from "@mui/icons-material/TableRestaurant";
import EditIcon from "@mui/icons-material/Edit";
import DiningIcon from "@mui/icons-material/Restaurant";
import TakeoutDiningIcon from "@mui/icons-material/TakeoutDining";
import { useKioskContext } from "../context/KioskContext";

const TableSelect = ({ tableNumber, onTableSelect, orderType, onOrderTypeChange }) => {
  const { translate } = useKioskContext();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [inputValue, setInputValue] = useState(tableNumber || "");
  const [error, setError] = useState("");

  // Predefined table numbers
  const predefinedTables = Array(16).fill().map((_, i) => (i + 1).toString());
  
  // Order type state with default to dine-in
  const [localOrderType, setLocalOrderType] = useState(orderType || "dine-in");

  // Open table selection dialog
  const handleOpenDialog = () => {
    setInputValue(tableNumber || "");
    setLocalOrderType(orderType || "dine-in");
    setError("");
    setDialogOpen(true);
  };
  
  // Handle order type change
  const handleOrderTypeChange = (event, newOrderType) => {
    if (newOrderType !== null) {
      setLocalOrderType(newOrderType);
    }
  };

  // Close table selection dialog
  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  // Validate and set table number
  const handleConfirm = () => {
    if (!inputValue.trim()) {
      setError("Please enter a table number");
      return;
    }
    
    // Use the actual input value for both dine-in and take-out
    onTableSelect(inputValue.trim());
    
    // Update the order type in parent component
    if (onOrderTypeChange) {
      onOrderTypeChange(localOrderType);
    }
    
    setDialogOpen(false);
  };

  // Handle selecting a predefined table
  const handleSelectTable = (table) => {
    setInputValue(table);
    setError("");
  };

  return (
    <Paper elevation={2} sx={{ p: 2, mb: 3 }}>
      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {orderType === "take-out" ? (
            <>
              <TakeoutDiningIcon color="primary" sx={{ mr: 1 }} />
              <Typography variant="h6">
                {translate("Take Out", {
                  en: "Take Out",
                  zh: "外带",
                  th: "สั่งกลับบ้าน",
                  tl: "Take Out"
                })}
              </Typography>
            </>
          ) : (
            <>
              <TableRestaurantIcon color="primary" sx={{ mr: 1 }} />
              <Typography variant="h6">
                {tableNumber ? `${translate("Table", {
                  en: "Table",
                  zh: "桌号",
                  th: "โต๊ะ",
                  tl: "Mesa"
                })} ${tableNumber}` : translate("Select a Table", {
                  en: "Select a Table",
                  zh: "选择桌号",
                  th: "เลือกโต๊ะ",
                  tl: "Pumili ng Mesa"
                })}
              </Typography>
            </>
          )}
        </Box>
        <Button
          variant="outlined"
          startIcon={tableNumber || orderType === "take-out" ? <EditIcon /> : null}
          onClick={handleOpenDialog}
          size="small"
          data-testid="table-select"
        >
          {tableNumber || orderType === "take-out" ? translate("Change", {
            en: "Change",
            zh: "更改",
            th: "เปลี่ยน",
            tl: "Palitan"
          }) : translate("Select", {
            en: "Select",
            zh: "选择",
            th: "เลือก",
            tl: "Piliin"
          })}
        </Button>
      </Box>

      <Dialog open={dialogOpen} onClose={handleCloseDialog} maxWidth="sm" fullWidth>
        <DialogTitle>
          {translate("Order Type & Table Selection", {
            en: "Order Type & Table Selection",
            zh: "订单类型和桌号选择",
            th: "ประเภทคำสั่งซื้อและการเลือกโต๊ะ",
            tl: "Uri ng Order at Pagpili ng Mesa"
          })}
        </DialogTitle>
        <DialogContent>
          {/* Order Type Selection */}
          <Box sx={{ mb: 3, mt: 1 }}>
            <Typography variant="subtitle1" gutterBottom fontWeight="medium">
              {translate("Order Type", {
                en: "Order Type",
                zh: "订单类型",
                th: "ประเภทคำสั่งซื้อ",
                tl: "Uri ng Order"
              })}:
            </Typography>
            
            <ToggleButtonGroup
              value={localOrderType}
              exclusive
              onChange={handleOrderTypeChange}
              fullWidth
              aria-label="order type"
              sx={{ mt: 1 }}
            >
              <ToggleButton value="dine-in" aria-label="dine in">
                <DiningIcon sx={{ mr: 1 }} />
                {translate("Dine In", {
                  en: "Dine In",
                  zh: "堂食",
                  th: "ทานที่ร้าน",
                  tl: "Dine In"
                })}
              </ToggleButton>
              <ToggleButton value="take-out" aria-label="take out">
                <TakeoutDiningIcon sx={{ mr: 1 }} />
                {translate("Take Out", {
                  en: "Take Out",
                  zh: "外带",
                  th: "สั่งกลับบ้าน",
                  tl: "Take Out"
                })}
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
          
          <Divider sx={{ my: 2 }} />
          
          {/* Show table selection for both Dine In and Take Out */}
          <Typography variant="subtitle1" gutterBottom fontWeight="medium">
            {translate("Table Number", {
              en: "Table Number",
              zh: "桌号",
              th: "หมายเลขโต๊ะ",
              tl: "Numero ng Mesa"
            })}:
          </Typography>
          
          <Box sx={{ mb: 2 }}>
            <TextField
              margin="dense"
              label={translate("Enter Table Number", {
                en: "Enter Table Number",
                zh: "输入桌号",
                th: "ใส่หมายเลขโต๊ะ",
                tl: "Ilagay ang Numero ng Mesa"
              })}
              fullWidth
              variant="outlined"
              value={inputValue}
              onChange={(e) => {
                setInputValue(e.target.value);
                setError("");
              }}
              error={!!error}
              helperText={error}
            />
          </Box>
          
          <Typography variant="subtitle2" gutterBottom>
            {translate("Quick Select", {
              en: "Quick Select",
              zh: "快速选择",
              th: "เลือกด่วน",
              tl: "Mabilis na Pagpili"
            })}:
          </Typography>
          
          <Grid container spacing={1}>
            {localOrderType === "dine-in" ? (
              // For dine-in, show regular table numbers
              predefinedTables.map((table) => (
                <Grid item key={table} xs={3}>
                  <Button
                    variant={inputValue === table ? "contained" : "outlined"}
                    fullWidth
                    onClick={() => handleSelectTable(table)}
                    sx={{ minWidth: 0 }}
                  >
                    {table}
                  </Button>
                </Grid>
              ))
            ) : (
              // For take-out, show take-out numbers
              ["T1", "T2", "T3", "T4", "T5", "T6", "T7", "T8"].map((table) => (
                <Grid item key={table} xs={3}>
                  <Button
                    variant={inputValue === table ? "contained" : "outlined"}
                    fullWidth
                    onClick={() => handleSelectTable(table)}
                    sx={{ minWidth: 0 }}
                  >
                    {table}
                  </Button>
                </Grid>
              ))
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button onClick={handleConfirm} variant="contained" color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};

export default TableSelect;
