import React from "react";
import Box from '@mui/material/Box'
import Typography from "@mui/material/Typography";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const BillInvChart = ({ bills }) => {
  const denominations = [
    ...new Set(
      bills.map((bill) =>
        bill.billType === "Coin"
          ? `₱${bill.denomination} (Coin)`
          : `₱${bill.denomination}`
      )
    ),
  ].sort((a, b) => parseInt(b) - parseInt(a));

  const chartData = [
    {
      name: "Cashbox",
      ...Object.fromEntries(denominations.map((denom) => [denom, 0])),
    },
    {
      name: "Changebox",
      ...Object.fromEntries(denominations.map((denom) => [denom, 0])),
    },
  ];

  bills.forEach((bill) => {
    const index = bill.boxType === "Cashbox" ? 0 : 1;
    const denomKey =
      bill.billType === "Coin"
        ? `₱${bill.denomination} (Coin)`
        : `₱${bill.denomination}`;
    chartData[index][denomKey] += bill.totalValue;
  });

  return (
    <Box sx={{ width: "100%", height: 400 }}>
      <Typography variant="h5" sx={{ textAlign: "center", marginBottom: 2 }}>
        Cash Denominations in Cashbox vs. Changebox
      </Typography>

      <ResponsiveContainer width="100%" height="100%">
        <BarChart data={chartData} layout="horizontal">
          <XAxis dataKey="name" type="category" />
          <YAxis type="number" />
          <Tooltip
            content={({ payload, label }) => {
              if (payload && payload.length) {
                const totalValue = payload.reduce(
                  (acc, entry) => acc + entry.value,
                  0
                );

                return (
                  <Box
                    sx={{
                      backgroundColor: "white",
                      padding: "10px",
                      borderRadius: "5px",
                      boxShadow: "0px 0px 5px rgba(0,0,0,0.2)",
                    }}
                  >
                    <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                      {label}
                    </Typography>
                    {payload.map((entry, index) => (
                      <Typography
                        key={index}
                        variant="body2"
                        sx={{ color: entry.color }}
                      >
                        {entry.name}: ₱{entry.value.toLocaleString()}
                      </Typography>
                    ))}
                    <Typography
                      variant="body2"
                      sx={{
                        fontWeight: "bold",
                        marginTop: 1,
                        borderTop: "1px solid #ddd",
                        paddingTop: "5px",
                      }}
                    >
                      Total: ₱{totalValue.toLocaleString()}
                    </Typography>
                  </Box>
                );
              }
              return null;
            }}
          />
          <Legend />
          {denominations.map((denom, index) => (
            <Bar
              key={index}
              dataKey={denom}
              stackId="a"
              fill={getColor(denom)}
            />
          ))}
        </BarChart>
      </ResponsiveContainer>
    </Box>
  );
};

// Assign colors to denominations
const getColor = (denomination) => {
  const colorMap = {
    "₱1000": "#4682B4",
    "₱500": "#008000",
    "₱200": "#FF0000",
    "₱100": "#FF4500",
    "₱50": "#9370DB",
    "₱20": "#DC143C",
    "₱20 (Coin)": "#C71585",
    "₱10 (Coin)": "#808080",
    "₱5 (Coin)": "#A9A9A9",
    "₱1 (Coin)": "#FFD700",
  };
  return colorMap[denomination] || "#000000";
};

export default BillInvChart;
