import React, { useState, useEffect } from "react";
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from '@mui/material/Select'
import MenuItem from "@mui/material/MenuItem";
import axios from "axios";
import Swal from "sweetalert2";
import BillHero from "../BillHero";
import BillInvDetailed from "./BillInvDetailed";
import BillInvChart from "./BillInvChart";
import { setupDefaultBills } from "./BillFunctions";
import { getAuthCredentials } from "../../../../utils/auth";

const BillInventory = () => {
  const [isFetching, setIsFetching] = useState(false);
  const [billType, setBillType] = useState("All");
  const [bills, setBills] = useState([]);
  const [viewMode, setViewMode] = useState("Detailed");
  const { token } = getAuthCredentials();

  useEffect(() => {
    fetchBills();
  }, [billType]);

  const fetchBills = async () => {
    setIsFetching(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/v1/api/bill/fetch-bills`,
        {
          params: { billType: billType !== "All" ? billType : undefined },
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      const sortedBills = response.data.sort(
        (a, b) => b.denomination - a.denomination
      );
      setBills(sortedBills);

      const lowBills = sortedBills.filter(
        (bill) =>
          bill.boxType === "Changebox" &&
          bill.quantity <= 10 &&
          bill.changeLimit > 0
      );

      if (lowBills.length > 0) {
        const lowBillMessages = lowBills
          .map(
            (bill) =>
              `• Changebox - ${bill.denomination} - ${bill.billType} (Qty: ${bill.quantity})`
          )
          .join("\n");

        Swal.fire({
          icon: "warning",
          title: "Replenishing Needed!",
          html: `<div style="text-align: left; white-space: pre-line;">${lowBillMessages}</div>`, // Proper formatting
        });
      }
    } catch (error) {
      console.error("Error fetching bills:", error);
    } finally {
      setIsFetching(false);
    }
  };

  const handleSetupBills = async () => {
    try {
      await setupDefaultBills(fetchBills());
      refreshBills();
    } catch (error) {
      console.error("Setup bills failed", error);
    }
  };

  return (
    <Box sx={{ padding: 3 }}>
      <BillHero activeTab="inventory" />

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: 3,
          marginBottom: 2,
        }}
      >
        {/* Bill Type Filter */}
        <FormControl sx={{ minWidth: 200 }}>
          <InputLabel>Bill Type</InputLabel>
          <Select
            value={billType}
            onChange={(e) => setBillType(e.target.value)}
          >
            <MenuItem value="All">All</MenuItem>
            <MenuItem value="Cash">Cash</MenuItem>
            <MenuItem value="Coin">Coin</MenuItem>
          </Select>
        </FormControl>

        {/* View Mode Dropdown */}
        <FormControl sx={{ minWidth: 200 }}>
          <InputLabel>View Mode</InputLabel>
          <Select
            value={viewMode}
            onChange={(e) => setViewMode(e.target.value)}
          >
            <MenuItem value="Detailed">Detailed View</MenuItem>
            <MenuItem value="Chart">Graph View</MenuItem>
          </Select>
        </FormControl>

        {!isFetching && bills.length === 0 && (
          <Button onClick={handleSetupBills}>Setup Bills</Button>
        )}
      </Box>

      {/* Render View Based on Selection */}
      {viewMode === "Chart" ? (
        <BillInvChart bills={bills} />
      ) : (
        <BillInvDetailed bills={bills} refreshBills={fetchBills} />
      )}
    </Box>
  );
};

export default BillInventory;
