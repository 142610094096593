import React, { useEffect } from "react";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import theme from "./theme/theme";
import AppRoutes from "./routes";
import { openDB } from "./demo/utilities/indexedDB";

const App = () => {
  useEffect(() => {
    openDB()
      .then(() => {
        console.log("IndexedDB initialized");
      })
      .catch((error) => {
        console.error("Failed to initialize IndexedDB", error);
      });
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppRoutes />
      {/* App version badge - fixed to bottom right of the screen */}
      <Box
        sx={{
          position: 'fixed',
          bottom: 8,
          right: 8,
          backgroundColor: 'rgba(0, 0, 0, 0.6)',
          color: 'white',
          padding: '4px 8px',
          borderRadius: 1,
          zIndex: 9999,
          userSelect: 'none',
          fontSize: '0.75rem',
          opacity: 0.8,
          '&:hover': {
            opacity: 1
          }
        }}
      >
        <Typography variant="caption" fontWeight="medium">
          Sparkle POS v 0.1.0
        </Typography>
      </Box>
    </ThemeProvider>
  );
};

export default App;
