import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import CancelIcon from '@mui/icons-material/Cancel';

const PaymentCancel = () => {
  const location = useLocation();
  
  const [countdown, setCountdown] = useState(5);

  useEffect(() => {
    // Parse query parameters
    const params = new URLSearchParams(location.search);
    const sessionId = params.get('session_id');
    const orderId = params.get('order_id');
    const origin = params.get('origin');
    
    // Attempt to communicate with opener window
    if (window.opener) {
      // Send cancel message to parent window
      window.opener.postMessage({
        type: 'payment_cancel',
        sessionId,
        orderId,
      }, '*');
      
      // Set up countdown timer
      const timer = setInterval(() => {
        setCountdown(prev => {
          if (prev <= 1) {
            clearInterval(timer);
            // Close this window after countdown completes
            setTimeout(() => window.close(), 500);
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
      
      return () => clearInterval(timer);
    } else if (origin) {
      // If no opener but we have origin, redirect back after delay
      setTimeout(() => {
        window.location.href = origin;
      }, 5000);
    }
  }, [location]);
  
  return (
    <Box 
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100vh',
        p: 3,
        bgcolor: '#f5f5f5'
      }}
    >
      <Paper 
        elevation={3} 
        sx={{
          p: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          maxWidth: 500,
          width: '100%'
        }}
      >
        <CancelIcon color="error" sx={{ fontSize: 64, mb: 2 }} />
        <Typography variant="h4" gutterBottom align="center">
          Payment Canceled
        </Typography>
        <Typography variant="body1" align="center" paragraph>
          Your payment process was canceled.
        </Typography>
        <Typography variant="body2" align="center" color="textSecondary">
          This window will close automatically in {countdown} seconds...
        </Typography>
        <CircularProgress size={24} sx={{ mt: 3 }} />
        <Button 
          variant="outlined" 
          color="primary" 
          sx={{ mt: 2 }}
          onClick={() => window.close()}
        >
          Close Now
        </Button>
      </Paper>
    </Box>
  );
};

export default PaymentCancel;
