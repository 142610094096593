import React, { useState, useEffect } from "react";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import axios from "axios";
import ReportHero from "./ReportHero";
import { getAuthCredentials } from "../../utils/auth";

const SystemLogs = () => {
  const [logs, setLogs] = useState([]);
  const [searchDate, setSearchDate] = useState("");
  const [loading, setLoading] = useState(false);
  const { token } = getAuthCredentials();

  const fetchLogs = async (date) => {
    if (!date) return;
    try {
      setLoading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/v1/api/reports/view-log-report`,
        { date },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setLogs(response.data.report || []);
    } catch (error) {
      console.error("Error fetching logs:", error);
      setLogs([]);
    } finally {
      setLoading(false);
    }
  };

  const handleDateChange = (e) => {
    const date = e.target.value;
    setSearchDate(date);
    fetchLogs(date);
  };

  useEffect(() => {
    const today = new Date().toISOString().split("T")[0];
    setSearchDate(today);
    fetchLogs(today);
  }, []);

  return (
    <Box sx={{ flexGrow: 1, padding: 3 }}>
      <ReportHero />
      <Typography variant="h5" textAlign="center" gutterBottom>
        System Audit Trail / Activity Log
      </Typography>
      <Box sx={{ marginBottom: 3, display: "flex", justifyContent: "center" }}>
        <TextField
          label="Search by Date"
          type="date"
          value={searchDate}
          onChange={handleDateChange}
          InputLabelProps={{
            shrink: true,
          }}
          fullWidth
          sx={{ maxWidth: 400 }}
        />
      </Box>
      <Paper elevation={3} sx={{ padding: 2 }}>
        {loading ? (
          <Box sx={{ display: "flex", justifyContent: "center", padding: 3 }}>
            <CircularProgress />
          </Box>
        ) : logs.length > 0 ? (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Date/Time</TableCell>
                <TableCell>User Name / ID</TableCell>
                <TableCell>Activity</TableCell>
                <TableCell>Item Name</TableCell>
                <TableCell>Values</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {logs.map((log, index) => (
                <TableRow
                  key={index}
                  sx={{
                    "&:hover": {
                      backgroundColor: "rgba(0, 0, 0, 0.1)",
                    },
                  }}
                >
                  <TableCell>
                    {new Date(log.dateTime).toLocaleString("en-US", {
                      timeZone: "Asia/Manila",
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                      hour: "2-digit",
                      minute: "2-digit",
                      second: "2-digit",
                      hour12: true,
                    })}
                  </TableCell>
                  <TableCell>{log.userNameId}</TableCell>
                  <TableCell>{log.activity}</TableCell>
                  <TableCell>{log.itemName || "N/A"}</TableCell>
                  <TableCell>{log.values}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : (
          <Typography variant="body1" textAlign="center" color="textSecondary">
            No logs found for the selected date.
          </Typography>
        )}
      </Paper>
    </Box>
  );
};

export default SystemLogs;
