import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Box from '@mui/material/Box'
import Typography from "@mui/material/Typography";
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import CircularProgress from "@mui/material/CircularProgress";
import Button from '@mui/material/Button'
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import axios from "axios";
import dayjs from "dayjs";
import { getAuthCredentials } from "../../utils/auth";
import SalesInventoryExport from "./SalesInventoryExport";

const SalesInventory = () => {
  const navigate = useNavigate();
  const [inventoryData, setInventoryData] = useState([]);
  const [totalInventory, setTotalInventory] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [dateRange, setDateRange] = useState([
    dayjs().startOf("day"),
    dayjs().endOf("day"),
  ]);
  const { user, token, posId } = getAuthCredentials(navigate);

  if (!user || !token || !posId) {
    return null;
  }

  const fetchInventoryData = async () => {
    try {
      setLoading(true);
      const { data } = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/v1/api/sales/reports`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            startDate: dateRange[0].format("YYYY-MM-DD"),
            endDate: dateRange[1].format("YYYY-MM-DD"),
            category: "Inventory",
            posId: user.posId,
          },
        }
      );

      const flattenedEntries = data.reduce((acc, sale) => {
        sale.entries.forEach((entry) => {
          if (entry.category === "Inventory") {
            acc.push({
              date: sale.createdAt,
              name: entry.name,
              category: entry.category,
              subcategory: entry.subcategory,
              price:
                entry.subcategory === "Deduction" ? -entry.price : entry.price,
              amount: entry.amount,
              newAmount: entry.newAmount,
            });
          }
        });
        return acc;
      }, []);

      flattenedEntries.sort((a, b) => new Date(b.date) - new Date(a.date));

      const totalInventoryValue = flattenedEntries.reduce(
        (total, item) => total + item.price,
        0
      );

      setInventoryData(flattenedEntries);
      setTotalInventory(totalInventoryValue);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching inventory data:", error);
      setError("Failed to load inventory data.");
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchInventoryData();
  }, [dateRange]);

  const handleDateRangeChange = (newRange, index) => {
    const updatedRange = [...dateRange];
    updatedRange[index] = newRange;
    setDateRange(updatedRange);
  };

  if (loading) {
    return (
      <Box sx={{ padding: 3, textAlign: "center" }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ padding: 3, textAlign: "center" }}>
        <Typography variant="h6" color="error">
          {error}
        </Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ padding: 3 }}>
      <Typography variant="h5" align="center" gutterBottom>
        Inventory Reports
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: 2,
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="Start Date"
            value={dateRange[0]}
            onChange={(newValue) => handleDateRangeChange(newValue, 0)}
            maxDate={dayjs()}
            sx={{ marginRight: 2 }}
          />
          <DatePicker
            label="End Date"
            value={dateRange[1]}
            onChange={(newValue) => handleDateRangeChange(newValue, 1)}
            maxDate={dayjs()}
          />
        </LocalizationProvider>
        <Button
          variant="contained"
          color="primary"
          onClick={fetchInventoryData}
          sx={{ marginLeft: 2 }}
        >
          Submit
        </Button>
      </Box>
      <SalesInventoryExport
        inventoryData={inventoryData}
        user={user}
        dateRange={dateRange}
      />
      ;
      <TableContainer component={Paper} sx={{ marginTop: 2 }}>
        <Table aria-label="inventory table">
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell>Item</TableCell>
              <TableCell>Category</TableCell>
              <TableCell>Quantity | New Quantity</TableCell>
              <TableCell>Price</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {inventoryData && inventoryData.length > 0 ? (
              inventoryData.map((item, index) => (
                <TableRow
                  key={index}
                  sx={{
                    "&:hover": {
                      backgroundColor: "rgba(0, 0, 0, 0.1)",
                    },
                  }}
                >
                  <TableCell>{dayjs(item.date).format("YYYY-MM-DD")}</TableCell>
                  <TableCell>{item.name}</TableCell>
                  <TableCell>{item.category}</TableCell>
                  <TableCell>
                    {item.amount} {item.newAmount ? `[${item.newAmount}]` : ""}
                  </TableCell>
                  <TableCell
                    sx={{
                      color:
                        item.subcategory === "Deduction" ? "red" : "inherit",
                    }}
                  >
                    {item.price.toFixed(2)}
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={5} align="center">
                  No inventory data available.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={{ marginTop: 4, textAlign: "center" }}>
        <Typography variant="h6">
          Total Inventory Value: {totalInventory.toFixed(2)}
        </Typography>
      </Box>
    </Box>
  );
};

export default SalesInventory;
