import React from "react";
import jsPDF from "jspdf";
import QRCode from "qrcode";

// A small helper to fetch store config by posId
async function fetchReceiptConfig(posId) {
  try {
    // Adjust your backend URL/path as needed
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/v1/api/receipt/config/public?posId=${posId}`
    );
    if (!response.ok) {
      throw new Error("Failed to fetch store config.");
    }
    const data = await response.json();
    return data.receiptConfig;
  } catch (error) {
    console.error("Error fetching store config:", error);
    return null;
  }
}

/**
 * Centers the given text (possibly split across multiple lines) at the given y position.
 * Each line is printed in the given fontSize, and we move down for each line.
 *
 * @param {Object} doc - jsPDF instance
 * @param {string} text - The text to print (can be long)
 * @param {number} y - The y position to start
 * @param {number} fontSize - Font size to use
 * @param {number} lineSpacing - Extra spacing between lines
 * @returns {number} The new y position after printing
 */
function centerMultilineText(doc, text, y, fontSize = 12, lineSpacing = 2) {
  doc.setFontSize(fontSize);

  const pageWidth = doc.internal.pageSize.getWidth();
  const maxLineWidth = pageWidth - 20;
  const lines = doc.splitTextToSize(text, maxLineWidth);

  lines.forEach((line) => {
    const textWidth = doc.getTextWidth(line);
    const x = (pageWidth - textWidth) / 2;
    doc.text(line, x, y);
    y += fontSize + lineSpacing;
  });
  return y;
}

export const generateQrPdf = async (
  receiptId,
  cartItems,
  grandTotal,
  posId,
  orderNumber,
  receiptNumber
) => {
  const receiptConfig = await fetchReceiptConfig(posId);

  function buildQrData(receiptNumber, cartItems, total) {
    // Build a timestamp in yyyyMMddTHHmmss format
    const now = new Date();
    const pad = (num) => String(num).padStart(2, "0");
    const year = now.getFullYear();
    const month = pad(now.getMonth() + 1);
    const day = pad(now.getDate());
    const hour = pad(now.getHours());
    const min = pad(now.getMinutes());
    const sec = pad(now.getSeconds());
    const dateStamp = `${year}${month}${day}T${hour}${min}${sec}`;

    // Start with receiptId and table "5"
    let qrString = `${receiptNumber}^5^`;

    // Append each item: name_noSpaces, quantity, unitPrice
    if (cartItems && cartItems.length > 0) {
      cartItems.forEach((item) => {
        const name = item.menuName
          ? item.menuName.replace(/\s/g, "")
          : "UnknownProduct";
        const quantity = item.quantity;
        const unitPrice = (item.totalPrice / quantity).toFixed(2);
        qrString += `${name}_${quantity}_${unitPrice}^`;
      });
    } else {
      qrString += "NoItems_0_0^";
    }

    // Finally, append the total and the timestamp
    qrString += `${total}^${dateStamp}`;

    return qrString;
  }
  const qrData = buildQrData(receiptNumber, cartItems, grandTotal);
  const qrCodeDataUrl = await QRCode.toDataURL(qrData, {
    errorCorrectionLevel: "H",
  });

  const doc = new jsPDF({ unit: "pt", format: [240, 400] });
  let y = 50;

  const { storeName, address, TIN, SN, MIN } = receiptConfig || {};

  doc.setFont("helvetica", "bold");
  y = centerMultilineText(doc, storeName || "Store Name", y, 14);

  doc.setFont("helvetica", "normal");
  y = centerMultilineText(doc, address || "Store Address", y, 10);
  if (TIN) y = centerMultilineText(doc, `VAT Registered TIN: ${TIN}`, y, 10);
  if (SN && MIN) y = centerMultilineText(doc, `SN: ${SN} MIN: ${MIN}`, y, 10);
  y = centerMultilineText(doc, "================================", y, 10);

  doc.setFont("helvetica", "bold");
  y = centerMultilineText(doc, `Order No.: ${orderNumber || receiptId}`, y, 14);

  doc.setFont("helvetica", "normal");
  const now = new Date();
  const dateStr =
    now.toLocaleDateString("en-US") +
    " " +
    now.toLocaleTimeString("en-US", { hour12: true });

  y = centerMultilineText(doc, dateStr, y, 10);

  y = centerMultilineText(doc, "================================", y, 10);

  y = centerMultilineText(
    doc,
    `Total Amount: PHP${parseFloat(grandTotal).toFixed(2)}`,
    y,
    10
  );

  const qrSize = 160;
  const pageWidth = doc.internal.pageSize.getWidth();
  const qrX = (pageWidth - qrSize) / 2;

  doc.addImage(qrCodeDataUrl, "PNG", qrX, y, qrSize, qrSize);
  y = centerMultilineText(doc, "[QR Code]", y, 10, 0);
  y += qrSize + 10;

  // Footer instructions
  y = centerMultilineText(doc, "Scan QR at cash terminal", y, 10);
  y = centerMultilineText(doc, "Insert coins/bills after scanning", y, 10);

  // Save the PDF
  doc.save(`receipt_${receiptId}.pdf`);
};

const KioskBuildQR = () => null;
export default KioskBuildQR;
