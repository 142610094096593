import React, { useState } from "react";

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Input from '@mui/material/Input';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';

import Swal from "sweetalert2";
import { getAuthCredentials } from "../../../utils/auth";

const ImportConfigDialog = ({ open, onClose, fetchInventory }) => {
  const [file, setFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const { token } = getAuthCredentials();

  const handleDownload = () => {
    window.location.href = `${process.env.REACT_APP_BACKEND_URL}/v1/api/inventory/download-template`;
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (!selectedFile) {
      setFile(null);
      return;
    }
    const fileExtension = selectedFile.name.split(".").pop().toLowerCase();
    if (["xlsx", "xls", "csv"].includes(fileExtension)) {
      setFile(selectedFile);
    } else {
      alert("Please select a valid Excel or CSV file.");
      setFile(null);
    }
  };

  const handleUpload = () => {
    if (!file) {
      console.log("No file selected");
      return;
    }
    setIsUploading(true);
    const formData = new FormData();
    formData.append("file", file);

    fetch(`${process.env.REACT_APP_BACKEND_URL}/v1/api/inventory/bulk-create`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Upload success:", data);
        setIsUploading(false);
        onClose();
        fetchInventory();
        Swal.fire({
          icon: "success",
          title: "Upload Successful",
          text: `Created ${data.createdCount} inventories.${
            data.duplicateCount > 0
              ? " " +
                data.duplicateCount +
                " duplicates skipped: " +
                data.duplicates.join(", ")
              : ""
          }`,
        });
      })
      .catch((error) => {
        console.error("Upload error:", error);
      });
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Import Inventory</DialogTitle>
      <DialogContent>
        <Box display="flex" flexDirection="column" gap={2} mt={2}>
          <Typography variant="body2" color="textSecondary">
            Step 1: Download Template and fill out your inventory details
            according to the format.
          </Typography>
          <Button onClick={handleDownload} variant="contained" color="primary">
            Download Template
          </Button>
          <Typography variant="body2" color="textSecondary">
            Step 2: Select your filled file and click "Upload Document" once
            done.
          </Typography>
          <Box display="flex" alignItems="center" gap={2}>
            <Input
              type="file"
              onChange={handleFileChange}
              inputProps={{ accept: ".xlsx,.xls,.csv" }}
            />
            <Button onClick={handleUpload} variant="outlined" color="primary">
              {isUploading ? <CircularProgress size={24} /> : "Upload Document"}
            </Button>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ImportConfigDialog;
