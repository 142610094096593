import axios from 'axios'

const getOrderStatus = async (orderId = '123') => {
    try{
        const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/v1/api/order/${orderId}/status`,
      );

      return response.data
    }catch(err){
        return {
            status: "none"
        }
    }
    
}

export default  {
    getOrderStatus
}