import React, { useState, useEffect } from "react";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import RestaurantIcon from "@mui/icons-material/Restaurant";
import FastfoodIcon from "@mui/icons-material/Fastfood";

const OptionsModal = ({
  open,
  menuItem,
  selectedOptions,
  onClose,
  onConfirm,
}) => {
  const [localOptions, setLocalOptions] = useState({});
  const [selectionStep, setSelectionStep] = useState(true); 
  const [mealType, setMealType] = useState(null); 

  useEffect(() => {
    if (menuItem) {
      const defaultOptions = {};
      menuItem.options.forEach((option) => {
        defaultOptions[option._id] = option.optionType === "single" ? null : [];
      });
      setLocalOptions(defaultOptions);
      setSelectionStep(true); 
      setMealType(null); 
    }
  }, [menuItem]);

  const handleOptionChange = (optionId, choice, type) => {
    setLocalOptions((prev) => {
      const updatedOptions = { ...prev };

      if (type === "single") {
        updatedOptions[optionId] = choice;
      } else if (type === "checkbox") {
        const selectedChoices = new Set(updatedOptions[optionId] || []);
        if (selectedChoices.has(choice)) {
          selectedChoices.delete(choice);
        } else {
          selectedChoices.add(choice);
        }
        updatedOptions[optionId] = Array.from(selectedChoices);
      }

      return updatedOptions;
    });
  };

  const handleMealSelection = (type) => {
    setMealType(type);

    if (type === "Ala-Carte") {
      onConfirm({ mealType: "Ala-Carte", options: {} });
      onClose();
    } else {
      setSelectionStep(false);
    }
  };

  const handleSubmit = () => {
    onConfirm({ mealType, options: localOptions });
    onClose();
  };

  if (!menuItem) return null;

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>
        {selectionStep
          ? `Choose Meal Type for ${menuItem.menuName}`
          : `Choose Options for ${menuItem.menuName}`}
      </DialogTitle>

      <DialogContent>
        {selectionStep ? (
          // Step 1: Choose between "Menu" or "Ala-Carte"
          <Box sx={{ display: "flex", justifyContent: "center", gap: 2 }}>
            <Card sx={{ width: "45%", textAlign: "center" }}>
              <CardActionArea onClick={() => handleMealSelection("Menu")}>
                <CardContent>
                  <RestaurantIcon
                    sx={{ fontSize: 50, color: "primary.main" }}
                  />
                  <Typography variant="h6">Meal</Typography>
                  <Typography variant="body2" color="textSecondary">
                    Includes a full meal
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>

            <Card sx={{ width: "45%", textAlign: "center" }}>
              <CardActionArea onClick={() => handleMealSelection("Ala-Carte")}>
                <CardContent>
                  <FastfoodIcon
                    sx={{ fontSize: 50, color: "secondary.main" }}
                  />
                  <Typography variant="h6">Ala-Carte</Typography>
                  <Typography variant="body2" color="textSecondary">
                    PHP {menuItem.basePrice.toFixed(2)}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Box>
        ) : (
          // Step 2: Choose options
          menuItem.options.map((option) => (
            <FormControl key={option._id} sx={{ marginBottom: 2 }}>
              <Typography variant="subtitle1">{option.optionName}</Typography>
              {option.optionType === "single" ? (
                <RadioGroup
                  value={localOptions[option._id] || ""}
                  onChange={(e) =>
                    handleOptionChange(option._id, e.target.value, "single")
                  }
                >
                  {option.choices.map((choice) => (
                    <FormControlLabel
                      key={choice._id}
                      value={choice.name}
                      control={<Radio />}
                      label={`${choice.name} (+ PHP ${choice.price || 0})`}
                    />
                  ))}
                </RadioGroup>
              ) : (
                option.choices.map((choice) => (
                  <FormControlLabel
                    key={choice._id}
                    control={
                      <Checkbox
                        checked={localOptions[option._id]?.includes(
                          choice.name
                        )}
                        onChange={() =>
                          handleOptionChange(
                            option._id,
                            choice.name,
                            "checkbox"
                          )
                        }
                      />
                    }
                    label={`${choice.name} (+ PHP ${choice.price || 0})`}
                  />
                ))
              )}
            </FormControl>
          ))
        )}
      </DialogContent>

      <DialogActions>
        {!selectionStep && (
          <>
            <Button onClick={() => setSelectionStep(true)} color="secondary">
              Back
            </Button>
            <Button onClick={handleSubmit} color="primary">
              Confirm Selection
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default OptionsModal;
