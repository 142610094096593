import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import axios from "axios";
import Swal from "sweetalert2";
import { getAuthCredentials } from "../../../utils/auth";

const CustomerInfoDialog = ({
  open,
  onClose,
  resetDiscount,
  chosenDiscountType,
}) => {

  // alert("ireached here boy");
  const navigate = useNavigate();
  // const { token } = getAuthCredentials(navigate);

  // // If no token, close dialog and reset discount
  // useEffect(() => {
  //     onClose();
  //     resetDiscount();
  // }, [ onClose, resetDiscount]);
  const [formData, setFormData] = useState({
    name: "",
    discountType: chosenDiscountType !== "None" ? chosenDiscountType : "",
    discountId: "",
    city: "",
    tin: "",
    age: "",
    dateIssued: "",
    birthday: "",
  });

  const [discountIDImg, setDiscountIDImg] = useState(null);
  const [discountSelfieImg, setDiscountSelfieImg] = useState(null);
  const [loading, setLoading] = useState(false);
  const videoRef = useRef(null);
  const canvasRef = useRef(null);

  const discountTypes = [
    { value: "Senior", label: "Senior Citizen" },
    { value: "PWD", label: "Person with Disability" },
    { value: "Athlete", label: "Athlete" },
    { value: "SoloParent", label: "Solo Parent" },
    { value: "Diplomat", label: "Diplomat" },
  ];

  useEffect(() => {
    setFormData((prev) => ({
      ...prev,
      discountType: chosenDiscountType !== "None" ? chosenDiscountType : "",
    }));
  }, [chosenDiscountType]);

  useEffect(() => {
    if (!open) {
      setFormData({
        name: "",
        discountType: chosenDiscountType !== "None" ? chosenDiscountType : "",
        discountId: "",
        city: "",
        age: "",
        dateIssued: "",
        birthday: "",
      });
      setDiscountIDImg(null);
      setDiscountSelfieImg(null);
    }
  }, [open, chosenDiscountType]);

  useEffect(() => {
    let mounted = true

    if(mounted && open){
      startCamera() 
    }

    return () => {
      mounted = false
    }
  }, [open])

  const handleChange = (e) => {
    const { name, value } = e.target;
    let updatedFormData = { ...formData, [name]: value };

    if (name === "birthday") {
      const birthDate = new Date(value);
      const today = new Date();
      let age = today.getFullYear() - birthDate.getFullYear();
      const monthDiff = today.getMonth() - birthDate.getMonth();
      if (
        monthDiff < 0 ||
        (monthDiff === 0 && today.getDate() < birthDate.getDate())
      ) {
        age -= 1;
      }
      updatedFormData.age = age;
    }

    setFormData(updatedFormData);
  };

  const startCamera = async () => {
    console.log('startingCamera')
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
      }
    } catch (error) {
      console.error("Error accessing camera:", error);
    }
  };

  const capturePhoto = (setImage) => {
    if (videoRef.current && canvasRef.current) {
      const context = canvasRef.current.getContext("2d");
      canvasRef.current.width = videoRef.current.videoWidth;
      canvasRef.current.height = videoRef.current.videoHeight;
      context.drawImage(
        videoRef.current,
        0,
        0,
        canvasRef.current.width,
        canvasRef.current.height
      );
      setImage(canvasRef.current.toDataURL("image/png"));
    }
  };

  const dataURLToBlob = (dataURL) => {
    const parts = dataURL.split(",");
    const mime = parts[0].match(/:(.*?);/)[1];
    const binary = atob(parts[1]);
    const array = [];
    for (let i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], { type: mime });
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const customerResponse = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/v1/api/customer/new-customer`,
        formData,
        // {
        //   headers: {
        //     Authorization: `Bearer ${token}`,
        //   },
        // }
      );

      const customer = customerResponse.data.customer;
      const formDataOrder = new FormData();
      formDataOrder.append("customerId", customer._id);
      formDataOrder.append(
        "orderId",
        `ORD-${Math.floor(100000 + Math.random() * 900000)}`
      );
      formDataOrder.append("orderDate", new Date());
      formDataOrder.append("discountId", formData.discountId);

      if (discountIDImg) {
        const discountIDBlob = dataURLToBlob(discountIDImg);
        formDataOrder.append("discountIDImg", discountIDBlob, "discountID.png");
      }

      if (discountSelfieImg) {
        const discountSelfieBlob = dataURLToBlob(discountSelfieImg);
        formDataOrder.append(
          "discountSelfieImg",
          discountSelfieBlob,
          "selfie.png"
        );
      }

      const orderResponse = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/v1/api/customer/new-customer-order`,
        formDataOrder
      );
  
      const order = orderResponse.data.order;
  
      if (typeof window.onCustomerCreated === 'function') {
        window.onCustomerCreated(customer._id, order._id);
      }

      onClose();
      Swal.fire({
        icon: "success",
        title: "Success!",
        text: "Customer and Order Created Successfully!",
      });
    } catch (error) {
      console.error("Error:", error);
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "An error occurred while processing the request.",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} fullWidth disableBackdropClick>
      <DialogTitle>Create Customer and Order</DialogTitle>
      <DialogContent>
        <TextField
          label="Customer Name"
          name="name"
          value={formData.name}
          onChange={handleChange}
          fullWidth
          margin="dense"
        />
        <TextField
          select
          label="Discount Type"
          name="discountType"
          value={formData.discountType}
          onChange={handleChange}
          fullWidth
          margin="dense"
          disabled
        >
          {discountTypes.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          label="Discount ID"
          name="discountId"
          value={formData.discountId}
          onChange={handleChange}
          fullWidth
          margin="dense"
        />
        <TextField
          label="City"
          name="city"
          value={formData.city}
          onChange={handleChange}
          fullWidth
          margin="dense"
        />
        <TextField
          label="TIN"
          name="tin"
          value={formData.tin}
          onChange={handleChange}
          fullWidth
          margin="dense"
        />
        <TextField
          label="Birthday"
          name="birthday"
          type="date"
          value={formData.birthday}
          onChange={handleChange}
          fullWidth
          margin="dense"
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          label="Age"
          name="age"
          type="number"
          value={formData.age}
          fullWidth
          margin="dense"
          disabled
        />
        <TextField
          label="Date Issued"
          name="dateIssued"
          type="date"
          value={formData.dateIssued}
          onChange={handleChange}
          fullWidth
          margin="dense"
          InputLabelProps={{ shrink: true }}
        />

        {/* Camera Preview */}
        <Typography variant="subtitle1">Capture Discount ID</Typography>
        <video ref={videoRef} autoPlay playsInline width="100%" />
        <canvas ref={canvasRef} style={{ display: "none" }} />
        <Button
          variant="contained"
          onClick={() => capturePhoto(setDiscountIDImg)}
          fullWidth
        >
          Capture Discount ID
        </Button>
        {discountIDImg && (
          <img
            src={discountIDImg}
            alt="Discount ID"
            style={{ width: "100%", marginTop: "10px" }}
          />
        )}

      </DialogContent>

      <DialogActions>
        <Button
          onClick={() => {
            onClose();
            resetDiscount();
          }}
          disabled={loading}
        >
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          color="primary"
          disabled={loading}
        >
          {loading ? "Processing..." : "Submit"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CustomerInfoDialog;
