import React, { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import TextField from "@mui/material/TextField";
import { Description, BarChart, ShoppingCart } from "@mui/icons-material";
import ReportHero from "./ReportHero";
import axios from "axios";
import OrderModal from "./Modal/OrderModal";
import SystemTrailModal from "./Modal/SystemTrailModal";
import BIRSalesModal from "./Modal/BIRSalesModal";
import DiscountModal from "./Modal/DiscountModal";
import AdjustmentModal from "./Modal/AdjustmentModal";
import EJournalModal from "./Modal/EJournalModal";
import CustomerTest from "./Customer/CustomerTest";
import Swal from "sweetalert2";
import { getAuthCredentials } from "../../utils/auth";

const Reports = () => {
  const [xReadingDialogOpen, setXReadingDialogOpen] = useState(false);
  const [zReadingDialogOpen, setZReadingDialogOpen] = useState(false);
  const [confirmZReadingToday, setConfirmZReadingToday] = useState(false);
  const [adjustmentDialogOpen, setAdjustmentDialogOpen] = useState(false);
  const [eJournalDialogOpen, setEJournalDialogOpen] = useState(false);
  const [systemTrailDialogOpen, setSystemTrailDialogOpen] = useState(false);
  const [birSalesDialogOpen, setBIRSalesDialogOpen] = useState(false);
  const [discountSummaryDialogOpen, setDiscountSummaryDialogOpen] =
    useState(false);
  const [orderModalOpen, setOrderModalOpen] = useState(false);
  const [customerTestModalOpen, setCustomerTestModalOpen] = useState(false);
  const [xReadingDateFrom, setXReadingDateFrom] = useState("");
  const [xReadingDateTo, setXReadingDateTo] = useState("");
  const [zReadingDate, setZReadingDate] = useState("");
  const { token } = getAuthCredentials();

  const handleOrderGenerated = (orderData) => {
    console.log("Order generated:", orderData);
  };

  const shifts = [
    { value: "Morning", label: "Morning Shift" },
    { value: "Afternoon", label: "Afternoon Shift" },
    { value: "Evening", label: "Evening Shift" },
  ];

  const handleZReadingSubmit = async () => {
    const selectedDate = new Date(zReadingDate);
    const today = new Date();
    const isToday =
      selectedDate.getFullYear() === today.getFullYear() &&
      selectedDate.getMonth() === today.getMonth() &&
      selectedDate.getDate() === today.getDate();

    const submitZReading = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/v1/api/receipt/generate-zreading`,
          {
            date: zReadingDate,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.status === 200) {
          Swal.fire("Success", "Z-Reading generated successfully", "success");
        }
      } catch (error) {
        console.error("Error generating Z-Reading:", error);
        Swal.fire(
          "Error",
          error.response?.data?.message || "Error generating Z-Reading",
          "error"
        );
      }

      setConfirmZReadingToday(false);
      setZReadingDialogOpen(false);
    };

    if (isToday && !confirmZReadingToday) {
      setZReadingDialogOpen(false);

      setTimeout(() => {
        Swal.fire({
          title: "The Z-Reading Date Chosen is the Current Day",
          text: "Are you sure you want to generate this Z-Reading? Doing so will stop future transactions until the next day.",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, generate it",
          cancelButtonText: "Cancel",
        }).then((result) => {
          if (result.isConfirmed) {
            setConfirmZReadingToday(true);
            submitZReading(); 
          }
        });
      }, 300);

      return;
    }

    await submitZReading();
  };

  const handleXReadingSubmit = async () => {
    try {
      const toUTCISOString = (localDateTimeStr) => {
        return new Date(localDateTimeStr).toISOString();
      };

      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/v1/api/receipt/generate-xreading`,
        {
          startDate: toUTCISOString(xReadingDateFrom),
          endDate: toUTCISOString(xReadingDateTo),
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 200) {
        Swal.fire("Success", "X-Reading generated successfully", "success");
      }
    } catch (error) {
      console.error("Error generating X-Reading:", error);
      Swal.fire(
        "Error",
        error.response?.data?.message || "Error generating X-Reading",
        "error"
      );
    }

    setXReadingDialogOpen(false);
  };

  return (
    <Box sx={{ flexGrow: 1, padding: 3 }}>
      <ReportHero />
      <Grid container spacing={3} justifyContent="center" alignItems="stretch">
        {/* X-Reading */}
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          sx={{ display: "flex", marginBottom: 3 }}
        >
          <Paper
            elevation={3}
            sx={{
              width: "100%",
              padding: 3,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <Description sx={{ fontSize: 60, marginBottom: 2 }} />
            <Typography variant="h6" gutterBottom>
              Generate X-Reading
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setXReadingDialogOpen(true)}
            >
              Generate
            </Button>
          </Paper>
        </Grid>

        {/* Z-Reading */}
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          sx={{ display: "flex", marginBottom: 3 }}
        >
          <Paper
            elevation={3}
            sx={{
              width: "100%",
              padding: 3,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <BarChart sx={{ fontSize: 60, marginBottom: 2 }} />
            <Typography variant="h6" gutterBottom>
              Generate Z-Reading
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setZReadingDialogOpen(true)}
            >
              Generate
            </Button>
          </Paper>
        </Grid>
      </Grid>

      {/* Generate Sample Order */}
      <Grid
        item
        xs={12}
        sm={6}
        md={4}
        sx={{ display: "flex", marginBottom: 3 }}
      >
        <Paper
          elevation={3}
          sx={{
            width: "100%",
            padding: 3,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          <ShoppingCart sx={{ fontSize: 60, marginBottom: 2 }} />
          <Typography variant="h6" gutterBottom>
            Generate Sample Order
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setOrderModalOpen(true)}
          >
            Generate
          </Button>
        </Paper>
      </Grid>

      {/* Generate Audit Trail Log Report */}
      <Grid
        item
        xs={12}
        sm={6}
        md={4}
        sx={{ display: "flex", marginBottom: 3 }}
      >
        <Paper
          elevation={3}
          sx={{
            width: "100%",
            padding: 3,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          <BarChart sx={{ fontSize: 60, marginBottom: 2 }} />
          <Typography variant="h6" gutterBottom>
            Generate Audit Trail Report
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setSystemTrailDialogOpen(true)}
          >
            Generate
          </Button>
        </Paper>
      </Grid>

      {/* Generate BIR Sales Report */}
      <Grid
        item
        xs={12}
        sm={6}
        md={4}
        sx={{ display: "flex", marginBottom: 3 }}
      >
        <Paper
          elevation={3}
          sx={{
            width: "100%",
            padding: 3,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          <BarChart sx={{ fontSize: 60, marginBottom: 2 }} />
          <Typography variant="h6" gutterBottom>
            Generate BIR Sales Report
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setBIRSalesDialogOpen(true)}
          >
            Generate
          </Button>
        </Paper>
      </Grid>

      {/* Generate Discount Report */}
      <Grid
        item
        xs={12}
        sm={6}
        md={4}
        sx={{ display: "flex", marginBottom: 3 }}
      >
        <Paper
          elevation={3}
          sx={{
            width: "100%",
            padding: 3,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          <BarChart sx={{ fontSize: 60, marginBottom: 2 }} />
          <Typography variant="h6" gutterBottom>
            Generate Discount Summary Report
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setDiscountSummaryDialogOpen(true)}
          >
            Generate
          </Button>
        </Paper>
      </Grid>

      {/* Generate Adjustment Records */}
      <Grid
        item
        xs={12}
        sm={6}
        md={4}
        sx={{ display: "flex", marginBottom: 3 }}
      >
        <Paper
          elevation={3}
          sx={{
            width: "100%",
            padding: 3,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          <BarChart sx={{ fontSize: 60, marginBottom: 2 }} />
          <Typography variant="h6" gutterBottom>
            Generate Adjustment Records
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setAdjustmentDialogOpen(true)}
          >
            Generate
          </Button>
        </Paper>
      </Grid>

      {/* Generate E-Journal */}
      <Grid
        item
        xs={12}
        sm={6}
        md={4}
        sx={{ display: "flex", marginBottom: 3 }}
      >
        <Paper
          elevation={3}
          sx={{
            width: "100%",
            padding: 3,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          <BarChart sx={{ fontSize: 60, marginBottom: 2 }} />
          <Typography variant="h6" gutterBottom>
            Generate E-Journal
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setEJournalDialogOpen(true)}
          >
            Generate
          </Button>
        </Paper>
      </Grid>

      {/* Generate Sample Customer & Order */}
      <Grid container spacing={3} justifyContent="center" alignItems="stretch">
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          sx={{ display: "flex", marginBottom: 3 }}
        >
          <Paper
            elevation={3}
            sx={{
              width: "100%",
              padding: 3,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <ShoppingCart sx={{ fontSize: 60, marginBottom: 2 }} />
            <Typography variant="h6" gutterBottom>
              Generate Sample Customer & Order
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setCustomerTestModalOpen(true)}
            >
              Generate
            </Button>
          </Paper>
        </Grid>
      </Grid>

      <CustomerTest
        open={customerTestModalOpen}
        onClose={() => setCustomerTestModalOpen(false)}
      />

      {/* X-Reading Dialog */}
      <Dialog
        open={xReadingDialogOpen}
        onClose={() => setXReadingDialogOpen(false)}
      >
        <DialogTitle>Generate X-Reading</DialogTitle>
        <DialogContent>
          <TextField
            label="Select Start Date and Time"
            type="datetime-local"
            value={xReadingDateFrom}
            onChange={(e) => setXReadingDateFrom(e.target.value)}
            fullWidth
            sx={{ marginBottom: 2, marginTop: 1 }}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            label="Select End Date and Time"
            type="datetime-local"
            value={xReadingDateTo}
            onChange={(e) => setXReadingDateTo(e.target.value)}
            fullWidth
            sx={{ marginBottom: 2 }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setXReadingDialogOpen(false)}>Cancel</Button>
          <Button
            onClick={handleXReadingSubmit}
            variant="contained"
            color="primary"
          >
            Generate
          </Button>
        </DialogActions>
      </Dialog>

      {/* Z-Reading Dialog */}
      <Dialog
        open={zReadingDialogOpen}
        onClose={() => setZReadingDialogOpen(false)}
      >
        <DialogTitle>Generate Z-Reading</DialogTitle>
        <DialogContent>
          <TextField
            label="Select Date"
            type="date"
            value={zReadingDate}
            sx={{ marginTop: 1 }}
            onChange={(e) => setZReadingDate(e.target.value)}
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setZReadingDialogOpen(false)}>Cancel</Button>
          <Button
            onClick={handleZReadingSubmit}
            variant="contained"
            color="primary"
          >
            Generate
          </Button>
        </DialogActions>
      </Dialog>

      <OrderModal
        open={orderModalOpen}
        onClose={() => setOrderModalOpen(false)}
        onOrderGenerated={handleOrderGenerated}
      />

      <SystemTrailModal
        open={systemTrailDialogOpen}
        onClose={() => setSystemTrailDialogOpen(false)}
      />

      <BIRSalesModal
        open={birSalesDialogOpen}
        onClose={() => setBIRSalesDialogOpen(false)}
      />

      <DiscountModal
        open={discountSummaryDialogOpen}
        onClose={() => setDiscountSummaryDialogOpen(false)}
      />

      <AdjustmentModal
        open={adjustmentDialogOpen}
        onClose={() => setAdjustmentDialogOpen(false)}
      />

      <EJournalModal
        open={eJournalDialogOpen}
        onClose={() => setEJournalDialogOpen(false)}
      />
    </Box>
  );
};

export default Reports;
