import React, { useState, useEffect } from "react";
import io from "socket.io-client";
import SecureLS from "secure-ls";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import { Fullscreen } from "@mui/icons-material";
import GenericViewHero from "./GenericViewHero";
import { getAuthCredentials } from "../../utils/auth";

const socket = io(process.env.REACT_APP_BACKEND_URL, {
  transports: ["websocket", "polling"],
});

const ls = new SecureLS();

const ClaimingView = () => {
  const [orders, setOrders] = useState([]);
  const [fullScreen, setFullScreen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [speechQueue, setSpeechQueue] = useState([]);
  const [isSpeaking, setIsSpeaking] = useState(false);

  useEffect(() => {
    fetchOrders();

    const unlockSpeech = new SpeechSynthesisUtterance(" ");
    speechSynthesis.speak(unlockSpeech);

    const intervalId = setInterval(() => {
      fetchOrders();
    }, 20 * 1000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    const storedFullScreen = localStorage.getItem("fullScreen");
    if (storedFullScreen === "true") {
      setFullScreen(true);
    }

    socket.on("connect", () => {
      console.log("Connected to Socket.IO server:", socket.id);
    });

    socket.on("updateOrderStatus", (updatedOrder) => {
      console.log("Received updateOrderStatus:", updatedOrder);

      setOrders((prevOrders) => {
        const orderExists = prevOrders.some(
          (order) => order._id === updatedOrder.orderId
        );

        let updatedList;
        const orderName =
          updatedOrder.order?.orderName ||
          updatedOrder.orderName ||
          updatedOrder.order?.orderNumber ||
          "Unknown";

        if (orderExists) {
          updatedList = prevOrders.map((order) =>
            order._id === updatedOrder.orderId
              ? { ...order, status: updatedOrder.status }
              : order
          );
        } else {
          updatedList = [
            ...prevOrders,
            {
              ...updatedOrder,
              _id: updatedOrder.orderId,
              orderName: orderName,
            },
          ];
        }

        if (updatedOrder.orderName === "Unknown") {
          console.log("Unknown order detected, reloading page...");
          localStorage.setItem("fullScreen", JSON.stringify(fullScreen));
          setTimeout(() => {
            window.location.reload();
          }, 500);
        }

        const completedOrder = updatedList.find(
          (order) =>
            order._id === updatedOrder.orderId && order.status === "Completed"
        );

        if (completedOrder && completedOrder.orderName) {
          console.log(`Queuing speech for order: ${completedOrder.orderName}`);

          setSpeechQueue((prevQueue) => {
            if (
              updatedOrder.status === "Completed" &&
              updatedOrder.orderName &&
              !prevQueue.includes(updatedOrder.orderName)
            ) {
              const newQueue = [...prevQueue, updatedOrder.orderName];
              console.log("Updated speechQueue: ", newQueue);
              return newQueue;
            }
            return prevQueue;
          });

          console.log("speech queue: ", speechQueue);
        }

        return updatedList;
      });
    });

    return () => {
      console.log("Cleaning up socket listener...");
      socket.off("orderStatusUpdate");
      socket.off("connect");
    };
  }, []);

  useEffect(() => {
    if (!isSpeaking && speechQueue.length > 0) {
      setIsSpeaking(true);

      const orderName = speechQueue[0];
      console.log("Processing speech for:", orderName);

      const utterance = new SpeechSynthesisUtterance(
        `ORDER ${orderName} is ready for serving`
      );
      utterance.lang = "en-US";
      utterance.volume = 1;
      utterance.rate = 1;
      utterance.pitch = 1;

      utterance.onend = () => {
        setIsSpeaking(false);
        setSpeechQueue((prevQueue) => prevQueue.slice(1));
      };

      setTimeout(() => {
        window.speechSynthesis.speak(utterance);
      }, 500);
    }
  }, [speechQueue, isSpeaking]);

  const fetchOrders = async () => {
    try {
      setLoading(true);
      const { token } = getAuthCredentials();
      if (!token) {
        console.error("No auth token found.");
        return;
      }

      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/v1/api/order/fetch-orders`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = await response.json();

      if (response.ok) {
        setOrders(data);
        ls.set("orders", data);
      } else {
        console.error("Failed to fetch orders:", data.message);
      }
    } catch (error) {
      console.error("Error fetching orders:", error);
    } finally {
      setLoading(false);
    }
  };

  const preparingOrders = orders.filter(
    (order) => order.status === "Processing"
  );
  const readyOrders = orders.filter((order) => order.status === "Completed");

  const toggleFullScreen = () => {
    setFullScreen((prev) => {
      const newFullScreenState = !prev;
      localStorage.setItem("fullScreen", newFullScreenState);
      return newFullScreenState;
    });

    const unlockSpeech = new SpeechSynthesisUtterance(" ");
    speechSynthesis.speak(unlockSpeech);
  };

  return (
    <Box
      sx={{ flexGrow: 1, padding: 3, height: "100vh", cursor: "pointer" }}
      onClick={() => fullScreen && toggleFullScreen()}
    >
      {!fullScreen && (
        <Box sx={{ flexGrow: 1, position: "relative" }}>
          <Button
            variant="contained"
            color="primary"
            onClick={toggleFullScreen}
            sx={{ position: "absolute", top: 1, right: 5 }}
            startIcon={<Fullscreen />}
          >
            Full Screen
          </Button>

          <GenericViewHero title="Customer View" />
        </Box>
      )}
      {loading ? (
        <Box
          sx={{ display: "flex", justifyContent: "center", minHeight: "50vh" }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Grid container spacing={3}>
          {/* Preparing Orders */}
          <Grid item xs={12} md={6}>
            <Typography variant="h5" align="center" gutterBottom>
              Preparing Orders
            </Typography>
            {preparingOrders.length === 0 ? (
              <Typography align="center">
                No orders are currently being prepared.
              </Typography>
            ) : (
              preparingOrders.map((order) => (
                <Card
                  key={order._id || order.orderName}
                  sx={{ marginBottom: 2 }}
                >
                  <CardContent>
                    <Typography variant="h4" align="center">
                      {order.orderName}
                    </Typography>
                  </CardContent>
                </Card>
              ))
            )}
          </Grid>

          {/* Ready for Serving Orders */}
          <Grid item xs={12} md={6}>
            <Typography variant="h5" align="center" gutterBottom>
              Ready for Serving
            </Typography>
            {readyOrders.length === 0 ? (
              <Typography align="center">
                No orders are ready for serving.
              </Typography>
            ) : (
              readyOrders.map((order) => (
                <Card
                  key={order._id || order.orderName}
                  sx={{ marginBottom: 2, backgroundColor: "#d4edda" }}
                >
                  <CardContent>
                    <Typography variant="h4" align="center">
                      {order.orderName}
                    </Typography>
                  </CardContent>
                </Card>
              ))
            )}
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default ClaimingView;
