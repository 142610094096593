import React, { useState, useEffect } from "react";
import {
  Button,
  Box,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  TableContainer,
  TextField,
  Pagination,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import axios from "axios";
import SecureLS from "secure-ls";
import AccountHero from "./AccountHero";

const ls = new SecureLS({ encodingType: "aes" });
const MySwal = withReactContent(Swal);

const OTPCountdown = ({ expiry }) => {
  const calculateTimeLeft = () => {
    const now = new Date();
    const endTime = new Date(expiry);
    const total = endTime - now;
    const minutes = Math.floor(total / 1000 / 60);
    const seconds = Math.floor((total / 1000) % 60);
    return { total, minutes, seconds };
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
    return () => clearInterval(timer);
  }, [expiry]);

  if (timeLeft.total <= 0) {
    return <span>00:00</span>;
  }

  const formattedMinutes = String(timeLeft.minutes).padStart(2, "0");
  const formattedSeconds = String(timeLeft.seconds).padStart(2, "0");

  return (
    <span>
      {formattedMinutes}:{formattedSeconds}
    </span>
  );
};

const AccountOTP = () => {
  const [otps, setOtps] = useState([]);
  const [selectedDate, setSelectedDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const pageSize = 10;
  const authToken = ls.get("authToken");

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  // Fetch OTP records from the backend (limit by selected date with pagination)
  const fetchOTPs = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/v1/api/otp/fetch`,
        {
          params: {
            date: selectedDate,
            page: currentPage,
            limit: pageSize,
          },
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      // Expected response: { otps: [...], totalPages: N }
      setOtps(response.data.otps);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      console.error("Error fetching OTPs:", error);
    }
  };

  const generateOTP = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/v1/api/otp/generate`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      const data = response.data;
      MySwal.fire({
        title: "OTP Generated",
        text: `Your OTP is: ${data.otp}`,
        icon: "success",
        confirmButtonText: "OK",
      });
      fetchOTPs();
    } catch (error) {
      console.error("Error generating OTP:", error);
      MySwal.fire({
        title: "Error",
        text:
          error.response?.data?.message ||
          "Failed to generate OTP. Please try again.",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };

  useEffect(() => {
    fetchOTPs();
    // eslint-disable-next-line
  }, [selectedDate, currentPage]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString();
  };

  return (
    <Box sx={{ padding: 3 }}>
      <AccountHero title="Account Management" />
      <Box
        sx={{
          padding: "20px",
          display: "flex",
          flexDirection: "column",
          gap: 2,
          alignItems: "center",
        }}
      >
        {/* Date Select Input */}
        <TextField
          label="Select Date"
          type="date"
          value={selectedDate}
          onChange={(e) => {
            setSelectedDate(e.target.value);
            setCurrentPage(1);
          }}
          InputLabelProps={{ shrink: true }}
          sx={{ width: "100%", maxWidth: 300 }}
        />
        <Button variant="contained" color="primary" onClick={generateOTP}>
          Generate OTP
        </Button>
        <TableContainer
          component={Paper}
          sx={{ marginTop: "20px", width: "100%" }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Date Created</TableCell>
                <TableCell>OTP</TableCell>
                <TableCell>Date Used</TableCell>
                <TableCell>Action</TableCell>
                <TableCell>Generated By</TableCell>
                <TableCell>Used By</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {otps.map((otp) => {
                const isUsed = !!otp.dateUsed;
                const isExpired = new Date() > new Date(otp.expiry);
                return (
                  <TableRow
                    key={otp._id}
                    sx={{
                      "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.1)" },
                    }}
                  >
                    <TableCell>{formatDate(otp.createdAt)}</TableCell>
                    <TableCell>{otp.otp}</TableCell>
                    <TableCell>
                      {otp.dateUsed ? formatDate(otp.dateUsed) : "Not used"}
                    </TableCell>
                    <TableCell>{otp.action || "N/A"}</TableCell>
                    <TableCell>{otp.generatedBy || "N/A"}</TableCell>
                    <TableCell>{otp.usedBy || "N/A"}</TableCell>
                    <TableCell>
                      {isUsed ? (
                        "Used"
                      ) : isExpired ? (
                        "Expired"
                      ) : (
                        <>
                          Valid (<OTPCountdown expiry={otp.expiry} /> left)
                        </>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        {/* Pagination Controls */}
        <Box sx={{ mt: 2, display: "flex", justifyContent: "center" }}>
          <Pagination
            count={totalPages}
            page={currentPage}
            onChange={(event, value) => setCurrentPage(value)}
            color="primary"
          />
        </Box>
      </Box>
    </Box>
  );
};

export default AccountOTP;
