import React, { useState, useEffect } from "react";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import { getAuthCredentials } from "../../../utils/auth";

const EditTagsItems = ({
  open,
  onClose,
  menuItem,
  menuTags,
  onSave,
  onRefetch,
}) => {
  const [tags, setTags] = useState(menuTags || []);
  const [newTag, setNewTag] = useState("");
  const [spicyDialogOpen, setSpicyDialogOpen] = useState(false);
  const [selectedSpicy, setSelectedSpicy] = useState("");
  const { token } = getAuthCredentials();

  useEffect(() => {
    setTags(menuTags || []);
  }, [menuTags]);

  const handleAddTag = async () => {
    if (newTag && !tags.includes(newTag)) {
      try {
        await axios.put(
          `${process.env.REACT_APP_BACKEND_URL}/v1/api/menu/add-tag/${menuItem._id}`,
          { tag: newTag },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setTags([...tags, newTag]);
        if (onRefetch) onRefetch();
        setNewTag("");
      } catch (error) {
        console.error("Error adding tag:", error);
      }
    }
  };

  const handleDeleteTag = async (tagToDelete) => {
    try {
      await axios.put(
        `${process.env.REACT_APP_BACKEND_URL}/v1/api/menu/delete-tag/${menuItem._id}`,
        { tag: tagToDelete },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setTags(tags.filter((tag) => tag !== tagToDelete));
      if (onRefetch) onRefetch();
    } catch (error) {
      console.error("Error deleting tag:", error);
    }
  };

  const handleSave = () => {
    const updatedItem = { ...menuItem, menuTags: tags };
    onSave(updatedItem);
    onClose();
    if (onRefetch) onRefetch();
  };

  return (
    <>
      <Dialog open={open} onClose={onClose} fullWidth>
        <DialogTitle>Edit Tags</DialogTitle>
        <DialogContent>
          {tags.length > 0 ? (
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
              {tags.map((tag, index) => (
                <Chip
                  key={index}
                  label={tag}
                  onDelete={() => handleDeleteTag(tag)}
                  deleteIcon={<DeleteIcon />}
                  color={tag.startsWith("spicy:") ? "error" : "primary"}
                />
              ))}
            </Box>
          ) : (
            <Typography>No Tags Assigned</Typography>
          )}

          <Box sx={{ display: "flex", marginTop: 2 }}>
            <TextField
              label="New Tag"
              value={newTag}
              onChange={(e) => setNewTag(e.target.value)}
              sx={{ width: "80%" }}
              error={
                tags.some(
                  (tag) => tag.toLowerCase() === newTag.trim().toLowerCase()
                ) && newTag.trim() !== ""
              }
              helperText={
                tags.some(
                  (tag) => tag.toLowerCase() === newTag.trim().toLowerCase()
                ) && newTag.trim() !== ""
                  ? "Duplicate tag name"
                  : ""
              }
            />
            <Button
              variant="contained"
              color="success"
              onClick={handleAddTag}
              sx={{ marginLeft: 1, width: "20%" }}
              disabled={
                tags.some(
                  (tag) => tag.toLowerCase() === newTag.trim().toLowerCase()
                ) && newTag.trim() !== ""
              }
            >
              Add Tag
            </Button>
          </Box>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "space-between" }}>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              const currentSpicy = tags.find((tag) => tag.startsWith("spicy:"));
              if (currentSpicy) {
                setSelectedSpicy(currentSpicy.split("spicy:")[1].trim());
              } else {
                setSelectedSpicy("");
              }
              setSpicyDialogOpen(true);
            }}
          >
            {tags.some((tag) => tag.startsWith("spicy:"))
              ? "Edit Spicy Level"
              : "Add Spicy Level"}
          </Button>

          <Button onClick={onClose} color="secondary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={spicyDialogOpen} onClose={() => setSpicyDialogOpen(false)}>
        <DialogTitle>Select Spicy Level</DialogTitle>
        <DialogContent>
          <Box sx={{ display: "flex", justifyContent: "space-around", mt: 2 }}>
            {["mild", "pinoy", "thai"].map((level) => (
              <Button
                key={level}
                variant={selectedSpicy === level ? "contained" : "outlined"}
                onClick={() => setSelectedSpicy(level)}
                sx={{ px: 3 }}
              >
                {level.split("").join("\u00A0")}{" "}
              </Button>
            ))}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={async () => {
              try {
                const existingSpicy = tags.find((tag) =>
                  tag.startsWith("spicy:")
                );
                if (existingSpicy) {
                  await axios.put(
                    `${process.env.REACT_APP_BACKEND_URL}/v1/api/menu/delete-tag/${menuItem._id}`,
                    { tag: existingSpicy },
                    {
                      headers: {
                        Authorization: `Bearer ${token}`,
                      },
                    }
                  );
                }

                await axios.put(
                  `${process.env.REACT_APP_BACKEND_URL}/v1/api/menu/add-tag/${menuItem._id}`,
                  { tag: `spicy: ${selectedSpicy}` },
                  {
                    headers: {
                      Authorization: `Bearer ${token}`,
                    },
                  }
                );

                setTags((prevTags) => [
                  ...prevTags.filter((tag) => !tag.startsWith("spicy:")),
                  `spicy: ${selectedSpicy}`,
                ]);

                if (onRefetch) onRefetch();
                setSpicyDialogOpen(false);
              } catch (error) {
                console.error("Error updating spicy level:", error);
              }
            }}
            color="primary"
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EditTagsItems;
