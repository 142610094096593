import React from "react";
import Box from '@mui/material/Box'
import Typography from "@mui/material/Typography";

import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";


const BillHistoryChart = ({ history }) => {
  const processedData = {};
  const latestValues = {};

  const latestHour = Math.max(
    ...history.map((record) => new Date(record.timestamp).getHours())
  );

  history.forEach((record) => {
    const date = new Date(record.timestamp);
    const hour = date.getHours();
    const minutes = date.getMinutes();

    let timeLabel;

    if (hour < latestHour) {
      timeLabel = `${hour.toString().padStart(2, "0")}:00`;
    } else {
      timeLabel = date.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      });
    }

    const denomKey = `${record.boxType || "Cashbox"} - ${
      record.billType === "Coin"
        ? `₱${record.denomination} (Coin)`
        : `₱${record.denomination}`
    }`;

    if (!processedData[timeLabel]) {
      processedData[timeLabel] = { time: timeLabel, ...latestValues };
    }

    latestValues[denomKey] = record.quantityAfter;
    processedData[timeLabel][denomKey] = record.quantityAfter;
  });

  const sortedTimestamps = Object.keys(processedData).sort(
    (a, b) => new Date(`2000-01-01 ${a}`) - new Date(`2000-01-01 ${b}`)
  );

  let lastKnownValues = {};
  sortedTimestamps.forEach((time) => {
    processedData[time] = { ...lastKnownValues, ...processedData[time] };
    lastKnownValues = { ...processedData[time] };
  });

  const chartData = sortedTimestamps.map((time) => processedData[time]);

  const cashboxData = chartData.map((data) =>
    Object.fromEntries(
      Object.entries(data).filter(
        ([key]) => key.includes("Cashbox") || key === "time"
      )
    )
  );

  const changeboxData = chartData.map((data) =>
    Object.fromEntries(
      Object.entries(data).filter(
        ([key]) => key.includes("Changebox") || key === "time"
      )
    )
  );

  return (
    <Box
      sx={{ width: "100%", display: "flex", flexDirection: "column", gap: 4 }}
    >
      <Box sx={{ width: "100%", height: "400px" }}>
        <Typography variant="h5" sx={{ textAlign: "center", marginBottom: 2 }}>
          Cashbox - Denomination Counts Over Time
        </Typography>
        <ResponsiveContainer width="100%" height="100%">
          <LineChart data={cashboxData}>
            <XAxis dataKey="time" />
            <YAxis />
            <Tooltip />
            <Legend />
            {Object.keys(cashboxData[0] || {})
              .filter((key) => key !== "time")
              .map((denom, index) => (
                <Line
                  key={index}
                  type="monotone"
                  dataKey={denom}
                  stroke={getColor(denom)}
                  strokeWidth={3}
                />
              ))}
          </LineChart>
        </ResponsiveContainer>
      </Box>
      <Box
        sx={{
          width: "100%",
          height: "1px",
          background:
            "linear-gradient(to right, transparent, #ccc, transparent)",
          marginY: 2,
        }}
      />
      <Box sx={{ width: "100%", height: "400px" }}>
        <Box sx={{ width: "100%", height: "400px" }}>
          <Typography
            variant="h5"
            sx={{ textAlign: "center", marginBottom: 2 }}
          >
            Changebox - Denomination Counts Over Time
          </Typography>
          <ResponsiveContainer width="100%" height="100%">
            <LineChart data={changeboxData}>
              <XAxis dataKey="time" />
              <YAxis />
              <Tooltip />
              <Legend />
              {Object.keys(changeboxData[0] || {})
                .filter((key) => key !== "time")
                .map((denom, index) => (
                  <Line
                    key={index}
                    type="monotone"
                    dataKey={denom}
                    stroke={getColor(denom)}
                    strokeWidth={3}
                  />
                ))}
            </LineChart>
          </ResponsiveContainer>
        </Box>
      </Box>
    </Box>
  );
};

const getColor = (() => {
  const colors = [
    "#4B0082", 
    "#556B2F",
    "#8B0000", 
    "#483D8B", 
    "#2F4F4F", 
    "#800000", 
    "#708090", 
    "#3CB371", 
    "#4682B4",
    "#6A5ACD", 
  ];

  const colorMap = {};
  let colorIndex = 0;

  return (denomination) => {
    if (!colorMap[denomination]) {
      colorMap[denomination] = colors[colorIndex];
      colorIndex = (colorIndex + 1) % colors.length;
    }
    return colorMap[denomination];
  };
})();

export default BillHistoryChart;
