import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import Collapse from "@mui/material/Collapse";
import Paper from "@mui/material/Paper";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import axios from "axios";
import SecureLS from "secure-ls";
import Swal from "sweetalert2";
import CustomerInfoDialog from "../dialog/CustomerInfoDialog";

const ls = new SecureLS({ encodingType: "aes" });

const Cart = ({
  cartItems,
  onUpdateQuantity,
  onRemoveItem,
  onOrderGenerated,
  formatPrice,
  paymentMethod,
  onCheckout,
  posId,
}) => {
  const [discountType, setDiscountType] = useState("None");
  const [discountValue, setDiscountValue] = useState("");
  const [voucherCode, setVoucherCode] = useState("");
  const [voucherDiscount, setVoucherDiscount] = useState(null);
  const [voucherApplied, setVoucherApplied] = useState(false);
  const [isVoucherError, setIsVoucherError] = useState(false);
  const [expandedItems, setExpandedItems] = useState({});
  const [customerDialogOpen, setCustomerDialogOpen] = useState(false);
  const [customerId, setCustomerId] = useState(null);
  const [orderId, setOrderId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [voucherMinimum, setVoucherMinimum] = useState(null);
  const [salesType, setSalesType] = useState("Dine-In");

  // Calculate subtotal (in cents)
  const subtotal = cartItems.reduce((sum, item) => sum + item.totalPrice, 0);

  // Calculate discount amount based on type
  const calculateDiscount = () => {
    if (discountType === "None") return 0;

    let discountAmount = 0;
    let vatAmount = subtotal * (12 / 112);
    let vatExemptBase = subtotal - vatAmount;

    if (discountType === "PWD" || discountType === "Senior") {
      let discountedBase = vatExemptBase * 0.8;
      discountAmount = vatExemptBase - discountedBase;
    } else if (discountType === "Athlete" || discountType === "SoloParent") {
      discountAmount = vatExemptBase * 0.2;
    } else if (discountType === "Diplomat") {
      discountAmount = vatAmount;
    } else if (discountType === "Voucher") {
      discountAmount = voucherDiscount !== null ? voucherDiscount : 0;
    }

    return Math.min(discountAmount, vatExemptBase);
  };

  const discount = calculateDiscount();

  const vatAmount = subtotal * (12 / 112);
  const subtotalExcludingVAT = subtotal - vatAmount;
  const vatExemptBase = subtotal - vatAmount;
  let finalVatAmount = vatAmount;
  let vatSales = vatExemptBase;
  let finalTotal = 0;
  if (discountType === "Athlete") {
    finalTotal = vatExemptBase - discount + vatAmount;
  } else if (discountType === "PWD" || discountType === "Senior") {
    finalTotal = subtotalExcludingVAT - discount;
  } else {
    finalTotal = subtotal - discount;
  }

  const toggleItemExpansion = (itemId) => {
    setExpandedItems((prev) => ({ ...prev, [itemId]: !prev[itemId] }));
  };

  const handleApplyVoucher = async () => {
    if (!voucherCode) {
      Swal.fire({
        icon: "error",
        title: "Voucher Error",
        text: "Please enter a voucher code.",
      });
      setIsVoucherError(true);
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/v1/api/vouchers/apply-voucher`,
        { voucherCode, subtotal, posId }
      );

      // Destructure voucher along with discount and message
      const { discount: appliedDiscount, message, voucher } = response.data;

      if (message && appliedDiscount === 0) {
        Swal.fire({
          icon: "error",
          title: "Voucher Error",
          text: message,
        });
        setVoucherDiscount(null);
        setIsVoucherError(true);
        setVoucherApplied(false);
        setVoucherMinimum(null);
      } else {
        Swal.fire({
          icon: "success",
          title: "Voucher Applied",
          text: `Voucher applied! Discount: ${formatPrice(
            appliedDiscount / 100
          )}`,
        });
        setVoucherDiscount(appliedDiscount);
        setIsVoucherError(false);
        setVoucherApplied(true);
        // Store the voucher's minimum requirement for later revalidation
        if (voucher && voucher.minimumDeduct) {
          setVoucherMinimum(voucher.minimumDeduct);
        }
      }
    } catch (error) {
      console.error("Error applying voucher:", error);
      Swal.fire({
        icon: "error",
        title: "Voucher Error",
        text: error.response?.data?.message || "Error applying voucher.",
      });
      setVoucherDiscount(null);
      setIsVoucherError(true);
      setVoucherMinimum(null);
    }
  };

  const handleDiscountTypeChange = (e) => {
    const selectedDiscount = e.target.value;

    if (selectedDiscount !== "Voucher") {
      setVoucherCode("");
      setVoucherDiscount(null);
      setVoucherApplied(false);
      setIsVoucherError(false);
    }

    setDiscountType(selectedDiscount);

    if (selectedDiscount !== "None" && selectedDiscount !== "Voucher") {
      setCustomerDialogOpen(true);
    }
  };

  const resetDiscount = () => setDiscountType("None");

  // New checkout handler simply validates the cart and then calls onCheckout
  const handleCheckout = () => {
    let voucherDesc = voucherCode || "";
    if (cartItems.length === 0) {
      Swal.fire({
        icon: "error",
        title: "Empty Cart",
        text: "Your cart is empty. Please add items before checking out.",
      });
      return;
    }
    if (discountType === "Voucher" && !voucherCode) {
      Swal.fire({
        icon: "error",
        title: "Voucher Error",
        text: "Please enter a voucher code.",
      });
      return;
    }
    // Delegate the payment processing to the PaymentDialog via onCheckout callback
    onCheckout({
      discount,
      discountType,
      discountValue,
      voucherCode,
      voucherDesc,
      finalTotal,
      customerId,
      orderId,
    });
  };

  React.useEffect(() => {
    if (
      discountType === "Voucher" &&
      voucherApplied &&
      voucherMinimum !== null
    ) {
      if (subtotal < voucherMinimum) {
        Swal.fire({
          icon: "error",
          title: "Voucher Error",
          text: `Your cart subtotal is below the required minimum of ${formatPrice(
            voucherMinimum / 100
          )} for this voucher.`,
        });
        setVoucherCode("");
        setVoucherDiscount(null);
        setVoucherApplied(false);
        setIsVoucherError(true);
        setVoucherMinimum(null);
      }
    }
  }, [subtotal, discountType, voucherApplied, voucherMinimum]);

  useEffect(() => {
    window.onCustomerCreated = (id, ordId) => {
      setCustomerId(id);
      setOrderId(ordId);
    };
    return () => {
      delete window.onCustomerCreated;
    };
  }, []);

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        Your Order
      </Typography>

      {cartItems.length === 0 ? (
        <Typography variant="body1" sx={{ py: 4, textAlign: "center" }}>
          Your cart is empty. Add some items to get started!
        </Typography>
      ) : (
        <>
          <List sx={{ mb: 2, maxHeight: "50vh", overflow: "auto" }}>
            {cartItems.map((item) => (
              <React.Fragment key={item.id}>
                <ListItem alignItems="flex-start">
                  <ListItemText
                    primary={
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Typography
                          variant="body1"
                          sx={{ fontWeight: "medium" }}
                        >
                          {item.menuName}
                        </Typography>
                        <IconButton
                          size="small"
                          onClick={() => toggleItemExpansion(item.id)}
                          sx={{ ml: 1 }}
                        >
                          {expandedItems[item.id] ? (
                            <ExpandLessIcon fontSize="small" />
                          ) : (
                            <ExpandMoreIcon fontSize="small" />
                          )}
                        </IconButton>
                      </Box>
                    }
                    secondary={
                      <>
                        <Box
                          sx={{ display: "flex", alignItems: "center", mt: 1 }}
                        >
                          <IconButton
                            size="small"
                            onClick={() =>
                              onUpdateQuantity(item.id, item.quantity - 1)
                            }
                            sx={{ border: 1, borderColor: "divider" }}
                          >
                            <RemoveIcon fontSize="small" />
                          </IconButton>
                          <Typography variant="body2" sx={{ mx: 1 }}>
                            {item.quantity}
                          </Typography>
                          <IconButton
                            size="small"
                            onClick={() =>
                              onUpdateQuantity(item.id, item.quantity + 1)
                            }
                            sx={{ border: 1, borderColor: "divider" }}
                          >
                            <AddIcon fontSize="small" />
                          </IconButton>
                        </Box>
                        <Collapse
                          in={expandedItems[item.id]}
                          timeout="auto"
                          unmountOnExit
                        >
                          <Box sx={{ mt: 1, ml: 1 }}>
                            {item.selectedOptions &&
                              item.selectedOptions.length > 0 && (
                                <Box sx={{ mb: 1 }}>
                                  <Typography
                                    variant="body2"
                                    sx={{ fontWeight: "bold" }}
                                  >
                                    Selected Options:
                                  </Typography>

                                  {item.selectedOptions.map((option, index) => (
                                    <Box key={index} sx={{ ml: 1 }}>
                                      <Typography
                                        variant="body2"
                                        sx={{ fontWeight: "bold" }}
                                      >
                                        - {option.name || option.optionId}:
                                      </Typography>

                                      {Array.isArray(option.choices) ? (
                                        option.choices.map((choice, idx) => {
                                          const choiceName =
                                            typeof choice === "object"
                                              ? choice.name
                                              : String(choice);
                                          const choicePrice =
                                            typeof choice === "object"
                                              ? choice.price
                                              : 0;

                                          return (
                                            <Typography
                                              key={idx}
                                              variant="body2"
                                              sx={{ ml: 2 }}
                                            >
                                              • {choiceName}
                                              {choicePrice > 0 && (
                                                <>
                                                  {" "}
                                                  (+
                                                  {formatPrice(
                                                    choicePrice / 100
                                                  )}
                                                  )
                                                </>
                                              )}
                                            </Typography>
                                          );
                                        })
                                      ) : (
                                        <Typography
                                          variant="body2"
                                          sx={{ ml: 2 }}
                                        >
                                          •{" "}
                                          {typeof option.choice === "object"
                                            ? option.choice.name
                                            : option.choice}
                                          {typeof option.choice === "object" &&
                                            option.choice.price > 0 && (
                                              <>
                                                {" "}
                                                (+
                                                {formatPrice(
                                                  option.choice.price / 100
                                                )}
                                                )
                                              </>
                                            )}
                                          {typeof option.choice !== "object" &&
                                            option.price > 0 && (
                                              <>
                                                {" "}
                                                (+
                                                {formatPrice(
                                                  option.price / 100
                                                )}
                                                )
                                              </>
                                            )}
                                        </Typography>
                                      )}
                                    </Box>
                                  ))}
                                </Box>
                              )}
                          </Box>
                        </Collapse>
                      </>
                    }
                  />
                  <ListItemSecondaryAction>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-end",
                      }}
                    >
                      <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                        {formatPrice(item.totalPrice / 100)}
                      </Typography>
                      <IconButton
                        edge="end"
                        onClick={() => onRemoveItem(item.id)}
                        size="small"
                      >
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </Box>
                  </ListItemSecondaryAction>
                </ListItem>
                <Divider />
              </React.Fragment>
            ))}
          </List>

          {/* Discount Section */}
          <Paper elevation={1} sx={{ p: 2, mb: 2 }}>
            <Typography variant="subtitle1" gutterBottom>
              Discount
            </Typography>
            <Box sx={{ display: "flex", alignItems: "flex-start", mb: 1 }}>
              <FormControl
                variant="outlined"
                size="small"
                sx={{ mr: 1, width: "50%" }}
              >
                <InputLabel>Type</InputLabel>
                <Select
                  value={discountType}
                  onChange={handleDiscountTypeChange}
                  label="Type"
                >
                  <MenuItem value="None">None</MenuItem>
                  <MenuItem value="PWD">PWD</MenuItem>
                  <MenuItem value="Senior">Senior</MenuItem>
                  <MenuItem value="SoloParent">Solo Parent</MenuItem>
                  <MenuItem value="Diplomat">Diplomat</MenuItem>
                  <MenuItem value="Athlete">Athlete</MenuItem>
                  <MenuItem value="Voucher">Voucher</MenuItem>
                </Select>
              </FormControl>

              {discountType === "Voucher" && (
                <Box sx={{ display: "flex", width: "50%", gap: 1 }}>
                  <TextField
                    label="Voucher Code"
                    variant="outlined"
                    size="small"
                    value={voucherCode}
                    onChange={(e) => {
                      setVoucherCode(e.target.value);
                      setIsVoucherError(false);
                      setVoucherApplied(false);
                    }}
                    fullWidth
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleApplyVoucher}
                  >
                    Apply
                  </Button>
                </Box>
              )}
            </Box>
          </Paper>

          {/* Order Summary */}
          <Box sx={{ mt: 2 }}>
            <Box
              sx={{ display: "flex", justifyContent: "space-between", mb: 1 }}
            >
              <Typography variant="body1">Subtotal:</Typography>
              <Typography variant="body1">
                {formatPrice(subtotalExcludingVAT / 100)}
              </Typography>
            </Box>
            <Box
              sx={{ display: "flex", justifyContent: "space-between", mb: 1 }}
            >
              <Typography variant="body1">12% VAT:</Typography>
              <Typography variant="body1">
                {formatPrice(finalVatAmount / 100)}
              </Typography>
            </Box>

            <Box
              sx={{ display: "flex", justifyContent: "space-between", mb: 1 }}
            >
              <Typography variant="body1">Discount:</Typography>
              <Typography variant="body1" color="error">
                -{formatPrice(discount / 100)}
              </Typography>
            </Box>

            <Divider sx={{ my: 1 }} />

            <Box
              sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}
            >
              <Typography variant="h6">Total:</Typography>
              <Typography variant="h6" color="primary">
                {formatPrice(finalTotal / 100)}
              </Typography>
            </Box>

            <Button
              variant="contained"
              color="primary"
              fullWidth
              size="large"
              onClick={handleCheckout}
              disabled={
                cartItems.length === 0 ||
                loading ||
                (discountType === "Voucher" &&
                  (!voucherApplied || isVoucherError))
              }
            >
              {loading ? "Processing Order..." : "Checkout"}
            </Button>
          </Box>

          {errorMessage && (
            <Typography
              color="error"
              sx={{ textAlign: "center", mb: 2, mt: 2, fontWeight: "bold" }}
            >
              {errorMessage}
            </Typography>
          )}

          <CustomerInfoDialog
            open={customerDialogOpen}
            onClose={() => setCustomerDialogOpen(false)}
            resetDiscount={resetDiscount}
            chosenDiscountType={discountType}
          />
        </>
      )}
    </Box>
  );
};

export default Cart;
