import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import useMediaQuery from '@mui/material/useMediaQuery'
import DescriptionIcon from "@mui/icons-material/Description";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { useTheme } from "@mui/material/styles";

export const exportToExcel = (data) => {
  const worksheet = XLSX.utils.json_to_sheet(
    data.map((voucher) => ({
      Name: voucher.name,
      Type: voucher.voucherType,
      "Min Purchase": voucher.minimumDeduct,
      "Amount Deduct":
        voucher.voucherType === "Percentage Deduction"
          ? `${voucher.amountDeduct}%`
          : voucher.amountDeduct,
      "Max Deduct":
        voucher.voucherType === "Percentage Deduction"
          ? voucher.maxDeduct
          : "N/A",
      Code: voucher.voucherCode,
      "Times Used": voucher.numberOfTimesUsed,
    }))
  );

  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Vouchers");
  XLSX.writeFile(workbook, "Vouchers.xlsx");
};

export const exportToPDF = (vouchers) => {
  const doc = new jsPDF();
  doc.text("Voucher List", 14, 15);

  const tableColumn = [
    "Name",
    "Type",
    "Min Purchase",
    "Amount Deduct",
    "Max Deduct",
    "Code",
    "Times Used",
  ];
  const tableRows = vouchers.map((voucher) => [
    voucher.name,
    voucher.voucherType,
    voucher.minimumDeduct,
    voucher.voucherType === "Percentage Deduction"
      ? `${voucher.amountDeduct}%`
      : voucher.amountDeduct,
    voucher.voucherType === "Percentage Deduction" ? voucher.maxDeduct : "N/A",
    voucher.voucherCode,
    voucher.numberOfTimesUsed,
  ]);

  doc.autoTable({
    head: [tableColumn],
    body: tableRows,
    startY: 20,
  });

  doc.save("Vouchers.pdf");
};

export const ExportButtons = ({ data }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: isMobile ? "column" : "row",
        gap: 2,
        alignItems: "center",
      }}
    >
      <Button
        variant="contained"
        color="primary"
        onClick={() => exportToExcel(data)}
        startIcon={<DescriptionIcon />}
        sx={{ width: isMobile ? "100%" : "auto" }}
      >
        {isMobile ? "Export CSV" : "Export to CSV"}
      </Button>
      <Button
        variant="contained"
        color="secondary"
        onClick={() => exportToPDF(data)}
        startIcon={<PictureAsPdfIcon />}
        sx={{ width: isMobile ? "100%" : "auto" }}
      >
        {isMobile ? "Export PDF" : "Export to PDF"}
      </Button>
    </Box>
  );
};
