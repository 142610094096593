import React, { useState } from "react";
import Box from '@mui/material/Box';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';

const SearchableSelect = ({
  options = [],
  value,
  onChange,
  placeholder = "Search...",
  displayKey = "name",
  valueKey = "_id",
  allOption = { value: "All", label: "All" },
  sx = {},
  size = "small",
}) => {
  const [searchTerm, setSearchTerm] = useState("");

  const filteredOptions = options.filter((option) => {
    const displayValue = option[displayKey] || "";
    return displayValue.toLowerCase().includes(searchTerm.toLowerCase());
  });

  return (
    <Select
      value={value}
      onChange={onChange}
      displayEmpty
      size={size}
      sx={{ minWidth: 150, ...sx }}
      MenuProps={{
        PaperProps: {
          style: {
            maxHeight: 300,
            overflow: "visible",
            position: "relative",
          },
        },
      }}
    >
      <Box
        sx={{
          padding: 1,
          backgroundColor: "white",
          position: "sticky",
          top: 0,
          zIndex: 1,
        }}
      >
        <TextField
          size="small"
          placeholder={placeholder}
          variant="outlined"
          fullWidth
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </Box>
      {allOption && (
        <MenuItem value={allOption.value}>{allOption.label}</MenuItem>
      )}
      {filteredOptions.map((option) => (
        <MenuItem key={option[valueKey]} value={option[valueKey]}>
          {option[displayKey]}
        </MenuItem>
      ))}
    </Select>
  );
};

export default SearchableSelect;
