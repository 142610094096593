import React from "react";
import Box from '@mui/material/Box'
import Typography from "@mui/material/Typography";
import Button from '@mui/material/Button'
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";

const ReceiptHero = () => {
  const navigate = useNavigate();

  return (
    <Box sx={{ padding: 3 }}>
      <Button
        variant="contained"
        color="primary"
        startIcon={<ArrowBackIcon />}
        onClick={() => navigate("/dashboard")}
        sx={{ marginBottom: 3 }}
      >
        Back
      </Button>
      <Typography variant="h4" align="center" gutterBottom>
        Receipt Configuration
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          marginBottom: 4,
          gap: 2,
        }}
      >
        <Button variant="outlined" onClick={() => navigate("/receipt-config")}>
          Configure Receipt
        </Button>
        <Button variant="outlined" onClick={() => navigate("/receipt-print")}>
          Test Print Receipt
        </Button>
      </Box>
    </Box>
  );
};

export default ReceiptHero;
