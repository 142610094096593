import React, { useState, useEffect } from "react";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Save, DragIndicator } from "@mui/icons-material";
import axios from "axios";
import Swal from "sweetalert2";
import { getAuthCredentials } from "../../../utils/auth";

const EditArrangementDialog = ({
  open,
  onClose,
  menuCategories,
  fetchMenuCategories,
  fetchMenuData,
  activeMenuItems,
}) => {
  const [arrangement, setArrangement] = useState([]);

  useEffect(() => {
    if (open) {
      setArrangement([...menuCategories]); // Ensure the list updates when dialog opens
    }
  }, [open, menuCategories]);

  const { token } = getAuthCredentials();

  const activeCategoryIds = Array.from(
    new Set(activeMenuItems.map((item) => item.categoryId))
  );

  // Handle Drag and Drop Sorting
  const onDragEnd = (result) => {
    if (!result.destination) return;
    const updatedArrangement = Array.from(arrangement);
    const [movedItem] = updatedArrangement.splice(result.source.index, 1);
    updatedArrangement.splice(result.destination.index, 0, movedItem);
    setArrangement(updatedArrangement);
  };

  const handleSave = async () => {
    try {
      const categoryOrder = arrangement.map((category) =>
        String(category.categoryId)
      );

      await axios.put(
        `${process.env.REACT_APP_BACKEND_URL}/v1/api/menu/update-category-order`,
        { categoryOrder },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      fetchMenuCategories();
      fetchMenuData();
      onClose();

      // Success Alert
      Swal.fire({
        icon: "success",
        title: "Success",
        text: "Category arrangement updated successfully!",
        confirmButtonColor: "#3085d6",
      });
    } catch (error) {
      console.error("Error updating category order:", error);

      // Error Alert
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Failed to update category arrangement. Please try again.",
        confirmButtonColor: "#d33",
      });
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Edit Category Arrangement</DialogTitle>
      <DialogContent>
        <DragDropContext onDragEnd={onDragEnd}>
          {/* Ensure Droppable is always rendered */}
          {arrangement.length > 0 && (
            <Droppable droppableId="categories">
              {(provided) => (
                <List
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  sx={{ width: "100%", bgcolor: "background.paper" }}
                >
                  {arrangement.map((category, index) => (
                    <Draggable
                      key={category._id}
                      draggableId={String(category._id)} // Ensure it's a string
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <ListItem
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            bgcolor: activeCategoryIds.includes(
                              category.categoryId
                            )
                              ? "success.light"
                              : "inherit",
                            borderRadius: 1,
                            boxShadow: snapshot.isDragging ? 3 : 0,
                            mb: 1,
                            transition: "background-color 0.2s ease",
                          }}
                        >
                          <IconButton
                            {...provided.dragHandleProps}
                            sx={{ cursor: "grab", mr: 2 }}
                          >
                            <DragIndicator />
                          </IconButton>
                          <ListItemText
                            primary={`${category.categoryName}${
                              !activeCategoryIds.includes(category.categoryId)
                                ? " (Not Used)"
                                : ""
                            }`}
                          />
                        </ListItem>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </List>
              )}
            </Droppable>
          )}
        </DragDropContext>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button
          onClick={handleSave}
          variant="contained"
          color="primary"
          startIcon={<Save />}
        >
          Save Arrangement
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditArrangementDialog;
