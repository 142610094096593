import React from "react";
import Box from '@mui/material/Box'
import Typography from  '@mui/material/Typography'
import Button from '@mui/material/Button'
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";

const VoucherHero = ({ activeTab }) => {
  const navigate = useNavigate();

  return (
    <Box sx={{ padding: 3 }}>
      <Button
        variant="contained"
        color="primary"
        startIcon={<ArrowBackIcon />}
        onClick={() => navigate("/dashboard")}
        sx={{ marginBottom: 3 }}
      >
        Back
      </Button>
      <Typography variant="h4" align="center" gutterBottom>
        Voucher Management
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          gap: 2,
          marginBottom: 3,
        }}
      >
        {/* <Button
          variant={activeTab === "details" ? "contained" : "outlined"}
          onClick={() => navigate("/vouchers")}
        >
          Voucher List
        </Button> */}
      </Box>
    </Box>
  );
};

export default VoucherHero;
