import React, { useState } from "react";

import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import CardMedia from '@mui/material/CardMedia'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormGroup from "@mui/material/FormGroup";
import Chip from '@mui/material/Chip'
import CardActionArea from "@mui/material/CardActionArea"; 
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

// formatPrice function is now imported from context


const MenuItems = ({ menuItems, onAddToCart, translate, language, formatPrice }) => {
  const [selectedItem, setSelectedItem] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const [selectedOptions, setSelectedOptions] = useState({});

  // Open item details dialog
  const handleItemClick = (item) => {
    setSelectedItem(item);
    setQuantity(1);

    // Initialize selected options
    const initialOptions = {};
    if (item.options && item.options.length > 0) {
      item.options.forEach(option => {
        if (option.optionType === "single") {
          initialOptions[option.optionName] = null;
        } else if (option.optionType === "checkbox") {
          initialOptions[option.optionName] = [];
        }
      });
    }
    setSelectedOptions(initialOptions);
  };

  // Close item details dialog
  const handleCloseDialog = () => {
    setSelectedItem(null);
    setSelectedOptions({});
  };

  // Handle option selection
  const handleOptionChange = (optionName, optionType, value) => {
    if (optionType === "single") {
      setSelectedOptions({
        ...selectedOptions,
        [optionName]: value
      });
    } else if (optionType === "checkbox") {
      const currentSelections = selectedOptions[optionName] || [];

      if (currentSelections.some(item => item.name === value.name)) {
        // Remove if already selected
        setSelectedOptions({
          ...selectedOptions,
          [optionName]: currentSelections.filter(item => item.name !== value.name)
        });
      } else {
        // Add if not already selected
        setSelectedOptions({
          ...selectedOptions,
          [optionName]: [...currentSelections, value]
        });
      }
    }
  };

  // Handle adding item to cart
  const handleAddToCart = () => {
    if (!selectedItem) return;

    const flattenedOptions = [];

    Object.entries(selectedOptions).forEach(([optionName, value]) => {
      const originalOption = selectedItem.options.find(o => o.optionName === optionName);

      if (!originalOption) return;

      if (Array.isArray(value)) {
        value.forEach(choice => {
          flattenedOptions.push({
            optionId: choice._id || null,
            name: optionName,
            choice: choice.name,
            price: choice.price || 0
          });
        });
      } else if (value) {
        flattenedOptions.push({
          optionId: value._id || null,
          name: optionName,
          choice: value.name,
          price: value.price || 0
        });
      }
    });

    onAddToCart(selectedItem, flattenedOptions, quantity);
    handleCloseDialog();
  };

  // Calculate total price including options
  const calculateTotalPrice = () => {
    if (!selectedItem) return 0;

    let total = selectedItem.basePrice;

    // Add prices from options
    Object.values(selectedOptions).forEach(value => {
      if (Array.isArray(value)) { // For checkbox options
        value.forEach(option => {
          total += option.price || 0;
        });
      } else if (value) { // For single select options
        total += value.price || 0;
      }
    });

    return total * quantity;
  };

  // Render allergen tags
  const renderAllergenTags = (menuTags) => {
    if (!menuTags || menuTags.length === 0) return null;

    const allergenTags = menuTags.filter(tag => tag.includes("allergen:"));

    if (allergenTags.length === 0) return null;

    return (
      <Box sx={{ mt: 1 }}>
        {allergenTags.map((tag, index) => (
          <Chip
            key={index}
            label={tag.replace("allergen:", "").trim()}
            size="small"
            color="warning"
            sx={{ mr: 0.5, mb: 0.5 }}
          />
        ))}
      </Box>
    );
  };

  // Render spicy level tags
  const renderSpicyLevelTags = (menuTags) => {
    if (!menuTags || menuTags.length === 0) return null;

    const spicyTags = menuTags.filter(tag => tag.includes("spicy:"));

    if (spicyTags.length === 0) return null;

    return (
      <Box sx={{ mt: 1 }}>
        {spicyTags.map((tag, index) => (
          <Chip
            key={index}
            label={`Spicy: ${tag.replace("spicy:", "").trim()}`}
            size="small"
            color="error"
            sx={{ mr: 0.5, mb: 0.5 }}
          />
        ))}
      </Box>
    );
  };

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        {translate ? translate('Menu Items', {
          en: 'Menu Items',
          zh: '菜单项目',
          th: 'รายการอาหาร',
          tl: 'Mga Item sa Menu'
        }) : 'Menu Items'}
      </Typography>

      {menuItems.length === 0 ? (
        <Typography>
          {translate ? translate('No items available in this category.', {
            en: 'No items available in this category.',
            zh: '此类别中没有可用的项目。',
            th: 'ไม่มีรายการในหมวดหมู่นี้',
            tl: 'Walang available na item sa kategoryang ito.'
          }) : 'No items available in this category.'}
        </Typography>
      ) : (
        <Grid container spacing={2}>
          {menuItems.map((item) => (
            <Grid item key={item._id} xs={12} sm={6} md={4}>
              <Card sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
                <CardActionArea onClick={() => handleItemClick(item)}>
                  <CardMedia
                    component="img"
                    height="140"
                    image={item.menuImage}
                    alt={item.menuName}
                  />
                  <CardContent sx={{ flexGrow: 1 }}>
                    <Typography gutterBottom variant="h6" component="div" noWrap>
                      {language && item.translations?.menuName?.[language] || item.menuName}
                    </Typography>
                    <Typography variant="body2" color="text.secondary" sx={{
                      height: "40px",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      display: "-webkit-box",
                      WebkitLineClamp: 2,
                      WebkitBoxOrient: "vertical"
                    }}>
                      {language && item.translations?.description?.[language] ||
                        item.description ||
                        (translate ? translate('No description available', {
                          en: 'No description available',
                          zh: '没有可用的描述',
                          th: 'ไม่มีคำอธิบาย',
                          tl: 'Walang available na paglalarawan'
                        }) : 'No description available')}
                    </Typography>
                    <Typography variant="h6" color="primary" sx={{ mt: 1 }}>
                      {formatPrice(item.basePrice / 100)}
                    </Typography>
                    {renderAllergenTags(item.menuTags)}
                    {renderSpicyLevelTags(item.menuTags)}
                  </CardContent>
                </CardActionArea>
                <CardActions>
                  <Button
                    variant="contained"
                    startIcon={<AddIcon />}
                    fullWidth
                    onClick={() => handleItemClick(item)}
                  >
                    {translate ? translate('Add to Cart', {
                      en: 'Add to Cart',
                      zh: '加入购物车',
                      th: 'เพิ่มลงในรถเข็น',
                      tl: 'Idagdag sa Cart'
                    }) : 'Add to Cart'}
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      )}

      {/* Item Details Dialog */}
      {selectedItem && (
        <Dialog open={!!selectedItem} onClose={handleCloseDialog} maxWidth="sm" fullWidth>
          <DialogTitle>
            {language && selectedItem.translations?.menuName?.[language] || selectedItem.menuName}
          </DialogTitle>
          <DialogContent dividers>
            <Box sx={{ mb: 2 }}>
              {selectedItem.menuImage && (
                <CardMedia
                  component="img"
                  image={selectedItem.menuImage}
                  alt={selectedItem.menuName}
                  sx={{ borderRadius: 1, mb: 2 }}
                />
              )}

              <Typography variant="body1">
                {language && selectedItem.translations?.description?.[language] ||
                  selectedItem.description ||
                  (translate ? translate('No description available', {
                    en: 'No description available',
                    zh: '没有可用的描述',
                    th: 'ไม่มีคำอธิบาย',
                    tl: 'Walang available na paglalarawan'
                  }) : 'No description available')}
              </Typography>

              <Typography variant="h6" color="primary" sx={{ mt: 1 }}>
                {translate ? translate('Base Price', {
                  en: 'Base Price',
                  zh: '基本价格',
                  th: 'ราคาพื้นฐาน',
                  tl: 'Base Price'
                }) : 'Base Price'}: {formatPrice(selectedItem.basePrice / 100)}
              </Typography>

              {renderAllergenTags(selectedItem.menuTags)}
              {renderSpicyLevelTags(selectedItem.menuTags)}
            </Box>

            {/* Options Section */}
            {selectedItem.options && selectedItem.options.length > 0 && (
              <Box sx={{ mt: 3 }}>
                <Typography variant="h6" gutterBottom>Options</Typography>

                {selectedItem.options.map((option, index) => (
                  <Box key={index} sx={{ mb: 3 }}>
                    <FormControl component="fieldset" fullWidth>
                      <FormLabel component="legend">{option.optionName}</FormLabel>

                      {option.optionType === "single" && (
                        <RadioGroup
                          name={option.optionName}
                          value={selectedOptions[option.optionName] ? selectedOptions[option.optionName].name : ""}
                          onChange={(e, value) => {
                            const selectedChoice = option.choices.find(c => c.name === value);
                            handleOptionChange(option.optionName, "single", selectedChoice);
                          }}
                        >
                          {option.choices.map((choice, choiceIndex) => (
                            <FormControlLabel
                              key={choiceIndex}
                              value={choice.name}
                              control={<Radio />}
                              label={
                                <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                                  <Typography>{choice.name}</Typography>
                                  {choice.price > 0 && (
                                    <Typography color="primary">
                                      + {formatPrice(choice.price / 100)}
                                    </Typography>
                                  )}
                                </Box>
                              }
                            />
                          ))}
                        </RadioGroup>
                      )}

                      {option.optionType === "checkbox" && (
                        <FormGroup>
                          {option.choices.map((choice, choiceIndex) => (
                            <FormControlLabel
                              key={choiceIndex}
                              control={
                                <Checkbox
                                  checked={Boolean(
                                    selectedOptions[option.optionName] &&
                                    selectedOptions[option.optionName].some(item => item.name === choice.name)
                                  )}
                                  onChange={() => handleOptionChange(option.optionName, "checkbox", choice)}
                                />
                              }
                              label={
                                <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                                  <Typography>{choice.name}</Typography>
                                  {choice.price > 0 && (
                                    <Typography color="primary">
                                      + {formatPrice(choice.price / 100)}
                                    </Typography>
                                  )}
                                </Box>
                              }
                            />
                          ))}
                        </FormGroup>
                      )}
                    </FormControl>
                  </Box>
                ))}
              </Box>
            )}

            {/* Quantity Selector */}
            <Box sx={{ mt: 2, display: "flex", alignItems: "center" }}>
              <Typography variant="body1" sx={{ mr: 2 }}>Quantity:</Typography>
              <IconButton
                size="small"
                onClick={() => setQuantity(Math.max(1, quantity - 1))}
                sx={{ border: 1, borderColor: "divider" }}
              >
                <RemoveIcon fontSize="small" />
              </IconButton>
              <TextField
                value={quantity}
                onChange={(e) => {
                  const value = parseInt(e.target.value, 10);
                  if (!isNaN(value) && value > 0) {
                    setQuantity(value);
                  }
                }}
                variant="outlined"
                size="small"
                inputProps={{
                  style: { textAlign: "center" },
                  min: 1
                }}
                sx={{ width: "60px", mx: 1 }}
              />
              <IconButton
                size="small"
                onClick={() => setQuantity(quantity + 1)}
                sx={{ border: 1, borderColor: "divider" }}
              >
                <AddIcon fontSize="small" />
              </IconButton>
            </Box>

            {/* Total Price */}
            <Typography variant="h6" color="primary" sx={{ mt: 2 }}>
              Total: {formatPrice(calculateTotalPrice() / 100)}
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog}>Cancel</Button>
            <Button
              variant="contained"
              onClick={handleAddToCart}
              color="primary"
              startIcon={<AddIcon />}
            >
              Add to Cart
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Box>
  );
};

export default MenuItems;
