import React from "react";
import { useNavigate } from "react-router-dom";
import Box from '@mui/material/Box'
import Typography from "@mui/material/Typography";
import Button from '@mui/material/Button'

const NotFound = () => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        height: "100vh",
        gap: 2,
      }}
    >
      <Typography variant="h3" color="error">
        404 - Page Not Found
      </Typography>
      <Typography variant="body1">
        The page you are looking for does not exist or you do not have
        permission to access it.
      </Typography>
      <Button variant="contained" color="primary" onClick={() => navigate(-1)}>
        Go Back
      </Button>
    </Box>
  );
};

export default NotFound;
