import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import useMediaQuery from '@mui/material/useMediaQuery';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { useTheme } from "@mui/material/styles";
import MenuCategories from "./components/MenuCategories";
import MenuItems from "./components/MenuItems";
import Cart from "./components/Cart";
import TableSelect from "./components/TableSelect";
import PaymentDialog from "./components/PaymentDialog";
import SettingsIcon from "@mui/icons-material/Settings";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import LanguageIcon from "@mui/icons-material/Language";
import { KioskProvider, useKioskContext, LANGUAGES } from "./context/KioskContext";

// Wrap entire KioskPage in the KioskProvider
const KioskPageWrapper = () => {
  return (
    <KioskProvider>
      <KioskPageContent />
    </KioskProvider>
  );
};

// Main kiosk page content with context
const KioskPageContent = () => {
  const { posId } = useParams();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { 
    darkMode, 
    toggleDarkMode, 
    language, 
    changeLanguage, 
    availableLanguages,
    translate,
    paymentMethod,
    changePaymentMethod,
    formatPrice,
    orderType,
    changeOrderType,
    clearCart
  } = useKioskContext();
  
  const [menuData, setMenuData] = useState({
    availableMenu: [],
    outOfStockMenu: [],
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [cartItems, setCartItems] = useState([]);
  const [tableNumber, setTableNumber] = useState("");
  const [paymentDialogOpen, setPaymentDialogOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [discountDetails, setDiscountDetails] = useState({
    discount: 0,
    discountType: "None",
    discountValue: "",
    voucherCode: "",
    voucherDesc: "",  
  });
  const [customerInfo, setCustomerInfo] = useState({ customerId: null, orderId: null });
  const [categories, setCategories] = useState([]);

  // Settings menu state
  const [settingsAnchorEl, setSettingsAnchorEl] = useState(null);
  const [languageMenuAnchorEl, setLanguageMenuAnchorEl] = useState(null);
  const [paymentMethodMenuAnchorEl, setPaymentMethodMenuAnchorEl] = useState(null);
  
  // Fetch menu data from backend
  useEffect(() => {
    const fetchMenuData = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/v1/api/menu/fetchmenu?posId=${posId || '672312ec5b53fbbe37d4b0d5'}`
        );
        setMenuData(response.data);
        
        // Extract unique categories from the menu data
        const uniqueCategories = [...new Set(
          [...response.data.availableMenu, ...response.data.outOfStockMenu]
            .map(item => item.categoryName)
        )];
        
        setCategories(uniqueCategories);
        if (uniqueCategories.length > 0) {
          setSelectedCategory(uniqueCategories[0]);
        }
        
        setLoading(false);
      } catch (err) {
        console.error("Error fetching menu data:", err);
        setError("Failed to load menu data. Please try again later.");
        setLoading(false);
      }
    };

    if (posId) {
      fetchMenuData();
    }
  }, [posId]);

  // Handle adding item to cart
  const handleAddToCart = (item, options = [], quantity = 1) => {
    const newItem = {
      ...item,
      id: Date.now().toString(),
      quantity,
      selectedOptions: options,
      totalPrice: calculateItemPrice(item, options) * quantity
    };

    // alert("ireacheed here");
    
    setCartItems([...cartItems, newItem]);
  };

  // Calculate item price with selected options
  const calculateItemPrice = (item, selectedOptions) => {
    let price = item.basePrice;
    
    selectedOptions.forEach(option => {
      price += option.price || 0;
    });
    
    return price;
  };

  // Handle removing item from cart
  const handleRemoveFromCart = (itemId) => {
    setCartItems(cartItems.filter(item => item.id !== itemId));
  };

  // Handle updating item quantity in cart
  const handleUpdateQuantity = (itemId, newQuantity) => {
    if (newQuantity <= 0) {
      handleRemoveFromCart(itemId);
      return;
    }
    
    setCartItems(cartItems.map(item => {
      if (item.id === itemId) {
        const unitPrice = item.totalPrice / item.quantity;
        return { 
          ...item, 
          quantity: newQuantity,
          totalPrice: unitPrice * newQuantity
        };
      }
      return item;
    }));
  };

  // Handle category selection
  const handleCategorySelect = (category) => {
    setSelectedCategory(category);
  };

  // Handle table number selection
  const handleTableSelect = (table) => {
    setTableNumber(table);
  };
  
  // Handle order type change
  const handleOrderTypeChange = (type) => {
    changeOrderType(type);
  };

  // Handle opening payment dialog
  const handleCheckout = () => {
    // If table number is not set, open the table selection dialog first
    if (!tableNumber) {
      setSnackbarMessage(translate("Please select your table number before checkout", {
        en: "Please select your table number before checkout",
        zh: "结账前请选择桌号",
        th: "กรุณาเลือกหมายเลขโต๊ะก่อนชำระเงิน",
        tl: "Mangyaring pumili ng numero ng mesa bago mag-checkout"
      }));
      setSnackbarOpen(true);
      // Automatically open the table selection dialog
      document.querySelector('[data-testid="table-select"]')?.click();
      return;
    }
    
    setPaymentDialogOpen(true);
  };

  // Handle closing payment dialog
  const handleClosePayment = () => {
    setError(null)
    setCartItems([])
    setTableNumber("")
    setDiscountDetails({
      discount: 0,
      discountType: "None",
      discountValue: "",
      voucherCode: "",
      voucherDesc: "",  
    })
    setSettingsAnchorEl(null)
    setLanguageMenuAnchorEl(null)
    setPaymentMethodMenuAnchorEl(null)
    setPaymentDialogOpen(false);

    const currentUrl = location.pathname;
    // alert(currentUrl)
    const kioskUrl = currentUrl.replace("/order", "");
    navigate(kioskUrl);
  };

  // Handle opening settings menu
  const handleSettingsClick = (event) => {
    setSettingsAnchorEl(event.currentTarget);
  };

  // Handle closing settings menu
  const handleSettingsClose = () => {
    setSettingsAnchorEl(null);
  };

  // Handle opening language menu
  const handleLanguageMenuOpen = (event) => {
    setLanguageMenuAnchorEl(event.currentTarget);
    setSettingsAnchorEl(null);
  };

  // Handle closing language menu
  const handleLanguageMenuClose = () => {
    setLanguageMenuAnchorEl(null);
  };

  // Handle selecting a language
  const handleLanguageSelect = (langCode) => {
    changeLanguage(langCode);
    setLanguageMenuAnchorEl(null);
  };

  // Handle opening payment method menu
  const handlePaymentMethodMenuOpen = (event) => {
    setPaymentMethodMenuAnchorEl(event.currentTarget);
    setSettingsAnchorEl(null);
  };

  // Handle closing payment method menu
  const handlePaymentMethodMenuClose = () => {
    setPaymentMethodMenuAnchorEl(null);
  };

  // Handle selecting a payment method
  const handlePaymentMethodSelect = (method) => {
    changePaymentMethod(method);
    setPaymentMethodMenuAnchorEl(null);
  };

  const handleClearCart = () => {
    setCartItems([]); 
  };

  const handleCancel = () => {
    setPaymentDialogOpen(false);
  };

  // Filter menu items by selected category
  const filteredMenuItems = menuData.availableMenu.filter(
    item => item.categoryName === selectedCategory
  );

  if (loading) {
    return (
      <Container>
        <Box sx={{ textAlign: "center", my: 4 }}>
          <Typography variant="h5">
            {translate("Loading menu...", {
              en: "Loading menu...",
              zh: "正在加载菜单...",
              th: "กำลังโหลดเมนู...",
              tl: "Naglo-load ng menu..."
            })}
          </Typography>
        </Box>
      </Container>
    );
  }

  if (error) {
    return (
      <Container>
        <Box sx={{ textAlign: "center", my: 4 }}>
          <Typography variant="h5" color="error">
            {translate(error, {
              en: "Failed to load menu data. Please try again later.",
              zh: "加载菜单数据失败。请稍后再试。",
              th: "ไม่สามารถโหลดข้อมูลเมนูได้ โปรดลองอีกครั้งในภายหลัง",
              tl: "Hindi nagawang mag-load ng data ng menu. Pakisubukang muli mamaya."
            })}
          </Typography>
        </Box>
      </Container>
    );
  }

  return (
    <>
      {/* App Bar with settings */}
      <AppBar position="static" color="primary" elevation={2}>
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            {translate("Restaurant Ordering Kiosk", {
              en: "Restaurant Ordering Kiosk",
              zh: "餐厅点餐亭",
              th: "ตู้สั่งอาหาร",
              tl: "Restaurant Ordering Kiosk"
            })}
          </Typography>
          
          {/* Currently selected language */}
          <Typography variant="body1" sx={{ mr: 2 }}>
            {availableLanguages[language]?.flag}
          </Typography>
          
          {/* Settings button */}
          <IconButton 
            color="inherit" 
            onClick={handleSettingsClick}
            aria-controls="settings-menu"
            aria-haspopup="true"
          >
            <SettingsIcon />
          </IconButton>
          
          {/* Settings menu */}
          <Menu
            id="settings-menu"
            anchorEl={settingsAnchorEl}
            open={Boolean(settingsAnchorEl)}
            onClose={handleSettingsClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <MenuItem onClick={toggleDarkMode}>
              {darkMode ? <Brightness7Icon sx={{ mr: 1 }} /> : <Brightness4Icon sx={{ mr: 1 }} />}
              {darkMode ? translate("Light Mode", {
                en: "Light Mode",
                zh: "亮色模式",
                th: "โหมดสว่าง",
                tl: "Light Mode"
              }) : translate("Dark Mode", {
                en: "Dark Mode",
                zh: "暗色模式",
                th: "โหมดมืด",
                tl: "Dark Mode"
              })}
            </MenuItem>
            <MenuItem onClick={handleLanguageMenuOpen}>
              <LanguageIcon sx={{ mr: 1 }} />
              {translate("Language", {
                en: "Language",
                zh: "语言",
                th: "ภาษา",
                tl: "Wika"
              })}
            </MenuItem>
            <MenuItem onClick={handlePaymentMethodMenuOpen}>
              {translate("Payment Method", {
                en: "Payment Method",
                zh: "支付方式",
                th: "วิธีการชำระเงิน",
                tl: "Paraan ng Pagbabayad"
              })}
            </MenuItem>
          </Menu>
          
          {/* Language selection menu */}
          <Menu
            id="language-menu"
            anchorEl={languageMenuAnchorEl}
            open={Boolean(languageMenuAnchorEl)}
            onClose={handleLanguageMenuClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            {Object.entries(availableLanguages).map(([code, lang]) => (
              <MenuItem 
                key={code} 
                onClick={() => handleLanguageSelect(code)}
                selected={language === code}
              >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Typography sx={{ mr: 1 }}>{lang.flag}</Typography>
                  <Typography>{lang.name}</Typography>
                </Box>
              </MenuItem>
            ))}
          </Menu>
          
          {/* Payment method selection menu */}
          <Menu
            id="payment-method-menu"
            anchorEl={paymentMethodMenuAnchorEl}
            open={Boolean(paymentMethodMenuAnchorEl)}
            onClose={handlePaymentMethodMenuClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <MenuItem 
              onClick={() => handlePaymentMethodSelect('cash')}
              selected={paymentMethod === 'cash'}
            >
              {translate("Cash", {
                en: "Cash",
                zh: "现金",
                th: "เงินสด",
                tl: "Cash"
              })}
            </MenuItem>
            <MenuItem 
              onClick={() => handlePaymentMethodSelect('credit_card')}
              selected={paymentMethod === 'credit_card'}
            >
              {translate("Credit Card", {
                en: "Credit Card",
                zh: "信用卡",
                th: "บัตรเครดิต",
                tl: "Credit Card"
              })}
            </MenuItem>
            <MenuItem 
              onClick={() => handlePaymentMethodSelect('gcash')}
              selected={paymentMethod === 'gcash'}
            >
              GCash
            </MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
      
      <Container maxWidth="xl" sx={{ py: 2 }}>
        <TableSelect 
          tableNumber={tableNumber} 
          onTableSelect={handleTableSelect}
          translate={translate}
        />
        
        <Grid container spacing={2}>
          {/* Mobile view - Stack Categories on top */}
          {isMobile && (
            <Grid item xs={12}>
              <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
                <MenuCategories 
                  categories={categories}
                  selectedCategory={selectedCategory}
                  onCategorySelect={handleCategorySelect}
                  translate={translate}
                />
              </Paper>
            </Grid>
          )}
          
          {/* Menu Items */}
          <Grid item xs={12} md={7}>
            <Paper elevation={3} sx={{ p: 2, height: '100%' }}>
              <MenuItems 
                menuItems={filteredMenuItems}
                onAddToCart={handleAddToCart}
                translate={translate}
                language={language}
                formatPrice={formatPrice}
              />
            </Paper>
          </Grid>
          
          {/* Cart + Categories (Desktop) */}
          <Grid item xs={12} md={5}>
            <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
              {/* Categories - Only show on desktop on the right */}
              {!isMobile && (
                <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
                  <MenuCategories 
                    categories={categories}
                    selectedCategory={selectedCategory}
                    onCategorySelect={handleCategorySelect}
                    translate={translate}
                  />
                </Paper>
              )}
              
              {/* Cart */}
              <Paper elevation={3} sx={{ p: 2, flexGrow: 1 }}>
              <Cart
                  cartItems={cartItems}
                  onUpdateQuantity={handleUpdateQuantity}
                  onRemoveItem={handleRemoveFromCart}
                  onCheckout={(details) => { 
                    setDiscountDetails(details);
                    setCustomerInfo({
                      customerId: details.customerId,
                      orderId: details.orderId,
                    });
                    handleCheckout()

                  }}
                  translate={translate}
                  formatPrice={formatPrice}
                  paymentMethod={paymentMethod}
                  posId={posId}
                />
              </Paper>
            </Box>
          </Grid>
        </Grid>
        
        {/* Payment Dialog */}
        <PaymentDialog 
          open={paymentDialogOpen} 
          onClose={handleClosePayment}
          onCancel={handleCancel}
          cartItems={cartItems}
          tableNumber={tableNumber}
          posId={posId}
          discount={discountDetails.discount}
          discountType={discountDetails.discountType}
          discountValue={discountDetails.discountValue}
          voucherCode={discountDetails.voucherCode}
          voucherDesc={discountDetails.voucherDesc}
          finalTotal={discountDetails.finalTotal}
          paymentMethod={paymentMethod}
          translate={translate}
          onClearCart={handleClearCart}
          customerId={customerInfo.customerId}
          orderId={customerInfo.orderId}
        />
      </Container>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity="warning"
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

// Export the wrapped component
const KioskPage = () => <KioskPageWrapper />;


export default KioskPage;
