import React from "react";
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'

const BillHistoryDetailed = ({ history }) => {
  console.log("history: ", history);
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Date/Time</TableCell>
          <TableCell>Action</TableCell>
          <TableCell>Remarks</TableCell>
          <TableCell>User</TableCell>
          <TableCell>Bill Type</TableCell>
          <TableCell>Box Type</TableCell>
          <TableCell>Denomination</TableCell>
          <TableCell>Qty Before</TableCell>
          <TableCell>Qty After</TableCell>
          <TableCell>Total Before</TableCell>
          <TableCell>Total After</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {history.map((record, index) => (
          <TableRow
            key={index}
            sx={{
              "&:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.1)",
              },
            }}
          >
            <TableCell>{new Date(record.timestamp).toLocaleString()}</TableCell>
            <TableCell>{record.action}</TableCell>
            <TableCell>{record.remarks || " "}</TableCell>
            <TableCell>{record.name || " "}</TableCell>
            <TableCell>{record.billType}</TableCell>
            <TableCell>{record.boxType || " "}</TableCell>
            <TableCell>₱{record.denomination}</TableCell>
            <TableCell>{record.quantityBefore}</TableCell>
            <TableCell>{record.quantityAfter}</TableCell>
            <TableCell>₱{record.totalValueBefore.toFixed(2)}</TableCell>
            <TableCell>₱{record.totalValueAfter.toFixed(2)}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default BillHistoryDetailed;
