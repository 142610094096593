// ReceiptActionModal.js
import React from "react";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

const ReceiptActionModal = ({ open, handleClose, handleAction }) => {
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Receipt Options</DialogTitle>
      <DialogContent>
        <Typography>Would you like to view or print the receipt?</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleAction("view")}>Print</Button>
        <Button onClick={() => handleAction("print")}>Download</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ReceiptActionModal;
