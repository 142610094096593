import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import useMediaQuery from '@mui/material/useMediaQuery'
import DescriptionIcon from "@mui/icons-material/Description";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { useTheme } from "@mui/material/styles";

export const exportToExcel = (data, type) => {
  let worksheet;
  let sheetName;
  let fileName;

  if (type === "history") {
    worksheet = XLSX.utils.json_to_sheet(
      data.map((record) => ({
        "Customer Name": record.customerId?.name || "N/A",
        "Order Date": new Date(record.orderDate).toLocaleDateString(),
        "Order ID": record.orderId,
        "Discount Type": record.customerId?.discountType || "N/A",
        "Discount ID": record.discountId || "N/A",
        "Discount ID Image": record.discountIDImg || "No Image",
        "Discount Selfie": record.discountSelfieImg || "No Image",
      }))
    );
    sheetName = "Customer History";
    fileName = "Customer_History.xlsx";
  } else {
    worksheet = XLSX.utils.json_to_sheet(
      data.map((record) => ({
        "Customer Name": record.name || "N/A",
        "Discount Type": record.discountType || "N/A",
        "Discount ID": record.discountId || "N/A",
        City: record.city || "N/A",
        "Date Issued": record.dateIssued
          ? new Date(record.dateIssued).toLocaleDateString()
          : "N/A",
      }))
    );
    sheetName = "Customer Details";
    fileName = "Customer_Details.xlsx";
  }

  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
  XLSX.writeFile(workbook, fileName);
};

export const exportToPDF = (data, type) => {
  const doc = new jsPDF();
  let tableColumn;
  let tableRows;

  if (type === "history") {
    doc.text("Customer Order History", 14, 10);
    tableColumn = [
      "Customer Name",
      "Order Date",
      "Order ID",
      "Discount Type",
      "Discount ID",
      "Discount ID Image",
      "Discount Selfie",
    ];
    tableRows = data.map((record) => [
      record.customerId?.name || "N/A",
      new Date(record.orderDate).toLocaleDateString(),
      record.orderId,
      record.customerId?.discountType || "N/A",
      record.discountId || "N/A",
      record.discountIDImg ? "[Image]" : "No Image",
      record.discountSelfieImg ? "[Image]" : "No Image",
    ]);
  } else {
    doc.text("Customer Details", 14, 10);
    tableColumn = [
      "Customer Name",
      "Discount Type",
      "Discount ID",
      "City",
      "Date Issued",
    ];
    tableRows = data.map((record) => [
      record.name || "N/A",
      record.discountType || "N/A",
      record.discountId || "N/A",
      record.city || "N/A",
      record.dateIssued
        ? new Date(record.dateIssued).toLocaleDateString()
        : "N/A",
    ]);
  }

  doc.autoTable({
    head: [tableColumn],
    body: tableRows,
  });

  doc.save(
    type === "history" ? "Customer_History.pdf" : "Customer_Details.pdf"
  );
};

export const ExportButtons = ({ data, type }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: isMobile ? "column" : "row",
        gap: 2,
        alignItems: "center",
      }}
    >
      <Button
        variant="contained"
        color="primary"
        onClick={() => exportToExcel(data, type)}
        startIcon={<DescriptionIcon />}
        sx={{ width: isMobile ? "100%" : "auto" }}
      >
        {isMobile ? "Export CSV" : "Export to CSV"}
      </Button>
      <Button
        variant="contained"
        color="secondary"
        onClick={() => exportToPDF(data, type)}
        startIcon={<PictureAsPdfIcon />}
        sx={{ width: isMobile ? "100%" : "auto" }}
      >
        {isMobile ? "Export PDF" : "Export to PDF"}
      </Button>
    </Box>
  );
};
