import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";

import BarChart from "@mui/icons-material/BarChart";
import Inventory from "@mui/icons-material/Inventory";
import Category from "@mui/icons-material/Category";
import Lock from "@mui/icons-material/Lock";
import Person from "@mui/icons-material/Person";
import AccountCircle from "@mui/icons-material/AccountCircle";
import Folder from "@mui/icons-material/Folder";
import FiberManualRecord from "@mui/icons-material/FiberManualRecord";
import ReceiptLong from "@mui/icons-material/ReceiptLong";
import Visibility from "@mui/icons-material/Visibility";
import CardGiftcard from "@mui/icons-material/CardGiftcard";
import { getAuthCredentials } from "../../utils/auth";
import axios from "axios";

import { useTheme } from "@mui/material/styles";
import SecureLS from "secure-ls";
import Swal from "sweetalert2";
import appConfig from "../../config/appConfig";

const ls = new SecureLS({ encodingType: "aes" });

const modulesList = [
  { title: "Sales Reports", icon: BarChart, path: "/sales" },
  { title: "Inventory Management", icon: Inventory, path: "/inventory" },
  { title: "Menu Items Creation", icon: Category, path: "/menucreation" },
  { title: "Access", icon: Lock, path: "/access-management" },
  { title: "Vouchers", icon: CardGiftcard, path: "/vouchers" },
  { title: "Reports", icon: Folder, path: "/reports" },
  {
    title: "Receipt Configuration",
    icon: ReceiptLong,
    path: "/receipt-config",
  },
  {
    title: "Views",
    icon: Visibility,
    path: "/views",
  },
  { title: "Account Management", icon: Person, path: "/account-management" },
];

const DashboardCard = ({ title, icon: Icon, onClick }) => {
  const theme = useTheme();

  return (
    <Button
      variant="contained"
      onClick={onClick}
      sx={{
        width: "100%",
        padding: 4,
        textAlign: "center",
        color: "#fff",
        backgroundColor: theme.palette.primary.main,
        "&:hover": {
          backgroundColor: theme.palette.primary.dark,
        },
        height: "100%",
      }}
    >
      <Paper
        elevation={3}
        sx={{
          width: "100%",
          padding: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
        }}
      >
        <Icon sx={{ fontSize: 60, marginBottom: 2 }} />
        <Typography variant="h6">{title}</Typography>
      </Paper>
    </Button>
  );
};

const Dashboard = () => {
  const navigate = useNavigate();
  const user = ls.get("user");
  const companyName = user?.companyname || "POS Client";
  const userRole = user?.role || "Guest";
  const userModules = user?.modules || [];
  const [netStatus, setNetStatus] = useState("Online");
  const fetchDataCalled = useRef(false);

  const handleLogout = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You will be logged out and redirected to the login page.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Logout!",
    }).then((result) => {
      if (result.isConfirmed) {
        ls.clear();
        Swal.fire({
          icon: "success",
          title: "Logged Out",
          text: "You have been logged out successfully.",
          confirmButtonText: "OK",
        }).then(() => {
          window.location.href = "/login";
        });
      }
    });
  };

  const formatModuleName = (moduleName) => {
    return moduleName.replace(/([a-z])([A-Z])/g, "$1 $2"); // Add spaces
  };

  const availableModules =
    userRole === "Superadmin"
      ? modulesList
      : modulesList.filter(
          (module) =>
            userModules.includes(module.title.replace(/ /g, "")) ||
            userModules.includes(module.title.replace(/\s/g, "")) ||
            userModules.includes(formatModuleName(module.title)) ||
            formatModuleName(module.title) === "Sales Reports" ||
            module.title === "Account Management"
        );

  useEffect(() => {
    const checkInternetSpeed = () => {
      const startTime = new Date().getTime();
      fetch("https://www.google.com/favicon.ico", { mode: "no-cors" })
        .then(() => {
          const duration = new Date().getTime() - startTime;
          if (duration > 1000) {
            setNetStatus("Slow Net");
          } else {
            setNetStatus("Online");
          }
        })
        .catch(() => setNetStatus("Local"));
    };

    const interval = setInterval(checkInternetSpeed, 5000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (fetchDataCalled.current) return; // Prevent duplicate execution
    fetchDataCalled.current = true;

    const fetchData = async () => {
      const { user, token, posId } = await getAuthCredentials(navigate);

      if (token) {
        const response = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/v1/api/receipt/validate-zreadings`,
          { posId: posId },
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );

        if (response.status === 201) {
          const days = response.data.missingDates.join(", ");
          alert(`Z-Readings missing for the following day(s):\n${days}`);
          window.location.replace("/reports");
        }
      }
    };

    fetchData();
  }, []);

  const netStatusIndicator = {
    "Local": { color: "red", label: "Local" },
    "Slow Net": { color: "orange", label: "Slow Net" },
    Online: { color: "green", label: "Online" },
  };

  return (
    <Box sx={{ flexGrow: 1, padding: 3 }}>
      {/* <Box 
        sx={{
          position: "absolute",
          top: 10,
          right: { xs: 10, sm: "auto" },
          left: { xs: "auto", sm: 10 },
          display: "flex",
          alignItems: "center",
          opacity: 0.75,
        }}
      >
        <FiberManualRecord
          sx={{
            fontSize: 14,
            color: netStatusIndicator[netStatus].color,
            marginRight: 1,
          }}
        />
        <Typography variant="body2" color="textSecondary">
          {netStatusIndicator[netStatus].label}
        </Typography>
      </Box> */}
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection={{ xs: "column", sm: "row" }}
        mb={4}
        sx={{ gap: 2 }}
      >
        <AccountCircle sx={{ fontSize: 100 }} />
        <Typography variant="h4" textAlign="center">
          {companyName}'s Dashboard
        </Typography>
      </Box>
      <Box
        sx={{
          position: { xs: "relative", sm: "absolute" },
          top: { xs: "auto", sm: 10 },
          right: { xs: "auto", sm: 10 },
          mt: { xs: 2, sm: 0 },
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Button variant="contained" color="secondary" onClick={handleLogout}>
          Logout
        </Button>
      </Box>

      <Grid container spacing={3} justifyContent="center" alignItems="stretch">
        {availableModules.map((module) => (
          <Grid
            key={module.title}
            item
            xs={12}
            sm={6}
            md={4}
            sx={{ display: "flex" }}
          >
            <DashboardCard
              title={module.title}
              icon={module.icon}
              onClick={() => navigate(module.path)}
            />
          </Grid>
        ))}
      </Grid>
      <Box
        sx={{
          position: { xs: "absolute", sm: "absolute" },
          top: { xs: 10, sm: "auto" },
          bottom: { xs: "auto", sm: 10 },
          left: 10,
          opacity: 0.75,
        }}
      >
        {/* <Typography
          variant="body2"
          color="textSecondary"
          textAlign={{ xs: "left", sm: "left" }}
        >
          {`${appConfig.appName} Version ${appConfig.appVersion}`}
        </Typography> */}
      </Box>
    </Box>
  );
};

export default Dashboard;
