import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Paper from '@mui/material/Paper';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import VoucherHero from "./VoucherHero";
import VoucherFunction from "./VoucherFunction";
import VoucherQR from "./VoucherQR";
import { Edit, Delete, Add, QrCode2 } from "@mui/icons-material";
import { Pagination, useMediaQuery, useTheme } from "@mui/material";
import { getAuthCredentials } from "../../utils/auth";
import { ExportButtons } from "./VoucherExport";
import Swal from "sweetalert2";

const Voucher = () => {
  const [vouchers, setVouchers] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedVoucher, setSelectedVoucher] = useState(null);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isAddOpen, setIsAddOpen] = useState(false);
  const [isQROpen, setIsQROpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [limit, setLimit] = useState(10);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { token } = getAuthCredentials();
  const navigate = useNavigate();

  useEffect(() => {
    fetchVouchers();
  }, [searchQuery, page, limit]);

  const fetchVouchers = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/v1/api/vouchers/fetch-vouchers`,
        {
          params: {
            page,
            limit,
            search: searchQuery || undefined,
          },
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      const { vouchers, totalPages } = response.data;
      setVouchers(vouchers);
      setTotalPages(totalPages);
    } catch (error) {
      console.error("Error fetching vouchers:", error);
      setVouchers([]);
    } finally {
      setLoading(false);
    }
  };

  const handleEditVoucher = (voucher) => {
    setSelectedVoucher(voucher);
    setIsEditOpen(true);
  };

  const handleCloseEditModal = () => {
    setIsEditOpen(false);
    setSelectedVoucher(null);
  };

  const handleOpenAddModal = () => {
    setSelectedVoucher(null);
    setIsAddOpen(true);
  };

  const handleCloseAddModal = () => {
    setIsAddOpen(false);
  };

  const handleDelete = async (voucherId) => {
    try {
      const confirmResult = await Swal.fire({
        title: "Are you sure?",
        text: "This voucher will be deleted permanently!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, delete it!",
      });

      if (confirmResult.isConfirmed) {
        await axios.delete(
          `${process.env.REACT_APP_BACKEND_URL}/v1/api/vouchers/delete-voucher/${voucherId}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );

        Swal.fire("Deleted!", "The voucher has been deleted.", "success");
        fetchVouchers();
      }
    } catch (error) {
      console.error("Error deleting voucher:", error);
      Swal.fire("Error!", "Failed to delete the voucher.", "error");
    }
  };

  const handleViewQR = (voucher) => {
    setSelectedVoucher(voucher);
    setIsQROpen(true);
  };

  return (
    <Box sx={{ padding: 3 }}>
      <VoucherHero activeTab="details" />

      <Box
        sx={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          gap: 2,
          justifyContent: isMobile ? "flex-start" : "center",
          marginBottom: 3,
          alignItems: isMobile ? "stretch" : "center",
        }}
      >
        <TextField
          label="Search Voucher"
          value={searchQuery}
          onChange={(e) => {
            setSearchQuery(e.target.value);
            setPage(1);
          }}
          fullWidth
          sx={{ maxWidth: isMobile ? "100%" : 250 }}
        />

        <Button
          variant="contained"
          startIcon={<Add />}
          onClick={handleOpenAddModal}
          sx={{
            width: isMobile ? "100%" : "auto",
            backgroundColor: "#4CAF50",
            "&:hover": {
              backgroundColor: "#388E3C",
            },
          }}
        >
          Add Voucher
        </Button>

        <ExportButtons data={vouchers} />
      </Box>

      <Paper elevation={3} sx={{ padding: 2, overflowX: "auto" }}>
        {loading ? (
          <Box sx={{ display: "flex", justifyContent: "center", padding: 3 }}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>Minimum Purchase</TableCell>
                  <TableCell>Amount Deduction</TableCell>
                  <TableCell>Max Deduction</TableCell>
                  <TableCell>Code</TableCell>
                  <TableCell>Times Used</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {vouchers.map((voucher) => (
                  <TableRow
                    key={voucher._id}
                    sx={{
                      cursor: "pointer",
                      "&:hover": {
                        backgroundColor: "rgba(0, 0, 0, 0.1)",
                      },
                    }}
                  >
                    <TableCell>{voucher.name}</TableCell>
                    <TableCell>{voucher.voucherType}</TableCell>
                    <TableCell>{voucher.minimumDeduct}</TableCell>
                    <TableCell>
                      {voucher.amountDeduct}
                      {voucher.voucherType === "Percentage Deduction"
                        ? " %"
                        : ""}
                    </TableCell>
                    <TableCell>{voucher.maxDeduct || "N/A"}</TableCell>
                    <TableCell>{voucher.voucherCode}</TableCell>
                    <TableCell>{voucher.numberOfTimesUsed}</TableCell>
                    <TableCell>
                      <IconButton
                        color="primary"
                        onClick={() => handleEditVoucher(voucher)}
                      >
                        <Edit />
                      </IconButton>
                      <IconButton
                        color="error"
                        onClick={() => handleDelete(voucher._id)}
                      >
                        <Delete />
                      </IconButton>
                      <IconButton
                        color="secondary"
                        onClick={() => handleViewQR(voucher)}
                      >
                        <QrCode2 />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>

            <Box
              sx={{
                display: "flex",
                flexDirection: isMobile ? "column" : "row",
                justifyContent: "center",
                alignItems: "center",
                marginTop: 3,
                gap: isMobile ? 1 : 3,
              }}
            >
              <FormControl
                sx={{
                  maxWidth: isMobile ? "100%" : 80,
                  "& .MuiInputBase-root": {
                    height: 30,
                    fontSize: "0.85rem",
                  },
                  "& .MuiSelect-select": {
                    padding: "5px 10px",
                  },
                }}
                fullWidth
              >
                <InputLabel sx={{ fontSize: "0.85rem" }}>Rows</InputLabel>
                <Select
                  value={limit}
                  onChange={(e) => {
                    setLimit(parseInt(e.target.value, 10));
                    setPage(1);
                  }}
                >
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={25}>25</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                  <MenuItem value={100}>100</MenuItem>
                </Select>
              </FormControl>
              <Pagination
                count={totalPages}
                page={page}
                onChange={(event, value) => setPage(value)}
                color="primary"
                showFirstButton
                showLastButton
              />
            </Box>
            {selectedVoucher && (
              <VoucherFunction
                open={isEditOpen}
                handleClose={handleCloseEditModal}
                voucher={selectedVoucher}
                refreshData={fetchVouchers}
                setIsEditOpen={setIsEditOpen}
                setIsAddOpen={setIsAddOpen}
              />
            )}
            <VoucherFunction
              open={isAddOpen}
              handleClose={handleCloseAddModal}
              voucher={null}
              refreshData={fetchVouchers}
              setIsEditOpen={setIsEditOpen}
              setIsAddOpen={setIsAddOpen}
            />
            <VoucherQR
              open={isQROpen}
              voucher={selectedVoucher}
              onClose={() => setIsQROpen(false)}
            />
          </>
        )}
      </Paper>
    </Box>
  );
};

export default Voucher;
