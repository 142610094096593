import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const BackButtonPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate(-1);
  }, [navigate]);

  return (
    <div>
      <h1>Redirecting...</h1>
      <p>If you are not redirected automatically, <a href="#" onClick={() => navigate(-1)}>click here</a>.</p>
    </div>
  );
};

export default BackButtonPage;
