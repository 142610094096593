import React, { useState } from "react";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Tooltip from '@mui/material/Tooltip';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';;
import ShuffleIcon from "@mui/icons-material/Shuffle";
import axios from "axios";
import Swal from "sweetalert2";
import { getAuthCredentials } from "../../utils/auth";

const VoucherFunction = ({
  open,
  handleClose,
  voucher,
  refreshData,
  setIsEditOpen,
  setIsAddOpen,
}) => {
  const { token } = getAuthCredentials();
  const [loading, setLoading] = useState(false);
  const [updatedVoucher, setUpdatedVoucher] = useState(
    voucher || {
      name: "",
      voucherType: "Full Deduction",
      minimumDeduct: "",
      amountDeduct: "",
      numberOfTimesUsed: 0,
    }
  );

  const handleChange = (e) => {
    setUpdatedVoucher({
      ...updatedVoucher,
      [e.target.name]: e.target.value,
    });
  };

  const handleSave = async () => {
    setLoading(true);

    try {
      if (voucher) {
        await axios.patch(
          `${process.env.REACT_APP_BACKEND_URL}/v1/api/vouchers/edit-voucher/${updatedVoucher._id}`,
          updatedVoucher,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        handleClose();
        Swal.fire("Success!", "Voucher details updated.", "success").then(
          () => {
            refreshData();
          }
        );
      } else {
        await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/v1/api/vouchers/create-voucher`,
          updatedVoucher,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        handleClose();
        Swal.fire("Success!", "New voucher created.", "success").then(() => {
          refreshData();
        });
      }
    } catch (error) {
      console.error("Error saving voucher:", error);
      const errorMessage =
        error.response?.data?.message || "Failed to save voucher.";

      handleClose();
      Swal.fire("Error!", errorMessage, "error").then(() => {
        setTimeout(() => {
          if (voucher) {
            setIsEditOpen(true);
          } else {
            setIsAddOpen(true);
          }
        }, 200);
      });
    } finally {
      setLoading(false);
    }
  };

  const handleGenerateCode = () => {
    const length = 15;
    const chars =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let randomCode = "";
    for (let i = 0; i < length; i++) {
      randomCode += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    setUpdatedVoucher((prev) => ({ ...prev, voucherCode: randomCode }));
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle>{voucher ? "Edit Voucher" : "Add Voucher"}</DialogTitle>
      <DialogContent>
        <TextField
          margin="dense"
          label="Name"
          name="name"
          fullWidth
          value={updatedVoucher?.name || ""}
          onChange={handleChange}
        />

        <TextField
          margin="dense"
          label="Voucher Code"
          name="voucherCode"
          fullWidth
          inputProps={{ maxLength: 15, pattern: "^[a-zA-Z0-9]*$" }}
          value={updatedVoucher?.voucherCode || ""}
          onChange={handleChange}
          helperText="Accepts 15 alphanumeric only"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Tooltip title="Generate Code">
                  <IconButton onClick={handleGenerateCode} edge="end">
                    <ShuffleIcon />
                  </IconButton>
                </Tooltip>
              </InputAdornment>
            ),
          }}
        />

        <FormControl margin="dense" fullWidth>
          <InputLabel>Type</InputLabel>
          <Select
            name="voucherType"
            value={updatedVoucher?.voucherType || "Full Deduction"}
            onChange={handleChange}
          >
            <MenuItem value="Full Deduction">Full Deduction</MenuItem>
            <MenuItem value="Percentage Deduction">
              Percentage Deduction
            </MenuItem>
          </Select>
        </FormControl>

        <TextField
          margin="dense"
          label="Minimum Purchase"
          name="minimumDeduct"
          fullWidth
          type="number"
          value={updatedVoucher?.minimumDeduct || ""}
          onChange={handleChange}
        />

        <Tooltip
          title={
            updatedVoucher.voucherType === "Full Deduction"
              ? "Enter the exact amount to deduct"
              : "Enter percentage to deduct"
          }
          arrow
        >
          <TextField
            margin="dense"
            label="Amount Deduct"
            name="amountDeduct"
            fullWidth
            type="number"
            value={updatedVoucher?.amountDeduct || ""}
            onChange={handleChange}
            InputProps={{
              endAdornment:
                updatedVoucher.voucherType === "Percentage Deduction" ? (
                  <InputAdornment position="end">%</InputAdornment>
                ) : null,
            }}
          />
        </Tooltip>
        <TextField
          margin="dense"
          label="Max Deduct"
          name="maxDeduct"
          fullWidth
          type="number"
          value={updatedVoucher?.maxDeduct || ""}
          onChange={handleChange}
          disabled={updatedVoucher.voucherType !== "Percentage Deduction"}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Cancel
        </Button>
        <Button
          onClick={handleSave}
          color="primary"
          disabled={
            loading ||
            !updatedVoucher.name?.trim() ||
            !updatedVoucher.voucherCode?.trim() ||
            (!updatedVoucher.minimumDeduct &&
              updatedVoucher.minimumDeduct !== 0) ||
            (!updatedVoucher.amountDeduct &&
              updatedVoucher.amountDeduct !== 0) ||
            (updatedVoucher.voucherType === "Percentage Deduction" &&
              !updatedVoucher.maxDeduct &&
              updatedVoucher.maxDeduct !== 0)
          }
        >
          {loading ? <CircularProgress size={24} color="inherit" /> : "Save"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default VoucherFunction;
