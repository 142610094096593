import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import TextField from "@mui/material/TextField";
import axios from "axios";
import { getAuthCredentials } from "../../../utils/auth";

const SystemTrailModal = ({ open, onClose }) => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const { token } = getAuthCredentials();

  const handleLogReportSubmit = async (format) => {
    if (!startDate || !endDate) {
      alert("Please select a full date range.");
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/v1/api/reports/log-report`,
        { startDate, endDate, format },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          responseType: "blob",
        }
      );

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const ext = format === "xlsx" ? "xlsx" : "txt";
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `log_report_${startDate}_to_${endDate}.${ext}`
      );
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      alert("Log report generated successfully.");
    } catch (error) {
      console.error("Error generating log report:", error);
      alert("Error generating log report.");
    }

    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Generate Audit Trail Report</DialogTitle>
      <DialogContent>
        <TextField
          label="Start Date"
          type="date"
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
          fullWidth
          sx={{ marginTop: 1 }}
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          label="End Date"
          type="date"
          value={endDate}
          onChange={(e) => setEndDate(e.target.value)}
          fullWidth
          sx={{ marginTop: 2 }}
          InputLabelProps={{ shrink: true }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          variant="contained"
          onClick={() => handleLogReportSubmit("txt")}
        >
          Generate TXT
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => handleLogReportSubmit("xlsx")}
        >
          Generate CSV
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SystemTrailModal;
