import React, { useState, useEffect } from "react";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Pagination from '@mui/material/Pagination';
import Swal from "sweetalert2";
import dayjs from "dayjs";
import axios from "axios";
import InventoryHero from "./InventoryHero";
import { getAuthCredentials } from "../../utils/auth";

const InventoryArchive = () => {
  const [archivedInventory, setArchivedInventory] = useState([]);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("expiryDate");
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const { token } = getAuthCredentials();

  useEffect(() => {
    const fetchArchivedInventory = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/v1/api/inventory/archive`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: { page, limit: 10, search: searchTerm },
          }
        );

        const { archivedInventory, totalPages } = response.data;
        const flattenedData = archivedInventory
          .filter((archiveDoc) => archiveDoc.inventoryArchive?.length > 0)
          .flatMap((archiveDoc) => {
            if (archiveDoc.type === "Inventory Item") {
              return archiveDoc.inventoryArchive.map((item) => {
                const totalPurchase = (item.instances || []).reduce(
                  (sum, inst) => sum + (inst.purchaseAmount || 0),
                  0
                );
                const totalCurrent = (item.instances || []).reduce(
                  (sum, inst) => sum + (inst.currentAmount || 0),
                  0
                );
                const totalPrice = (item.instances || []).reduce(
                  (sum, inst) => sum + (inst.inventoryPrice || 0),
                  0
                );

                let earliestDelivery = null;
                let latestExpiry = null;

                if ((item.instances || []).length > 0) {
                  earliestDelivery = new Date(item.instances[0].deliveryDate);
                  latestExpiry = new Date(item.instances[0].expiryDate);

                  item.instances.forEach((inst) => {
                    const dDate = new Date(inst.deliveryDate);
                    const eDate = new Date(inst.expiryDate);

                    if (dDate < earliestDelivery) earliestDelivery = dDate;
                    if (eDate > latestExpiry) latestExpiry = eDate;
                  });
                }

                return {
                  parentArchiveId: archiveDoc._id,
                  archivedDate: new Date(archiveDoc.createdAt),
                  type: archiveDoc.type || "Unknown",
                  inventoryId: item.inventoryId,
                  inventoryName: item.inventoryName,
                  instanceId: "",
                  purchaseAmount: totalPurchase,
                  currentAmount: totalCurrent,
                  inventoryPrice: totalPrice,
                  deliveryDate: earliestDelivery,
                  expiryDate: latestExpiry,
                };
              });
            } else {
              return archiveDoc.inventoryArchive.flatMap((item) =>
                (item.instances || []).map((inst) => ({
                  parentArchiveId: archiveDoc._id,
                  archivedDate: new Date(archiveDoc.createdAt),
                  type: archiveDoc.type || "Unknown",
                  inventoryId: item.inventoryId,
                  inventoryName: item.inventoryName,
                  instanceId: inst._id.toString(),
                  purchaseAmount: inst.purchaseAmount,
                  currentAmount: inst.currentAmount,
                  inventoryPrice: inst.inventoryPrice,
                  deliveryDate: inst.deliveryDate,
                  expiryDate: inst.expiryDate,
                }))
              );
            }
          })
          .sort((a, b) => new Date(b.archivedDate) - new Date(a.archivedDate));

        setArchivedInventory(flattenedData);
        setTotalPages(totalPages);
      } catch (error) {
        if (error.response && error.response.status === 404) {
          setArchivedInventory([]);
          Swal.fire({
            icon: "info",
            title: "No Archived Items",
            text: error.response.data.message,
          });
        } else {
          console.error("Error fetching archived inventory:", error);
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "Failed to fetch archived inventory.",
          });
        }
      }
    };

    fetchArchivedInventory();
  }, [token, page, searchTerm]);

  const handleSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);

    setArchivedInventory((prevInventory) =>
      [...prevInventory].sort((a, b) =>
        isAsc
          ? new Date(a[property]) - new Date(b[property])
          : new Date(b[property]) - new Date(a[property])
      )
    );
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setPage(1);
  };

  const handleSearchChange = (event) => {
    setSearchInput(event.target.value);
  };

  const handleSearchSubmit = () => {
    setSearchTerm(searchInput);
    setPage(1);
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const descendingComparator = (a, b, orderBy) => {
    if (orderBy === "createdAt") {
      return new Date(b.createdAt) - new Date(a.createdAt);
    }
    return 0;
  };

  const handleRestore = async (item, instanceId, type) => {
    console.log("item:", item);
    try {
      await axios.put(
        `${process.env.REACT_APP_BACKEND_URL}/v1/api/inventory/unarchive`,
        {
          inventoryId: item.inventoryId,
          instanceId,
          type,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      Swal.fire({
        icon: "success",
        title: "Success!",
        text: `Instance from ${item.inventoryName} unarchived successfully.`,
      });

      setArchivedInventory((prev) =>
        prev.filter((row) => row.instanceId !== instanceId)
      );
    } catch (error) {
      console.error("Error unarchiving instance:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: `Failed to unarchive the instance from ${item.inventoryName}. Please try again.`,
      });
    }
  };

  return (
    <Box sx={{ padding: 3 }}>
      <InventoryHero />
      <Typography variant="h5" gutterBottom>
        Archived Inventory
      </Typography>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <TextField
          label="Search Inventory"
          variant="outlined"
          size="small"
          value={searchInput}
          onChange={handleSearchChange}
        />
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={handleSearchSubmit}
        >
          Search
        </Button>
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Archived Date</TableCell>
              <TableCell
                sortDirection={orderBy === "inventoryName" ? order : false}
              >
                <TableSortLabel
                  active={orderBy === "inventoryName"}
                  direction={orderBy === "inventoryName" ? order : "asc"}
                  onClick={() => handleSort("inventoryName")}
                >
                  Name
                </TableSortLabel>
              </TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Items Ordered</TableCell>
              <TableCell>Current Items</TableCell>
              <TableCell>Price</TableCell>
              <TableCell>Delivery Date</TableCell>
              <TableCell>Expiry Date</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {archivedInventory
              .sort((a, b) => descendingComparator(a, b, orderBy))
              .map((row, index) => (
                <TableRow
                  key={`${row.inventoryId}-${row.instanceId}-${index}`}
                  sx={{
                    transition: "background-color 0.3s",
                    "&:hover": {
                      backgroundColor: "rgba(0, 0, 0, 0.1)",
                    },
                  }}
                >
                  <TableCell>
                    {row.archivedDate
                      ? new Date(row.archivedDate).toLocaleString()
                      : "N/A"}
                  </TableCell>
                  <TableCell>{row.inventoryName || "N/A"}</TableCell>
                  <TableCell>{row.type || "Unknown"}</TableCell>
                  <TableCell>{row.purchaseAmount ?? "N/A"}</TableCell>
                  <TableCell>{row.currentAmount ?? "N/A"}</TableCell>
                  <TableCell>{row.inventoryPrice ?? "N/A"}</TableCell>
                  <TableCell>
                    {row.deliveryDate
                      ? new Date(row.deliveryDate).toISOString().split("T")[0]
                      : "N/A"}
                  </TableCell>
                  <TableCell>
                    {row.expiryDate
                      ? new Date(row.expiryDate).toISOString().split("T")[0]
                      : "N/A"}
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="outlined"
                      size="small"
                      onClick={() =>
                        handleRestore(
                          {
                            inventoryId: row.inventoryId,
                            inventoryName: row.inventoryName,
                          },
                          row.instanceId,
                          row.type
                        )
                      }
                    >
                      Restore
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box display="flex" justifyContent="center" mt={3}>
        <Pagination
          count={totalPages}
          page={page}
          onChange={handlePageChange}
          color="primary"
        />
      </Box>
    </Box>
  );
};

export default InventoryArchive;
