import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import { toggleChangebox, replenishBill } from "./BillFunctions";

const BillDialog = ({ open, onClose, bill, refreshBills }) => {
  const [quantityChange, setQuantityChange] = useState("");
  const [newChangeLimit, setNewChangeLimit] = useState("");
  const [remarks, setRemarks] = useState("");
  const [loadingButton, setLoadingButton] = useState(null);

  useEffect(() => {
    if (open) {
      setQuantityChange("");
      setNewChangeLimit("");
      setRemarks("");
      setLoadingButton(null);
    }
  }, [open]);

  if (!bill) return null;

  const handleToggleChangebox = async () => {
    try {
      setLoadingButton("enableChangebox");
      const changeLimit = parseInt(newChangeLimit, 10);
      if (isNaN(changeLimit) || changeLimit <= 0) {
        alert("Please enter a valid change limit.");
        return;
      }

      await toggleChangebox(bill.denomination, changeLimit);
      refreshBills();
      onClose();
    } catch (error) {
      console.error("Error toggling changebox:", error);
    } finally {
      setLoadingButton(null);
    }
  };

  const handleDisableChangebox = async () => {
    try {
      setLoadingButton("disableChangebox");
      await toggleChangebox(bill.denomination, 0);
      refreshBills();
      onClose();
    } catch (error) {
      console.error("Error disabling changebox:", error);
    } finally {
      setLoadingButton(null);
    }
  };

  const handleReplenishBill = async () => {
    try {
      setLoadingButton("replenishBill");
      const quantity = parseInt(quantityChange, 10);
      if (isNaN(quantity) || quantity === 0) {
        alert("Please enter a valid number to replenish or reduce.");
        return;
      }

      await replenishBill(bill._id, quantity, remarks);
      refreshBills();
      onClose();
    } catch (error) {
      console.error("Error replenishing bill:", error);
    } finally {
      setLoadingButton(null);
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Manage Bill</DialogTitle>
      <DialogContent>
        <Typography variant="h6">
          ₱{bill.denomination} - {bill.billType} | {bill.boxType}
        </Typography>

        <Typography variant="h6" sx={{ mt: 3 }}>
          Replenish or Reduce Bill
        </Typography>

        <TextField
          fullWidth
          type="number"
          variant="outlined"
          label="Enter Amount (+ to add, - to reduce)"
          value={quantityChange}
          onChange={(e) => setQuantityChange(e.target.value)}
          sx={{ mt: 2 }}
        />
        <TextField
          fullWidth
          variant="outlined"
          label="Remarks (optional)"
          value={remarks}
          onChange={(e) => setRemarks(e.target.value)}
          sx={{ mt: 2 }}
        />

        <Button
          variant="contained"
          color="primary"
          onClick={handleReplenishBill}
          sx={{ mt: 2, mr: 2 }}
          disabled={loadingButton === "replenishBill" || !quantityChange.trim()} // Disable if empty
        >
          {loadingButton === "replenishBill" ? (
            <CircularProgress size={24} />
          ) : (
            "Submit"
          )}
        </Button>

        {["Cash", "Coin"].includes(bill.billType) &&
          (bill.changeLimit === 0 ? (
            <>
              <TextField
                fullWidth
                type="number"
                variant="outlined"
                label="Set Changebox Limit"
                value={newChangeLimit}
                onChange={(e) => setNewChangeLimit(e.target.value)}
                sx={{ mt: 2 }}
              />
              <Button
                variant="contained"
                color="success"
                onClick={handleToggleChangebox}
                sx={{ mt: 2 }}
                disabled={
                  loadingButton === "enableChangebox" || !newChangeLimit.trim()
                }
              >
                {loadingButton === "enableChangebox" ? (
                  <CircularProgress size={24} />
                ) : (
                  "Enable Changebox"
                )}
              </Button>
            </>
          ) : (
            <>
              <Typography variant="body2" sx={{ mt: 1, color: "gray" }}>
                Current Change Limit: {bill.changeLimit}
              </Typography>
              {bill.billType === "Cash" ? (
                <Button
                  variant="contained"
                  color="error"
                  onClick={handleDisableChangebox}
                  sx={{ mt: 2 }}
                  disabled={loadingButton === "disableChangebox"}
                >
                  {loadingButton === "disableChangebox" ? (
                    <CircularProgress size={24} />
                  ) : (
                    "Disable Changebox"
                  )}
                </Button>
              ) : (
                <Typography
                  variant="body2"
                  sx={{ mt: 2, color: "gray", fontStyle: "italic" }}
                >
                  🔒 Disable Not Allowed for Coins
                </Typography>
              )}
            </>
          ))}
      </DialogContent>
    </Dialog>
  );
};

export default BillDialog;
