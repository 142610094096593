import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TextField from "@mui/material/TextField";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Pagination from "@mui/material/Pagination";
import { Visibility } from "@mui/icons-material";
import axios from "axios";
import ReportHero from "./ReportHero";
import ActionModal from "./Modal/ActionModal";
import ReceiptActionModal from "./Modal/ReceiptActionModal";
import { getAuthCredentials } from "../../utils/auth";
import generateZReadingPDF from "./Format/ZReading";
import generateXReadingPDF from "./Format/XReading";

const ReportRecords = () => {
  // activeTab: 0 = Invoices (Complete), 1 = Pending, 2 = X-Reading, 3 = Z-Reading
  const [activeTab, setActiveTab] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [records, setRecords] = useState([]);
  const [actionModalOpen, setActionModalOpen] = useState(false);
  const [selectedReceipt, setSelectedReceipt] = useState(null);
  const [filteredRecords, setFilteredRecords] = useState([]);
  const [searchDate, setSearchDate] = useState("");
  const [loading, setLoading] = useState(false);
  const [receiptConfig, setReceiptConfig] = useState(null);
  const [receiptActionModalOpen, setReceiptActionModalOpen] = useState(false);
  const [completeCount, setCompleteCount] = useState(0);
  const [pendingCount, setPendingCount] = useState(0);
  const { token } = getAuthCredentials();

  // Fetch records based on selected date, active tab and page.
  const fetchRecords = async (date = "", tab = activeTab, page = 1) => {
    if (!date) return;

    try {
      setLoading(true);
      setRecords([]);
      setFilteredRecords([]);

      let endpoint = "";
      if (tab === 0) {
        endpoint = `${process.env.REACT_APP_BACKEND_URL}/v1/api/receipt/receipts/complete`;
      } else if (tab === 1) {
        endpoint = `${process.env.REACT_APP_BACKEND_URL}/v1/api/receipt/receipts/pending`;
      } else if (tab === 2) {
        endpoint = `${process.env.REACT_APP_BACKEND_URL}/v1/api/receipt/xreadings`;
      } else if (tab === 3) {
        endpoint = `${process.env.REACT_APP_BACKEND_URL}/v1/api/receipt/zreadings`;
      }

      const response = await axios.get(endpoint, {
        params: { startDate: date, endDate: date, page, limit: 10 },
        headers: { Authorization: `Bearer ${token}` },
      });

      if (tab === 0) {
        const receipts = response.data.receipts || [];
        setRecords(receipts);
        setFilteredRecords(receipts);
        setTotalPages(response.data.totalPages || 1);
        setCurrentPage(response.data.currentPage || 1);
        setCompleteCount(response.data.totalRecords || receipts.length);
      } else if (tab === 1) {
        const receipts = response.data.receipts || [];
        setRecords(receipts);
        setFilteredRecords(receipts);
        setTotalPages(response.data.totalPages || 1);
        setCurrentPage(response.data.currentPage || 1);
        setPendingCount(response.data.totalRecords || receipts.length);
      } else if (tab === 2) {
        setRecords(response.data.xReadings || []);
        setFilteredRecords(response.data.xReadings || []);
      } else {
        setRecords(response.data.zReadings || []);
        setFilteredRecords(response.data.zReadings || []);
      }
    } catch (error) {
      console.error("Error fetching records:", error);
    } finally {
      setLoading(false);
    }
  };

  // Fetch counts for complete and pending receipts on initial load.
  const fetchReceiptCounts = async (date) => {
    try {
      const [completeRes, pendingRes] = await Promise.all([
        axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/v1/api/receipt/receipts/complete`,
          {
            params: { startDate: date, page: 1, limit: 1 },
            headers: { Authorization: `Bearer ${token}` },
          }
        ),
        axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/v1/api/receipt/receipts/pending`,
          {
            params: { startDate: date, page: 1, limit: 1 },
            headers: { Authorization: `Bearer ${token}` },
          }
        ),
      ]);

      setCompleteCount(completeRes.data.totalRecords || 0);
      setPendingCount(pendingRes.data.totalRecords || 0);
    } catch (error) {
      console.error("Error fetching receipt counts:", error);
    }
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
    setCurrentPage(1);
    fetchRecords(searchDate, newValue, 1);
  };

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
    fetchRecords(searchDate, activeTab, newPage);
  };

  const handleActionModalOpen = (type, receipt) => {
    setSelectedReceipt(receipt);
    setActionModalOpen(true);
  };

  const handleActionModalClose = () => {
    setActionModalOpen(false);
    setSelectedReceipt(null);
  };

  const handleReceiptAction = async (action) => {
    const receiptData = {
      posclient: true,
      menuItems: selectedReceipt.menuItems,
      discountType: selectedReceipt.discountType,
      salesType: selectedReceipt.salesType,
      orderNumber: selectedReceipt.orderNumber,
      customerCount: selectedReceipt.customerCount,
      receiptNumber: selectedReceipt.receiptNumber,
      voucherCode: selectedReceipt.voucherCode,
      discount: selectedReceipt.discount,
      timestamp: selectedReceipt.createdAt,
      paymentType: selectedReceipt.paymentType,
      status: selectedReceipt.status,
      refReceiptNumber: selectedReceipt.refReceiptNumber,
      invoiceNumber: selectedReceipt.invoiceNumber,
      refInvoiceNumber: selectedReceipt.refInvoiceNumber,
      dateGenerated: selectedReceipt.dateGenerated,
      printCount: selectedReceipt.printCount,
      voidCount: selectedReceipt.voidCount,
    };

    if (action === "print") {
      try {
        const endpoint =
          selectedReceipt.status === "voided"
            ? `${process.env.REACT_APP_BACKEND_URL}/v1/api/receipt/generate-void-receipt?disposition=print`
            : `${process.env.REACT_APP_BACKEND_URL}/v1/api/receipt/generate-receipt?disposition=print`;
        const response = await axios.post(endpoint, receiptData, {
          responseType: "blob",
          headers: { Authorization: `Bearer ${token}` },
        });
        const pdfBlob = new Blob([response.data], { type: "application/pdf" });
        const pdfUrl = URL.createObjectURL(pdfBlob);
        const link = document.createElement("a");
        link.href = pdfUrl;
        link.download = "receipt.pdf";
        link.click();
      } catch (error) {
        console.error("Error generating receipt:", error);
      }
    } else if (action === "view") {
      try {
        const endpoint =
          selectedReceipt.status === "voided"
            ? `${process.env.REACT_APP_BACKEND_URL}/v1/api/receipt/generate-void-receipt`
            : `${process.env.REACT_APP_BACKEND_URL}/v1/api/receipt/generate-receipt`;
        const response = await axios.post(endpoint, receiptData, {
          responseType: "blob",
          headers: { Authorization: `Bearer ${token}` },
        });
        const pdfBlob = new Blob([response.data], { type: "application/pdf" });
        const pdfUrl = URL.createObjectURL(pdfBlob);
        window.open(pdfUrl, "_blank");
      } catch (error) {
        console.error("Error generating receipt:", error);
      }
    }
    setReceiptActionModalOpen(false);
  };

  const handleDateSearch = (e) => {
    const value = e.target.value;
    setSearchDate(value);
    setRecords([]);
    setFilteredRecords([]);
    fetchRecords(value, activeTab, 1);
    fetchReceiptCounts(value);
  };

  const handleViewClick = (record) => {
    if (!receiptConfig) {
      console.error(
        "Receipt configuration not found. Please wait, loading configuration."
      );
      return;
    }
    if (!record) {
      console.error("Record data not found.");
      return;
    }
    try {
      let pdfUrl = "";
      if (activeTab === 2) {
        pdfUrl = generateXReadingPDF(receiptConfig, record);
      } else if (activeTab === 3) {
        pdfUrl = generateZReadingPDF(receiptConfig, record);
      }
      if (pdfUrl) {
        window.open(pdfUrl, "_blank");
      } else {
        console.error("PDF URL is empty.");
      }
    } catch (error) {
      console.error("Error generating PDF:", error);
    }
  };

  useEffect(() => {
    if (!searchDate) {
      const currentDate = new Date().toISOString().split("T")[0];
      setSearchDate(currentDate);
      fetchRecords(currentDate, activeTab, 1);
      fetchReceiptCounts(currentDate);
    }
  }, []);

  useEffect(() => {
    fetchRecords(searchDate, activeTab, currentPage);
  }, [activeTab, currentPage]);

  useEffect(() => {
    const fetchReceiptConfig = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/v1/api/receipt/config`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setReceiptConfig(response.data.receiptConfig || null);
      } catch (error) {
        console.error("Error fetching receipt configuration:", error);
        setReceiptConfig(null);
      }
    };

    fetchReceiptConfig();
  }, [token]);

  return (
    <Box sx={{ flexGrow: 1, padding: 3 }}>
      <ReportHero />
      <Typography variant="h5" textAlign="center" gutterBottom>
        {activeTab === 0
          ? "Records"
          : activeTab === 1
            ? "Records"
            : activeTab === 2
              ? "Records"
              : "Records"}
      </Typography>
      <Tabs
        value={activeTab}
        onChange={handleTabChange}
        centered
        sx={{ marginBottom: 3 }}
      >
        <Tab label={`Invoices (${completeCount})`} />
        <Tab label={`Pending (${pendingCount})`} />
        <Tab label="X-Reading" />
        <Tab label="Z-Reading" />
      </Tabs>
      <Box
        sx={{
          marginBottom: 3,
          display: "flex",
          justifyContent: "center",
        }}
      >
        <TextField
          label="Search by Date"
          type="date"
          value={searchDate}
          onChange={handleDateSearch}
          InputLabelProps={{ shrink: true }}
          fullWidth
          sx={{ maxWidth: 400 }}
        />
      </Box>
      <Paper elevation={3} sx={{ padding: 2 }}>
        {loading ? (
          <Typography variant="body1" textAlign="center">
            Loading records...
          </Typography>
        ) : (
          <Table>
            <TableHead>
              <TableRow>
                {(activeTab === 0 || activeTab === 1) && (
                  <>
                    <TableCell>Order No.</TableCell>
                    <TableCell>Invoice No.</TableCell>
                  </>
                )}
                <TableCell>
                  {activeTab === 0 || activeTab === 1 ? "Order" : "Date"}
                </TableCell>
                {activeTab === 2 && <TableCell>Shift</TableCell>}
                <TableCell>Total Sales</TableCell>
                {(activeTab === 0 || activeTab === 1) && (
                  <TableCell>Status | Remarks</TableCell>
                )}
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredRecords.map((record) => (
                <TableRow key={record.receiptId || record._id}>
                  {(activeTab === 0 || activeTab === 1) && (
                    <>
                      <TableCell style={{ display: "none" }}>
                        {record._id || "N/A"}
                      </TableCell>
                      <TableCell>{record.receiptNumber || "N/A"}</TableCell>
                      <TableCell>{record.invoiceNumber || "N/A"}</TableCell>
                    </>
                  )}
                  <TableCell>
                    {activeTab === 0 || activeTab === 1
                      ? record.items.map((item) => item.name).join(", ")
                      : new Date(
                        record.startDate || record.date
                      ).toLocaleDateString()}
                  </TableCell>
                  {activeTab === 2 && (
                    <TableCell>
                      {record.startDate && record.endDate
                        ? `${new Date(record.startDate).toLocaleTimeString([], {
                          hour: "2-digit",
                          minute: "2-digit",
                        })} - ${new Date(record.endDate).toLocaleTimeString(
                          [],
                          {
                            hour: "2-digit",
                            minute: "2-digit",
                          }
                        )}`
                        : "N/A"}
                    </TableCell>
                  )}
                  <TableCell>
                    {record.grossSales?.toFixed(2) ||
                      record.totalSales?.toFixed(2) ||
                      record.total?.toFixed(2) ||
                      "N/A"}
                  </TableCell>
                  {(activeTab === 0 || activeTab === 1) && (
                    <TableCell>
                      {record.status || "N/A"}
                      {record.remarks ? ` | ${record.remarks}` : ""}
                    </TableCell>
                  )}
                  <TableCell>
                    <Tooltip
                      title={
                        activeTab !== 0 && !receiptConfig
                          ? "Loading configuration, please wait"
                          : "View"
                      }
                    >
                      <span>
                        <IconButton
                          disabled={activeTab !== 0 && !receiptConfig}
                          onClick={() =>
                            activeTab === 0 || activeTab === 1
                              ? (setSelectedReceipt(record),
                                setReceiptActionModalOpen(true))
                              : handleViewClick(record)
                          }
                        >
                          <Visibility />
                        </IconButton>
                      </span>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
        {filteredRecords.length === 0 && !loading && (
          <Typography
            variant="body1"
            textAlign="center"
            color="textSecondary"
            sx={{ marginTop: 2 }}
          >
            No records found.
          </Typography>
        )}
      </Paper>
      {(activeTab === 0 || activeTab === 1) && filteredRecords.length > 0 && (
        <Box sx={{ display: "flex", justifyContent: "center", marginTop: 3 }}>
          <Pagination
            count={totalPages}
            page={currentPage}
            onChange={handlePageChange}
          />
        </Box>
      )}
      <ActionModal
        fetchRecords={fetchRecords}
        open={actionModalOpen}
        handleClose={handleActionModalClose}
        receipt={selectedReceipt}
        onActionComplete={() => fetchRecords(searchDate, activeTab)}
      />
      <ReceiptActionModal
        open={receiptActionModalOpen}
        handleClose={() => setReceiptActionModalOpen(false)}
        handleAction={handleReceiptAction}
      />
    </Box>
  );
};

export default ReportRecords;
