import React, { useState } from "react";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Input from '@mui/material/Input';
import Typography from '@mui/material/Typography';
import axios from "axios";
import Swal from "sweetalert2";
import { getAuthCredentials } from "../../../utils/auth";

const CustomerTest = ({ open, onClose }) => {
  const { token } = getAuthCredentials();
  const [formData, setFormData] = useState({
    name: "",
    discountType: "",
    discountId: "",
    city: "",
    age: "",
    dateIssued: "",
    birthday: "",
  });
  const [discountIDImg, setDiscountIDImg] = useState(null);
  const [discountSelfieImg, setDiscountSelfieImg] = useState(null);
  const [loading, setLoading] = useState(false);

  const discountTypes = [
    { value: "Senior", label: "Senior Citizen" },
    { value: "PWD", label: "Person with Disability" },
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;

    let updatedFormData = { ...formData, [name]: value };

    if (name === "birthday") {
      const birthDate = new Date(value);
      const today = new Date();
      const age = today.getFullYear() - birthDate.getFullYear();
      const monthDiff = today.getMonth() - birthDate.getMonth();
      const dayDiff = today.getDate() - birthDate.getDate();

      if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
        updatedFormData.age = age - 1;
      } else {
        updatedFormData.age = age;
      }
    }

    setFormData(updatedFormData);
  };

  const handleFileChange = (e, setImage) => {
    setImage(e.target.files[0]);
  };

  const generateOrderId = () => {
    return `ORD-${Math.floor(100000 + Math.random() * 900000)}`;
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      // Step 1: Create New Customer
      const customerResponse = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/v1/api/customer/new-customer`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const customer = customerResponse.data.customer;

      // Step 2: Upload Images & Create New Order
      const formDataOrder = new FormData();
      formDataOrder.append("customerId", customer._id);
      formDataOrder.append("orderId", generateOrderId());
      formDataOrder.append("orderDate", new Date());
      formDataOrder.append("discountId", formData.discountId);
      if (discountIDImg) formDataOrder.append("discountIDImg", discountIDImg);
      if (discountSelfieImg)
        formDataOrder.append("discountSelfieImg", discountSelfieImg);

      const orderResponse = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/v1/api/customer/new-customer-order`,
        formDataOrder,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      // Close modal before showing SweetAlert
      onClose();

      Swal.fire({
        icon: "success",
        title: "Success!",
        text: "Customer and Order Created Successfully!",
        confirmButtonColor: "#3085d6",
      });
    } catch (error) {
      console.error("Error:", error);

      const errorMessage =
        error.response?.data?.message ||
        "An error occurred while processing the request.";

      // Close modal before showing SweetAlert
      onClose();

      Swal.fire({
        icon: "error",
        title: "Error!",
        text: errorMessage,
        confirmButtonColor: "#d33",
      });
    } finally {
      setLoading(false);
    }
  };

  const isFormValid = () => {
    return (
      formData.name.trim() !== "" &&
      formData.discountType.trim() !== "" &&
      formData.discountId.trim() !== "" &&
      formData.city.trim() !== "" &&
      formData.age !== "" &&
      formData.dateIssued.trim() !== "" &&
      formData.birthday.trim() !== "" &&
      discountIDImg !== null &&
      discountSelfieImg !== null
    );
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth disableEnforceFocus>
      <DialogTitle>Create Customer and Order</DialogTitle>
      <DialogContent>
        <TextField
          label="Customer Name"
          name="name"
          value={formData.name}
          onChange={handleChange}
          fullWidth
          margin="dense"
        />
        <TextField
          select
          label="Discount Type"
          name="discountType"
          value={formData.discountType}
          onChange={handleChange}
          fullWidth
          margin="dense"
        >
          {discountTypes.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          label="Discount ID"
          name="discountId"
          value={formData.discountId}
          onChange={handleChange}
          fullWidth
          margin="dense"
        />
        <TextField
          label="City"
          name="city"
          value={formData.city}
          onChange={handleChange}
          fullWidth
          margin="dense"
        />
        <TextField
          label="Birthday"
          name="birthday"
          type="date"
          value={formData.birthday}
          onChange={handleChange}
          fullWidth
          margin="dense"
          InputLabelProps={{ shrink: true }}
          inputProps={{ max: new Date().toISOString().split("T")[0] }}
        />
        <TextField
          label="Age"
          name="age"
          type="number"
          value={formData.age}
          fullWidth
          margin="dense"
          disabled
        />
        <TextField
          label="Date Issued"
          name="dateIssued"
          type="date"
          value={formData.dateIssued}
          onChange={handleChange}
          fullWidth
          margin="dense"
          InputLabelProps={{ shrink: true }}
          inputProps={{ max: new Date().toISOString().split("T")[0] }}
        />

        {/* Upload Discount ID Image */}
        <Input
          type="file"
          accept="image/*"
          onChange={(e) => handleFileChange(e, setDiscountIDImg)}
          fullWidth
          sx={{ marginTop: 2 }}
        />
        <Typography variant="caption">Upload Discount ID Image</Typography>

        {/* Upload Discount Selfie Image */}
        <Input
          type="file"
          accept="image/*"
          onChange={(e) => handleFileChange(e, setDiscountSelfieImg)}
          fullWidth
          sx={{ marginTop: 2 }}
        />
        <Typography variant="caption">Upload Discount Selfie Image</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} disabled={loading}>
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          color="primary"
          disabled={loading || !isFormValid()}
        >
          {loading ? "Processing..." : "Submit"}
        </Button>
        ;
      </DialogActions>
    </Dialog>
  );
};

export default CustomerTest;
