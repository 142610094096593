import translate from "translate";

translate.engine = "google";

export const translateText = async (text, targetLang) => {
  try {
    const result = await translate(text, { to: targetLang });
    return result;
  } catch (error) {
    console.error("Translation Error:", error);
    return text;
  }
};
