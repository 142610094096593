import SecureLS from "secure-ls";

const ls = new SecureLS({ encodingType: "aes" });

export const isAuthenticated = () => {
  const token = ls.get("authToken");
  return !!token;
};

export const setAuthToken = (token) => {
  ls.set("authToken", token);
};

export const clearAuthToken = () => {
  ls.remove("authToken");
};

export const getAuthCredentials = (navigate) => {
  const user = ls.get("user");
  const token = ls.get("authToken");
  const posId = user?.posId;

  if (!token || !posId) {
    // Only redirect if navigate function is provided
    if (navigate && typeof navigate === 'function') {
      navigate("/login");
    }
    return { user: null, token: '', posId: '' };
  }

  return { user, token, posId };
};
