import React, { useState } from "react";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';

const RestockDialog = ({ open, onClose, onRestock, availableUOMs }) => {
  console.log("availableUOMs", availableUOMs);
  const [restockData, setRestockData] = useState({
    amountOrdered: "",
    price: "",
    deliveryDate: "",
    expiryDate: "",
  });

  const [selectedUOM, setSelectedUOM] = useState(
    availableUOMs && availableUOMs.length > 0
      ? availableUOMs[0]
      : { name: "", value: 1 }
  );

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setRestockData((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>Restock Inventory</DialogTitle>
      <DialogContent>
        <TextField
          select
          fullWidth
          label="Unit of Measurement"
          margin="dense"
          value={selectedUOM.name}
          onChange={(e) => {
            const newUOM = availableUOMs.find((u) => u.name === e.target.value);
            setSelectedUOM(newUOM);
          }}
        >
          {availableUOMs.map((uom, index) => (
            <MenuItem key={uom.name} value={uom.name}>
              {uom.name}
              {index === 0
                ? " (Primary)"
                : ` (1 ${uom.name} = ${uom.value} ${availableUOMs[0].name})`}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          fullWidth
          label="Items Ordered"
          name="amountOrdered"
          type="number"
          margin="dense"
          value={restockData.amountOrdered}
          onChange={handleInputChange}
        />
        <TextField
          fullWidth
          label="Price"
          name="price"
          type="number"
          margin="dense"
          value={restockData.price}
          onChange={handleInputChange}
        />
        <TextField
          fullWidth
          label="Delivery Date"
          name="deliveryDate"
          type="date"
          margin="dense"
          InputLabelProps={{ shrink: true }}
          value={restockData.deliveryDate}
          onChange={handleInputChange}
        />
        <TextField
          fullWidth
          label="Expiry Date"
          name="expiryDate"
          type="date"
          margin="dense"
          InputLabelProps={{ shrink: true }}
          value={restockData.expiryDate}
          onChange={handleInputChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button
          onClick={() => {
            const convertedAmount =
              Number(restockData.amountOrdered) * (selectedUOM.value || 1);
            onRestock({ ...restockData, amountOrdered: convertedAmount });
          }}
          color="primary"
          disabled={
            !restockData.amountOrdered ||
            !restockData.price ||
            !restockData.deliveryDate ||
            !restockData.expiryDate
          }
        >
          Restock
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RestockDialog;
