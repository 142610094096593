import React, { useState, useEffect } from "react";
import io from "socket.io-client";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Tooltip from "@mui/material/Tooltip";
import SecureLS from "secure-ls";
import Swal from "sweetalert2";
import GenericViewHero from "./GenericViewHero";
import { validateOTP } from "../../component/OTPValidator";
import { getAuthCredentials } from "../../utils/auth";

const socket = io(process.env.REACT_APP_BACKEND_URL, {
  transports: ["websocket", "polling"],
});

const frontDeskLS = new SecureLS();
const userLS = new SecureLS({ encodingType: "aes" });

const FrontOfDeskView = () => {
  const [loading, setLoading] = useState(true);
  const [selectedDate, setSelectedDate] = useState("");
  const [viewModuleBills, setViewModuleBills] = useState([]);
  const [allOrders, setAllOrders] = useState(() => ({
    newOrders: [],
    processingOrders: [],
    completedOrders: [],
    claimedOrders: [],
    historicalOrders: [],
  }));
  // This state holds countdown timers for orders (if needed)
  const [orderTimers, setOrderTimers] = useState({});

  // -----------------------
  // TIMER: Update countdown every second for New & Processing orders
  // -----------------------
  useEffect(() => {
    const timerInterval = setInterval(() => {
      const updated = {};
      [...allOrders.newOrders, ...allOrders.processingOrders].forEach(
        (order) => {
          if (order.orderTime) {
            const now = Date.now();
            const orderTime = new Date(order.orderTime).getTime();
            const diffSeconds = Math.floor((orderTime - now) / 1000);
            updated[order.orderNumber] = diffSeconds > 0 ? diffSeconds : 0;
          }
        }
      );
      setOrderTimers(updated);
    }, 1000);

    return () => clearInterval(timerInterval);
  }, [allOrders.newOrders, allOrders.processingOrders]);

  // -----------------------
  // FETCH & SOCKET: Fetch orders on mount and set up socket listeners
  // Also, refetch orders every 2 minutes based on selected date.
  // -----------------------
  useEffect(() => {
    fetchOrders();

    const savedOrders = frontDeskLS.get("frontDeskOrders") || {};
    if (savedOrders) {
      setAllOrders({
        newOrders: savedOrders.newOrders || [],
        processingOrders: savedOrders.processingOrders || [],
        completedOrders: savedOrders.completedOrders || [],
        claimedOrders: savedOrders.claimedOrders || [],
        historicalOrders: savedOrders.historicalOrders || [],
      });
    }

    socket.on("orderList", (orderList) => {
      setAllOrders((prev) => {
        const updated = {
          ...prev,
          newOrders: orderList.filter((order) => order.status === "Accepted"),
        };
        frontDeskLS.set("frontDeskOrders", updated);
        return updated;
      });
    });

    socket.on("newOrder", (newOrder) => {
      setAllOrders((prev) => {
        const updated = {
          ...prev,
          newOrders: [...prev.newOrders, newOrder],
        };
        frontDeskLS.set("frontDeskOrders", updated);
        return updated;
      });
    });

    socket.on("updateOrderStatus", ({ status, order }) => {
      setAllOrders((prev) => {
        const updatedState = { ...prev };

        // Remove the order from all arrays
        Object.keys(updatedState).forEach((key) => {
          updatedState[key] = updatedState[key].filter(
            (o) =>
              (o.orderNumber || o.orderId) !==
              (order.orderNumber || order.orderId)
          );
        });

        // Add to correct array
        if (status === "Accepted") {
          updatedState.newOrders.push(order);
        } else if (status === "Processing") {
          updatedState.processingOrders.push(order);
        } else if (status === "Completed") {
          updatedState.completedOrders.push(order);
        } else if (status === "Claimed") {
          updatedState.claimedOrders.push(order);
        } else if (
          ["Cancelled", "Refunded", "Returned", "Voided"].includes(status)
        ) {
          updatedState.historicalOrders.push(order);
        }

        frontDeskLS.set("frontDeskOrders", updatedState);
        return updatedState;
      });
    });

    socket.on("updateHistoricalOrders", ({ order, action }) => {
      setAllOrders((prev) => {
        const historicalOrderExists = prev.historicalOrders.some(
          (hist) => String(hist.orderNumber) === String(order.orderNumber)
        );
        const updatedHistorical = historicalOrderExists
          ? prev.historicalOrders
          : [...prev.historicalOrders, { ...order, action }];
        const updatedState = { ...prev, historicalOrders: updatedHistorical };
        frontDeskLS.set("frontDeskOrders", updatedState);
        return updatedState;
      });
    });

    socket.on(
      "itemStatusUpdated",
      ({ orderId, menuItemId, optionId, completed }) => {
        setAllOrders((prev) => {
          const updateArray = (ordersArray) =>
            ordersArray.map((o) =>
              o._id === orderId || o.orderId === orderId
                ? {
                    ...o,
                    items: o.items.map((item) => {
                      if (item.menuItemId === menuItemId) {
                        if (optionId) {
                          return {
                            ...item,
                            options: item.options.map((opt) =>
                              opt.optionId === optionId
                                ? {
                                    ...opt,
                                    completed: completed ?? opt.completed,
                                  }
                                : opt
                            ),
                          };
                        } else {
                          return {
                            ...item,
                            completed: completed ?? item.completed,
                          };
                        }
                      }
                      return item;
                    }),
                  }
                : o
            );
          return {
            newOrders: updateArray(prev.newOrders),
            processingOrders: updateArray(prev.processingOrders),
            completedOrders: updateArray(prev.completedOrders),
            claimedOrders: updateArray(prev.claimedOrders),
            historicalOrders: updateArray(prev.historicalOrders),
          };
        });
      }
    );

    // Refetch orders every 2 minutes based on the selected date
    const intervalId = setInterval(() => {
      fetchOrders(selectedDate);
    }, 2 * 60 * 1000);

    return () => {
      socket.off("orderList");
      socket.off("newOrder");
      socket.off("updateOrderStatus");
      socket.off("updateHistoricalOrders");
      socket.off("itemStatusUpdated");
      clearInterval(intervalId);
    };
  }, [selectedDate]);

  // Initial fetch on mount: set selected date to today and fetch orders.
  useEffect(() => {
    const today = new Date().toISOString().split("T")[0];
    setSelectedDate(today);
    fetchOrders(today);
  }, []);

  const fetchOrders = async (date) => {
    if (!date) return;
    setLoading(true);
    try {
      const { token } = getAuthCredentials();
      if (!token) {
        console.error("No auth token found.");
        return;
      }
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/v1/api/order/fetch-orders?viewType=frontdesk&date=${date}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();
      console.log("data:", data);
      if (response.ok) {
        const formattedOrders = data.map((order) => ({
          ...order,
          orderNumber: order.orderName,
          receiptId: order._id,
        }));
        setAllOrders({
          newOrders: formattedOrders.filter((o) => o.status === "Accepted"),
          processingOrders: formattedOrders.filter(
            (o) => o.status === "Processing"
          ),
          completedOrders: formattedOrders.filter(
            (o) => o.status === "Completed"
          ),
          claimedOrders: formattedOrders.filter((o) => o.status === "Claimed"),
          historicalOrders: formattedOrders.filter(
            (o) =>
              !["Accepted", "Processing", "Completed", "Claimed"].includes(
                o.status
              )
          ),
        });
        frontDeskLS.set("frontDeskOrders", formattedOrders);
      } else {
        console.error("Failed to fetch front desk orders:", data.message);
      }
    } catch (error) {
      console.error("Error fetching front desk orders:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleDateChange = (e) => {
    const date = e.target.value;
    setSelectedDate(date);
    fetchOrders(date);
  };

  // Toggle item or option completion
  const toggleItemCompletion = async (
    orderId2,
    menuItemId,
    order,
    optionId = null
  ) => {
    try {
      const { token } = getAuthCredentials();
      if (!token) {
        console.error("No auth token found.");
        return;
      }
      const orderId = orderId2 || order._id || order.orderId;
      const requestBody = optionId
        ? { orderId, menuItemId, optionId }
        : { orderId, menuItemId };

      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/v1/api/order/toggle-item-status`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(requestBody),
        }
      );
      const data = await response.json();
      if (response.ok) {
        setAllOrders((prev) => {
          const updateArray = (ordersArray) =>
            ordersArray.map((o) => {
              if (o._id === orderId || o.orderId === orderId) {
                return {
                  ...o,
                  items: o.items.map((item) => {
                    if (item.menuItemId === menuItemId) {
                      if (optionId) {
                        return {
                          ...item,
                          options: item.options.map((opt) =>
                            opt.optionId === optionId
                              ? {
                                  ...opt,
                                  completed:
                                    data.order.items
                                      .find((i) => i.menuItemId === menuItemId)
                                      ?.options.find(
                                        (o) => o.optionId === optionId
                                      )?.completed || false,
                                }
                              : opt
                          ),
                        };
                      } else {
                        return {
                          ...item,
                          completed:
                            data.order.items.find(
                              (i) => i.menuItemId === menuItemId
                            )?.completed || false,
                        };
                      }
                    }
                    return item;
                  }),
                };
              }
              return o;
            });
          const updatedOrders = {
            newOrders: updateArray(prev.newOrders),
            processingOrders: updateArray(prev.processingOrders),
            completedOrders: updateArray(prev.completedOrders),
            claimedOrders: updateArray(prev.claimedOrders),
            historicalOrders: updateArray(prev.historicalOrders),
          };
          frontDeskLS.set("frontDeskOrders", updatedOrders);
          return updatedOrders;
        });

        socket.emit("itemStatusUpdated", {
          orderId,
          menuItemId,
          optionId,
          completed: optionId
            ? data.order.items
                .find((i) => i.menuItemId === menuItemId)
                ?.options.find((o) => o.optionId === optionId)?.completed ||
              false
            : data.order.items.find((i) => i.menuItemId === menuItemId)
                ?.completed || false,
        });
      } else {
        console.error("Failed to toggle item status:", data.message);
      }
    } catch (error) {
      console.error("Error toggling item status:", error);
    }
  };

  const updateOrderStatus = async (orderId, status, orderObj = null) => {
    try {
      const { token } = getAuthCredentials();
      if (!token) {
        console.error("No auth token found.");
        return;
      }
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/v1/api/order/update-order-status`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ orderId, status }),
        }
      );
      const data = await response.json();
      if (response.ok) {
        const updatedOrder = orderObj
          ? { ...orderObj, status }
          : { orderId, status };
        socket.emit("updateOrderStatus", { status, order: updatedOrder });
        setAllOrders((prev) => {
          const updatedState = { ...prev };
          Object.keys(updatedState).forEach((key) => {
            updatedState[key] = updatedState[key].filter(
              (o) =>
                (o.orderNumber || o.orderId) !==
                (updatedOrder.orderNumber || updatedOrder.orderId)
            );
          });
          if (status === "Processing") {
            updatedState.processingOrders.push(updatedOrder);
          } else if (status === "Completed") {
            updatedState.completedOrders.push(updatedOrder);
          } else if (status === "Accepted") {
            updatedState.newOrders.push(updatedOrder);
          } else if (status === "Claimed") {
            updatedState.claimedOrders.push(updatedOrder);
          } else if (
            ["Cancelled", "Refunded", "Returned", "Voided"].includes(status)
          ) {
            updatedState.historicalOrders.push(updatedOrder);
          }
          frontDeskLS.set("frontDeskOrders", updatedState);
          return updatedState;
        });
        socket.emit("updateOrderStatus", { status, order: updatedOrder });
        console.log(`✅ Order ${orderId} updated to ${status}`);
      } else {
        console.error("❌ Failed to update order status:", data.message);
      }
    } catch (error) {
      console.error("❌ Error updating order status:", error);
    }
  };

  const handleCancelOrder = (order) => {
    const receiptId = order.receipt || order.receiptId;
    validateOTP(`Cancel Order + ${receiptId}`)
      .then((isValid) => {
        if (isValid) {
          Swal.fire({
            title: "Confirm Cancellation",
            text: "Are you sure you want to cancel this order?",
            icon: "warning",
            input: "textarea",
            inputPlaceholder: "Enter cancellation remarks (optional)...",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, cancel it!",
          }).then((result) => {
            if (result.isConfirmed) {
              const remarks = result.value || "";
              const { token } = getAuthCredentials();
              fetch(
                `${process.env.REACT_APP_BACKEND_URL}/v1/api/order/cancel-order`,
                {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                    Accept: "application/pdf",
                    Authorization: `Bearer ${token}`,
                  },
                  body: JSON.stringify({ receiptId, remarks }),
                }
              )
                .then(async (res) => {
                  if (!res.ok) {
                    const errorMsg = await res.text();
                    throw new Error(errorMsg || "Failed to cancel order.");
                  }
                  return res.blob();
                })
                .then((pdfBlob) => {
                  const pdfUrl = window.URL.createObjectURL(pdfBlob);
                  window.open(pdfUrl, "_blank");
                  setAllOrders((prev) => {
                    const removeMatch = (o) =>
                      [o._id, o.orderId, o.receipt].includes(receiptId);
                    const cancelledOrder = [
                      ...prev.newOrders,
                      ...prev.processingOrders,
                      ...prev.completedOrders,
                      ...prev.claimedOrders,
                    ].find((o) => removeMatch(o));
                    if (!cancelledOrder) {
                      console.log(
                        `Order with Receipt ID ${receiptId} not found.`
                      );
                      return prev;
                    }
                    const updatedState = {
                      ...prev,
                      newOrders: prev.newOrders.filter((o) => !removeMatch(o)),
                      processingOrders: prev.processingOrders.filter(
                        (o) => !removeMatch(o)
                      ),
                      completedOrders: prev.completedOrders.filter(
                        (o) => !removeMatch(o)
                      ),
                      claimedOrders: prev.claimedOrders.filter(
                        (o) => !removeMatch(o)
                      ),
                      historicalOrders: [
                        ...prev.historicalOrders,
                        { ...cancelledOrder, salesType: "Cancelled", remarks },
                      ],
                    };
                    frontDeskLS.set("frontDeskOrders", updatedState);
                    const actualOrderId =
                      cancelledOrder._id || cancelledOrder.orderId || receiptId;
                    updateOrderStatus(
                      actualOrderId,
                      "Cancelled",
                      cancelledOrder
                    );
                    return updatedState;
                  });
                })
                .catch((error) => {
                  console.error("Error calling cancel order endpoint:", error);
                });
            }
          });
        }
      })
      .catch((error) => {
        console.error("OTP validation cancelled or failed:", error);
      });
  };

  const handleClaimOrder = (index) => {
    setAllOrders((prev) => {
      const newCompleted = [...prev.completedOrders];
      const claimedOrder = newCompleted[index];
      if (!claimedOrder) return prev;
      newCompleted.splice(index, 1);
      const newClaimed = [...prev.claimedOrders, claimedOrder];
      updateOrderStatus(
        claimedOrder.orderId || claimedOrder.receiptId || claimedOrder._id,
        "Claimed"
      );
      const updated = {
        ...prev,
        completedOrders: newCompleted,
        claimedOrders: newClaimed,
      };
      frontDeskLS.set("frontDeskOrders", updated);
      return updated;
    });
  };

  const handleReturnOrder = (order) => {
    const receiptId = order.receipt || order.receiptId;
    validateOTP(`Return Order + ${receiptId}`)
      .then((isValid) => {
        if (isValid) {
          Swal.fire({
            title: "Confirm Return",
            text: "Are you sure you want to return this order?",
            icon: "question",
            input: "textarea",
            inputPlaceholder: "Enter return remarks (optional)...",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, return it!",
          }).then((result) => {
            if (result.isConfirmed) {
              const remarks = result.value || "";
              const { token } = getAuthCredentials();
              fetch(
                `${process.env.REACT_APP_BACKEND_URL}/v1/api/order/return-order`,
                {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                    Accept: "application/pdf",
                    Authorization: `Bearer ${token}`,
                  },
                  body: JSON.stringify({ receiptId, remarks }),
                }
              )
                .then(async (res) => {
                  if (!res.ok) {
                    const errorMsg = await res.text();
                    throw new Error(errorMsg || "Failed to return order.");
                  }
                  return res.blob();
                })
                .then((pdfBlob) => {
                  const pdfUrl = window.URL.createObjectURL(pdfBlob);
                  window.open(pdfUrl, "_blank");
                  setAllOrders((prev) => {
                    const removeMatch = (o) =>
                      [o._id, o.orderId, o.receipt].includes(receiptId);
                    const returnedOrder = prev.claimedOrders.find((o) =>
                      removeMatch(o)
                    );
                    if (!returnedOrder) {
                      console.log(
                        `Order with Receipt ID ${receiptId} not found.`
                      );
                      return prev;
                    }
                    const updatedHistorical = prev.historicalOrders.some((o) =>
                      removeMatch(o)
                    )
                      ? prev.historicalOrders.map((h) =>
                          removeMatch(h)
                            ? {
                                ...returnedOrder,
                                salesType: "Returned",
                                remarks,
                              }
                            : h
                        )
                      : [
                          ...prev.historicalOrders,
                          { ...returnedOrder, salesType: "Returned", remarks },
                        ];
                    const updatedState = {
                      ...prev,
                      claimedOrders: prev.claimedOrders.filter(
                        (o) => !removeMatch(o)
                      ),
                      historicalOrders: updatedHistorical,
                    };
                    frontDeskLS.set("frontDeskOrders", updatedState);
                    const actualOrderId =
                      returnedOrder._id || returnedOrder.orderId || receiptId;
                    updateOrderStatus(actualOrderId, "Returned", returnedOrder);
                    return updatedState;
                  });
                })
                .catch((error) => {
                  console.error("Error calling return order endpoint:", error);
                });
            }
          });
        }
      })
      .catch((error) => {
        console.error("OTP validation cancelled or failed:", error);
      });
  };

  const handleRefundOrder = (order) => {
    const receiptId = order.receipt || order.receiptId;
    validateOTP(`Refund Order + ${receiptId}`)
      .then((isValid) => {
        if (isValid) {
          Swal.fire({
            title: "Confirm Refund",
            text: "Are you sure you want to refund this order?",
            icon: "question",
            input: "textarea",
            inputPlaceholder: "Enter refund remarks (optional)...",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, refund it!",
          }).then((result) => {
            if (result.isConfirmed) {
              const remarks = result.value || "";
              const { token } = getAuthCredentials();
              fetch(
                `${process.env.REACT_APP_BACKEND_URL}/v1/api/order/refund-order`,
                {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                    Accept: "application/pdf",
                    Authorization: `Bearer ${token}`,
                  },
                  body: JSON.stringify({ receiptId, remarks }),
                }
              )
                .then(async (res) => {
                  if (!res.ok) {
                    const errorMsg = await res.text();
                    throw new Error(errorMsg || "Failed to refund order.");
                  }
                  return res.blob();
                })
                .then((pdfBlob) => {
                  const pdfUrl = window.URL.createObjectURL(pdfBlob);
                  window.open(pdfUrl, "_blank");
                  setAllOrders((prev) => {
                    const removeMatch = (o) =>
                      [o._id, o.orderId, o.receipt].includes(receiptId);
                    const refundedOrder = prev.claimedOrders.find((o) =>
                      removeMatch(o)
                    );
                    if (!refundedOrder) {
                      console.log(
                        `Order with Receipt ID ${receiptId} not found.`
                      );
                      return prev;
                    }
                    const updatedHistorical = prev.historicalOrders.some((o) =>
                      removeMatch(o)
                    )
                      ? prev.historicalOrders.map((h) =>
                          removeMatch(h)
                            ? {
                                ...refundedOrder,
                                salesType: "Refunded",
                                remarks,
                              }
                            : h
                        )
                      : [
                          ...prev.historicalOrders,
                          { ...refundedOrder, salesType: "Refunded", remarks },
                        ];
                    const updatedState = {
                      ...prev,
                      claimedOrders: prev.claimedOrders.filter(
                        (o) => !removeMatch(o)
                      ),
                      historicalOrders: updatedHistorical,
                    };
                    frontDeskLS.set("frontDeskOrders", updatedState);
                    const actualOrderId =
                      refundedOrder._id || refundedOrder.orderId || receiptId;
                    updateOrderStatus(actualOrderId, "Refunded", refundedOrder);
                    return updatedState;
                  });
                })
                .catch((error) => {
                  console.error("Error calling refund order endpoint:", error);
                });
            }
          });
        }
      })
      .catch((error) => {
        console.error("OTP validation cancelled or failed:", error);
      });
  };

  const handleVoidOrder = (order, actionLabel) => {
    const receiptId = order.receipt || order.receiptId;
    validateOTP(`Void Order + ${receiptId}`)
      .then((isValid) => {
        if (isValid) {
          Swal.fire({
            title: `Confirm ${actionLabel}`,
            text: `Are you sure you want to ${actionLabel.toLowerCase()} this order?`,
            icon: "warning",
            input: "textarea",
            inputPlaceholder: `Enter ${actionLabel.toLowerCase()} remarks (optional)...`,
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: `Yes, ${actionLabel.toLowerCase()} it!`,
          }).then((result) => {
            if (result.isConfirmed) {
              const remarks = result.value || "";
              const { token } = getAuthCredentials();
              fetch(
                `${process.env.REACT_APP_BACKEND_URL}/v1/api/order/void-order`,
                {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                    Accept: "application/pdf",
                    Authorization: `Bearer ${token}`,
                  },
                  body: JSON.stringify({ receiptId, remarks }),
                }
              )
                .then(async (res) => {
                  if (!res.ok) {
                    const errorMsg = await res.text();
                    throw new Error(errorMsg || "Failed to void order.");
                  }
                  return res.blob();
                })
                .then((pdfBlob) => {
                  const pdfUrl = window.URL.createObjectURL(pdfBlob);
                  window.open(pdfUrl, "_blank");
                  setAllOrders((prev) => {
                    const removeMatch = (o) =>
                      [o._id, o.orderId, o.receipt, o.receiptId].includes(
                        receiptId
                      );
                    const affectedOrder = [
                      ...prev.newOrders,
                      ...prev.processingOrders,
                      ...prev.completedOrders,
                      ...prev.claimedOrders,
                    ].find((o) => removeMatch(o));
                    if (!affectedOrder) {
                      console.log(
                        `Order with Receipt ID ${receiptId} not found.`
                      );
                      return prev;
                    }
                    const updatedState = {
                      ...prev,
                      newOrders: prev.newOrders.filter((o) => !removeMatch(o)),
                      processingOrders: prev.processingOrders.filter(
                        (o) => !removeMatch(o)
                      ),
                      completedOrders: prev.completedOrders.filter(
                        (o) => !removeMatch(o)
                      ),
                      claimedOrders: prev.claimedOrders.filter(
                        (o) => !removeMatch(o)
                      ),
                      historicalOrders: [
                        ...prev.historicalOrders,
                        {
                          ...affectedOrder,
                          salesType: actionLabel,
                          remarks,
                          status: "Voided",
                        },
                      ],
                    };
                    frontDeskLS.set("frontDeskOrders", updatedState);
                    const actualOrderId =
                      affectedOrder._id || affectedOrder.orderId || receiptId;
                    updateOrderStatus(actualOrderId, "Voided", {
                      ...affectedOrder,
                      salesType: actionLabel,
                      remarks,
                      status: "Voided",
                    });
                    return updatedState;
                  });
                })
                .catch((error) => {
                  console.error("Error calling void order endpoint:", error);
                });
            }
          });
        }
      })
      .catch((error) => {
        console.error("OTP validation cancelled or failed:", error);
      });
  };

  const {
    newOrders,
    processingOrders,
    completedOrders,
    claimedOrders,
    historicalOrders,
  } = allOrders;

  const validClaimedOrders = (claimedOrders ?? []).filter(
    (order) => (order?.items ?? []).length > 0
  );

  return (
    <Box sx={{ flexGrow: 1, padding: 3 }}>
      <GenericViewHero title="Front Of Desk View" />
      <Box sx={{ marginBottom: 3, display: "flex", justifyContent: "center" }}>
        <TextField
          label="Select Date"
          type="date"
          value={selectedDate}
          onChange={handleDateChange}
          InputLabelProps={{ shrink: true }}
          fullWidth
          sx={{ maxWidth: 400 }}
        />
      </Box>

      {/* Bills Section */}
      <Box sx={{ mb: 3 }}>
        <Grid container spacing={2}>
          {viewModuleBills.map((bill, index) => (
            <Grid item xs={12} md={4} key={index}>
              <Card sx={{ padding: 2, textAlign: "center" }}>
                <CardContent>
                  <Typography variant="h6">
                    {bill.denomination} - {bill.boxType}
                  </Typography>
                  <Typography variant="body2">
                    Quantity: {bill.quantity}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>

      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "50vh",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          {/* Top Row: Three Columns */}
          <Grid container spacing={3}>
            {/* New Orders */}
            <Grid item xs={12} md={4}>
              <Typography variant="h6" align="center" sx={{ mt: 3 }}>
                New Orders
              </Typography>
              <Box sx={{ maxHeight: "70vh", overflowY: "auto" }}>
                {newOrders.map((order, index) => {
                  const timeLeft = orderTimers[order.orderNumber] ?? null;
                  const isSolelyBeverage = order.items.every((item) =>
                    item.menuTags?.includes("beverage")
                  );
                  return (
                    <Card
                      key={index}
                      sx={{
                        mb: 2,
                        border:
                          timeLeft !== null && timeLeft <= 60
                            ? "2px solid red"
                            : "1px solid gray",
                        backgroundColor:
                          timeLeft !== null && timeLeft === 0
                            ? "#ffe6e6"
                            : "white",
                      }}
                    >
                      <CardContent>
                        {/* Top row: Order # and Timer */}
                        <Box sx={{ mb: 1 }}>
                          <Typography variant="h6">
                            Order #: {order.orderNumber}
                          </Typography>
                          <Typography variant="body1">
                            Tent Number: {order.tentNumber}
                          </Typography>
                          {timeLeft !== null && (
                            <Typography
                              variant="body1"
                              sx={{
                                color: timeLeft === 0 ? "red" : "inherit",
                                fontWeight: timeLeft === 0 ? "bold" : "normal",
                                fontSize: timeLeft === 0 ? "1.1rem" : "inherit",
                              }}
                            >
                              {timeLeft === 0
                                ? "Order Behind"
                                : `${Math.floor(timeLeft / 60)}:${(
                                    timeLeft % 60
                                  )
                                    .toString()
                                    .padStart(2, "0")}`}
                            </Typography>
                          )}
                        </Box>
                        <Typography variant="body1">
                          Items:
                          <ul>
                            {order.items.map((item, i) => (
                              <li key={i}>
                                {item.menuTags?.includes("beverage") ? (
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={item.completed || false}
                                        onChange={() =>
                                          toggleItemCompletion(
                                            order._id,
                                            item.menuItemId,
                                            order
                                          )
                                        }
                                        disabled={order.status !== "Processing"}
                                      />
                                    }
                                    label={
                                      <span
                                        style={{
                                          textDecoration: item.completed
                                            ? "line-through"
                                            : "none",
                                          color: item.completed
                                            ? "green"
                                            : "inherit",
                                        }}
                                      >
                                        {`${item.name} - ${item.amount}x`}
                                      </span>
                                    }
                                  />
                                ) : (
                                  <span
                                    style={{
                                      textDecoration: item.completed
                                        ? "line-through"
                                        : "none",
                                      color: item.completed
                                        ? "green"
                                        : "inherit",
                                    }}
                                  >
                                    {`${item.name} - ${item.amount}x`}
                                  </span>
                                )}
                                {Array.isArray(item.options) && (
                                  <ul>
                                    {(item.options ?? []).map((option, j) => (
                                      <li key={j}>
                                        {option.menuTags?.includes(
                                          "beverage"
                                        ) ? (
                                          <FormControlLabel
                                            control={
                                              <Checkbox
                                                checked={
                                                  option.completed || false
                                                }
                                                onChange={() =>
                                                  toggleItemCompletion(
                                                    order._id,
                                                    item.menuItemId,
                                                    order,
                                                    option.optionId
                                                  )
                                                }
                                                disabled={
                                                  order.status !== "Processing"
                                                }
                                              />
                                            }
                                            label={
                                              <span
                                                style={{
                                                  textDecoration:
                                                    option.completed
                                                      ? "line-through"
                                                      : "none",
                                                  color: option.completed
                                                    ? "green"
                                                    : "inherit",
                                                }}
                                              >
                                                {`${
                                                  option.name
                                                }: ${option.choices.join(
                                                  ", "
                                                )}`}
                                              </span>
                                            }
                                          />
                                        ) : (
                                          <FormControlLabel
                                            control={
                                              <Checkbox
                                                checked={
                                                  option.completed || false
                                                }
                                                disabled
                                              />
                                            }
                                            label={
                                              <span
                                                style={{
                                                  textDecoration:
                                                    option.completed
                                                      ? "line-through"
                                                      : "none",
                                                  color: option.completed
                                                    ? "green"
                                                    : "inherit",
                                                }}
                                              >
                                                {`${
                                                  option.name
                                                }: ${option.choices.join(
                                                  ", "
                                                )}`}
                                              </span>
                                            }
                                          />
                                        )}
                                      </li>
                                    ))}
                                  </ul>
                                )}
                              </li>
                            ))}
                          </ul>
                        </Typography>
                        <Typography variant="body1">
                          Total: PHP {order.total.toFixed(2)}
                        </Typography>
                        <Typography variant="body1">
                          Sales Type: {order.salesType}
                        </Typography>
                        <Box sx={{ mt: 2, display: "flex", gap: 2 }}>
                          {(() => {
                            const orderDate = new Date(
                              order.createdAt || order.orderTime
                            );
                            const now = new Date();
                            const daysDiff = Math.floor(
                              (now - orderDate) / (1000 * 60 * 60 * 24)
                            );
                            const isVoidDisabled = daysDiff >= 8;

                            return (
                              <Tooltip
                                title={
                                  isVoidDisabled
                                    ? "7 days have already passed for Voiding this Order"
                                    : ""
                                }
                              >
                                <span>
                                  <Button
                                    variant="contained"
                                    color="error"
                                    onClick={() =>
                                      handleVoidOrder(order, "Void")
                                    }
                                    disabled={isVoidDisabled}
                                  >
                                    VOID Order
                                  </Button>
                                </span>
                              </Tooltip>
                            );
                          })()}

                          {isSolelyBeverage && (
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() =>
                                updateOrderStatus(
                                  order.orderId || order.receiptId,
                                  "Processing",
                                  order
                                )
                              }
                            >
                              Process Order
                            </Button>
                          )}
                        </Box>
                      </CardContent>
                    </Card>
                  );
                })}
              </Box>
            </Grid>

            {/* Processing Orders */}
            <Grid item xs={12} md={4}>
              <Typography variant="h6" align="center" sx={{ mt: 3 }}>
                Processing Orders
              </Typography>
              <Box sx={{ maxHeight: "70vh", overflowY: "auto" }}>
                {processingOrders.map((order, index) => {
                  const timeLeft = orderTimers[order.orderNumber] ?? null;
                  const isSolelyBeverage = order.items.every((item) =>
                    item.menuTags?.includes("beverage")
                  );
                  return (
                    <Card
                      key={index}
                      sx={{
                        mb: 2,
                        border:
                          timeLeft !== null && timeLeft <= 60
                            ? "2px solid red"
                            : "1px solid gray",
                        backgroundColor:
                          timeLeft !== null && timeLeft === 0
                            ? "#ffe6e6"
                            : "white",
                      }}
                    >
                      <CardContent>
                        <Box sx={{ mb: 1 }}>
                          <Typography variant="h6">
                            Order #: {order.orderNumber}
                          </Typography>
                          <Typography variant="body1">
                            Tent Number: {order.tentNumber}
                          </Typography>
                          {timeLeft !== null && (
                            <Typography
                              variant="body1"
                              sx={{
                                color: timeLeft === 0 ? "red" : "inherit",
                                fontWeight: timeLeft === 0 ? "bold" : "normal",
                                fontSize: timeLeft === 0 ? "1.1rem" : "inherit",
                              }}
                            >
                              {timeLeft === 0
                                ? "Order Behind"
                                : `${Math.floor(timeLeft / 60)}:${(
                                    timeLeft % 60
                                  )
                                    .toString()
                                    .padStart(2, "0")}`}
                            </Typography>
                          )}
                        </Box>
                        <Typography variant="body1">
                          Items:
                          <ul>
                            {order.items.map((item, i) => (
                              <li key={i}>
                                {item.menuTags?.includes("beverage") ? (
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={item.completed || false}
                                        onChange={() =>
                                          toggleItemCompletion(
                                            order._id,
                                            item.menuItemId,
                                            order
                                          )
                                        }
                                        disabled={order.status !== "Processing"}
                                      />
                                    }
                                    label={
                                      <span
                                        style={{
                                          textDecoration: item.completed
                                            ? "line-through"
                                            : "none",
                                          color: item.completed
                                            ? "green"
                                            : "inherit",
                                        }}
                                      >
                                        {`${item.name} - ${item.amount}x`}
                                      </span>
                                    }
                                  />
                                ) : (
                                  <span
                                    style={{
                                      textDecoration: item.completed
                                        ? "line-through"
                                        : "none",
                                      color: item.completed
                                        ? "green"
                                        : "inherit",
                                    }}
                                  >
                                    {`${item.name} - ${item.amount}x`}
                                  </span>
                                )}
                                {Array.isArray(item.options) && (
                                  <ul>
                                    {(item.options ?? []).map((option, j) => (
                                      <li key={j}>
                                        {option.menuTags?.includes(
                                          "beverage"
                                        ) ? (
                                          <FormControlLabel
                                            control={
                                              <Checkbox
                                                checked={
                                                  option.completed || false
                                                }
                                                onChange={() =>
                                                  toggleItemCompletion(
                                                    order._id,
                                                    item.menuItemId,
                                                    order,
                                                    option.optionId
                                                  )
                                                }
                                                disabled={
                                                  order.status !== "Processing"
                                                }
                                              />
                                            }
                                            label={
                                              <span
                                                style={{
                                                  textDecoration:
                                                    option.completed
                                                      ? "line-through"
                                                      : "none",
                                                  color: option.completed
                                                    ? "green"
                                                    : "inherit",
                                                }}
                                              >
                                                {`${
                                                  option.name
                                                }: ${option.choices.join(
                                                  ", "
                                                )}`}
                                              </span>
                                            }
                                          />
                                        ) : (
                                          <FormControlLabel
                                            control={
                                              <Checkbox
                                                checked={
                                                  option.completed || false
                                                }
                                                disabled
                                              />
                                            }
                                            label={
                                              <span
                                                style={{
                                                  textDecoration:
                                                    option.completed
                                                      ? "line-through"
                                                      : "none",
                                                  color: option.completed
                                                    ? "green"
                                                    : "inherit",
                                                }}
                                              >
                                                {`${
                                                  option.name
                                                }: ${option.choices.join(
                                                  ", "
                                                )}`}
                                              </span>
                                            }
                                          />
                                        )}
                                      </li>
                                    ))}
                                  </ul>
                                )}
                              </li>
                            ))}
                          </ul>
                        </Typography>
                        <Typography variant="body1">
                          Total: PHP {order.total.toFixed(2)}
                        </Typography>
                        <Typography variant="body1">
                          Sales Type: {order.salesType}
                        </Typography>
                        <Box sx={{ mt: 2, display: "flex", gap: 2 }}>
                          {/* <Button
                            variant="contained"
                            color="error"
                            onClick={() => handleCancelOrder(order)}
                          >
                            Cancel Order
                          </Button> */}
                          {isSolelyBeverage && (
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() =>
                                updateOrderStatus(
                                  order.orderId || order.receiptId,
                                  "Completed",
                                  order
                                )
                              }
                            >
                              Complete Order
                            </Button>
                          )}
                        </Box>
                      </CardContent>
                    </Card>
                  );
                })}
              </Box>
            </Grid>

            {/* Completed Orders */}
            <Grid item xs={12} md={4}>
              <Typography variant="h6" align="center" sx={{ mt: 3 }}>
                Completed Orders
              </Typography>
              <Box sx={{ maxHeight: "70vh", overflowY: "auto" }}>
                {(completedOrders || []).map((order, index) => {
                  const isDrinksOnly = order.items.every((item) =>
                    item.menuTags?.includes("beverage")
                  );
                  const allItemsCompleted = isDrinksOnly
                    ? order.items.every((item) => item.completed)
                    : order.items.every((item) => {
                        if (!item.completed) return false;
                        if (item.options && item.options.length > 0) {
                          return item.options.every((opt) => opt.completed);
                        }
                        return true;
                      });

                  return (
                    <Card key={index} sx={{ mb: 2 }}>
                      <CardContent>
                        <Typography variant="h6">
                          Order #: {order.orderNumber}
                        </Typography>
                        <Typography variant="body1">
                          Tent Number: {order.tentNumber}
                        </Typography>
                        <Typography variant="body1">
                          Items:
                          <ul>
                            {(order?.items ?? []).map((item, i) => (
                              <li key={i}>
                                {item.menuTags?.includes("beverage") ? (
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={item.completed || false}
                                        onChange={() =>
                                          toggleItemCompletion(
                                            order._id,
                                            item.menuItemId,
                                            order
                                          )
                                        }
                                      />
                                    }
                                    label={
                                      <span
                                        style={{
                                          textDecoration: item.completed
                                            ? "line-through"
                                            : "none",
                                          color: item.completed
                                            ? "green"
                                            : "inherit",
                                        }}
                                      >
                                        {`${item.name} - ${item.amount}x`}
                                      </span>
                                    }
                                  />
                                ) : (
                                  <span
                                    style={{
                                      textDecoration: item.completed
                                        ? "line-through"
                                        : "none",
                                      color: item.completed
                                        ? "green"
                                        : "inherit",
                                    }}
                                  >
                                    {`${item.name} - ${item.amount}x`}
                                  </span>
                                )}
                                {Array.isArray(item.options) && (
                                  <ul>
                                    {(item.options ?? []).map((option, j) => (
                                      <li key={j}>
                                        {option.menuTags?.includes(
                                          "beverage"
                                        ) ? (
                                          <FormControlLabel
                                            control={
                                              <Checkbox
                                                checked={
                                                  option.completed || false
                                                }
                                                onChange={() =>
                                                  toggleItemCompletion(
                                                    order._id,
                                                    item.menuItemId,
                                                    order,
                                                    option.optionId
                                                  )
                                                }
                                              />
                                            }
                                            label={
                                              <span
                                                style={{
                                                  textDecoration:
                                                    option.completed
                                                      ? "line-through"
                                                      : "none",
                                                  color: option.completed
                                                    ? "green"
                                                    : "inherit",
                                                }}
                                              >
                                                {`${
                                                  option.name
                                                }: ${option.choices.join(
                                                  ", "
                                                )}`}
                                              </span>
                                            }
                                          />
                                        ) : (
                                          <FormControlLabel
                                            control={
                                              <Checkbox
                                                checked={
                                                  option.completed || false
                                                }
                                                disabled
                                              />
                                            }
                                            label={
                                              <span
                                                style={{
                                                  textDecoration:
                                                    option.completed
                                                      ? "line-through"
                                                      : "none",
                                                  color: option.completed
                                                    ? "green"
                                                    : "inherit",
                                                }}
                                              >
                                                {`${
                                                  option.name
                                                }: ${option.choices.join(
                                                  ", "
                                                )}`}
                                              </span>
                                            }
                                          />
                                        )}
                                      </li>
                                    ))}
                                  </ul>
                                )}
                              </li>
                            ))}
                          </ul>
                        </Typography>
                        <Typography variant="body1">
                          Total: PHP {order.total.toFixed(2)}
                        </Typography>
                        <Typography variant="body1">
                          Sales Type: {order.salesType}
                        </Typography>
                        <Box sx={{ mt: 2, display: "flex", gap: 2 }}>
                          <Button
                            variant="contained"
                            color="success"
                            onClick={() => handleClaimOrder(index)}
                            disabled={!allItemsCompleted}
                          >
                            Claim Order
                          </Button>
                        </Box>
                      </CardContent>
                    </Card>
                  );
                })}
              </Box>
            </Grid>
          </Grid>

          {/* Claimed Orders (full-width) */}
          {validClaimedOrders.length > 0 && (
            <Card sx={{ mb: 3 }}>
              <CardContent>
                <Typography variant="h6" align="center" sx={{ mt: 3 }}>
                  Claimed Orders
                </Typography>
                <Grid container spacing={3}>
                  {validClaimedOrders.map((order, index) => (
                    <Grid item xs={12} md={6} key={index}>
                      <Card sx={{ mb: 2 }}>
                        <CardContent>
                          <Typography variant="h6">
                            Order #: {order.orderNumber}
                          </Typography>
                          <Typography variant="body1">
                            Tent Number: {order.tentNumber}
                          </Typography>
                          <Typography variant="body1">
                            Items:
                            <ul>
                              {(order.items ?? []).map((item, i) => (
                                <li key={i}>
                                  <span
                                    style={{
                                      textDecoration: item.completed
                                        ? "line-through"
                                        : "none",
                                      color: item.completed
                                        ? "green"
                                        : "inherit",
                                    }}
                                  >
                                    {`${item.name} - ${item.amount}x`}
                                  </span>
                                  {Array.isArray(item.options) &&
                                    item.options.length > 0 && (
                                      <ul>
                                        {(item.options ?? []).map(
                                          (option, j) => (
                                            <li key={j}>
                                              <span
                                                style={{
                                                  textDecoration:
                                                    option.completed
                                                      ? "line-through"
                                                      : "none",
                                                  color: option.completed
                                                    ? "green"
                                                    : "inherit",
                                                }}
                                              >
                                                {`${
                                                  option.name
                                                }: ${option.choices.join(
                                                  ", "
                                                )}`}
                                              </span>
                                            </li>
                                          )
                                        )}
                                      </ul>
                                    )}
                                </li>
                              ))}
                            </ul>
                          </Typography>
                          <Typography variant="body1">
                            Total: PHP {order.total?.toFixed(2)}
                          </Typography>
                          <Typography variant="body1">
                            Sales Type: {order.salesType}
                          </Typography>
                          <Box sx={{ mt: 2, display: "flex", gap: 2 }}>
                            {/* <Button
                              variant="contained"
                              color="warning"
                              onClick={() => handleVoidOrder(order)}
                            >
                              VOID Order
                            </Button> */}
                            {(() => {
                              const orderDate = new Date(
                                order.createdAt || order.orderTime
                              );
                              const now = new Date();
                              const daysDiff = Math.floor(
                                (now - orderDate) / (1000 * 60 * 60 * 24)
                              );
                              const isVoidDisabled = daysDiff >= 8;

                              return (
                                <Tooltip
                                  title={
                                    isVoidDisabled
                                      ? "7 days have already passed for Voiding this Order"
                                      : ""
                                  }
                                >
                                  <span>
                                    <Button
                                      variant="contained"
                                      color="error"
                                      onClick={() =>
                                        handleVoidOrder(order, "Void")
                                      }
                                      disabled={isVoidDisabled}
                                    >
                                      VOID Order
                                    </Button>
                                  </span>
                                </Tooltip>
                              );
                            })()}
                          </Box>
                        </CardContent>
                      </Card>
                    </Grid>
                  ))}
                </Grid>
              </CardContent>
            </Card>
          )}

          {/* Historical Orders (full-width) */}
          <Card sx={{ mb: 3 }}>
            <CardContent>
              <Typography variant="h6" align="center" sx={{ mt: 3 }}>
                Historical Orders
              </Typography>
              <Grid container spacing={3}>
                {historicalOrders.length > 0 ? (
                  historicalOrders.map((order, index) => (
                    <Grid item xs={12} key={index}>
                      <Card sx={{ mb: 2 }}>
                        <CardContent>
                          <Typography variant="h6">
                            Order #: {order.orderNumber}
                          </Typography>
                          <Typography variant="body1">
                            Tent Number: {order.tentNumber}
                          </Typography>
                          <Typography variant="body1">
                            Items:
                            <ul>
                              {order.items.map((item, i) => (
                                <li
                                  key={i}
                                  style={{
                                    color: item.completed ? "green" : "black",
                                  }}
                                >
                                  <span
                                    style={{
                                      textDecoration: item.completed
                                        ? "line-through"
                                        : "none",
                                    }}
                                  >
                                    {`${item.name} - ${item.amount}x ${
                                      item.completed ? "(DONE)" : ""
                                    }`}
                                  </span>
                                  {Array.isArray(item.options) &&
                                    item.options.length > 0 && (
                                      <ul>
                                        {item.options.map((option, j) => (
                                          <li key={j}>
                                            <span
                                              style={{
                                                textDecoration: option.completed
                                                  ? "line-through"
                                                  : "none",
                                                color: option.completed
                                                  ? "green"
                                                  : "inherit",
                                              }}
                                            >
                                              {`${
                                                option.name
                                              }: ${option.choices.join(", ")}`}
                                            </span>
                                          </li>
                                        ))}
                                      </ul>
                                    )}
                                </li>
                              ))}
                            </ul>
                          </Typography>
                          <Typography variant="body1">
                            Total: PHP{" "}
                            {order.total ? order.total.toFixed(2) : "0.00"}
                          </Typography>
                          <Typography variant="body1">
                            Sales Type: {order.salesType || "N/A"}
                          </Typography>
                          <Typography variant="body1">
                            Order Action: {order.status || "N/A"}
                          </Typography>
                        </CardContent>
                      </Card>
                    </Grid>
                  ))
                ) : (
                  <Typography>No historical orders available</Typography>
                )}
              </Grid>
            </CardContent>
          </Card>
        </>
      )}
    </Box>
  );
};

export default FrontOfDeskView;
