import React, { useEffect, useState } from "react";
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import CardContent from "@mui/material/CardContent";
import Typography from '@mui/material/Typography'
import TextField from "@mui/material/TextField";
import IconButton from '@mui/material/IconButton'
import UnarchiveIcon from "@mui/icons-material/Unarchive";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import Swal from "sweetalert2";
import { getAuthCredentials } from "../../utils/auth";

const MenuArchive = ({ ingredients, fetchMenuData }) => {
  const [archivedItems, setArchivedItems] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const { token } = getAuthCredentials();

  useEffect(() => {
    fetchArchivedItems();
  }, [token]);

  const fetchArchivedItems = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/v1/api/menu/fetch-archive`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setArchivedItems(response.data.archivedItems);
    } catch (error) {
      console.error("Error fetching archived items:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Error fetching archived items. Please try again.",
      });
    }
  };

  const handleUnarchive = async (item) => {
    try {
      console.log("item:", item);
      await axios.put(
        `${process.env.REACT_APP_BACKEND_URL}/v1/api/menu/unarchive/${item.menuId}`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      fetchArchivedItems();
      fetchMenuData();
      Swal.fire({
        icon: "success",
        title: "Unarchived",
        text: "Item unarchived successfully.",
      });
    } catch (error) {
      console.error("Error unarchiving item:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Error unarchiving item. Please try again.",
      });
    }
  };

  const handleDeleteItem = async (item) => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_BACKEND_URL}/v1/api/menu/delete-archive/${item.menuId}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setArchivedItems((prev) =>
        prev.filter((archived) => archived.menuId !== item.menuId)
      );
      fetchArchivedItems();
      Swal.fire({
        icon: "success",
        title: "Deleted",
        text: "Archived item deleted successfully.",
      });
    } catch (error) {
      console.error("Error deleting archived item:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Error deleting archived item. Please try again.",
      });
    }
  };

  const filteredArchivedItems = archivedItems.filter((item) => {
    const nameMatch = item.menuName
      ?.toLowerCase()
      .includes(searchQuery.toLowerCase());
    const categoryMatch = item.categoryName
      ?.toLowerCase()
      .includes(searchQuery.toLowerCase());
    return nameMatch || categoryMatch;
  });

  const categorizedArchivedItems = filteredArchivedItems.reduce((acc, item) => {
    const category = item.categoryName || "Uncategorized";
    if (!acc[category]) {
      acc[category] = [];
    }
    acc[category].push(item);
    return acc;
  }, {});

  return (
    <Box>
      <Box sx={{ display: "flex", justifyContent: "center", mb: 2 }}>
        <TextField
          label="Search Archived Items"
          variant="outlined"
          size="small"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </Box>
      {Object.keys(categorizedArchivedItems).map((category, categoryIndex) => (
        <Box key={categoryIndex} sx={{ marginBottom: 4 }}>
          <Typography variant="h5" gutterBottom>
            {category}
          </Typography>
          <Grid container spacing={3}>
            {categorizedArchivedItems[category].map((item, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Card
                  sx={{
                    backgroundColor: "#ADD8E6",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  {/* {item.menuImage && (
                    <CardMedia
                      component="img"
                      image={item.menuImage}
                      alt={item.menuName}
                      height="150"
                      sx={{ objectFit: "cover" }}
                    />
                  )} */}
                  <CardContent>
                    <Typography variant="h6" gutterBottom>
                      {item.menuName}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      Ingredients:
                    </Typography>
                    <ul>
                      {item.menuIngredients.map((ingredient, idx) => (
                        <li key={idx}>
                          {ingredient.ingredientName} ({ingredient.amount}x)
                        </li>
                      ))}
                    </ul>
                    <Typography variant="body1">
                      Price: PHP {item.basePrice}
                    </Typography>
                  </CardContent>

                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "flex-end",
                      padding: "8px",
                    }}
                  >
                    <IconButton
                      onClick={() => handleUnarchive(item)}
                      color="primary"
                      size="small"
                    >
                      <UnarchiveIcon />
                    </IconButton>
                    <IconButton
                      onClick={() => handleDeleteItem(item)}
                      color="error"
                      size="small"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>
      ))}
    </Box>
  );
};

export default MenuArchive;
