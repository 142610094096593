import axios from "axios";
import Swal from "sweetalert2";
import { getAuthCredentials } from "../../../../utils/auth";

const API_URL = `${process.env.REACT_APP_BACKEND_URL}/v1/api/bill`;

const getHeaders = () => {
  const { token } = getAuthCredentials();
  return {
    headers: { Authorization: `Bearer ${token}` },
  };
};

export const toggleChangebox = async (denomination, changeLimit) => {
  try {
    const response = await axios.post(
      `${API_URL}/toggle-changebox`,
      { denomination, changeLimit },
      getHeaders()
    );

    Swal.fire({
      icon: "success",
      title: "Success",
      text:
        changeLimit > 0
          ? `Changebox enabled successfully. Limit set to ${changeLimit}.`
          : "Changebox disabled successfully.",
    });

    return response.data;
  } catch (error) {
    console.error("Error toggling changebox:", error);

    Swal.fire({
      icon: "error",
      title: "Error",
      text: error.response?.data?.message || "Failed to toggle changebox.",
    });

    throw error;
  }
};

export const replenishBill = async (billId, quantityChange, remarks) => {
  try {
    const response = await axios.post(
      `${API_URL}/replenish-bill`,
      { billId, quantity: quantityChange, ...(remarks && { remarks }) },
      getHeaders()
    );

    Swal.fire({
      icon: "success",
      title: "Success",
      text: `Successfully updated bill quantity by ${quantityChange}.`,
    });

    return response.data;
  } catch (error) {
    console.error("Error replenishing bill:", error);

    Swal.fire({
      icon: "error",
      title: "Error",
      text: error.response?.data?.message || "Failed to replenish bill.",
    });

    throw error;
  }
};

export const toggleViewModule = async (billId, refreshBills) => {
  try {
    const response = await axios.post(
      `${API_URL}/toggle-view-module`,
      { billId },
      getHeaders()
    );

    if (response.status === 200) {
      const { bill } = response.data;
      const status = bill.viewModule ? "On" : "Off";
      const message = `${bill.denomination} - ${bill.boxType} - ${status}`;

      Swal.fire({
        icon: "success",
        title: "View Module Toggled",
        text: message,
        timer: 1500,
        showConfirmButton: false,
      });

      refreshBills();
    }
  } catch (error) {
    console.error("Error toggling view module:", error);
    Swal.fire({
      icon: "error",
      title: "Error",
      text: "Failed to toggle view module.",
    });
  }
};

export const setupDefaultBills = async () => {
  try {
    const response = await axios.post(
      `${API_URL}/setup-default-bills`,
      {},
      getHeaders()
    );
    Swal.fire({
      icon: "success",
      title: "Setup Complete",
      text: "Default bills and coins have been set up.",
    });
    window.location.reload();
  } catch (error) {
    console.error("Error setting up default bills:", error);
    Swal.fire({
      icon: "error",
      title: "Error",
      text: error.response?.data?.message || "Failed to setup default bills.",
    });
    throw error;
  }
};
