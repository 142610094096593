import React from "react";
import Box from '@mui/material/Box'
import Typography from "@mui/material/Typography";
import Button from '@mui/material/Button'
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";

const BillHero = ({ activeTab }) => {
  const navigate = useNavigate();

  return (
    <Box sx={{ padding: 3 }}>
      <Button
        variant="contained"
        color="primary"
        startIcon={<ArrowBackIcon />}
        onClick={() => navigate("/reports")}
        sx={{ marginBottom: 3 }}
      >
        Back
      </Button>
      <Typography variant="h4" align="center" gutterBottom>
        {activeTab === "inventory" ? "Bill Inventory" : "Bill History"}
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          gap: 2,
          marginBottom: 3,
        }}
      >
        <Button
          variant={activeTab === "inventory" ? "contained" : "outlined"}
          onClick={() => navigate("/reports/bill-inventory")}
        >
          Bill Inventory
        </Button>
        <Button
          variant={activeTab === "history" ? "contained" : "outlined"}
          onClick={() => navigate("/reports/bill-history")}
        >
          Bill History
        </Button>
      </Box>
    </Box>
  );
};

export default BillHero;
