import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import axios from "axios";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import { getAuthCredentials } from "../../../utils/auth";

const BIRSalesModal = ({ open, onClose }) => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [loading, setLoading] = useState(false);
  const { token } = getAuthCredentials();

  const handleGenerateReport = async () => {
    if (!startDate || !endDate) {
      alert("Please select both start and end dates.");
      return;
    }

    setLoading(true);
    try {
      // Load Excel template
      const templateRes = await fetch("/templates/birsalessummary_format.xlsx");
      const templateBuffer = await templateRes.arrayBuffer();

      const workbook = new ExcelJS.Workbook();
      await workbook.xlsx.load(templateBuffer);
      const worksheet = workbook.getWorksheet("Sheet1");

      // Fetch raw JSON data
      let response;
      try {
        response = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/v1/api/reports/sales-summary`,
          { startDate, endDate },
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
      } catch (err) {
        console.error(
          "❌ Failed to fetch report data:",
          err.response?.data || err.message
        );

        const apiMessage = err.response?.data?.message;
        const missingDates = err.response?.data?.missingDates;

        if (
          apiMessage === "Z-Readings missing for some dates." &&
          Array.isArray(missingDates)
        ) {
          const formattedDates = missingDates.join(", ");
          alert(
            `Cannot generate report.\nMissing Z-Readings for:\n${formattedDates}`
          );
        } else {
          alert("Failed to get report data.");
        }

        return;
      }

      const data = response.data;

      try {
        worksheet.getCell("A1").value = data.taxpayerName;
        worksheet.getCell("A2").value = data.taxpayerAddress;
        worksheet.getCell("A3").value = data.tin;
        worksheet.getCell("A5").value = data.softwareName;
        worksheet.getCell("A6").value = data.serialNumber;
        worksheet.getCell("A7").value = data.machineId;
        worksheet.getCell("A8").value = data.terminalNo;
        worksheet.getCell("A9").value = new Date().toLocaleString();
        worksheet.getCell("A10").value = data.generatedBy;

        const startRow = 16;
        data.rows.forEach((row, index) => {
          worksheet.insertRow(startRow + index, row);
        });
      } catch (err) {
        console.error("❌ Failed to populate Excel template:", err);
        alert("Something went wrong while filling the report.");
        return;
      }

      try {
        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        saveAs(blob, `BIR_Sales_Summary_${startDate}_to_${endDate}.xlsx`);
        alert("Report generated successfully.");
      } catch (err) {
        console.error("❌ Failed to generate/download Excel file:", err);
        alert("Failed to generate Excel file.");
      }
    } catch (error) {
      console.error("❌ General error:", error);
      alert("Failed to generate the report. Please try again.");
    } finally {
      setLoading(false);
      onClose();
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Generate BIR Sales Report</DialogTitle>
      <DialogContent>
        <Typography variant="body1" sx={{ marginBottom: 2 }}>
          Please select the date range for the BIR Sales Report:
        </Typography>
        <TextField
          label="Start Date"
          type="date"
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
          fullWidth
          InputLabelProps={{ shrink: true }}
          sx={{ marginBottom: 2 }}
        />
        <TextField
          label="End Date"
          type="date"
          value={endDate}
          onChange={(e) => setEndDate(e.target.value)}
          fullWidth
          InputLabelProps={{ shrink: true }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} disabled={loading}>
          Cancel
        </Button>
        <Button
          onClick={handleGenerateReport}
          variant="contained"
          color="primary"
          disabled={loading}
        >
          {loading ? "Generating..." : "Generate"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default BIRSalesModal;
