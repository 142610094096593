// OTPValidator.js
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import axios from "axios";
import SecureLS from "secure-ls";

const ls = new SecureLS({ encodingType: "aes" });
const MySwal = withReactContent(Swal);

export const validateOTP = async (action) => {
  while (true) {
    const { value: otp, dismiss } = await MySwal.fire({
      title: "Enter Manager OTP",
      input: "text",
      inputLabel: "Please enter the Generated Manager's 6-digit OTP",
      inputPlaceholder: "e.g., 123456",
      showCancelButton: true,
      inputValidator: (value) => {
        if (!value) return "You need to enter the OTP!";
        if (value.trim().length !== 6) return "OTP should be exactly 6 digits!";
        return null;
      },
    });

    if (!otp || dismiss) {
      return false;
    }

    try {
      const authToken = ls.get("authToken");
      const actionString = action?.action || action;
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/v1/api/otp/validate`,
        { otp: otp.trim(), action: actionString },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      if (response.status === 200) {
        await MySwal.fire({
          title: "Success",
          text: "OTP validated successfully",
          icon: "success",
          confirmButtonText: "OK",
        });
        return true;
      } else {
        await MySwal.fire({
          title: "Error",
          text:
            response.data?.message ||
            "OTP validation failed. Please try again.",
          icon: "error",
          confirmButtonText: "Retry",
        });
      }
    } catch (error) {
      const result = await MySwal.fire({
        title: "Error",
        text:
          error.response?.data?.message ||
          error.message ||
          "OTP validation failed. Please try again.",
        icon: "error",
        showCancelButton: true,
        confirmButtonText: "Retry",
        cancelButtonText: "Cancel",
      });
      if (result.dismiss) {
        return false;
      }
    }
  }
};
