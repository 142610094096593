import React, { createContext, useContext, useState, useEffect } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

// Define available languages
export const LANGUAGES = {
  en: {
    code: 'en',
    name: 'English',
    flag: '🇺🇸',
  },
  zh: {
    code: 'zh',
    name: '中文',
    flag: '🇨🇳',
  },
  th: {
    code: 'th',
    name: 'ภาษาไทย',
    flag: '🇹🇭',
  },
  tl: {
    code: 'tl',
    name: 'Filipino',
    flag: '🇵🇭',
  },
};

// Create context
const KioskContext = createContext();

// Create base theme
const baseTheme = createTheme({
  palette: {
    primary: {
      main: '#1976d2',
    },
    secondary: {
      main: '#dc004e',
    },
  },
});

// Format price in PHP currency
export const formatPrice = (price) => {
  // Convert to integer value for PHP display
  // Multiply by 100 to display as PHP 50 instead of PHP 0.50
  return `PHP ${(price * 100).toFixed(2)}`;
};

// Context provider component
export const KioskProvider = ({ children }) => {
  const [language, setLanguage] = useState(() => {
    return localStorage.getItem('kiosk_language') || 'en';
  });
  
  const [darkMode, setDarkMode] = useState(() => {
    return localStorage.getItem('kiosk_darkMode') === 'true' || false;
  });

  const [paymentMethod, setPaymentMethod] = useState('cash');
  
  // Order type state (dine-in or take-out)
  const [orderType, setOrderType] = useState('dine-in');
  
  // Cart items state
  const [cartItems, setCartItems] = useState([]);

  // Save preferences to localStorage whenever they change
  useEffect(() => {
    localStorage.setItem('kiosk_language', language);
  }, [language]);

  useEffect(() => {
    localStorage.setItem('kiosk_darkMode', darkMode.toString());
  }, [darkMode]);

  // Function to toggle dark mode
  const toggleDarkMode = () => {
    setDarkMode(prev => !prev);
  };

  // Function to change language
  const changeLanguage = (newLanguage) => {
    if (LANGUAGES[newLanguage]) {
      setLanguage(newLanguage);
    }
  };

  // Function to change payment method
  const changePaymentMethod = (method) => {
    setPaymentMethod(method);
  };

  // Translation function
  const translate = (text, translations) => {
    if (!translations) return text;
    return translations[language] || translations.en || text;
  };

  // Create theme based on dark mode preference
  const theme = createTheme({
    ...baseTheme,
    palette: {
      ...baseTheme.palette,
      mode: darkMode ? 'dark' : 'light',
      background: {
        default: darkMode ? '#121212' : '#f5f5f5',
        paper: darkMode ? '#1e1e1e' : '#ffffff',
      },
    },
  });

  // Function to change order type
  const changeOrderType = (type) => {
    setOrderType(type);
  };
  
  // Function to add item to cart
  const addToCart = (item) => {
    setCartItems(prevItems => [...prevItems, item]);
  };
  
  // Function to update cart item
  const updateCartItem = (itemId, updatedItem) => {
    setCartItems(prevItems => 
      prevItems.map(item => item.id === itemId ? updatedItem : item)
    );
  };
  
  // Function to remove item from cart
  const removeFromCart = (itemId) => {
    setCartItems(prevItems => prevItems.filter(item => item.id !== itemId));
  };
  
  // Function to clear the cart
  const clearCart = () => {
    setCartItems([]);
  };

  // Context value
  const contextValue = {
    language,
    changeLanguage,
    darkMode,
    toggleDarkMode,
    paymentMethod,
    changePaymentMethod,
    orderType,
    changeOrderType,
    cartItems,
    addToCart,
    updateCartItem,
    removeFromCart,
    clearCart,
    translate,
    formatPrice,
    availableLanguages: LANGUAGES,
  };

  return (
    <KioskContext.Provider value={contextValue}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </KioskContext.Provider>
  );
};

// Custom hook to use the context
export const useKioskContext = () => {
  const context = useContext(KioskContext);
  if (!context) {
    throw new Error('useKioskContext must be used within a KioskProvider');
  }
  return context;
};
