import React, { useState, useEffect } from "react";
import io from "socket.io-client";
import SecureLS from "secure-ls";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import GenericViewHero from "./GenericViewHero";
import { getAuthCredentials } from "../../utils/auth";

const socket = io(process.env.REACT_APP_BACKEND_URL, {
  transports: ["websocket", "polling"],
});

const ls = new SecureLS();

const KitchenView = () => {
  const [orders, setOrders] = useState([]);
  const [flashingOrders, setFlashingOrders] = useState({});
  const [orderTimers, setOrderTimers] = useState({});
  const [loading, setLoading] = useState(true);
  const [orderTimestamps, setOrderTimestamps] = useState({});

  useEffect(() => {
    const fetchKitchenOrders = async () => {
      try {
        setLoading(true);
        const { token } = getAuthCredentials();
        if (!token) {
          console.error("No auth token found.");
          return;
        }
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/v1/api/order/fetch-orders?viewType=kitchen`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const data = await response.json();

        if (response.ok) {
          const updatedOrders = data.map((order) => ({
            ...order,
            orderNumber: order.orderName || order._id,
          }));
          setOrders(updatedOrders);
          ls.set("orders", updatedOrders);
        } else {
          console.error("Failed to fetch kitchen orders:", data.message);
        }
      } catch (error) {
        console.error("Error fetching kitchen orders:", error);
      } finally {
        setLoading(false);
      }
    };

    // Initial fetch
    fetchKitchenOrders();

    // Refetch every 2 minutes (120,000 milliseconds)
    const intervalId = setInterval(fetchKitchenOrders, 2 * 60 * 1000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    const savedTimers = ls.get("orderTimers");
    const savedTimestamps = ls.get("orderTimestamps");

    if (savedTimers && savedTimestamps) {
      const currentTime = Date.now();

      const recalculatedTimers = Object.fromEntries(
        Object.entries(savedTimers).map(([orderNumber, time]) => {
          const elapsedTime = Math.floor(
            (currentTime - savedTimestamps[orderNumber]) / 1000
          );
          return [orderNumber, Math.max(time - elapsedTime, 0)];
        })
      );

      setOrderTimers(recalculatedTimers);
    }

    const savedOrders = ls.get("orders");
    if (savedOrders) {
      setOrders(savedOrders);
    }
  }, []);

  useEffect(() => {
    socket.on("connect", () => {
      console.log("Connected to Socket.IO server:", socket.id);
    });

    socket.on("orderList", (orderList) => {
      console.log("Initial orders received:", orderList);
      setOrders(orderList);
      ls.set("orders", orderList);

      socket.emit("orderList", orderList);
    });

    socket.on("newOrder", (newOrder) => {
      const bell = new Audio("/assets/sounds/orderarrive.mp3");
      bell.play();
      console.log("newOrder: ", newOrder);

      setOrders((prevOrders) => {
        if (
          prevOrders.some(
            (order) =>
              order.orderId === newOrder.orderId ||
              order.orderNumber === newOrder.orderNumber
          )
        ) {
          return prevOrders;
        }

        const completeNewOrder = {
          ...newOrder,
          orderId: newOrder.orderId || newOrder._id,
        };

        const updatedOrders = [...prevOrders, completeNewOrder];
        ls.set("orders", updatedOrders);

        setOrderTimers((prevTimers) => {
          const updatedTimers = {
            ...prevTimers,
            [completeNewOrder.orderNumber]: completeNewOrder.processTime * 60,
          };
          ls.set("orderTimers", updatedTimers);

          setOrderTimestamps((prevTimestamps) => {
            const updatedTimestamps = {
              ...prevTimestamps,
              [completeNewOrder.orderNumber]: Date.now(),
            };
            ls.set("orderTimestamps", updatedTimestamps);
            return updatedTimestamps;
          });

          return updatedTimers;
        });

        socket.emit("newOrder", completeNewOrder);
        return updatedOrders;
      });
    });

    socket.on("cancelOrder", ({ orderNumber }) => {
      console.log("Received cancelOrder: ", orderNumber);

      setOrders((prevOrders) => {
        const updatedOrders = prevOrders.filter(
          (order) => order.orderNumber !== orderNumber
        );
        return updatedOrders;
      });

      setOrderTimers((prevTimers) => {
        const { [orderNumber]: _, ...remainingTimers } = prevTimers;
        return remainingTimers;
      });

      setFlashingOrders((prevFlashing) => {
        const { [orderNumber]: _, ...remainingFlashing } = prevFlashing;
        return remainingFlashing;
      });

      console.log(
        "Order removed from KitchenView for orderNumber:",
        orderNumber
      );
    });

    return () => {
      socket.off("connect");
      socket.off("orderList");
      socket.off("newOrder");
      socket.off("cancelOrder");
    };
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setOrderTimers(() => {
        const updatedTimers = {};

        orders.forEach((order) => {
          if (!order.orderTime) return;

          const timeRemaining = order.orderTime
            ? Math.max(
                Math.floor((new Date(order.orderTime) - Date.now()) / 1000),
                0
              )
            : 0;

          updatedTimers[order.orderNumber] = isNaN(timeRemaining)
            ? 0
            : timeRemaining;

          if (timeRemaining === 0) {
            setFlashingOrders((prevFlashing) => ({
              ...prevFlashing,
              [order.orderNumber]: true,
            }));
          }

          if (timeRemaining === 61) {
            const buzzer = new Audio("/assets/sounds/orderalmostelapse.mp3");
            buzzer.play();

            setFlashingOrders((prevFlashing) => ({
              ...prevFlashing,
              [order.orderNumber]: true,
            }));
          }
        });

        ls.set("orderTimers", updatedTimers);
        return updatedTimers;
      });

      setOrderTimestamps((prevTimestamps) => {
        const updatedTimestamps = {
          ...prevTimestamps,
          ...Object.fromEntries(
            orders.map((order) => [
              order.orderNumber,
              prevTimestamps[order.orderNumber] || Date.now(),
            ])
          ),
        };
        ls.set("orderTimestamps", updatedTimestamps);
        return updatedTimestamps;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [orders]);

  // Update the handler to find the order based on its unique orderNumber
  const handleProcessOrder = async (orderNumber) => {
    const index = orders.findIndex(
      (order) => order.orderNumber === orderNumber
    );
    if (index === -1) return;

    const updatedOrders = [...orders];
    const orderToUpdate = updatedOrders[index];

    try {
      const { token } = getAuthCredentials();
      if (!token) {
        console.error("No auth token found.");
        return;
      }

      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/v1/api/order/update-order-status`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            orderId: orderToUpdate._id || orderToUpdate.orderId,
            status: "Processing",
          }),
        }
      );

      const data = await response.json();

      if (response.ok) {
        updatedOrders[index] = { ...orderToUpdate, status: "Processing" };
        setOrders(updatedOrders);
        ls.set("orders", updatedOrders);

        socket.emit("updateOrderStatus", {
          status: "Processing",
          order: updatedOrders[index],
          orderId: orderToUpdate._id || orderToUpdate.orderId,
        });

        console.log(`Order ${orderToUpdate.orderNumber} is now Processing`);
      } else {
        console.error("Failed to update order status:", data.message);
      }
    } catch (error) {
      console.error("Error updating order status:", error);
    }
  };

  // Similarly update handleOrderComplete to use orderNumber
  const handleOrderComplete = async (orderNumber) => {
    const index = orders.findIndex(
      (order) => order.orderNumber === orderNumber
    );
    if (index === -1) return;

    const updatedOrders = [...orders];
    const orderToUpdate = updatedOrders[index];

    try {
      const { token } = getAuthCredentials();
      if (!token) {
        console.error("No auth token found.");
        return;
      }

      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/v1/api/order/update-order-status`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            orderId: orderToUpdate._id || orderToUpdate.orderId,
            status: "Completed",
          }),
        }
      );

      const data = await response.json();

      if (response.ok) {
        updatedOrders.splice(index, 1);
        setOrders(updatedOrders);
        ls.set("orders", updatedOrders);

        console.log("startting update order status");
        socket.emit("updateOrderStatus", {
          status: "Completed",
          order: orderToUpdate,
          orderId: orderToUpdate._id || orderToUpdate.orderId,
        });
      } else {
        console.error("Failed to update order status:", data.message);
      }
    } catch (error) {
      console.error("Error updating order status:", error);
    }
  };

  const toggleItemCompletion = async (
    orderId2,
    menuItemId,
    order,
    optionId = null
  ) => {
    try {
      const { token } = getAuthCredentials();
      if (!token) {
        console.error("No auth token found.");
        return;
      }

      const orderId = orderId2 || order._id || order.orderId;
      const requestBody = optionId
        ? { orderId, menuItemId, optionId }
        : { orderId, menuItemId };

      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/v1/api/order/toggle-item-status`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(requestBody),
        }
      );

      const data = await response.json();
      if (response.ok) {
        setOrders((prevOrders) => {
          const updatedOrders = prevOrders.map((o) => {
            if (o._id === orderId || o.orderId === orderId) {
              return {
                ...o,
                items: o.items.map((item) => {
                  if (item.menuItemId === menuItemId) {
                    if (optionId) {
                      return {
                        ...item,
                        options: item.options.map((opt) =>
                          opt.optionId === optionId
                            ? {
                                ...opt,
                                completed:
                                  data.order.items
                                    .find((i) => i.menuItemId === menuItemId)
                                    ?.options.find(
                                      (o) => o.optionId === optionId
                                    )?.completed || false,
                              }
                            : opt
                        ),
                      };
                    } else {
                      return {
                        ...item,
                        completed:
                          data.order.items.find(
                            (i) => i.menuItemId === menuItemId
                          )?.completed || false,
                      };
                    }
                  }
                  return item;
                }),
              };
            }
            return o;
          });

          ls.set("orders", updatedOrders);
          return updatedOrders;
        });

        socket.emit("itemStatusUpdated", {
          orderId,
          menuItemId,
          optionId,
          completed: optionId
            ? data.order.items
                .find((i) => i.menuItemId === menuItemId)
                ?.options.find((o) => o.optionId === optionId)?.completed ||
              false
            : data.order.items.find((i) => i.menuItemId === menuItemId)
                ?.completed || false,
        });
      } else {
        console.error("Failed to toggle item status:", data.message);
      }
    } catch (error) {
      console.error("Error toggling item status:", error);
    }
  };

  socket.on(
    "itemStatusUpdated",
    ({ orderId, menuItemId, optionId, completed }) => {
      setOrders((prevOrders) =>
        prevOrders.map((order) =>
          order._id === orderId || order.orderId === orderId
            ? {
                ...order,
                items: order.items.map((item) => {
                  if (item.menuItemId === menuItemId) {
                    if (optionId) {
                      return {
                        ...item,
                        options: item.options.map((opt) =>
                          opt.optionId === optionId
                            ? { ...opt, completed: completed ?? opt.completed }
                            : opt
                        ),
                      };
                    } else {
                      return {
                        ...item,
                        completed: completed ?? item.completed,
                      };
                    }
                  }
                  return item;
                }),
              }
            : order
        )
      );
    }
  );

  useEffect(() => {
    socket.on("updateOrderStatus", ({ status, order }) => {
      setOrders((prevOrders) => {
        if (status === "Completed") {
          const updatedOrders = prevOrders.filter(
            (o) => o.orderNumber !== order.orderNumber
          );

          ls.set("orders", updatedOrders);
          console.log(
            `✅ Kitchen View: Order ${order.orderNumber} removed after completion`
          );
          return updatedOrders;
        }

        const updatedOrders = prevOrders.filter(
          (o) => o.orderNumber !== order.orderNumber
        );

        if (status === "Processing") {
          updatedOrders.push(order);
        }

        ls.set("orders", updatedOrders);
        console.log(
          `🔄 Kitchen View: Order ${order.orderNumber} updated to ${status}`
        );
        return updatedOrders;
      });
    });

    return () => {
      socket.off("updateOrderStatus");
    };
  }, []);

  // Filter orders into two columns
  const newOrders = orders.filter((order) => order.status !== "Processing");
  const processingOrders = orders.filter(
    (order) => order.status === "Processing"
  );

  // Reusable component for an order card
  const renderOrderCard = (order) => {
    // Check if non-beverage items are completed
    const allItemsCompleted = order.items
      .filter((item) => !(item.menuTags && item.menuTags.includes("beverage")))
      .every(
        (item) =>
          item.completed &&
          (item.options
            ? item.options
                .filter(
                  (opt) => !(opt.menuTags && opt.menuTags.includes("beverage"))
                )
                .every((opt) => opt.completed)
            : true)
      );

    return (
      <Card
        key={order.orderNumber}
        sx={{
          border:
            orderTimers[order.orderNumber] <= 60
              ? "2px solid red"
              : "1px solid gray",
          backgroundColor:
            orderTimers[order.orderNumber] === 0 ? "#ffe6e6" : "white",
          transition: "background-color 0.3s, border 0.3s",
          mb: 2,
        }}
      >
        <CardContent>
          <Typography
            variant="h6"
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            Order #: {order.orderNumber}
            Tent #: {order.tentNumber}
            <span
              style={{
                color: orderTimers[order.orderNumber] === 0 ? "red" : "inherit",
                fontWeight:
                  orderTimers[order.orderNumber] === 0 ? "bold" : "normal",
                fontSize:
                  orderTimers[order.orderNumber] === 0 ? "1.1rem" : "inherit",
              }}
            >
              {orderTimers[order.orderNumber] === 0 ? (
                <strong style={{ color: "red" }}>Order Behind</strong>
              ) : orderTimers[order.orderNumber] !== undefined &&
                !isNaN(orderTimers[order.orderNumber]) ? (
                `${Math.floor(orderTimers[order.orderNumber] / 60)}:${(
                  orderTimers[order.orderNumber] % 60
                )
                  .toString()
                  .padStart(2, "0")}`
              ) : (
                "--:--"
              )}
            </span>
          </Typography>
          <Typography variant="body1">
            Items:
            <ul>
              {order.items.map((item, i) => (
                <li key={i}>
                  {item.menuTags?.includes("beverage") ? (
                    <span
                      style={{
                        textDecoration: item.completed
                          ? "line-through"
                          : "none",
                        color: item.completed ? "green" : "inherit",
                      }}
                    >
                      {`${item.name} - ${item.amount}x`}
                    </span>
                  ) : (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={item.completed || false}
                          onChange={() =>
                            toggleItemCompletion(
                              order._id,
                              item.menuItemId,
                              order
                            )
                          }
                          disabled={order.status !== "Processing"}
                        />
                      }
                      label={
                        <span
                          style={{
                            textDecoration: item.completed
                              ? "line-through"
                              : "none",
                            color: item.completed ? "green" : "inherit",
                          }}
                        >
                          {`${item.name} - ${item.amount}x`}
                        </span>
                      }
                    />
                  )}
                  <ul>
                    {item.options.map((option, j) => (
                      <li key={j}>
                        {option.menuTags?.includes("beverage") ? (
                          <span
                            style={{
                              textDecoration: option.completed
                                ? "line-through"
                                : "none",
                              color: option.completed ? "green" : "inherit",
                            }}
                          >
                            {`${option.name}: ${option.choices.join(", ")}`}
                          </span>
                        ) : (
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={option.completed || false}
                                onChange={() =>
                                  toggleItemCompletion(
                                    order._id,
                                    item.menuItemId,
                                    order,
                                    option.optionId
                                  )
                                }
                                disabled={order.status !== "Processing"}
                              />
                            }
                            label={
                              <span
                                style={{
                                  textDecoration: option.completed
                                    ? "line-through"
                                    : "none",
                                  color: option.completed ? "green" : "inherit",
                                }}
                              >
                                {`${option.name}: ${option.choices.join(", ")}`}
                              </span>
                            }
                          />
                        )}
                      </li>
                    ))}
                  </ul>
                </li>
              ))}
            </ul>
          </Typography>
          <Typography variant="body1">
            Total: PHP {order.total.toFixed(2)}
          </Typography>
          <Typography variant="body1">Sales Type: {order.salesType}</Typography>
          <Box sx={{ mt: 2, display: "flex", gap: 2 }}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleProcessOrder(order.orderNumber)}
              disabled={
                order.status === "Processing" || order.status === "Completed"
              }
            >
              Start Order
            </Button>
            <Button
              variant="contained"
              color="success"
              onClick={() => handleOrderComplete(order.orderNumber)}
              disabled={!allItemsCompleted || order.status !== "Processing"}
            >
              Order Complete
            </Button>
          </Box>
        </CardContent>
      </Card>
    );
  };

  return (
    <Box sx={{ flexGrow: 1, padding: 3 }}>
      <GenericViewHero title="Kitchen View" />
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "50vh",
          }}
        >
          <CircularProgress />
        </Box>
      ) : orders.length === 0 ? (
        <Typography variant="h6" align="center" sx={{ mt: 5 }}>
          No Orders
        </Typography>
      ) : (
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Typography variant="h5" align="center" sx={{ mb: 2 }}>
              New Orders
            </Typography>
            <Box sx={{ maxHeight: "70vh", overflowY: "auto" }}>
              {newOrders.map((order) => renderOrderCard(order))}
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h5" align="center" sx={{ mb: 2 }}>
              Processing Orders
            </Typography>
            <Box sx={{ maxHeight: "70vh", overflowY: "auto" }}>
              {processingOrders.map((order) => renderOrderCard(order))}
            </Box>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default KitchenView;
