import React, { useState } from "react";
import Swal from "sweetalert2";

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Autocomplete from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';

import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import SecureLS from "secure-ls";
import AddCategoryDialog from "./AddCategoryDialog";

const ls = new SecureLS({ encodingType: "aes" });

const AddInventoryDialog = ({
  open,
  onClose,
  onAdd,
  categories,
  fetchCategories,
  setCategories,
  existingInventoryNames,
}) => {
  const [name, setName] = useState("");
  const [category, setCategory] = useState("");
  const [newCategory, setNewCategory] = useState("");
  const [amountOrdered, setAmountOrdered] = useState("");
  const [unitOfMeasurement, setUnitOfMeasurement] = useState("");
  const [optionalMeasurements, setOptionalMeasurements] = useState([]);
  const [price, setPrice] = useState("");
  const [priceType, setPriceType] = useState("Total");
  const [deliveryDate, setDeliveryDate] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [allergenInput, setAllergenInput] = useState("");
  const [allergenTag, setAllergenTag] = useState([]);
  const [isAddingCategory, setIsAddingCategory] = useState(false);

  const handleDeleteCategory = async (categoryId) => {
    const user = ls.get("user");
    const token = ls.get("authToken");
    const posId = user?.posId;

    if (!token || !posId) {
      onClose();
      return;
    }

    try {
      await axios.delete(
        `${process.env.REACT_APP_BACKEND_URL}/v1/api/inventory/delete-category`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          data: { categoryId, posId },
        }
      );

      await fetchCategories(token);

      alert(`Category has been successfully deleted.`);
    } catch (error) {
      console.error("Error deleting category:", error);
      alert("Failed to delete category. Please try again.");
    }
  };

  const handleAdd = () => {
    let finalPrice = price;

    if (priceType === "Per Piece" && amountOrdered) {
      finalPrice = price * amountOrdered;
    }

    const newItem = {
      name,
      categoryId: category,
      amountOrdered,
      price: finalPrice,
      deliveryDate,
      expiryDate,
      unitOfMeasurement,
      optionalMeasurements,
      allergenTag,
    };

    onAdd(newItem);

    Swal.fire({
      title: "Added!",
      text: `${name} has been successfully added to the inventory.`,
      icon: "success",
      confirmButtonText: "OK",
    });
    resetFields();
    onClose();
  };

  const resetFields = () => {
    setName("");
    setCategory("");
    setAmountOrdered("");
    setUnitOfMeasurement("");
    setPrice("");
    setDeliveryDate("");
    setExpiryDate("");
  };

  const addOptionalMeasurement = () => {
    setOptionalMeasurements((prev) => [...prev, { name: "", value: "" }]);
  };

  const handleOptionalMeasurementChange = (index, field, newValue) => {
    setOptionalMeasurements((prev) =>
      prev.map((measurement, i) =>
        i === index ? { ...measurement, [field]: newValue } : measurement
      )
    );
  };

  const removeOptionalMeasurement = (index) => {
    setOptionalMeasurements((prev) => prev.filter((_, i) => i !== index));
  };

  const handleAddAllergen = () => {
    const trimmed = allergenInput.trim();
    if (trimmed) {
      const normalized = trimmed.charAt(0).toUpperCase() + trimmed.slice(1);
      if (
        !allergenTag.some((a) => a.toLowerCase() === normalized.toLowerCase())
      ) {
        setAllergenTag([...allergenTag, normalized]);
      }
    }
    setAllergenInput("");
  };

  const handleDeleteAllergen = (index) => {
    setAllergenTag(allergenTag.filter((_, i) => i !== index));
  };

  const isDuplicateName = existingInventoryNames?.some(
    (existingName) => existingName.toLowerCase() === name.trim().toLowerCase()
  );
  const isFormValid =
    name &&
    category &&
    amountOrdered &&
    unitOfMeasurement &&
    price &&
    deliveryDate &&
    expiryDate &&
    !isDuplicateName;

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>Add Inventory</DialogTitle>
      <DialogContent>
        <TextField
          margin="normal"
          label="Name"
          fullWidth
          value={name}
          onChange={(e) => setName(e.target.value)}
          error={existingInventoryNames?.some(
            (existingName) =>
              existingName.toLowerCase() === name.trim().toLowerCase()
          )}
          helperText={
            existingInventoryNames?.some(
              (existingName) =>
                existingName.toLowerCase() === name.trim().toLowerCase()
            )
              ? "Name already exists"
              : ""
          }
        />
        <Autocomplete
          options={[
            ...categories,
            { categoryId: "add-new", categoryName: "Add New Category" },
          ]}
          getOptionLabel={(option) => option.categoryName || ""}
          value={categories.find((cat) => cat.categoryId === category) || null}
          onChange={(event, newValue) => {
            if (newValue && newValue.categoryId === "add-new") {
              setIsAddingCategory(true);
            } else {
              setCategory(newValue ? newValue.categoryId : "");
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Category"
              variant="outlined"
              margin="normal"
            />
          )}
          renderOption={(props, option) => (
            <li {...props} key={option.categoryId}>
              <span style={{ flexGrow: 1 }}>{option.categoryName}</span>
              {option.categoryId !== "add-new" && (
                <Tooltip title="Delete category">
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDeleteCategory(option.categoryId);
                    }}
                    color="error"
                    size="small"
                  >
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              )}
            </li>
          )}
        />

        {isAddingCategory && (
          <AddCategoryDialog
            open={isAddingCategory}
            onClose={() => setIsAddingCategory(false)}
            onCategoryAdded={(newCategory) => {
              setCategories((prevCategories) => [
                ...prevCategories,
                newCategory,
              ]);
              setIsAddingCategory(false);
            }}
          />
        )}

        <TextField
          margin="normal"
          label="Items Ordered"
          type="number"
          fullWidth
          value={amountOrdered}
          onChange={(e) => setAmountOrdered(e.target.value)}
        />

        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <TextField
            margin="normal"
            label="Primary Unit of Measurement"
            fullWidth
            value={unitOfMeasurement}
            onChange={(e) => setUnitOfMeasurement(e.target.value)}
            helperText="Lowest Measurement for this Inventory"
          />
          <Button variant="outlined" onClick={addOptionalMeasurement}>
            +UOM
          </Button>
        </Box>

        {optionalMeasurements.map((measurement, index) => (
          <Grid container spacing={2} key={index} sx={{ marginBottom: 1 }}>
            <Grid item xs={5}>
              <TextField
                label="Optional Unit of Measurement"
                fullWidth
                value={measurement.name}
                onChange={(e) =>
                  handleOptionalMeasurementChange(index, "name", e.target.value)
                }
              />
            </Grid>
            <Grid item xs={5}>
              <TextField
                label="Value"
                type="number"
                fullWidth
                value={measurement.value}
                onChange={(e) =>
                  handleOptionalMeasurementChange(
                    index,
                    "value",
                    e.target.value
                  )
                }
                helperText={
                  measurement.name && measurement.value
                    ? `${measurement.name} = ${measurement.value} ${unitOfMeasurement}`
                    : ""
                }
              />
            </Grid>
            <Grid
              item
              xs={2}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <IconButton
                color="error"
                onClick={() => removeOptionalMeasurement(index)}
              >
                <DeleteIcon />
              </IconButton>
            </Grid>
          </Grid>
        ))}

        <FormControl fullWidth margin="normal">
          <InputLabel id="price-type-label">Price Type</InputLabel>
          <Select
            labelId="price-type-label"
            value={priceType}
            onChange={(e) => setPriceType(e.target.value)}
            displayEmpty
          >
            <MenuItem value="Total">Total</MenuItem>
            <MenuItem value="Per Piece">Per Piece</MenuItem>
          </Select>
        </FormControl>

        <TextField
          margin="normal"
          label={
            priceType === "Per Piece"
              ? "Purchase Price per Unit"
              : "Total Purchase Price"
          }
          type="number"
          fullWidth
          value={price}
          onChange={(e) => setPrice(e.target.value)}
        />

        <TextField
          margin="normal"
          label="Delivery Date"
          type="date"
          fullWidth
          InputLabelProps={{ shrink: true }}
          value={deliveryDate}
          onChange={(e) => setDeliveryDate(e.target.value)}
        />

        <TextField
          margin="normal"
          label="Expiry Date"
          type="date"
          fullWidth
          InputLabelProps={{ shrink: true }}
          value={expiryDate}
          onChange={(e) => setExpiryDate(e.target.value)}
          inputProps={{
            min: deliveryDate || new Date().toISOString().split("T")[0],
          }}
        />

        <TextField
          margin="normal"
          label="Allergen"
          fullWidth
          value={allergenInput}
          onChange={(e) => setAllergenInput(e.target.value)}
          helperText={
            allergenInput.trim() &&
            allergenTag.some(
              (a) => a.toLowerCase() === allergenInput.trim().toLowerCase()
            )
              ? "Allergen already exists"
              : "Type an allergen and click 'Add Allergen'"
          }
        />

        <Button
          variant="outlined"
          onClick={handleAddAllergen}
          sx={{ marginTop: 1 }}
          disabled={
            !allergenInput.trim() ||
            allergenTag.some(
              (a) => a.toLowerCase() === allergenInput.trim().toLowerCase()
            )
          }
        >
          Add Allergen
        </Button>

        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1, marginTop: 2 }}>
          {allergenTag.map((allergen, index) => (
            <Chip
              key={index}
              label={allergen}
              onDelete={() => handleDeleteAllergen(index)}
              color="primary"
              variant="outlined"
              sx={{ textTransform: "none" }}
            />
          ))}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            resetFields();
            onClose();
          }}
          color="secondary"
        >
          Cancel
        </Button>
        <Button onClick={handleAdd} color="primary" disabled={!isFormValid}>
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddInventoryDialog;
